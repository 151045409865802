import { Card, IconFolder, IconWarning, ListItem } from '@veneer/core'
import {
  Checkbox,
  Button,
  colors,
  IconTrash,
  IconEllipsis
} from '@clientos/ui-toolkit'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

type CustomIconProps = {
  usePadding: boolean
}

export const SaveOptionsContainer = styled.div`
  grid-area: 'container';
  overflow: auto;
`

export const CustomCheckbox = styled(Checkbox)`
  margin-right: 10px;
  padding-bottom: 8px;
  display: grid !important;
`

export const CustomIcon = styled.img<CustomIconProps>`
  ${(props) => props.usePadding && 'padding-bottom: 10px;'}
`

export const StyledContextualMenuButton = styled(Button)`
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
`

export const StyledIconEllipsis = styled(IconEllipsis)`
  fill: ${colors.inkPrimary} !important;
  transform: rotate(90deg);
  stroke: ${colors.inkPrimary} !important;
  stroke-width: 2px !important;
`

export const CheckboxContainer = styled.div`
  display: flex !important;
  flex-direction: row !important;
`

export const SaveTitle = styled.div`
  margin: 25px 0;
  color: ${colors.inkPrimary};
`

export const CenterContainer = styled.div`
  display: inline-grid;
  white-space: pre-wrap;
  margin-left: 10px;
`

export const ActionsButtonContainer = styled.div`
  display: flex;
  gap: 15px;

  .clientos-ui-toolkit-button {
    &.clientos-ui-toolkit-button__icon-button {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .clientos-ui-toolkit-radio-button.clientos-ui-toolkit-radio-button {
    &.no-label .vn-radio-button__icon {
      margin-bottom: 0;
    }
  }
`

export const StyledAuthorizedListContainer = styled.div`
  border-radius: 12px;
`

export const StyledCardMobile = styled(Card)`
  padding: ${tokens.layout.size3} ${tokens.layout.size2} 0px
    ${tokens.layout.size2} !important;

  &:last-child {
    padding-bottom: ${tokens.layout.size3} !important;
  }
`

export const MobileAuthorizedListItem = styled(ListItem)`
  display: flex !important;
  flex-direction: row !important;
  border: 1px solid #e7e7e7 !important;
  padding: ${tokens.layout.size2} ${tokens.layout.size3} !important;
  div {
    &:nth-child(2) {
      padding: 0px !important;
      width: 60%;
    }
  }
  &:nth-child(even) {
    background-color: ${tokens.color.gray1};
  }

  p {
    font-family: ${tokens.typography.family0};
    font-size: 14px;
    padding: 4px 0 4px 0;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
  }
`
export const MobileUnauthorizedListItem = styled(ListItem)`
  display: flex !important;
  flex-direction: row !important;
  padding: ${tokens.layout.size2} ${tokens.layout.size3} !important;
  div {
    &:nth-child(2) {
      padding: 0px !important;
    }
  }
`

export const IntroContainer = styled.div`
  display: grid;
  background-color: white;
  padding: 10px 0px 20px 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-items: center;
`

export const IntroText = styled.h6`
  text-align: center;
  margin: 0px 60px 0px 60px;
`

export const DesktopContainer = styled.div`
  grid-area: 'container';
  overflow: auto;

  svg {
    display: block;
  }
`

export const DesktopAuthorizedListContainer = styled.div`
  background-color: ${colors.royalBlue0} !important;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 15px;
`

export const DesktopGrid = styled.div`
  .clientos-ui-toolkit-generic-card__content__body {
    &.clientos-ui-toolkit-generic-card__content__body__padding-horizontal {
      padding: 0px 22px 0 22px !important;
    }
  }
`

export const AccountProviderContainer = styled.div`
  .clientos-ui-toolkit-list-container__item {
    padding-left: 19px !important;
    padding-right: 22px !important;
  }
`

export const FoldersListContainer = styled.div`
  .clientos-ui-toolkit-list-container__divider {
    margin: 0px 12px 0 12px !important;
  }
`

export const ProgressIndicatorContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
`

export const StyledIconFolder = styled(IconFolder)``

export const StyledIconWarning = styled(IconWarning)``

export const StyledIconTrash = styled(IconTrash)<{ disabled?: boolean }>`
  ${(props) =>
    props.disabled &&
    `
      fill: ${tokens.color.gray5} !important;
  `}
`
