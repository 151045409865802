import React, { SyntheticEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { i18n } from '../../assets/locale'
import FileTypeForm from '../../components/FileTypeForm'
import Header from '../../components/Header'
import { useAnimationContext } from '../../contexts/animations/AnimationContext'
import { useSettingsContext } from '../../contexts/settings/SettingsContext'
import { useUserContext } from '../../contexts/users/UserContext'
import { ocrFileTypes } from '../../data/enum/ocrFileTypes'
import { PageProps } from '../../data/schemas/app'
import { LocationState } from '../../data/schemas/location'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import { ContentContainer, MainContainer } from './styles'

export const FileType = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()
  const { setAnimationDirection } = useAnimationContext()
  const { settingsState, settingsActions } = useSettingsContext()
  const { userContextState } = useUserContext()

  const onBackButtonPress = () => {
    setAnimationDirection('left')
    history.push(`${base}/creation_settings`)
  }

  const onClickFileType = (_e: SyntheticEvent, value: string) => {
    settingsActions.updateFileType(value)
  }

  useNativeBackButton(onBackButtonPress)

  return (
    <>
      <MainContainer id="main-container-filetype">
        <Header
          title={i18n.t('pages.FileType.header')}
          backButtonLabel={'back'}
          onPressIconLeft={onBackButtonPress}
        />
        <ContentContainer id="content-container-filetype">
          <FileTypeForm
            selectedItem={settingsState.fileType}
            image={i18n.t('pages.FileType.image')}
            pdf={i18n.t('pages.FileType.basicPdf')}
            searchablePdf={i18n.t('pages.FileType.searchablePdf')}
            searchableDescription={i18n.t('pages.FileType.pdfDescription')}
            wordDoc={i18n.t('pages.FileType.wordDoc')}
            wordDescription={i18n.t('pages.FileType.wordDescription')}
            plainText={i18n.t('pages.FileType.plainText')}
            textDescription={i18n.t('pages.FileType.textDescription')}
            desktopMode={props.desktopMode}
            entitled={
              userContextState.isEntitled ||
              ocrFileTypes.includes(settingsState.fileType)
            }
            onClickFileType={onClickFileType}
          />
        </ContentContainer>
      </MainContainer>
    </>
  )
}
