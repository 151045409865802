import { Modal } from '@clientos/ui-toolkit'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.vn-modal--dialog {
    width: 80%;
    border-radius: 20px;
    display: flex;
    justify-content: center;

    button {
      display: flex;
      justify-content: center;
    }

    p {
      font-size: 16px;
      line-height: 20px !important;
      color: #212121;
      text-align: center;
    }

    p:nth-child(1) {
      padding-bottom: 12px !important;
    }

    @media (min-width: 700px) {
      button {
        width: 210px;
        margin-right: 0px;
      }
    }
  }

  div.vn-modal--content {
    @media (max-width: 700px) {
      padding: 20px;
    }
  }
`
