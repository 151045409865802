import styled from 'styled-components'
import { breakpoints } from '@clientos/ui-toolkit'

export const MainContainer = styled.div`
  margin-bottom: 20px;
  .clientos-ui-toolkit-list-container__divider {
    margin-left: 30px !important;
    @media screen and (max-width: ${breakpoints.md}) {
      margin-left: 24px !important;
    }
  }
`

export const Divider = styled.div`
  margin-left: 30px !important;
  height: 1px;
  background-color: #edeefe;
`
