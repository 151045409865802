import { JarvisWebHttpClient } from '@jarvis/web-http'
import { authProvider } from '../../auth'
import Config from '../../config'

const client: JarvisWebHttpClient = new JarvisWebHttpClient({
  defaultBaseURL: Config.TENANTS_URL,
  defaultAuthProvider: authProvider,
  defaultRetries: 0
})

export const getTenantById = async (tenantId: string) => {
  const response = await client.get({
    url: `/tenants/${tenantId}`,
    timeout: 30000
  })
  return response.data
}
