/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { InlineAlert } from '@clientos/ui-toolkit'
import React from 'react'

type bannerProps = {
  showBanner: boolean
  onClick: () => void
  deviceQt: number
}

const NotificationBanner = ({ showBanner, onClick, deviceQt }: bannerProps) => {
  if (!showBanner) return null
  // TODO: when new batch translation comes, replace per i18n.t('application.NotificationBanner.titleHPX')
  const title: string = 'Your cloud connection is expired or will expire soon'
  // TODO: when new batch translation comes, replace per i18n.t('application.NotificationBanner.multiplePrinters-contentHPX')
  // TODO: when new batch translation comes, replace per i18n.t('application.NotificationBanner.singlePrinter-contentHPX')
  const description: string =
    deviceQt > 1
      ? 'Reset or extend your cloud connections to continue accessing your scanned files.'
      : 'Reset or extend your cloud connection to continue accessing your scanned files.'
  // TODO: when new batch translation comes, replace per i18n.t('application.NotificationBanner.labelHPX')
  const label: string = 'Manage'
  return (
    <div
      data-testid="notification-expiry-banner"
      className="notification-expiry-banner"
    >
      <InlineAlert
        id="notification-expiry-banner"
        action={{
          action: onClick,
          label: label
        }}
        type="WARN"
        title={title}
        description={description}
      />
    </div>
  )
}

export default NotificationBanner
