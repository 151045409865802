import { Button } from '@clientos/ui-toolkit'
import React, { Ref } from 'react'
import Footer from '../Footer'
import { MainContainer } from './styles'

type ButtonProps = {
  appearance?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'negative'
    | 'ghost'
    | 'ghost-flush'
  text: string
  disabled?: boolean
  loading?: boolean
  onClick?: { (): void }
  id: string
}

const Content = (buttons: ButtonProps[]) => (
  <MainContainer>
    {buttons?.map((button, index) => (
      <Button
        id={button.id}
        key={index}
        type={button.appearance}
        size="medium"
        disabled={Boolean(button.disabled)}
        onClick={button.onClick}
      >
        {button.text}
      </Button>
    ))}
  </MainContainer>
)

type CustomFooterProps = {
  leftButtons?: ButtonProps[]
  buttons: ButtonProps[]
  desktopMode: boolean
}

const CustomFooter = React.forwardRef(
  (props: CustomFooterProps, ref: Ref<HTMLElement>) => (
    <Footer
      content={Content(props.buttons)}
      leftContent={Content(props.leftButtons)}
      ref={ref}
      desktopMode={props.desktopMode}
    />
  )
)

CustomFooter.displayName = 'CustomFooter'

export default CustomFooter
