import React, { useEffect, useState } from 'react'
import Images from '../../assets/images/index'
import { i18n } from '../../assets/locale'
import { getShortcuts } from '../../clients/shortcuts'
import Loader from '../../components/Loader'
import { colors } from '../../data/enum/colors'
import { PageProps } from '../../data/schemas/app'
import {
  PrintConfig,
  RepositoryConfig,
  ShortcutItem,
  SmartTask
} from '../../data/schemas/shortcut'
import { resolveFolderLabel } from '../../modules/common/helpers'
import { resolveLabel } from '../../modules/destinations/helpers'
import {
  handleFlyoutFolderNames,
  isEmailType,
  isPrintType,
  isSaveType,
  resolveProviderIcon
} from '../../modules/shortcuts/helpers'
import { sendUiEvent, uiData } from '../../plugins/jweb/DataCollection'
import {
  ContainerButton,
  ContainerGenericCard,
  DescriptionContainer,
  DescriptionItem,
  ErrorContainer,
  ErrorMessage,
  MainContainerHPX,
  ShortcutsMessage
} from './styles'
import {
  Button,
  CustomIcon,
  CustomImage,
  GenericCard,
  IconEnvelope,
  IconInfo,
  IconPrinter,
  IconSmartTasks,
  ListContainer
} from '@clientos/ui-toolkit'
import { dispatchEvent } from 'src/plugins/jweb/EventService'
import { Tooltip } from '@veneer/core'
import MobileScroll from 'src/components/MobileScroll'

const addCurrentShortcutIntoContext = async (shortcut: ShortcutItem) => {
  if (shortcut.smartTask.smartTaskConfig.repositories) {
    const repositories = await handleFlyoutFolderNames(
      shortcut?.smartTask?.smartTaskConfig?.repositories
    )
    shortcut.smartTask.smartTaskConfig.repositories = repositories
  }

  return shortcut
}

export const Flyout = ({ props }: PageProps) => {
  const [loadingShortcuts, setShortcutsLoading] = useState(false)
  const [shortcuts, setShortcuts] = useState<ShortcutItem[]>([])
  const [fetchShortcutsFailed, setFetchShortcutsFailed] = useState(false)
  const [shortcutsChecked, setShortcutsChecked] = useState(false)
  const [openTooltipId, setOpenTooltipId] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setShortcutsLoading(true)
        const shortcuts: ShortcutItem[] = await getShortcuts()
        await Promise.all(
          shortcuts?.map((shortcut: ShortcutItem) => {
            return addCurrentShortcutIntoContext(shortcut)
          })
        )

        sendUiEvent(
          shortcuts.length === 0
            ? uiData.noShortcutsOnFlyout
            : uiData.listOfShortcutOnFlyout
        )

        setShortcuts(shortcuts)
        setShortcutsChecked(true)
        setShortcutsLoading(false)
      } catch (e) {
        setShortcutsLoading(false)
        setFetchShortcutsFailed(true)
      }
    }

    fetchData()
  }, [])

  const NoShortcuts = () => (
    <>
      <MainContainerHPX>
        <GenericCard id="no-shortcuts">
          <>
            <CustomIcon
              size={props.desktopMode ? 72 : 56}
              iconPath={Images.noShortcuts}
            />
            <GenericCard.Header
              title={i18n.t('pages.ListShortcuts.noShortcutsTitle')}
              borderBottom={false}
            />
            <GenericCard.Body
              paddingBodyBottom={props.desktopMode ? false : true}
              paddingBodyTop={false}
              paddingBodyHorizontal={true}
            >
              <ShortcutsMessage id={'no-shortcuts-message-flyout'}>
                {/*TODO: Replace by  {i18n.t('pages.Flyout.noShortcutMessageHPX')} */}
                Go to the Shortcuts section of this app and create one-touch
                shortcuts to speed up your repetitive tasks.
              </ShortcutsMessage>
            </GenericCard.Body>
          </>
        </GenericCard>
      </MainContainerHPX>
    </>
  )

  const FailedToFetch = () => (
    <ErrorContainer id="error-container-flyout">
      <h3>{i18n.t('pages.AccessShortcuts.deviceFetchErrorTitle')}</h3>
      <ErrorMessage id="error-message-flyout">
        {i18n.t('pages.Flyout.fetchShortcutsErrorMessage')}
      </ErrorMessage>
    </ErrorContainer>
  )

  const TooltipData = (smartTask: SmartTask) => (
    <DescriptionContainer>
      {smartTask.smartTaskConfig.prints && (
        <DescriptionItem>
          {i18n.t('pages.Destinations.printTitle')}:{' '}
          {smartTask.smartTaskConfig.prints.map((item: PrintConfig) => {
            return `${item.numberOfCopies} ${i18n.t('pages.AddPrint.copies')},
              ${
                item.color === 'true'
                  ? i18n.t('pages.AddPrint.color')
                  : i18n.t('pages.AddPrint.grayscale')
              },
              ${i18n.t('pages.AddPrint.two-sided')}: ${resolveLabel(
                item.printDuplex
              )}`
          })}
        </DescriptionItem>
      )}
      {smartTask.smartTaskConfig.email && (
        <DescriptionItem>
          {i18n.t('pages.Destinations.emailTitle')}:{' '}
          {smartTask.smartTaskConfig.email.tos.map(
            (item: string, index: number) => {
              return `${(index ? ', ' : '') + item}`
            }
          )}
        </DescriptionItem>
      )}
      {smartTask.smartTaskConfig.repositories?.length && (
        <DescriptionItem>
          {i18n.t('pages.Destinations.saveTitle')}:{' '}
          {smartTask.smartTaskConfig.repositories?.map(
            (item: RepositoryConfig) => {
              return item.folderList?.length
                ? `${item.type} / ${item.folderList?.map((folder) =>
                    resolveFolderLabel(
                      folder.folderId,
                      item.type,
                      folder.folderName
                    )
                  )} \n`
                : `${item.type} / HP Smart \n`
            }
          )}
        </DescriptionItem>
      )}
    </DescriptionContainer>
  )

  const handleCancelButton = () => {
    dispatchEvent('com.hp.jarvis.servicerouting.serviceinstance.closed')
    dispatchEvent('Close')
  }

  const resolveShortcutIcon = (shortcut: ShortcutItem) => {
    const smartTaskConfig = shortcut.smartTask?.smartTaskConfig
    if (isEmailType(smartTaskConfig)) {
      return (
        <IconEnvelope
          filled={true}
          size={36}
          className="rounded-icon"
          customStyle={{
            backgroundColor: shortcut.smartTask.presentation?.color ?? colors[0]
          }}
        />
      )
    } else if (isPrintType(smartTaskConfig)) {
      return (
        <IconPrinter
          filled={true}
          className="rounded-icon"
          customStyle={{
            backgroundColor: shortcut.smartTask.presentation?.color ?? colors[0]
          }}
        />
      )
    } else if (isSaveType(smartTaskConfig)) {
      const iconPath = resolveProviderIcon(smartTaskConfig.repositories)
      if (iconPath) {
        return (
          <CustomImage
            className="rounded-icon"
            src={iconPath}
            customStyle={{
              backgroundColor:
                shortcut.smartTask.presentation?.color ?? colors[0]
            }}
          />
        )
      }
    }

    return (
      <IconSmartTasks
        filled={true}
        className="rounded-icon"
        customStyle={{
          backgroundColor: shortcut.smartTask.presentation?.color ?? colors[0]
        }}
      />
    )
  }

  return (
    <ContainerGenericCard desktopMode={props.desktopMode}>
      <GenericCard
        id={props.desktopMode ? 'generic-card-desktop' : 'generic-card-mobile'}
      >
        {loadingShortcuts ? (
          <Loader />
        ) : (
          <>
            {props.desktopMode &&
              !fetchShortcutsFailed &&
              shortcuts.length > 0 && (
                <>
                  <GenericCard.Header
                    title={i18n.t('pages.ListShortcuts.header')}
                    borderBottom={false}
                  />
                  <GenericCard.Body
                    paddingBodyBottom={false}
                    paddingBodyTop={false}
                    paddingBodyHorizontal={true}
                  >
                    <ShortcutsMessage id="shortcuts-message-content">
                      {i18n.t('pages.Flyout.shortcutsMessage')}
                    </ShortcutsMessage>
                  </GenericCard.Body>
                </>
              )}

            <GenericCard.Body
              paddingBodyBottom={props.desktopMode ? true : false}
              paddingBodyTop={false}
              paddingBodyHorizontal={true}
            >
              <MobileScroll offset={props.desktopMode ? '300px' : '70px'}>
                <ListContainer
                  items={shortcuts.map((shortcut) => ({
                    id: props.desktopMode
                      ? 'shortcut-items-desktop'
                      : 'shortcut-items-mobile',
                    start: resolveShortcutIcon(shortcut),
                    label: i18n.t('pages.Flyout.startShortcut', {
                      shortcutName: shortcut.smartTask.jobName
                    }),
                    // For iPad, tooltip should follow mobile behavior for best experience
                    end:
                      props.desktopMode &&
                      !props.deviceModel?.includes('ipad') ? (
                        <Tooltip
                          content={TooltipData(shortcut.smartTask)}
                          placement="leading"
                        >
                          <div className="tooltip-container">
                            <IconInfo
                              id="open-tooltip"
                              className="icon"
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          content={TooltipData(shortcut.smartTask)}
                          placement="leading"
                          open={openTooltipId === shortcut.id}
                        >
                          <div className="tooltip-container">
                            <Button
                              type="ghost-flush"
                              onClick={(event) => {
                                event.stopPropagation()
                                setOpenTooltipId(
                                  openTooltipId === shortcut.id
                                    ? null
                                    : shortcut.id
                                )
                              }}
                              icon={<IconInfo size={24} />}
                            />
                          </div>
                        </Tooltip>
                      ),
                    alignStart: 'center',
                    alignEnd: 'center',
                    paddingStart: false,
                    paddingEnd: false,
                    onClick: () => {
                      sendUiEvent({
                        ...uiData.executeShortcutFromFlyout,
                        controlDetail: `${shortcut.title
                          ?.replace(/ /g, '')
                          .toLocaleLowerCase()}TileClicked`
                      })
                      dispatchEvent('ExecuteShortcut', {
                        shortcutDefinition: shortcut
                      })
                    }
                  }))}
                />
              </MobileScroll>
              {props.desktopMode && shortcuts.length !== 0 && (
                <ContainerButton>
                  <Button
                    expanded
                    onClick={handleCancelButton}
                    type="secondary"
                    size="medium"
                  >
                    {i18n.t('common.contextAction.cancel')}
                  </Button>
                </ContainerButton>
              )}
              {shortcuts.length === 0 && shortcutsChecked && <NoShortcuts />}
              {fetchShortcutsFailed && <FailedToFetch />}
            </GenericCard.Body>
          </>
        )}
      </GenericCard>
    </ContainerGenericCard>
  )
}
