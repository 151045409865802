import React from 'react'
import CopyToClipboard from '../CopyToClipboard'
import IconButton from '../../IconButton'
import SpellCheck from '../SpellCheckIcon'
import { ChildBottomProps } from '..'
import useAssetsProvider from '../../../hooks/useAssetsProvider'

export default function MobileBottomBar({ hasText }: ChildBottomProps) {
  const assetsProvider = useAssetsProvider()
  if (!assetsProvider) {
    console.error('Does not have assets provider')
    return null
  }
  const getText = (subkey: string) => {
    const assetKey = `application.${subkey}`
    return assetsProvider.getText(assetKey)
  }

  return (
    <div className="bottomBar-container bottomBar-container-mobile">
      <div className="actions">
        <CopyToClipboard disabled={!hasText} />
        <IconButton
          icon={<SpellCheck />}
          label={getText('bottomBar.spellCheck')}
          disabled={!hasText}
          render={false}
        />
      </div>
    </div>
  )
}
