import styled from 'styled-components'

export const Container = styled.div<{ desktopMode: boolean }>`
  overflow: auto;
  grid-area: 'container';
  padding: 10px;
  padding-bottom: 20px;
  ${(props) => props.desktopMode && 'padding: 20px 3%;'}
`

export const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'container'
    'footer';
`

export const StyledEndContent = styled.div`
  display: flex;
  gap: 15px;

  .clientos-ui-toolkit-button__icon-button > span > svg {
    color: #4759f5 !important;
    fill: #4759f5 !important;
  }
`

export const NoShortcutsContainer = styled.div`
  padding-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-area: 'container';
`

export const NoShortcutsTitle = styled.h3`
  margin-top: 70px;
  font-size: 18px;
`

export const NoShortcutsText = styled.p`
  text-align: center;
  margin: 10px 30px 0 30px;
  font-size: 16px;
  line-height: 20px;
`

export const ShortcutsContainer = styled.div<{ desktopMode: boolean }>`
  ${(props) =>
    props.desktopMode &&
    `
      display: flex;
      flex-wrap: wrap;
  `}
`

export const MainListContainer = styled.div`
  .clientos-ui-toolkit-list-container {
    > .clientos-ui-toolkit-list-container__item {
      padding: 24px 12px;
    }

    > .clientos-ui-toolkit-list-container__item.hoverable.disabled {
      > .clientos-ui-toolkit-list-container__start-icon svg {
        background-color: #edeefe !important;
        -webkit-filter: none !important;
        filter: none !important;
      }
    }
  }
  .clientos-ui-toolkit-list-container__start-content {
    width: 0;
  }
  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__label {
    white-space: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-word;
  }
`

export const DoneButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);

  .clientos-ui-toolkit-button {
    &.clientos-ui-toolkit-button__secondary {
      background-color: white;
    }
  }
`
