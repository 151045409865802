import React from 'react'
import useAssetsProvider from '../../../hooks/useAssetsProvider'
import { ChildHeadProps } from '..'
import { Button, IconArrowLeft } from '@clientos/ui-toolkit'

const DesktopHeader = ({ handleCancel }: ChildHeadProps) => {
  const assetsProvider = useAssetsProvider()
  if (!assetsProvider) {
    console.error('Does not have assets provider')
    return null
  }
  // TODO: Uncomment when batch translation is done
  // const getText = (subkey: string) => {
  //   const assetKey = `application.${subkey}`
  //   return assetsProvider.getText(assetKey)
  // }
  return (
    <div className={'header header-desktop'}>
      <Button
        id="cancelButton"
        type="tertiary"
        onClick={handleCancel}
        icon={<IconArrowLeft />}
        size="medium"
      >
        {/* TODO: Replace when batch translation is done: {getText('header.preview')} */}
        Preview
      </Button>
    </div>
  )
}

export default DesktopHeader
