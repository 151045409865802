import { createSlice } from '@reduxjs/toolkit'

export interface EventDataType {
  refreshJobsEvent: boolean
  refreshSessionEvent: boolean
  reloadJobList: boolean
  interactNotificationBanner: boolean
  showExpirationBanner: boolean
  devicesSessionLoaded: boolean
  anySessionCreated: boolean
}

export const initialEventState: EventDataType = {
  refreshJobsEvent: false,
  refreshSessionEvent: false,
  reloadJobList: true,
  interactNotificationBanner: false,
  showExpirationBanner: false,
  devicesSessionLoaded: false,
  anySessionCreated: false
}

export const eventSlice = createSlice({
  name: 'event',
  initialState: initialEventState,
  reducers: {
    updateRefreshJobsEvent: (state, action) => {
      state.refreshJobsEvent = action.payload
    },
    updateRefreshSessionEvent: (state, action) => {
      state.refreshSessionEvent = action.payload
    },
    updateReloadJobList: (state, action) => {
      state.reloadJobList = action.payload
    },
    updateInteractNotificationBanner: (state, action) => {
      state.interactNotificationBanner = action.payload
    },
    updateShowExpirationBanner: (state, action) => {
      state.showExpirationBanner = action.payload
    },
    updateDevicesSessionLoaded: (state, action) => {
      state.devicesSessionLoaded = action.payload
    },
    updateAnySessionCreated: (state, action) => {
      state.anySessionCreated = action.payload
    }
  }
})

export const {
  updateRefreshJobsEvent,
  updateRefreshSessionEvent,
  updateReloadJobList,
  updateInteractNotificationBanner,
  updateShowExpirationBanner,
  updateDevicesSessionLoaded,
  updateAnySessionCreated
} = eventSlice.actions

export default eventSlice.reducer
