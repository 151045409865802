import styled from 'styled-components'

export const Container = styled.div<{ height?: string }>`
  ${(props) => props.height && `height: ${props.height};`}

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default Container
