import { DestinationsConfig } from '../../data/schemas/destinations'

export type DestinationsState = {
  currentState: DestinationsConfig
  checkpointState: DestinationsConfig
}

export const initialState: DestinationsState = {
  currentState: {
    email: {
      tos: [],
      subject: '',
      message: '',
      set: false
    },
    print: {
      numberOfCopies: 1,
      color: 'true',
      printDuplex: 'one-sided',
      set: false
    },
    repositories: {
      selected: [],
      set: false
    }
  },
  checkpointState: {
    email: {
      tos: [],
      subject: '',
      message: '',
      set: false
    },
    print: {
      numberOfCopies: 1,
      color: 'true',
      printDuplex: 'one-sided',
      set: false
    },
    repositories: {
      selected: [],
      set: false
    }
  }
}

export const destinationsReducer = (
  state: DestinationsState,
  action: { type: string; payload?: any }
): DestinationsState => {
  switch (action.type) {
    case 'email':
      return {
        ...state,
        currentState: {
          ...state.currentState,
          email: action.payload
        }
      }
    case 'print':
      return {
        ...state,
        currentState: {
          ...state.currentState,
          print: action.payload
        }
      }
    case 'repository':
      return {
        ...state,
        currentState: {
          ...state.currentState,
          repositories: action.payload
        }
      }
    case 'checkpoint':
      return {
        ...state,
        checkpointState: action.payload
      }
    case 'reset':
      return {
        ...initialState
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
