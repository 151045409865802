import { InlineAlert } from '@clientos/ui-toolkit'
import React from 'react'
import { i18n } from '../../assets/locale'
import { StyledDiv } from './styles'

type InlineAlertProps = {
  accessStatus: string
  daysRemaining: number
  printerName: string
  onClick: { (): void }
}

const AccessBanner = (props: InlineAlertProps) => {
  const notificationData = {
    noAccess: {
      // TODO: Replace by i18n.t('pages.ListShortcuts.accessNotif.offTitleHPX')
      title: 'Shortcuts access is not enabled',
      description: i18n.t('pages.ListShortcuts.accessNotif.offMessage', {
        printerName: props.printerName
      }),
      label: i18n.t('common.contextAction.enable')
    },
    expiring: {
      // TODO: Replace by i18n.t('pages.ListShortcuts.accessNotif.expiringTitleHPX')
      title: 'Shortcuts access is expiring',
      //TODO: Replace with i18n.t('pages.ListShortcuts.accessNotif.expiringMessageHPX')
      description: `Access expires on your ${props.printerName} in ${props.daysRemaining} days.`,
      label: i18n.t('common.contextAction.renew')
    },
    expired: {
      //TODO: Replace by i18n.t('pages.ListShortcuts.accessNotif.expiredTitleHPX') once translations are ready
      title: 'Shortcuts access',
      description: i18n.t('pages.ListShortcuts.accessNotif.expiredMessage', {
        printerName: props.printerName
      }),
      label: i18n.t('pages.ListShortcuts.accessNotif.label')
    }
  }[props.accessStatus]

  return (
    <StyledDiv id="inline-notification-container">
      <InlineAlert
        id="shortcuts-access-notification"
        type="WARN"
        title={notificationData?.title ?? ''}
        description={notificationData?.description ?? ''}
        action={{
          action: props.onClick,
          label: notificationData?.label ?? ''
        }}
        styleVariant="PROMINENT"
      />
    </StyledDiv>
  )
}

export default AccessBanner
