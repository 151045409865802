import React from 'react'
import { returnToPreviousService } from '../../services/ServiceRouter'
import './Header.scss'
import useAssetsProvider from '../../hooks/useAssetsProvider'
import MobileHeader from './MobileHeader/MobileHeader'
import useIsDesktop from '../../hooks/useIsDesktop'
import DesktopHeader from './DesktopHeader/DesktopHeader'
import DataCollection, { uiEvents } from '../../services/DataCollection'

export type ChildHeadProps = {
  handleCancel(): void
}

const Header = () => {
  const assetsProvider = useAssetsProvider()
  const isDesktop = useIsDesktop()
  if (!assetsProvider) {
    console.error('Does not have assets provider')
    return null
  }
  const handleAnalytics = () => {
    DataCollection.sendUIEvent(uiEvents.cancelButton)
  }
  const onClickCancel = () => {
    handleAnalytics()
    returnToPreviousService('backButton')
  }
  return isDesktop ? (
    <DesktopHeader handleCancel={onClickCancel} />
  ) : (
    <MobileHeader handleCancel={onClickCancel} />
  )
}

export default Header
