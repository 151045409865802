import locale from './locale'
interface ISplitResponse {
  language?: string
  country?: string
}
interface ILocale {
  language: string
  country: string
}
interface ICountryFallback {
  [key: string]: string
}
const DEFAULT_COUNTRY = 'US'
const DEFAULT_LANGUAGE = 'en'
const fallbackCountries: ICountryFallback = {
  bg: 'BG',
  cs: 'CZ',
  da: 'DK',
  de: 'DE',
  el: 'GR',
  en: 'US',
  es: 'ES',
  et: 'EE',
  fi: 'FI',
  fr: 'FR',
  hr: 'HR',
  hu: 'HU',
  it: 'IT',
  ja: 'JP',
  ko: 'KR',
  lt: 'LT',
  lv: 'LV',
  nb: 'NO',
  nl: 'NL',
  pl: 'PL',
  pt: 'BR',
  ro: 'RO',
  ru: 'RU',
  sk: 'SK',
  sl: 'SI',
  sv: 'SE',
  tr: 'TR',
  zh: 'CN'
}
const availableLocalesList = () => {
  return Object.keys(locale)
}
function splitLocaleIntoCountryAndLanguage(locale = ''): ISplitResponse {
  locale = locale.length > 5 ? locale.slice(0, 5) : locale
  const [, language, country] =
    /^(..)(?:[-_](..))?$/.exec(locale.toLowerCase()) || []
  return { country, language }
}
function getFallbackCountry(language: string): string {
  return fallbackCountries[language]
}
export function isLanguageSupported(language: string): boolean {
  return Boolean(getFallbackCountry(language))
}
function checkCountryLanguageAndGetFallbacks(
  jWebCountry?: string,
  jWebLanguage?: string
): ILocale {
  const language = jWebLanguage || DEFAULT_LANGUAGE
  let country = jWebCountry || DEFAULT_COUNTRY
  if (!jWebLanguage || !isLanguageSupported(jWebLanguage)) {
    return { country: DEFAULT_COUNTRY, language: DEFAULT_LANGUAGE }
  }
  if (
    (!jWebCountry ||
      availableLocalesList().indexOf(`${language}_${country.toUpperCase()}`) ===
        -1) &&
    isLanguageSupported(jWebLanguage)
  ) {
    country = getFallbackCountry(jWebLanguage)
    return { country, language }
  }
  return { country, language }
}
export function handleLocale(jWebLocaleString?: string): string {
  const { country: jWebCountry, language: jWebLanguage } =
    splitLocaleIntoCountryAndLanguage(jWebLocaleString)
  const { country, language } = checkCountryLanguageAndGetFallbacks(
    jWebCountry,
    jWebLanguage
  )
  return `${language}_${country.toUpperCase()}`
}
