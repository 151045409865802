// eslint-disable-next-line no-restricted-imports
import { DocProviderPluginError } from '@jarvis/jweb-core'
import { getDocset } from '.'

export function isDocProviderPluginError(
  value: any | DocProviderPluginError
): value is DocProviderPluginError {
  return (value as DocProviderPluginError)?.errorType !== undefined
}
const getTextFromDocSet = async () => {
  const docSet = await getDocset()
  return Promise.all(docSet.getDocs().map(async (doc) => await doc.getText()))
}
export const getTextBrokeByLine = async () => {
  const text = (await getTextFromDocSet())
    .join('\n')
    .split('\n')
    .map((text) => ({
      type: 'paragraph',
      children: [{ text }]
    }))
  return text
}
