import { Card, IconEllipsis, IconPlus } from '@veneer/core'
import { Button } from '@clientos/ui-toolkit'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

export const Title = styled.h2`
  color: #ffffff;
  text-align: left;
  padding: 23px 23px 0px 24px;
  display: -webkit-box;
  line-height: 28px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
`

export const StyledContextualMenuButton = styled(Button)<{
  desktopMode: boolean
}>`
  ${(props) =>
    props.desktopMode &&
    `
      padding: 5px !important;
      margin: 5px !important;
      border-radius: 25px !important;

      &:active, &:hover {
        background-color: rgba(0, 0, 0, 0.2) !important;
      }
  `}
`

export const StyledIconEllipsis = styled(IconEllipsis)`
  transform: rotate(90deg);
  fill: ${tokens.color.white} !important;
  stroke: ${tokens.color.white} !important;
  stroke-width: 2px !important;
`

export const Actions = styled.div`
  text-align: right;
  padding-right: 14px;
  padding-bottom: 10px;
`

export const StyledCard = styled(Card)<{ desktopMode?: boolean }>`
  && {
    background-color: ${(props) => props.color};
  }
  margin: 10px 10px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 153px;
  ${(props) =>
    props.desktopMode && 'width: 292px; margin: 5px 5px 5px; cursor:pointer;'}
`

export const StyledIconPlus = styled(IconPlus)`
  padding-bottom: 6px;
`
