import { OptionInterface, ProgressIndicator } from '@veneer/core'
import React from 'react'
import { i18n } from '../../assets/locale'
import { Connector } from '../../data/schemas/connector'
import { ConnectorFolders } from '../../data/schemas/folders'
import { resolveRepositoryType } from '../../modules/destinations/helpers'
import { StyledContextualMenu } from '../../styles/global'
import { AccountProviderIcon } from './components/AccountProviderIcon'
import ListAddAccountProvider from './components/ListAddAccountProvider'
import {
  AccountProviderContainer,
  ActionsButtonContainer,
  DesktopAuthorizedListContainer,
  DesktopContainer,
  DesktopGrid,
  FoldersListContainer,
  ProgressIndicatorContainer,
  SaveOptionsContainer,
  SaveTitle,
  StyledContextualMenuButton,
  StyledIconEllipsis,
  StyledIconTrash
} from './styles'
import {
  Button,
  RadioButton,
  IconPencil,
  IconFolder,
  IconWarning,
  GenericCard,
  ListContainer
} from '@clientos/ui-toolkit'

type SaveOptionsProps = {
  authorizedProvidersList: Connector[]
  providersList: Connector[]
  desktopMode: boolean
  onContextualMenuOptionClick: (
    providerItem: Connector,
    option: OptionInterface
  ) => void
  contextualMenuOptions: OptionInterface[]
  isChecked: { (value: Connector): boolean }
  onCheckBoxPress: (providerItem: Connector) => void
  onSignInPress: (providerItem: Connector) => void
  loadingSignInButton: boolean
  updatingAccountsLoader: boolean
  onDestinationEditClick: (providerItem: Connector, folderId?: string) => void
  onDestinationDeleteClick: (providerItem: Connector, folderId: string) => void
  destinationFolder: ConnectorFolders[]
  currentConnectorId?: string
}

const SaveOptions = (props: SaveOptionsProps) => (
  <SaveOptionsContainer id="save-options-container">
    <DesktopContainer id="save-container">
      {props.authorizedProvidersList && (
        <div>
          {props.updatingAccountsLoader ? (
            <ProgressIndicatorContainer>
              <ProgressIndicator />
            </ProgressIndicatorContainer>
          ) : (
            <DesktopGrid id="desktop-grid-save-options">
              {!props.desktopMode && (
                <>
                  <div
                    id="intro-text-add-save"
                    style={{
                      paddingBottom: '16px',
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#212121'
                    }}
                  >
                    {/* TODO: Replace when batch translation is done: title={i18n.t('pages.AddSave.introHPX')} */}
                    {
                      'Send scanned files to your cloud storage. All files are saved to the folder "HP app".'
                    }
                  </div>
                  <SaveTitle className="body-large">
                    {i18n.t('pages.AddSave.accounts')}
                  </SaveTitle>
                </>
              )}

              {props.authorizedProvidersList.map((providerItem, index) => (
                <DesktopAuthorizedListContainer
                  key={`${providerItem.type}-${index}`}
                >
                  <AccountProviderContainer>
                    <ListContainer
                      items={[
                        {
                          start: AccountProviderIcon[providerItem.type](false),
                          label: resolveRepositoryType(providerItem.type),
                          description: providerItem.connectorName,
                          end: (
                            <ActionsButtonContainer>
                              <StyledContextualMenu
                                id="contextual-menu-save-options"
                                placement="bottom-end"
                                onClick={(_, option) =>
                                  props.onContextualMenuOptionClick(
                                    providerItem,
                                    option
                                  )
                                }
                                options={props.contextualMenuOptions}
                              >
                                <StyledContextualMenuButton
                                  id={`contextual-menu-${providerItem.type}-${index}`}
                                  aria-label="ContextualMenuButton"
                                  size="large"
                                  type="ghost-flush"
                                  icon={<StyledIconEllipsis />}
                                />
                              </StyledContextualMenu>
                              <RadioButton
                                id={`checkbox-${providerItem.type}-${index}`}
                                checked={props.isChecked(providerItem)}
                                onClick={() => {
                                  props.onCheckBoxPress(providerItem)
                                }}
                              ></RadioButton>
                            </ActionsButtonContainer>
                          ),
                          alignStart: 'center',
                          alignEnd: 'center',
                          paddingStart: false,
                          paddingEnd: false
                        }
                      ]}
                    />
                  </AccountProviderContainer>
                  <FoldersListContainer>
                    <GenericCard>
                      <GenericCard.Body
                        paddingBodyBottom={false}
                        paddingBodyTop={false}
                        paddingBodyHorizontal={true}
                      >
                        <ListContainer
                          items={
                            !!props.destinationFolder.length &&
                            props.destinationFolder.some(
                              (item) =>
                                item.connectorId === providerItem.connectorId &&
                                item.folders.length
                            )
                              ? props.destinationFolder
                                  .find(
                                    (item) =>
                                      item.connectorId ===
                                      providerItem.connectorId
                                  )!
                                  .folders.map((item) => ({
                                    start:
                                      // isValid has been used to not cause issues in the previousTree.
                                      item.isValid === undefined ||
                                      item.isValid ? (
                                        <IconFolder size={24} />
                                      ) : (
                                        <IconWarning size={24} />
                                      ),
                                    label: item.id.includes('root')
                                      ? resolveRepositoryType(providerItem.type)
                                      : item.name,
                                    end: (
                                      <ActionsButtonContainer>
                                        <Button
                                          id={`trash-btn-${index}`}
                                          size="medium"
                                          type="ghost-flush"
                                          disabled={item.name === 'HP app'}
                                          onClick={() => {
                                            props.onDestinationDeleteClick(
                                              providerItem,
                                              item.id
                                            )
                                          }}
                                          icon={
                                            item.name === 'HP app' ? (
                                              <StyledIconTrash
                                                size={24}
                                                disabled
                                              />
                                            ) : (
                                              <StyledIconTrash size={24} />
                                            )
                                          }
                                          aria-label="TrashBtn"
                                        />
                                        <Button
                                          id={`pencil-btn-${index}`}
                                          size="medium"
                                          type="ghost-flush"
                                          onClick={() => {
                                            props.onDestinationEditClick(
                                              providerItem,
                                              item.id
                                            )
                                          }}
                                          icon={<IconPencil size={24} />}
                                          arial-label="PencilBtn"
                                        />
                                      </ActionsButtonContainer>
                                    ),
                                    alignStart: 'center',
                                    alignEnd: 'center',
                                    paddingStart: false,
                                    paddingEnd: false
                                  }))
                              : [
                                  {
                                    start: <IconFolder size={24} />,
                                    label: 'HP app',
                                    end: (
                                      <ActionsButtonContainer>
                                        <Button
                                          id={`pencil-btn-${index}`}
                                          size="medium"
                                          type="ghost-flush"
                                          onClick={() => {
                                            props.onDestinationEditClick(
                                              providerItem,
                                              props.currentConnectorId
                                            )
                                          }}
                                          icon={<IconPencil size={24} />}
                                          arial-label="PencilBtn"
                                        />
                                      </ActionsButtonContainer>
                                    ),
                                    alignStart: 'center',
                                    alignEnd: 'center',
                                    paddingStart: false,
                                    paddingEnd: false
                                  }
                                ]
                          }
                        ></ListContainer>
                      </GenericCard.Body>
                    </GenericCard>
                  </FoldersListContainer>
                </DesktopAuthorizedListContainer>
              ))}
            </DesktopGrid>
          )}
        </div>
      )}
      <ListAddAccountProvider
        desktopMode={props.desktopMode}
        loadingSignInButton={props.loadingSignInButton}
        onSignInPress={props.onSignInPress}
        providersList={props.providersList}
      />
    </DesktopContainer>
  </SaveOptionsContainer>
)
export default SaveOptions
