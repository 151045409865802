const configs = {
  proxy: {
    TENZING_URL: 'http://localhost:4000/tenzing',
    STRATUS_DEVICES_URL: 'http://localhost:4000',
    SHORTCUTS_MANAGER_URL: 'http://localhost:4000/manager',
    LAUNCH_DARKLY_CLIENT_ID: '5f6b5e0cd393fd0a6c84b303'
  },
  proxy_android: {
    TENZING_URL: 'http://10.0.2.2:4000/tenzing',
    STRATUS_DEVICES_URL: 'http://10.0.2.2:4000',
    SHORTCUTS_MANAGER_URL: 'http://10.0.2.2:4000/manager',
    LAUNCH_DARKLY_CLIENT_ID: '5f6b5e0cd393fd0a6c84b303'
  },
  dev: {
    TENZING_URL: 'https://jees-pie.hp8.us/tenzing',
    STRATUS_DEVICES_URL: 'https://devices.pie-us1.api.ws-hp.com',
    SHORTCUTS_MANAGER_URL: 'https://dev-us1.api.ws-hp.com/shortcuts/manager',
    LAUNCH_DARKLY_CLIENT_ID: '5f6b5dfc732fa50ba507048d'
  },
  pie: {
    TENZING_URL: 'https://jees-pie.hp8.us/tenzing',
    STRATUS_DEVICES_URL: 'https://devices.pie-us1.api.ws-hp.com',
    SHORTCUTS_MANAGER_URL: 'https://pie-us1.api.ws-hp.com/shortcuts/manager',
    LAUNCH_DARKLY_CLIENT_ID: '5f6b5e0cd393fd0a6c84b303'
  },
  stg: {
    TENZING_URL: 'https://jees-stage.hp8.us/tenzing',
    STRATUS_DEVICES_URL: 'https://devices.stage-us1.api.ws-hp.com',
    SHORTCUTS_MANAGER_URL: 'https://stage-us1.api.ws-hp.com/shortcuts/manager',
    LAUNCH_DARKLY_CLIENT_ID: '5f6b5e1d732fa50ba5070497'
  },
  pro: {
    TENZING_URL: 'https://jees.hp8.us/tenzing',
    STRATUS_DEVICES_URL: 'https://devices.us1.api.ws-hp.com',
    SHORTCUTS_MANAGER_URL: 'https://us1.api.ws-hp.com/shortcuts/manager',
    LAUNCH_DARKLY_CLIENT_ID: '5f6b5e2292e4c40ae0ad89c3'
  }
}

export const environments = {
  dev: 'dev',
  pie: 'pie',
  stage: 'stg',
  hpsmart: 'pro',
  localhost: 'proxy',
  local: 'proxy',
  10: 'proxy_android'
}

const getDomain = (url) =>
  url.replace(/(?:hpx\.|consumer\.)?/, '').split('.')[0]

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getEnvironment = (url) => {
  const domain = getDomain(url)
  const env = environments[domain]

  return env ? env : 'proxy'
}

const Config = {
  ...configs[getEnvironment(window.location.hostname)]
}

export const getLaunchDarklyClientId = () => {
  return configs[getEnvironment(window.location.hostname)]
    .LAUNCH_DARKLY_CLIENT_ID
}

export default Config
