import { List } from '@veneer/core'
import React from 'react'
import { copiesConfigOptions } from '../../data/schemas/destinations'
import { MainContainer, StyledListItem } from './styles'
import { SelectInput } from '@clientos/ui-toolkit'

type SelectCopiesProps = {
  copiesConfigOptionsList: copiesConfigOptions[]
  label: string
  numberOfCopies: number[]
  onChangeFunction: { (value: any): void }
}

type SelectOption = {
  label: string
  value: number | string
}

type SelectColorProps = {
  options: SelectOption[]
  label: string
  value: number[]
  onChangeFunction: { (value: any): void }
}

type SelectTwoSidedProps = {
  options: SelectOption[]
  label: string
  value: ('one-sided' | 'two-sided-short-edge' | 'two-sided-long-edge')[]
  onChangeFunction: { (value: any): void }
}

export const SelectCopies = (props: SelectCopiesProps) => (
  <SelectInput
    items={props.copiesConfigOptionsList}
    id="select-copies-setting"
    label={props.label}
    value={props.numberOfCopies}
    onValueChange={props.onChangeFunction}
  />
)

const SelectColor = (props: SelectColorProps) => (
  <SelectInput
    items={props.options}
    id="select-color-setting"
    label={props.label}
    value={props.value}
    onValueChange={props.onChangeFunction}
  />
)

const SelectTwoSided = (props: SelectTwoSidedProps) => (
  <SelectInput
    items={props.options}
    id="select-twosided-setting"
    label={props.label}
    value={props.value}
    onValueChange={props.onChangeFunction}
  />
)

type PrintOptionsProps = {
  copiesFieldOptions: copiesConfigOptions[]
  copiesFieldLabel: string
  numberOfCopies: number[]
  onChangeCopies: { (value: any): void }
  colorFieldOptions: SelectOption[]
  colorFieldLabel: string
  colorFieldValue: number[]
  onChangeColor: { (value: any): void }
  twoSidedFieldOptions: SelectOption[]
  twoSidedFieldLabel: string
  twoSidedFieldValue: (
    | 'one-sided'
    | 'two-sided-short-edge'
    | 'two-sided-long-edge'
  )[]
  onChangeTwoSided: { (value: any): void }
  desktopMode: boolean
}

const PrintOptions = (props: PrintOptionsProps) =>
  props.desktopMode ? (
    <MainContainer id="print-main-container">
      <SelectCopies
        copiesConfigOptionsList={props.copiesFieldOptions}
        label={props.copiesFieldLabel}
        numberOfCopies={props.numberOfCopies}
        onChangeFunction={props.onChangeCopies}
      />
      <SelectColor
        options={props.colorFieldOptions}
        label={props.colorFieldLabel}
        value={props.colorFieldValue}
        onChangeFunction={props.onChangeColor}
      />
      <SelectTwoSided
        options={props.twoSidedFieldOptions}
        label={props.twoSidedFieldLabel}
        value={props.twoSidedFieldValue}
        onChangeFunction={props.onChangeTwoSided}
      />
    </MainContainer>
  ) : (
    <List id="list-print-options">
      <StyledListItem
        leadingArea={<></>}
        centerArea={
          <SelectCopies
            copiesConfigOptionsList={props.copiesFieldOptions}
            label={props.copiesFieldLabel}
            numberOfCopies={props.numberOfCopies}
            onChangeFunction={props.onChangeCopies}
          />
        }
        trailingArea={<></>}
      />
      <StyledListItem
        leadingArea={<></>}
        centerArea={
          <SelectColor
            options={props.colorFieldOptions}
            label={props.colorFieldLabel}
            value={props.colorFieldValue}
            onChangeFunction={props.onChangeColor}
          />
        }
        trailingArea={<></>}
      />
      <StyledListItem
        leadingArea={<></>}
        centerArea={
          <SelectTwoSided
            options={props.twoSidedFieldOptions}
            label={props.twoSidedFieldLabel}
            value={props.twoSidedFieldValue}
            onChangeFunction={props.onChangeTwoSided}
          />
        }
        trailingArea={<></>}
      />
    </List>
  )

export default PrintOptions
