import { Footer } from '@clientos/ui-toolkit'
import styled from 'styled-components'

export const StyledFooter = styled(Footer)`
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  width: 100% !important;

  & div:first-child {
    padding-left: 0px;
    padding-right: 0px;
  }
`
