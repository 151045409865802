import { JarvisWebHttpClient } from '@jarvis/web-http'
import type { AxiosResponse } from 'axios'
import { authProvider } from 'src/plugins/jweb/Auth'
import Config from '../../config'
import { DeviceLists } from './Type'

const client: JarvisWebHttpClient = new JarvisWebHttpClient({
  defaultBaseURL: Config.STRATUS_DEVICES_URL,
  defaultAuthProvider: authProvider,
  defaultRetries: 0
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getDeviceByModel = async (model: string) => {
  try {
    const result: AxiosResponse = await client.get({
      url: `/devices/v1/models/${model}`,
      timeout: 30000
    })

    return result.data
  } catch (error) {
    console.error(error)
    return null
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAllDevicesFromOrg = async () => {
  try {
    const result: AxiosResponse = await client.get({
      url: `/devices/v2`,
      timeout: 30000
    })

    return result.data as DeviceLists
  } catch (error) {
    console.error(error)
    return { version: '', devices: [] } as DeviceLists
  }
}
