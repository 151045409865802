import { AccordionItemInterface } from '@veneer/core'
import { Toggle } from '@clientos/ui-toolkit'
import React, { useEffect, useState } from 'react'
import Images from '../../assets/images'
import { CenterContainer, StyledAccordion } from './styles'

type ContentProps = {
  printTitle: string
  saveTitle: string
  emailTitle: string
  printSubtitle: React.ReactElement
  emailSubtitle: React.ReactElement
  saveSubtitle: React.ReactElement
  printContent?: React.ReactNode
  saveContent?: React.ReactNode
  emailContent?: React.ReactNode
  isPrintDestinationSet: boolean
  isEmailDestinationSet: boolean
  isSaveDestinationSet: boolean
  enableEmailToggle: boolean
  enablePrinterToggle: boolean
  enableSaveToggle: boolean
  handleToggleEmail: { (): void }
  handleTogglePrinter: { (): void }
  handleToggleSave: { (): void }
}

type DestinationItemProps = {
  id: string
  ariaLabel: string
  title: string
  subtitle: React.ReactElement
  iconPath: string
  isDestinationSet: boolean
  content: React.ReactNode
  enableToggle: boolean
  handleToggle: { (): void }
  expanded: boolean
}

const createDestinationItem = (
  props: DestinationItemProps
): AccordionItemInterface => {
  return {
    content: props.content,
    header: {
      centralArea: (
        <CenterContainer id="content-toggle-container">
          <div
            id="list-item-title-destinations-toggle"
            className="vn-list-item__title body-large"
          >
            {props.title}
          </div>
          <div
            id="list-item-subtitle-destinations-toggle"
            className="vn-list-item__subtitle caption-large"
          >
            {props.subtitle}
          </div>
        </CenterContainer>
      ),
      endArea: (
        <Toggle
          id={props.ariaLabel}
          aria-label={props.ariaLabel}
          on={props.enableToggle}
          onChange={props.handleToggle}
        />
      )
    },
    id: props.id,
    expanded: props.expanded
  }
}

const createDestinationItems = (
  props: ContentProps,
  expandState: boolean[]
) => {
  return [
    createDestinationItem({
      id: 'printItem',
      ariaLabel: 'toggle-print',
      content: props.printContent,
      title: props.printTitle,
      subtitle: props.printSubtitle,
      isDestinationSet: props.isPrintDestinationSet,
      iconPath: Images.printAction,
      handleToggle: props.handleTogglePrinter,
      enableToggle: props.enablePrinterToggle,
      expanded: expandState[0]
    }),
    createDestinationItem({
      id: 'emailItem',
      ariaLabel: 'toggle-email',
      content: props.emailContent,
      title: props.emailTitle,
      subtitle: props.emailSubtitle,
      isDestinationSet: props.isEmailDestinationSet,
      iconPath: Images.emailAction,
      handleToggle: props.handleToggleEmail,
      enableToggle: props.enableEmailToggle,
      expanded: expandState[1]
    }),
    createDestinationItem({
      id: 'saveItem',
      ariaLabel: 'toggle-cloud',
      content: props.saveContent,
      title: props.saveTitle,
      subtitle: props.saveSubtitle,
      isDestinationSet: props.isSaveDestinationSet,
      iconPath: Images.uploadAction,
      handleToggle: props.handleToggleSave,
      enableToggle: props.enableSaveToggle,
      expanded: expandState[2]
    })
  ]
}

const DestinationsToggleList = (props: ContentProps) => {
  const [data, setData] = useState<AccordionItemInterface[]>(
    createDestinationItems(props, [])
  )
  const [expandState, setExpandState] = useState<boolean[]>([])

  const handleExpand = (
    e: React.UIEvent,
    index: number,
    item: AccordionItemInterface
  ) => {
    const updatedExpandState = [...expandState]
    updatedExpandState[index] = true
    setExpandState(updatedExpandState)
  }

  const handleCollapse = (
    e: React.UIEvent,
    index: number,
    item: AccordionItemInterface
  ) => {
    const updatedExpandState = [...expandState]
    updatedExpandState[index] = false
    setExpandState(updatedExpandState)
  }

  useEffect(() => {
    setData(createDestinationItems(props, expandState))
  }, [props, expandState])

  useEffect(() => {
    setExpandState([
      props.enablePrinterToggle,
      props.enableEmailToggle,
      props.enableSaveToggle
    ])
  }, [
    props.enablePrinterToggle,
    props.enableEmailToggle,
    props.enableSaveToggle
  ])

  return (
    <StyledAccordion
      id="destinations-accordion"
      onCollapse={handleCollapse}
      onExpand={handleExpand}
      items={data}
      appearance="outlined"
    />
  )
}

export default DestinationsToggleList
