// eslint-disable-next-line no-restricted-imports
import {
  SimpleUiEvent,
  WhenJWebReady,
  DataCollectionEventNames,
  CreatePublisherOptions,
  EventServicePlugin,
  CDMEventData,
  ValveControllerMetadata,
  AssetUnitType,
  EventServicePluginError,
  Publisher
} from '@jarvis/jweb-core'
import { uiEvents } from './simpleUiEvents'
import { getServiceOptions } from '../ServiceRouter'
import { ServiceOptions } from '../../types'

let EventService: EventServicePlugin
async function ensureJWeb() {
  const res = await WhenJWebReady
  if (res) {
    EventService = res.Plugins.EventService
  } else {
    console.info('JWeb plugins not available')
    throw new Error('JWeb plugins not available')
  }
  return res
}

export default class JWebDataCollection {
  private static analyticsPublisherId =
    'com.hp.texteditwebapp.datacollection.publisher'
  private static instance: JWebDataCollection | undefined
  private publisher: Publisher | undefined
  private serviceOptions: ServiceOptions | undefined
  private platform: string | undefined

  public Ready: Promise<void>

  private constructor() {
    this.Ready = new Promise<void>((resolve, reject) => {
      return Promise.all([
        this.setupPlatform(),
        this.setupPublisher(),
        this.setupServiceOptions()
      ])
        .then(() => {
          resolve()
        })
        .catch(reject)
    })
  }

  public static getInstance(): JWebDataCollection {
    if (!JWebDataCollection.instance) {
      JWebDataCollection.instance = new JWebDataCollection()
    }
    return JWebDataCollection.instance
  }

  public static async destroy() {
    //Attempt to cleanup the instance resources
    if (!this.instance) return
    if (this.instance.publisher) {
      try {
        await this.instance.publisher.destroy()
      } catch (error) {
        console.error('Instance publisher destroy failed', error)
      }
    }
    delete this.instance
  }

  public static sendUIEvent(simpleUIEvent: SimpleUiEvent) {
    const instance = JWebDataCollection.getInstance()
    return instance.sendUIEventFn(simpleUIEvent).catch((error) => {
      console.warn(error)
    })
  }

  protected static instanceOfError(
    object: any
  ): object is EventServicePluginError {
    if (object === undefined) return false
    return 'errorType' in object
  }

  protected static getCDMEventData(simpleUIEvent: SimpleUiEvent): CDMEventData {
    return {
      dateTime: new Date().toISOString(),
      eventDetailType:
        'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      version: '1.4.0',
      eventDetail: simpleUIEvent
    }
  }

  protected static isPascalCase(data: string): boolean {
    const pascalCaseRegex = new RegExp('^[A-Z][a-z]+(?:[A-Z][a-z]+)*$')
    return pascalCaseRegex.test(data)
  }

  private async setupPlatform(): Promise<void> {
    if (this.platform === undefined) {
      const res = await ensureJWeb()
      this.platform = res.JWeb.platform
    }
  }

  protected getAssetUnit() {
    switch (this.platform) {
      case 'ios':
      case 'android':
        return AssetUnitType.mobile as AssetUnitType
      case 'mac':
      case 'windows':
      default:
        return AssetUnitType.desktop as AssetUnitType
    }
  }

  protected async getValveControllerMetadata(): Promise<ValveControllerMetadata> {
    await this.Ready
    return {
      assetUnit: this.getAssetUnit(),
      appInstanceId: this.serviceOptions?.appInstanceId
    }
  }

  protected async getDataValveCDMEvent(
    cdmEventData: CDMEventData
  ): Promise<Record<string, unknown>> {
    return {
      events: [cdmEventData],
      valveControllerMetadata: await this.getValveControllerMetadata()
    }
  }

  private async setupPublisher(): Promise<Publisher> {
    let publisher: Publisher | EventServicePluginError | undefined
    if (this.publisher === undefined) {
      await ensureJWeb()
      // Create our publisher options to allow the JWeb core to send events with the legacy Eventing plugin if the EventingService plugin is not available.

      const createPublisherOptions: CreatePublisherOptions = {
        allowEventingFallback: true
      }
      publisher = await EventService.createPublisher(
        JWebDataCollection.analyticsPublisherId,
        createPublisherOptions
      )
      if (!publisher) {
        console.warn('Null publisher created.')
        return Promise.reject(new Error('Null publisher created.'))
      } else if (JWebDataCollection.instanceOfError(publisher)) {
        console.warn('Failed to acquire publisher', publisher.errorType)
        return Promise.reject(
          new Error('Failed to acquire publisher. ' + publisher.errorType)
        )
      }
      this.publisher = publisher
    }
    return this.publisher
  }

  private async setupServiceOptions(): Promise<ServiceOptions | undefined> {
    if (this.serviceOptions === undefined) {
      this.serviceOptions = await getServiceOptions()
      if (this.serviceOptions === undefined) {
        throw new Error('missing serviceOptions')
      } else if (this.serviceOptions.appInstanceId == undefined) {
        throw new Error('missing serviceOptions.appInstanceId')
      } else {
        if (!JWebDataCollection.isPascalCase(this.serviceOptions.source)) {
          console.warn(
            'ServiceOptions.source is not in PascalCase: ' +
              this.serviceOptions.source +
              '. Substituting.'
          )
          this.serviceOptions.source = ''
        }
        if (!JWebDataCollection.isPascalCase(this.serviceOptions.preset)) {
          console.warn(
            'ServiceOptions.preset is not in PascalCase: ' +
              this.serviceOptions.source +
              '. Substituting.'
          )
          this.serviceOptions.preset = ''
        }
      }
    }
    return this.serviceOptions
  }

  protected async updateSimpleUIEvent(
    simpleUIEvent: SimpleUiEvent
  ): Promise<SimpleUiEvent> {
    await this.Ready
    const simpleEvent = Object.assign({}, simpleUIEvent)
    if (
      this.serviceOptions?.source !== undefined &&
      this.serviceOptions?.source !== ''
    ) {
      simpleEvent.screenMode = this.serviceOptions.source
    }
    const originalScreenPath = simpleEvent.screenPath
      ? simpleEvent.screenPath
      : ''
    simpleEvent.screenPath =
      this.serviceOptions?.preset !== undefined &&
      this.serviceOptions?.preset !== ''
        ? '/' + this.serviceOptions.preset + '/' + originalScreenPath
        : '/' + originalScreenPath
    return simpleEvent
  }

  public async sendUIEventFn(simpleUIEvent: SimpleUiEvent): Promise<void> {
    const simpleEvent = await this.updateSimpleUIEvent(simpleUIEvent)
    const eventName = DataCollectionEventNames.cdmEvent
    const cdmEventData: CDMEventData =
      JWebDataCollection.getCDMEventData(simpleEvent)
    // Construct DataValveCDMEvent
    const eventData = await this.getDataValveCDMEvent(cdmEventData)

    if (!this.publisher) {
      return Promise.reject(new Error('Missing publisher'))
    }

    // Attempt to Publish event, it returns something only if an error occurred otherwise is undefined
    const result: void | EventServicePluginError = await this.publisher.publish(
      eventName,
      eventData
    )
    if (result && JWebDataCollection.instanceOfError(result)) {
      //in case the error does not use reject, but instead resolve
      console.warn('Error sending message')
      return Promise.reject(result)
    }
    return Promise.resolve()
  }
}

export { uiEvents }
