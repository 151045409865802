import { ToastReturn } from '@veneer/core'
import React from 'react'
import { i18n } from 'src/assets/locale'
import './ToastNotification.scss'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ToastNotification = (toast: ToastReturn) => {
  const { addToast } = toast

  addToast?.({
    id: 'toastId',
    type: 'negative',
    text: i18n.t('application.toast.title'),
    subtitle: i18n.t('application.toast.subtitle'),
    timeout: 300,
    pauseTimeoutOnHover: true,
    action: (
      <a
        style={{ cursor: 'pointer' }}
        onClick={() => window.location.reload()}
      >
        {i18n.t('application.toast.action')}
      </a>
    )
  })
}

export default ToastNotification
