import React from 'react'
import Header from '../../components/Header'
import TextEdit from '../../components/TextEdit'
import BottomBar from '../../components/BottomBar'
import './TextExtract.scss'
import ResizableContainer from '../../components/ResizableContainer'
import useIsDesktop from 'src/hooks/useIsDesktop'

const TextExtract = () => {
  const isDesktop = useIsDesktop()
  return (
    <>
      <Header />
      <ResizableContainer className="textExtract-container">
        <div
          className={
            isDesktop ? 'textExtract-main-desktop' : 'textExtract-main-mobile'
          }
          aria-label="Editable text area"
        >
          <TextEdit />
        </div>
        <div className="textExtract-bottomBar">
          <BottomBar />
        </div>
      </ResizableContainer>
    </>
  )
}

export default TextExtract
