// eslint-disable-next-line no-restricted-imports
import { JWebPromise, WhenJWebReady } from '@jarvis/jweb-core'
import { isTablet } from 'react-device-detect'
import { ExtendedDeviceInfo } from './Type'

export default class JWeb {
  static async getInstance(): Promise<JWebPromise> {
    const res = await WhenJWebReady
    if (res) {
      return res
    } else throw 'JWeb plugins not available'
  }

  static async getDeviceInfo(): Promise<ExtendedDeviceInfo> {
    const jWeb = await this.getInstance()
    const deviceInfo: ExtendedDeviceInfo = await jWeb.Plugins.Device.getInfo()

    deviceInfo.model = deviceInfo.model.toLocaleLowerCase()
    deviceInfo.isTablet = isTablet

    console.info('Device Info: ', JSON.stringify(deviceInfo, null, 2))
    return deviceInfo
  }

  static async isMobileMode(): Promise<boolean> {
    const deviceInfo: ExtendedDeviceInfo = await JWeb.getDeviceInfo()
    const platform = deviceInfo.platform

    return !(
      platform === 'windows' ||
      platform === 'mac' ||
      (platform === 'ios' && deviceInfo.model.indexOf('iphone') === -1) ||
      deviceInfo.isTablet
    )
  }
}
