import React, { SyntheticEvent } from 'react'
import { i18n } from '../../assets/locale'
import { Modal } from '@clientos/ui-toolkit'

type ModalDuplicateNameProps = {
  show: boolean
  onClose: { (event: SyntheticEvent): void }
  onConfirm: { (): void }
  shortcutName: string
  desktopMode: boolean
}

const ModalDuplicateName = (props: ModalDuplicateNameProps) => {
  return (
    <Modal
      isOpen={props.show}
      buttonGroup={{
        textPrimaryButton: i18n.t('common.contextAction.rename'),
        textSecondaryButton: i18n.t('common.contextAction.no'),
        onClickPrimaryButton: props.onConfirm,
        onClickSecondaryButton: () => props.onClose({} as SyntheticEvent),
        size: 'large',
        orientation: 'vertical'
      }}
      onClose={props.onClose}
      closeOnBlur={false}
      title={i18n.t('pages.ModalDuplicateName.duplicateNameTitle', {
        shortcutName: `${props.shortcutName}`
      })}
      align="center"
    >
      <p id="duplicate-name-modal-text">
        {i18n.t('pages.ModalDuplicateName.duplicateNameMessage')}
      </p>
    </Modal>
  )
}

export default ModalDuplicateName
