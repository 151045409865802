import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'container';
`

export const ContentContainer = styled.div<{ desktopMode: boolean }>`
  grid-area: 'container';
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 55px;
  ${(props) => (props.desktopMode ? 'margin: 0 30%;' : 'margin: 0 10%;')}
`

export const UpgradeTitle = styled.h2`
  font-size: 24px;
  line-height: 30px;
  padding-top: 18px;
  text-align: center;
`

export const UpgradeDescription = styled.h3`
  font-size: 15px;
  line-height: 20px;
  padding-bottom: 50px;
  padding-top: 20px;
  text-align: center;
`
