import React, { HTMLAttributes } from 'react'
import useDeviceHeight from '../../hooks/useDeviceHeight'
const ResizableContainer = ({
  children,
  ...props
}: HTMLAttributes<Element>) => {
  const height = useDeviceHeight()
  return (
    <div
      {...props}
      style={{ height: `${height}px` }}
    >
      {children}
    </div>
  )
}

export default ResizableContainer
