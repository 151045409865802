import { TextEditActionType } from '../actions/textEdit'
import { TextEditAction, TextEditState } from '../types'

export const initialTextEditState: TextEditState = {
  isEditing: false,
  text: null,
  receivedText: null
}

export const textEditReducer = (
  state: TextEditState,
  action: TextEditAction
): TextEditState => {
  switch (action.type) {
    case TextEditActionType.SET_IS_EDITING:
      return {
        ...state,
        isEditing: action.data.isEditing
      }
    case TextEditActionType.SET_TEXT:
      return {
        ...state,
        text: action.data.text
      }
    case TextEditActionType.RESET_TEXT:
      return {
        ...state,
        text: state.receivedText
      }
    case TextEditActionType.SET_INITIAL_TEXT:
      return {
        ...state,
        text: action.data.text,
        receivedText: action.data.text
      }
    default:
      return state
  }
}
