import React, { Ref } from 'react'
import { StyledFooter } from './styles'

type FooterProps = {
  leftContent?: React.ReactNode
  content: React.ReactNode
  ref?: Ref<'footer'>
  desktopMode: boolean
}

const Footer = React.forwardRef((props: FooterProps, ref: Ref<HTMLElement>) => (
  <StyledFooter
    id="footer"
    mainContent={{
      leadingArea: props.leftContent,
      trailingArea: props.content
    }}
    appearance="minimal"
    ref={ref}
    customStyle={{
      height: props.desktopMode ? '94px' : '80px',
      paddingBottom: props.desktopMode ? '23px' : '16px'
    }}
  />
))

Footer.displayName = 'Footer'

export default Footer
