// eslint-disable-next-line no-restricted-imports
import { WhenJWebReady } from '@jarvis/jweb-core'
import { useEffect } from 'react'
import nativeBackButtonHandler from '../data/handlers/nativeBackButtonHandler'

const useNativeBackButton = (backButtonCallBack: { (): void }) => {
  useEffect(() => {
    const backButton = async () => {
      const jweb = await WhenJWebReady
      if (jweb.JWeb.platform === 'android') {
        nativeBackButtonHandler.setBackButtonCallBack(backButtonCallBack)
      }
    }
    backButton()
  }, [backButtonCallBack])
}

export default useNativeBackButton
