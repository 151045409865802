import { i18n } from '../../assets/locale'
import { colors } from '../enum/colors'
import { PresetTemplate } from '../schemas/template'

export const saveToGoogleDriveTemplate = (): PresetTemplate => ({
  data: {
    actions: [
      {
        route: 'repositories',
        triggerSignIn: true,
        signInTargetType: {
          type: 'googledrive',
          supportedInputFileTypes: ['any'],
          isAuthorized: true,
          subFoldersSupported: true,
          connectorName: '',
          connectorId: ''
        }
      }
    ],
    index: 0
  },
  defaultDestinationsValues: {
    repositories: {
      selected: [],
      set: true
    }
  },
  defaultShortcutSettingsValues: {
    jobName: i18n.t('pages.AddShortcut.save'),
    quickRun: false,
    presentation: { color: colors[1] },
    smartFileName: false,
    fileType: 'jpeg',
    ocrLanguage: ''
  }
})

export const printEmailSaveTemplate = (): PresetTemplate => ({
  data: {
    actions: [
      {
        route: 'print'
      },
      {
        route: 'email'
      },
      {
        route: 'repositories'
      }
    ],
    index: 0
  },
  defaultDestinationsValues: {
    print: {
      numberOfCopies: 1,
      color: 'false',
      printDuplex: 'one-sided',
      set: true
    },
    email: {
      tos: [],
      subject: '',
      message: '',
      set: true
    },
    repositories: {
      selected: [],
      set: true
    }
  },
  defaultShortcutSettingsValues: {
    jobName: i18n.t('pages.AddShortcut.combo'),
    quickRun: false,
    presentation: { color: colors[2] },
    smartFileName: false,
    fileType: 'jpeg',
    ocrLanguage: ''
  }
})
