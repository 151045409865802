import { Doc, DocData } from '@jarvis/jweb-core'
import mockedText from '../../../assets/demo/text-extracted.json'
import { getDocData } from '..'
export default class TextDoc implements Doc {
  readonly id: string
  readonly docSetId: string
  readonly mediaType: string
  readonly uri: string
  constructor(id: string, docSetId: string, mediaType: string, uri: string) {
    this.id = id
    this.docSetId = docSetId
    this.mediaType = mediaType
    this.uri = uri
  }
  async getText(): Promise<string | null> {
    try {
      const text = (await getDocData({
        docId: this.id,
        docSetId: this.docSetId
      })) as DocData
      return decodeURIComponent(escape(window.atob(text.data)))
    } catch (error) {
      if (process.env.NODE_ENV === 'development') return mockedText.text
      console.error('Error while getting text', error)
      throw error
    }
  }
}
