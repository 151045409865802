import React from 'react'
import { IconCheckCircle } from '@clientos/ui-toolkit'
import { StyledModal } from './styles'
import { ShortcutItem } from 'src/data/schemas/shortcut'
import { dispatchEvent } from '../../plugins/jweb/EventService'
import { useAnimationContext } from 'src/contexts/animations/AnimationContext'
import { LocationState } from 'src/data/schemas/location'
import { useHistory } from 'react-router-dom'
import { i18n } from 'src/assets/locale'

type ModalSavedShortcutProps = {
  show: boolean
  shortcut: ShortcutItem
  base: string
  desktopMode: boolean
  editMode?: boolean
}

const ModalSavedShortcut = (props: ModalSavedShortcutProps) => {
  const history = useHistory<LocationState>()
  const { setAnimationDirection } = useAnimationContext()

  const onMyShortcutsButtonPress = () => {
    setAnimationDirection('right')
    history.push(`${props.base}/list`)
  }

  const onStartShortcutPress = () => {
    dispatchEvent('ExecuteShortcut', { shortcutDefinition: props.shortcut })
    history.push(`${props.base}/list`)
  }

  return (
    <StyledModal
      // TODO: Replace when batch translation is done: i18n.t('pages.ShortcutSaved.headerHPX')
      title={
        props.desktopMode && !props.editMode
          ? i18n.t('pages.ShortcutSaved.firstShortcutTitle')
          : 'Shortcut saved'
      }
      align="center"
      closeOnBlur={false}
      isOpen={props.show}
      leadingArea={
        <IconCheckCircle
          size={72}
          color="green5"
        />
      }
      buttonGroup={{
        // TODO: Replace when batch translation is done: i18n.t('pages.ShortcutSaved.startButtonHPX')
        textPrimaryButton:
          props.desktopMode && !props.editMode
            ? i18n.t('pages.ShortcutSaved.startButton')
            : 'Run this Shortcut',
        // TODO: Replace when batch translation is done: i18n.t('pages.ShortcutSaved.doneButtonHPX')
        textSecondaryButton: 'Done',
        onClickPrimaryButton: () => {
          onStartShortcutPress()
        },
        onClickSecondaryButton: () => {
          onMyShortcutsButtonPress()
        },
        size: 'large',
        orientation: 'vertical'
      }}
    >
      {props.desktopMode && !props.editMode && (
        <>
          <div
            className="body-regular"
            style={{ marginTop: '24px' }}
          >
            {i18n.t('pages.ShortcutSaved.firstShortcutMessage')}
          </div>
        </>
      )}
    </StyledModal>
  )
}

export default ModalSavedShortcut
