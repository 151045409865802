import { TextArea, TextField } from '@clientos/ui-toolkit'
import React from 'react'
import { MainContainer } from './styles'

type EmailFormProps = {
  toFieldLabel: string
  subjectFieldLabel: string
  bodyFieldLabel: string
  onChangeEmailCallback: { (value: string): void }
  onBlurEmailCallback: { (): void }
  onChangeSubjectCallback: { (value: string): void }
  onChangeBodyCallback: { (value: string): void }
  emailValue?: string
  subjectValue?: string
  bodyValue?: string
  emailErrorMessage?: string
}

const EmailForm = (props: EmailFormProps) => (
  <MainContainer id="email-form-main-container">
    <TextField
      id="email-receiver-box"
      name="email-receiver-box"
      label={props.toFieldLabel}
      onChange={props.onChangeEmailCallback}
      onBlur={props.onBlurEmailCallback}
      value={props.emailValue ?? undefined}
      error={
        props.emailErrorMessage?.length
          ? props.emailErrorMessage.length > 0
          : false
      }
      supportiveText={props.emailErrorMessage}
    />
    <TextField
      id="email-subject-box"
      name="email-subject-box"
      autoComplete="none"
      label={props.subjectFieldLabel}
      onChange={props.onChangeSubjectCallback}
      value={props.subjectValue ?? undefined}
    />
    <TextArea
      id="email-body-box"
      label={props.bodyFieldLabel}
      onChange={props.onChangeBodyCallback}
      value={props.bodyValue ?? undefined}
    />
  </MainContainer>
)

export default EmailForm
