// eslint-disable-next-line no-restricted-imports
import { WhenJWebReady } from '@jarvis/jweb-core'

interface PluginResponse {
  uuid: string
  type: string
  value: any
  responseCode: number
  pluginError: any
}

export const getPrinterData = async (
  uuid: string,
  type: string
): Promise<PluginResponse> => {
  const jweb = await WhenJWebReady
  return await jweb.Plugins.GetPrinterData.getPrinterData({ uuid, type })
}
