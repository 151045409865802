import Images from 'src/assets/images'
import { i18n } from '../../assets/locale'
import { getFoldersName } from '../../clients/shortcuts'
import { randomColor } from '../../data/enum/colors'
import {
  FolderId,
  FolderInfo,
  FolderNameResponse
} from '../../data/schemas/connector'
import { RepositoryConfigState } from '../../data/schemas/destinations'
import { ConnectorFolders } from '../../data/schemas/folders'
import {
  FolderItem,
  RepositoryConfig,
  ShortcutItem,
  SmartTaskConfig
} from '../../data/schemas/shortcut'

export const getActionsFromData = (s: ShortcutItem) => {
  const { email, prints, repositories } = s.smartTask.smartTaskConfig
  const actions: string[] = []

  if (email) {
    actions.push('email')
  }
  if (prints) {
    actions.push('print')
  }
  if (repositories) {
    const seen = new Set()
    repositories.forEach((item) => {
      if (!seen.has(item.type)) {
        actions.push(item.type!)
      }
      seen.add(item.type!)
    })
  }

  return actions
}

export const resolveRepositoryObject = (repositories: RepositoryConfig[]) => {
  const state: RepositoryConfigState = {
    selected: repositories,
    set: true
  }

  return state
}

export const prebuiltEmailToMe = (
  emailAddress: string,
  givenName: string
): ShortcutItem => {
  const userName = emailAddress
    .substring(0, emailAddress.indexOf('@'))
    .replace(/[\W]+/g, '_')

  return {
    id: 'EMAIL_TO_ME',
    smartTask: {
      fileType: 'pdf',
      jobName: i18n.t('pages.ListShortcuts.prebuilt.jobName', {
        userName: `${userName}`
      }),
      smartTaskConfig: {
        email: {
          tos: [`${emailAddress}`],
          subject: i18n.t('pages.AddEmail.subjectSuggestion', {
            userFirstName: `${givenName}`
          }),
          //TODO: Replace once translation batch is available i18n.t('pages.AddEmail.bodySuggestionHPX', {userFirstName: `${givenName}`,userEmail: `${emailAddress}`})
          message: `The attached files have been sent to you from ${givenName} (${emailAddress}). Use the HP app to edit, print, and share them!`
        }
      },
      presentation: {
        color: randomColor()
      }
    }
  }
}

const builderFolderList = (
  folderList?: FolderItem[],
  folderInfoList?: FolderInfo[]
) => {
  return (
    folderList?.map((item) => {
      const folderInfo = folderInfoList?.find(
        (folderItem) => folderItem.folderId === item.folderId
      )

      return {
        name: folderInfo?.folderName || i18n.t('pages.AddSave.folderDeleted'),
        id: item.folderId,
        type: item.folderId,
        isValid: folderInfo?.isValid || false
      }
    }) || []
  )
}

const builderRepositoryFolderList = (
  folderList?: FolderItem[],
  folderInfoList?: FolderInfo[]
) => {
  return (
    folderList?.map((item) => {
      const folderInfo = folderInfoList?.find(
        (folderItem) => folderItem.folderId === item.folderId
      )

      return {
        folderName:
          folderInfo?.folderName || i18n.t('pages.AddSave.folderDeleted'),
        folderId: item.folderId
      }
    }) || []
  )
}

const builderConnectorFolders = (
  repositories: RepositoryConfig[],
  folderInfoList: FolderInfo[] | undefined
) => {
  return repositories.map((value) => {
    const folderList = builderFolderList(value.folderList, folderInfoList)
    return {
      connectorId: value.connectorId!,
      folders: folderList
    }
  })
}

const builderRepositoryFolders = (
  repositories: RepositoryConfig[],
  folderInfoList: FolderInfo[] | undefined
) => {
  return repositories.map((value) => {
    const folderList = builderRepositoryFolderList(
      value.folderList,
      folderInfoList
    )
    return {
      ...value,
      folderList
    }
  })
}

const getFolderIds = (repositories: RepositoryConfig[]) => {
  return repositories.reduce(
    (prevFolderId: FolderId[], currentRepository: RepositoryConfig) => {
      const ids = currentRepository?.folderList?.map((item) => ({
        id: item.folderId
      }))
      if (ids) {
        prevFolderId = [...prevFolderId, ...ids]
      }
      return prevFolderId
    },
    []
  )
}

export const handleFolderNames = async (
  repositories: RepositoryConfig[]
): Promise<ConnectorFolders[]> => {
  const folderIds = getFolderIds(repositories)
  const response: FolderNameResponse =
    folderIds.length > 0 ? await getFoldersName(folderIds) : []
  return builderConnectorFolders(repositories, response?.folderInfoList)
}

export const handleFlyoutFolderNames = async (
  repositories: RepositoryConfig[] | undefined
): Promise<RepositoryConfig[]> => {
  const folderIds = repositories ? getFolderIds(repositories) : []
  const response: FolderNameResponse =
    folderIds.length > 0 ? await getFoldersName(folderIds) : []
  return builderRepositoryFolders(
    repositories ? repositories : [],
    response?.folderInfoList
  )
}

export const resolveProviderIcon = (repositories: RepositoryConfig[]) => {
  let iconPath = null
  if (repositories?.length === 1) {
    const type = repositories[0].type
    switch (type) {
      case 'googledrive':
        iconPath = Images.googleDriveTile
        break
      case 'onedrive':
        iconPath = Images.oneDriveTile
        break
      case 'dropbox':
        iconPath = Images.dropBoxTile
        break
      case 'box':
        iconPath = Images.boxTile
        break
      case 'quickbooksonline':
        iconPath = Images.quickbooksTile
        break
      case 'evernote':
        iconPath = Images.evernoteTile
        break
      case 'onedrivebusiness':
        iconPath = Images.oneDriveTile
        break
      default:
        break
    }
  }

  return iconPath
}

export const isEmailType = (smartTaskConfig: SmartTaskConfig) => {
  return (
    smartTaskConfig &&
    smartTaskConfig.email &&
    !smartTaskConfig.prints &&
    !smartTaskConfig.repositories
  )
}

export const isPrintType = (smartTaskConfig: SmartTaskConfig) => {
  return (
    smartTaskConfig &&
    !smartTaskConfig.email &&
    smartTaskConfig.prints &&
    !smartTaskConfig.repositories
  )
}

export const isSaveType = (smartTaskConfig: SmartTaskConfig) => {
  return (
    smartTaskConfig &&
    !smartTaskConfig.email &&
    !smartTaskConfig.prints &&
    smartTaskConfig.repositories
  )
}
