// eslint-disable-next-line no-restricted-imports
import { AccessToken } from '@jarvis/jweb-core'
import { DateRange, useToast } from '@veneer/core'
import { DateTime } from 'luxon'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { i18n } from '../../assets/locale'
import { getDeviceById } from '../../clients/devices'
import CustomFooter from '../../components/CustomFooter'
import { deleteSession, deviceLookup } from '../../clients/shortcuts'
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import { useAnimationContext } from '../../contexts/animations/AnimationContext'
import { useDeviceContext } from '../../contexts/devices/DeviceContext'
import { PageProps } from '../../data/schemas/app'
import { LocationState } from '../../data/schemas/location'
import { DeviceSession, SessionState } from '../../data/schemas/session'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import {
  resolveAccessTimeDetail,
  toastMessages
} from '../../modules/common/helpers'
import { createDeviceSession } from '../../modules/devices/helpers'
import { getAuthToken } from '../../plugins/jweb/Auth'
import { sendUiEvent, uiData } from '../../plugins/jweb/DataCollection'
import { dispatchEvent } from '../../plugins/jweb/EventService'
import { getPrinterData } from '../../plugins/jweb/GetPrinterData'
import { isNative } from '../../plugins/jweb/Platform'
import {
  MainContainerHPX,
  Paragraph,
  StyledButtonGroup,
  StyledDatePicker
} from './styles'
import ModalSetupSession from '../../components/ModalSetupSession'
import {
  ButtonGroup,
  DatePicker,
  GenericCard,
  ListContainer,
  RadioButton
} from '@clientos/ui-toolkit'
import { ListItemProps } from '@clientos/ui-toolkit/dist/types/components/list-item/types'
import PageTemplate from '../PageTemplate'
import MobileScroll from 'src/components/MobileScroll'

enum DurationType {
  OFF = 'off',
  ONEDAY = 'day',
  ONEYEAR = 'year',
  CUSTOM = 'custom'
}

export const AccessShortcuts = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()
  const location = useLocation<LocationState>()
  const { addToast } = useToast()

  const { setAnimationDirection } = useAnimationContext()
  const { deviceActions, deviceState } = useDeviceContext()

  // Assuming there will always be a selected printer when accessing this page.
  const selectedPrinterUuid = props.selectedPrinterUuid as string

  const defaultDate = DateTime.local()

  const [postcard, setPostcard] = useState<string>(
    location.state?.session?.context?.postcard || ''
  )

  const [selectedAccessTime, setSelectedAccessTime] = useState(
    location.state?.session?.context?.selectedAccessTime || 'off'
  )

  const [checkedSessionIndex, setCheckedSessionIndex] = useState(0)

  const [showSuccessSetupModal, setShowSuccessSetupModal] = useState(false)

  const [enabledSession, setEnabledSession] = useState<SessionState | null>(
    null
  )

  const [pickerDate, setPickerDate] = useState<Moment>(
    location.state?.session?.context?.pickerDate
      ? moment(location.state?.session?.context?.pickerDate)
      : moment(defaultDate.toISO())
  )
  const [isCreatingSession, setIsCreatingSession] = useState(false)
  const [fetchPrinterInfoFailed, setFetchPrinterInfoFailed] = useState(false)
  const fromURL = location.state?.session?.context?.from || ''
  const rootPath = location.state?.session?.context?.rootPath

  const handleOnDateChange = (date?: Moment | DateRange) => {
    const newDate = date as Moment
    setPickerDate(newDate)
  }

  const onChangeAccessTime = (duration: DurationType) => {
    switch (duration) {
      case DurationType.OFF:
        setCheckedSessionIndex(0)
        setSelectedAccessTime(DurationType.OFF)
        break
      case DurationType.ONEYEAR:
        setCheckedSessionIndex(1)
        setSelectedAccessTime(DurationType.ONEYEAR)
        break
      case DurationType.ONEDAY:
        setCheckedSessionIndex(2)
        setSelectedAccessTime(DurationType.ONEDAY)
        break
      case DurationType.CUSTOM:
        setCheckedSessionIndex(3)
        setSelectedAccessTime(DurationType.CUSTOM)
        break
    }
  }

  const onBackButtonHandler = () => {
    setAnimationDirection('left')
    fromURL
      ? history.push(fromURL, {
          session: {
            context: {
              rootPath: rootPath
            }
          }
        })
      : onBackButtonPress()
  }

  const onOkButtonPress = async () => {
    setAnimationDirection('right')
    fromURL ? goBackToPreviousPage(fromURL) : onBackButtonPress()
  }

  const goBackToPreviousPage = (pathName: string) => {
    setAnimationDirection('left')
    history.push({
      pathname: pathName,
      state: {
        session: {
          context: {
            from: `${base}/access_shortcuts`,
            rootPath: 'AccessShortcuts'
          }
        }
      }
    })
  }

  const onBackButtonPress = () => {
    dispatchEvent('com.hp.jarvis.servicerouting.serviceinstance.closed')
    dispatchEvent('Close')
  }

  const getExpirationSeconds = () => {
    const ONEDAYSECONDS = 60 * 60 * 24
    const ONEYEARSECONDS = 60 * 60 * 24 * 365
    if (selectedAccessTime === DurationType.ONEYEAR) {
      return ONEYEARSECONDS
    } else if (selectedAccessTime === DurationType.ONEDAY) {
      return ONEDAYSECONDS
    } else {
      pickerDate?.endOf('day')
      const selectedDate = moment(pickerDate).toISOString()
      return Math.round(
        DateTime.fromISO(selectedDate).diffNow().valueOf() / 1000
      )
    }
  }

  const onClickSave = async () => {
    sendUiEvent(uiData.btnSaveAccess)
    setIsCreatingSession(true)
    setAnimationDirection('right')

    if (selectedAccessTime === DurationType.OFF) {
      try {
        await deleteSession(selectedPrinterUuid)
        sessionStorage.setItem('deviceUuid', selectedPrinterUuid)
      } catch (e) {
        setIsCreatingSession(false)
        addToast?.({
          type: 'negative',
          ...toastMessages('deleteSessionError')
        })

        return
      }
      setShowSuccessSetupModal(true)
    } else {
      try {
        const token: AccessToken = await getAuthToken()
        const deviceSessionItem: DeviceSession = {
          target: selectedPrinterUuid,
          targetFriendlyName: token.account?.emailAddress || '',
          expiration: getExpirationSeconds(),
          claimPostcard: postcard
        }

        const session = await createDeviceSession(deviceSessionItem)
        setShowSuccessSetupModal(true)
        setEnabledSession(session)
        sendUiEvent({
          ...uiData.btnOptionAccess,
          controlDetail: resolveAccessTimeDetail(selectedAccessTime)
        })
        sessionStorage.setItem('deviceUuid', selectedPrinterUuid)
      } catch (e) {
        setIsCreatingSession(false)
        sendUiEvent(uiData.toastEnableAccessShortcutError)
        addToast?.({
          type: 'negative',
          ...toastMessages('createSessionError')
        })

        return
      }
    }
  }

  const accessShortcutsOptions = [
    {},
    {
      label: i18n.t('pages.AccessShortcuts.radioOff'),
      end: (
        <RadioButton
          aria-label={'Off'}
          checked={checkedSessionIndex === 0}
          onChange={() => onChangeAccessTime(DurationType.OFF)}
        />
      ),
      alignEnd: 'center',
      paddingStart: false,
      paddingEnd: false
    },
    {
      label: i18n.t('pages.AccessShortcuts.radioYear'),
      description: i18n.t('pages.AccessShortcuts.radioYearTip'),
      end: (
        <RadioButton
          aria-label={'Year'}
          checked={checkedSessionIndex === 1}
          onChange={() => onChangeAccessTime(DurationType.ONEYEAR)}
        />
      ),
      alignEnd: 'center',
      paddingStart: false,
      paddingEnd: false
    },
    {
      label: i18n.t('pages.AccessShortcuts.radioDay'),
      description: i18n.t('pages.AccessShortcuts.radioDayTip'),
      end: (
        <RadioButton
          aria-label={'Day'}
          checked={checkedSessionIndex === 2}
          onChange={() => onChangeAccessTime(DurationType.ONEDAY)}
        />
      ),
      alignEnd: 'center',
      paddingStart: false,
      paddingEnd: false
    },
    {
      label: i18n.t('pages.AccessShortcuts.radioCustom'),
      description: i18n.t('pages.AccessShortcuts.radioCustomTip'),
      end: (
        <RadioButton
          aria-label={'Custom'}
          checked={checkedSessionIndex === 3}
          onChange={() => onChangeAccessTime(DurationType.CUSTOM)}
        />
      ),
      alignEnd: 'center',
      paddingStart: false,
      paddingEnd: false
    }
  ]

  useEffect(() => {
    const setReadOnlyAttribute = () => {
      const datePickerInput = document.getElementById('date_picker')
      if (datePickerInput) {
        datePickerInput.setAttribute('readonly', 'readonly')
      }
    }
    setReadOnlyAttribute()
  }, [])

  useEffect(() => {
    const fetchPrinterData = async () => {
      try {
        if (await isNative()) {
          const printerData = await getPrinterData(
            selectedPrinterUuid,
            'claimPostcard'
          )
          if (printerData.responseCode === 200) {
            setPostcard(printerData.value)
          } else {
            console.error(
              `Unable to retrieve printer data for deviceUuid: ${selectedPrinterUuid} - response code: ${printerData.responseCode}`
            )
          }
        }
      } catch {
        console.error(
          `Exception while getting printer data for deviceUuid: ${selectedPrinterUuid}`
        )
      }
    }

    fetchPrinterData()
  }, [selectedPrinterUuid, addToast])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!deviceState.modelName) {
          let cloudId = deviceState.cloudId

          if (!cloudId) {
            const deviceLookupResponse = await deviceLookup(selectedPrinterUuid)
            deviceActions.setCloudId(deviceLookupResponse.cloud_id)
            deviceActions.setSupportsSession(
              deviceLookupResponse.supportsSession
            )
            cloudId = deviceLookupResponse.cloud_id
          }

          const deviceInfosResponse = await getDeviceById(cloudId)
          deviceActions.setModelName(
            deviceInfosResponse.deviceDescription.deviceIdentity.modelName
          )
          deviceActions.setModelImage(
            deviceInfosResponse.deviceDescription.deviceImages[0] ?? ''
          )
        }
      } catch (e) {
        console.error(
          `Exception while getting device info for deviceUuid: ${selectedPrinterUuid}`
        )
        sendUiEvent(uiData.toastDeviceFetchError)
        setFetchPrinterInfoFailed(true)
        addToast?.({
          type: 'warning',
          ...toastMessages('deviceFetchError')
        })
      }
    }

    fetchData()
  }, [
    addToast,
    deviceActions,
    deviceState.cloudId,
    deviceState.modelName,
    selectedPrinterUuid
  ])

  useNativeBackButton(onBackButtonHandler)

  const CardContent = () => {
    return (
      <>
        <Paragraph
          style={{ paddingTop: '24px' }}
          id="first-paragraph-access-shortcut"
        >
          {i18n.t('pages.AccessShortcuts.question', {
            printerName: deviceState.modelName
          })}
        </Paragraph>
        <Paragraph id="second-paragraph-access-shortcut">
          {i18n.t('pages.AccessShortcuts.info')}
        </Paragraph>
        <ListContainer items={accessShortcutsOptions as ListItemProps[]} />
        <StyledDatePicker>
          <DatePicker
            id="date_picker"
            // TODO: Replace by i18n.t('pages.AccessShortcuts.datePickerLabelHPX')
            label={'End Date'}
            format="ll"
            onChange={handleOnDateChange}
            blockPastDates
            value={pickerDate}
            yearsRange={{
              start: defaultDate.year,
              end: defaultDate.year + 20
            }}
            disabled={selectedAccessTime !== DurationType.CUSTOM}
          />
        </StyledDatePicker>
        <ModalSetupSession
          show={showSuccessSetupModal}
          onClose={onOkButtonPress}
          enabled={enabledSession}
          modelName={deviceState.modelName}
          expiryDate={
            enabledSession
              ? DateTime.fromSeconds(enabledSession.expirationDate!)
                  .setLocale(`${i18n.language}-${i18n.country}`)
                  .toLocaleString(DateTime.DATE_FULL)
              : ''
          }
        />
        {/* TODO: Replace by new desktop/mobile footer specific components when possible */}
        {props.desktopMode ? (
          <CustomFooter
            desktopMode={true}
            buttons={[
              {
                id: 'save-btn-desktop',
                text: i18n.t('common.contextAction.save'),
                disabled: isCreatingSession,
                loading: isCreatingSession,
                onClick: onClickSave,
                appearance: 'primary'
              }
            ]}
          />
        ) : (
          <StyledButtonGroup>
            <ButtonGroup
              id="save-btn-mobile"
              textPrimaryButton={i18n.t('common.contextAction.save')}
              size="large"
              orientation="horizontal-full"
              onClickPrimaryButton={onClickSave}
              disabled={isCreatingSession}
            />
          </StyledButtonGroup>
        )}
      </>
    )
  }

  return (
    <>
      <PageTemplate
        desktopMode={props.desktopMode}
        header={<Header onPressIconLeft={onBackButtonHandler} />}
        content={
          deviceState.modelName || fetchPrinterInfoFailed ? (
            <MainContainerHPX id="main-container-access-shortcut">
              <GenericCard>
                {/* TODO: Replace by i18n.t('pages.AccessShortcuts.headerHPX') */}
                <GenericCard.Header title={'Shortcuts access'} />
                <GenericCard.Body
                  paddingBodyBottom={false}
                  paddingBodyTop={false}
                  paddingBodyHorizontal={true}
                >
                  {props.desktopMode ? (
                    CardContent()
                  ) : (
                    <MobileScroll offset="190px">{CardContent()}</MobileScroll>
                  )}
                </GenericCard.Body>
              </GenericCard>
            </MainContainerHPX>
          ) : (
            <Loader />
          )
        }
      />
    </>
  )
}
