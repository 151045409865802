import React, { useContext, useEffect, useMemo } from 'react'
import { createEditor } from 'slate'
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import useIsEditing from '../../hooks/useIsEditing'
import { TextEditActionType } from '../../actions/textEdit'
import { TextEditContext } from '../../contexts/textEditContext'
import { scrollSelectionIntoView } from './helper'
import './TextEdit.scss'
import useDeviceHeight from '../../hooks/useDeviceHeight'
import useIsDesktop from '../../hooks/useIsDesktop'
import { colors } from '@clientos/ui-toolkit'

const TextEdit = () => {
  const { textEditState, dispatch } = useContext(TextEditContext)
  const isDesktop = useIsDesktop()
  const isEditing = useIsEditing()
  const deviceHeight = useDeviceHeight()
  const editor = useMemo(() => withReact(createEditor() as ReactEditor), [])
  useEffect(() => {
    if (isEditing) {
      requestAnimationFrame(scrollSelectionIntoView)
    }
  }, [isEditing, deviceHeight])
  if (!textEditState?.text || !dispatch) {
    console.error(
      'Does not have the text in text edit state or dispatch function'
    )
    return null
  }
  const handleChange = (text: any) => {
    dispatch({ type: TextEditActionType.SET_TEXT, data: { text } })
  }
  const setEditingState = (isEditing: boolean) => () => {
    dispatch({
      type: TextEditActionType.SET_IS_EDITING,
      data: { isEditing }
    })
  }
  return (
    <Slate
      editor={editor}
      value={textEditState.text}
      onChange={handleChange}
    >
      <Editable
        style={{
          scrollbarColor: `${colors.inkDisabled} transparent`
        }}
        className={`${isDesktop ? 'textEdit-editor-desktop' : 'textEdit-editor-mobile'}`}
        onFocus={setEditingState(true)}
        onBlur={setEditingState(false)}
      />
    </Slate>
  )
}
export default TextEdit
