import styled from 'styled-components'

export const PageContainer = styled.div`
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
`
export const PageContent = styled.div``
export const PageHeader = styled.div`
  margin-bottom: 28px;
`

const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DesktopContent = styled(MainContent)``

export const MobileContent = styled(MainContent)`
  .clientos-ui-toolkit-generic-card {
    .clientos-ui-toolkit-generic-card__content__body__padding-horizontal {
      padding-right: 0px !important;
    }
  }
`
