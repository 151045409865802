import { Button } from '@clientos/ui-toolkit'
import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { i18n } from '../../assets/locale'
import {
  CheckMark,
  FirstCreationDesktop,
  FirstCreationMobile
} from '../../assets/lottie/shortcutCreation'
import Header from '../../components/Header'
import LottieAnimation from '../../components/LottieAnimation'
import { useAnimationContext } from '../../contexts/animations/AnimationContext'
import { PageProps } from '../../data/schemas/app'
import { LocationState } from '../../data/schemas/location'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import { dispatchEvent } from '../../plugins/jweb/EventService'
import {
  ContentContainer,
  DesktopButtonsContainer,
  LinkButton,
  MainContainer
} from './styles'

export const SavedShortcut = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()
  const location = useLocation<LocationState>()

  const { setAnimationDirection } = useAnimationContext()

  const onMyShortcutsButtonPress = () => {
    setAnimationDirection('right')
    history.push(`${base}`)
  }

  const onStartShortcutPress = () => {
    const shortcut = location.state?.shortcut
    dispatchEvent('ExecuteShortcut', { shortcutDefinition: shortcut })
  }

  const onBackButtonPress = () => {
    setAnimationDirection('left')
    dispatchEvent('com.hp.jarvis.servicerouting.serviceinstance.closed')
    dispatchEvent('Close')
  }

  const isFirstCreation =
    sessionStorage.getItem('emptyShortcutsList') === 'true'

  const getAnimationSource = useCallback(() => {
    const { desktopMode } = props

    if (isFirstCreation && desktopMode) {
      return FirstCreationDesktop
    }

    if (isFirstCreation && !desktopMode) {
      return FirstCreationMobile
    }

    return CheckMark
  }, [props, isFirstCreation])

  useNativeBackButton(() => {})

  return (
    <MainContainer id="main-container-saved-shortcut">
      <Header title={i18n.t('pages.ShortcutSaved.header')} />
      <ContentContainer id="content-container-saved-shortcut">
        <LottieAnimation
          source={JSON.stringify(getAnimationSource())}
          loop={false}
          autoplay
        />
        {isFirstCreation ? (
          <div>
            <h1 id="first-shortcut-created-title">
              {i18n.t('pages.ShortcutSaved.firstShortcutTitle')}
            </h1>
            <p id="first-shortcut-created-paragraph">
              {i18n.t('pages.ShortcutSaved.firstShortcutMessage')}
            </p>
          </div>
        ) : (
          <h1 id="shortcut-saved-title">
            {i18n.t('pages.ShortcutSaved.title')}
          </h1>
        )}
        <DesktopButtonsContainer
          id="desktop-btns-containter"
          desktopMode={props.desktopMode}
        >
          <Button
            id="start-shortcut-btn"
            onClick={onStartShortcutPress}
          >
            {i18n.t('pages.ShortcutSaved.startButton')}
          </Button>
          <Button
            id="my-shortcuts-btn"
            type="secondary"
            onClick={onMyShortcutsButtonPress}
          >
            {i18n.t('pages.ShortcutSaved.shortcutsButton')}
          </Button>
          {props.desktopMode ? (
            <Button
              id="back-btn-desktop-saved-shortcut"
              type="secondary"
              onClick={onBackButtonPress}
            >
              {i18n.t('pages.ShortcutSaved.homeLink')}
            </Button>
          ) : (
            <LinkButton
              id="back-btn-saved-shortcut"
              onClick={onBackButtonPress}
            >
              {i18n.t('pages.ShortcutSaved.homeLink')}
            </LinkButton>
          )}
        </DesktopButtonsContainer>
      </ContentContainer>
    </MainContainer>
  )
}
