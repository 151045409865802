import { IconChevronRight, ListItem } from '@veneer/core'
import { breakpoints, Button } from '@clientos/ui-toolkit'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const MainContainer = styled.div`
  @media screen and (max-width: ${breakpoints.md}) {
    ul {
      display: grid;
      gap: 24px;
    }
    .clientos-ui-toolkit-list-container__item {
      padding: 0px !important;
    }
  }
`

export const StyledFileTypeItem = styled(ListItem)`
  padding-right: 0px !important;
`

export const SubSectionTitle = styled.h5`
  display: flex;
`

export const FileTypeButton = styled(Button)`
  font-family: ${tokens.typography.family0} !important;
  color: #4e4e4e !important;
  font-size: 15px !important;
  fill: #4e4e4e !important;
`

export const StyledIconArrow = styled(IconChevronRight)<{ id: string }>`
  fill: #4e4e4e !important;
  color: #4e4e4e !important;
`
