/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line no-restricted-imports
import {
  DocData,
  DocInDocSetOptions,
  DocProviderPlugin,
  DocSet,
  WhenJWebReady
} from '@jarvis/jweb-core'
import TextDoc from './docs/TextDoc'
import TextEditDocSet from './TextEditDocSet'
import { isDocProviderPluginError } from './helper'
import { mockedTextEditDocSet } from '../../mocks/TextEditDocSet'
let DocProvider: DocProviderPlugin
export const ensureDocProvider = async () => {
  const res = await WhenJWebReady
  if (res) {
    DocProvider = res.Plugins.DocProvider as DocProviderPlugin
  } else {
    console.error('JWeb plugins not available')
    throw new Error('JWeb plugins not available')
  }
}

export const getCurrentDocSetId = async (): Promise<string> => {
  await ensureDocProvider()
  try {
    const resultDocSetTest = await DocProvider.getResultDocSet()
    const resultDocSet = resultDocSetTest as DocSet
    return resultDocSet.id
  } catch (error) {
    console.error('ResultDocSet undefined or null', error)
    throw new Error('ResultDocSet undefined or null')
  }
}
export const getDocData = async (
  options: DocInDocSetOptions
): Promise<DocData> => {
  await ensureDocProvider()
  const docData = await DocProvider.getDocData(options)
  if (isDocProviderPluginError(docData)) {
    console.error('Error while getting DocData')
    throw new Error('Error while getting DocData')
  }
  return docData as DocData
}
export const getDocset = async (): Promise<TextEditDocSet> => {
  try {
    await ensureDocProvider()
    const docSetId = await getCurrentDocSetId()
    const docSet = await DocProvider.getDocSet({
      docSetId
    })
    if (isDocProviderPluginError(docSet)) {
      console.info('Error while getting DocSet')
      throw new Error('Error while getting DocSet')
    }
    const textDocSet = new TextEditDocSet(
      docSetId,
      docSet.docs.map(
        (doc) => new TextDoc(doc.id, docSetId, doc.mediaType, doc.uri)
      )
    )
    return textDocSet
  } catch (error) {
    console.error('Error while ensuring Doc Provider', error)
    if (process.env.NODE_ENV === 'development') return mockedTextEditDocSet
    throw error
  }
}
export const saveImagesOnDocset = async (images: string[]) => {
  await ensureDocProvider()
  const docSet = await getDocset()
  DocProvider.removeDocsFromResultDocSet({
    docIds: docSet.docs.map(({ id }) => id)
  })
  return await Promise.all(
    images.map((img, idx) =>
      DocProvider.addDocToResultDocSet({
        data: img,
        fileName: `image-${idx + 1}.jpeg`,
        mediaType: 'image/jpeg'
      }).then((result) => {
        if (!isDocProviderPluginError(result)) return result
        else {
          console.error(
            'Error on adding doc to result docSet: ' + result.reason
          )
          throw new Error(
            'Error on adding doc to result docSet: ' + result.reason
          )
        }
      })
    )
  )
}
