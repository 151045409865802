import { useToast } from '@veneer/core'
import { TextField } from '@clientos/ui-toolkit'
import React, { useEffect, useState } from 'react'
import { i18n } from '../../assets/locale'
import { editConnectorName } from '../../clients/shortcuts'
import { Connector } from '../../data/schemas/connector'
import { toastMessages } from '../../modules/common/helpers'
import { StyledModal } from './styles'

type ModalEditAccountNameProps = {
  success: () => void
  repositories: Connector[]
  connector?: Connector
  show: boolean
  onCancel: () => void
  desktopMode: boolean
}

const ModalEditAccountName = (props: ModalEditAccountNameProps) => {
  const { addToast } = useToast()
  const [newAccountName, setNewAccountName] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (props.connector) {
      setNewAccountName(props.connector.connectorName || '')
    }
  }, [props])

  useEffect(() => {
    if (error.length > 0) {
      setError('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAccountName])

  const persistNewAccountName = async () => {
    try {
      if (!newAccountName) {
        setError(i18n.t('pages.AddSave.connectorNameCannotBeEmpty'))
        return
      }

      const taken = props.repositories.map((repo) => repo.connectorName)
      if (taken.includes(newAccountName)) {
        setError(i18n.t('pages.AddSave.duplicateConnectorName'))
        return
      }

      await editConnectorName(props.connector?.authorizationId, {
        connectorId: props.connector?.connectorId,
        newConnectorName: newAccountName,
        oldConnectorName: props.connector?.connectorName
      })
      props.success()
    } catch (e) {
      addToast?.({
        type: 'negative',
        ...toastMessages('cloudStorageLoginError')
      })
    }

    props.onCancel()
  }

  return (
    <StyledModal
      aria-label="modal-edit-account-name"
      title={'Edit name'}
      // TODO: Replace when batch translation is done: title={i18n.t('common.contextAction.editAccountNameHPX')}
      onClose={props.onCancel}
      closeOnBlur={false}
      isOpen={props.show}
      align="center"
      buttonGroup={{
        textPrimaryButton: i18n.t('common.contextAction.save'),
        textSecondaryButton: i18n.t('common.contextAction.cancel'),
        onClickPrimaryButton: () => {
          persistNewAccountName()
        },
        onClickSecondaryButton: () => {
          props.onCancel()
          setError('')
        },
        disabledPrimary: error.length > 0,
        size: 'large',
        orientation: 'vertical'
      }}
    >
      <TextField
        id="edit-account-name-text"
        value={newAccountName}
        onChange={(value) => setNewAccountName(value)}
        supportiveText={error}
        error={error.length > 0}
      />
    </StyledModal>
  )
}

export default ModalEditAccountName
