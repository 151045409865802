import React from 'react'
import Lottie from 'react-lottie'

type LottieAnimationProps = {
  loop?: boolean
  autoplay?: boolean
  rendererSettings?: object
  source: string
  height?: number
  width?: number
}

const LottieAnimation = (props: LottieAnimationProps) => (
  <Lottie
    options={{
      loop: props.loop,
      autoplay: props.autoplay,
      animationData: JSON.parse(props.source),
      rendererSettings: props.rendererSettings
    }}
    height={props.height}
    width={props.width}
  />
)

export default LottieAnimation
