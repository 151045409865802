import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState
} from 'react'
import { TextEditActionType } from '../../../../actions/textEdit'
import { TextEditContext } from '../../../../contexts/textEditContext'
import useAssetsProvider from '../../../../hooks/useAssetsProvider'
import useIsDesktop from '../../../../hooks/useIsDesktop'
import { returnToPreviousService } from '../../../../services/ServiceRouter'
import DataCollection, { uiEvents } from '../../../../services/DataCollection'
import { Modal } from '@clientos/ui-toolkit'
export type NoTextModalRef = {
  triggerModal(): void
}
const NoTextModal = forwardRef((_, ref) => {
  const [showModal, setShowModal] = useState(false)
  const { textEditState, dispatch } = useContext(TextEditContext)
  const assetsProvider = useAssetsProvider()
  const isDesktop = useIsDesktop()
  useImperativeHandle(
    ref,
    () =>
      ({
        triggerModal: () => {
          DataCollection.sendUIEvent(uiEvents.noTextModal)
          handleShowModal(true)()
        }
      }) as NoTextModalRef
  )
  const handleShowModal = (show: boolean) => () => {
    setShowModal(show)
  }
  if (!assetsProvider || !textEditState?.text || !dispatch) {
    console.error(
      'Does not have assets provider or text in edit state or dispatch function'
    )
    return null
  }
  const getText = (subkey: string) => {
    const assetKey = `application.${subkey}`
    return assetsProvider.getText(assetKey)
  }

  const resetText = () => {
    DataCollection.sendUIEvent(uiEvents.noTextModalRestoreTextButton)
    dispatch({ type: TextEditActionType.RESET_TEXT })
    handleShowModal(false)()
  }
  const handleNewScan = () => {
    DataCollection.sendUIEvent(uiEvents.noTextModalStartNewScanButton)
    returnToPreviousService('startNewScanButton')
  }
  return (
    <Modal
      closeOnBlur
      align="center"
      title={getText('modal.noTextModal.title')}
      onClose={handleShowModal(false)}
      isOpen={showModal}
      alignFooter="center"
      buttonGroup={{
        id: 'button-no-text-modal-' + (isDesktop ? 'desktop' : 'mobile'),
        orientation: 'vertical',
        // TODO: Apply TM or request new translation for changing to sentence case and replace by getText('modal.noTextModal.restoreButtonHPX')
        textPrimaryButton: 'Restore text',
        // TODO: Apply TM or request new translation for changing to sentence case and replace by getText('modal.noTextModal.newScanButtonHPX')
        textSecondaryButton: 'Start new scan',
        // TODO: Replace when batch translation is done: getText('modal.noTextModal.close')
        textTertiaryButton: 'Close',
        onClickPrimaryButton: resetText,
        onClickSecondaryButton: handleNewScan,
        onClickTertiaryButton: handleShowModal(false),
        tertiaryButtonType: 'ghost',
        size: 'large'
      }}
    >
      <p style={{ paddingTop: '24px' }}>{getText('modal.noTextModal.body')}</p>
    </Modal>
  )
})
NoTextModal.displayName = 'NoTextModal'
export default NoTextModal
