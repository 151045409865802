import React from 'react'
import useIsDesktop from '../../hooks/useIsDesktop'
import './IconButton.scss'
export type IconButtonProps = {
  id?: string
  icon: JSX.Element
  label: string
  onClick?: () => void
  disabled?: boolean
  appearence?: 'primary' | ''
  render?: boolean
}
const IconButton = ({
  icon,
  label,
  onClick,
  id,
  disabled = false,
  appearence = '',
  render = true
}: IconButtonProps) => {
  const isDesktop = useIsDesktop()
  if (!render) return null
  const handleOnClick = () => {
    if (disabled || !onClick) return
    onClick()
  }
  return (
    <div
      id={id}
      aria-disabled={disabled}
      className={`iconButton-container ${disabled ? 'disabled' : ''} ${
        isDesktop ? 'desktop ' : ''
      } ${appearence}`}
      onClick={handleOnClick}
    >
      <div className="iconButton-icon">{icon}</div>
      <label
        role="label"
        className="caption"
      >
        {label}
      </label>
    </div>
  )
}

export default IconButton
