import { colors } from '@clientos/ui-toolkit'
import styled from 'styled-components'

export const DesktopGrid = styled.div`
  .clientos-ui-toolkit-list-container__item {
    background: ${colors.royalBlue0};
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .clientos-ui-toolkit-list-container__divider {
    display: none;
  }

  .clientos-ui-toolkit-list-container {
    .clientos-ui-toolkit-list-container__end-content svg {
      color: ${colors.royalBlue7} !important;
    }
  }
`
