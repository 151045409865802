// eslint-disable-next-line no-restricted-imports
import {
  LaunchServiceOptions,
  Service,
  ServiceId,
  ServiceRoutingError,
  ServiceRoutingPlugin,
  WhenJWebReady
} from '@jarvis/jweb-core'
import { LocalPrinter, LocalPrinterInterface } from 'src/services/JWeb/Type'

const serviceId = 'SaveDocs'

let ServiceRouting: ServiceRoutingPlugin

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ensureServiceRouting = async () => {
  const res = await WhenJWebReady
  if (res && res.Plugins && res.Plugins.ServiceRouting) {
    ServiceRouting = res.Plugins.ServiceRouting as ServiceRoutingPlugin
  } else throw new Error('JWeb plugins not available')
}

const isServiceRoutingError = (
  value: unknown | ServiceRoutingError
): value is ServiceRoutingError => {
  return (value as ServiceRoutingError).errorType !== undefined
}

export const getAvailableServices = async (): Promise<Service[]> => {
  await ensureServiceRouting()
  const serviceList = await ServiceRouting.getServices()

  return isServiceRoutingError(serviceList)
    ? ([] as Service[])
    : serviceList.services
}

const isServiceAvailable = async (
  serviceId: ServiceId
): Promise<Service | null> => {
  const services = await getAvailableServices()
  const service = services?.find((service: Service) => service.id === serviceId)

  if (service) {
    return service as Service
  }

  return null
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const launchJarvisService = async (docSetId: string) => {
  await ensureServiceRouting()
  const serviceIsAvailable = await isServiceAvailable(serviceId)

  if (serviceIsAvailable) {
    const launchServiceOptions: LaunchServiceOptions = {
      serviceId: serviceId,
      serviceOptions: {
        jarvisDocSetId: docSetId
      }
    }

    const serviceInstance =
      await ServiceRouting.launchService(launchServiceOptions)

    if (isServiceRoutingError(serviceInstance)) {
      console.error(
        `Error while launching service: ${serviceInstance.errorType} - ${serviceInstance.reason}`
      )
    } else {
      console.log(
        `Launched service ${serviceInstance.serviceId} as launched service instance ${serviceInstance.instanceId}`
      )
    }
  }
}

export const isServiceRoutingAvailable = async (): Promise<boolean> => {
  return (await isServiceAvailable(serviceId)) != null
}

// NOTE: This code is intended for use after the implementation by another team is complete.
// For the time being, we will use `localPrintersMock` as a temporary solution.
export const getLocalPrinters = async (): Promise<
  LocalPrinterInterface | { localPrinters: LocalPrinter[] }
> => {
  await ensureServiceRouting()
  try {
    const options: LaunchServiceOptions =
      (await ServiceRouting.getServiceInstanceLaunchOptions()) as LaunchServiceOptions
    if (options && options.serviceOptions) {
      return options.serviceOptions as LocalPrinterInterface
    }
  } catch (error) {
    console.warn((error as ServiceRoutingError).errorType)
  }
  return { localPrinters: [] }
}
