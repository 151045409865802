// eslint-disable-next-line no-restricted-imports
import {
  Action,
  AssetUnitType,
  CDMEventData,
  CreatePublisherOptions,
  DataCollectionEventNames,
  DataValveCDMEventData,
  EventServicePlugin,
  EventServicePluginError,
  JWebPromise,
  Publisher,
  SimpleUiEvent,
  Subscriber,
  ValveControllerMetadata,
  WhenJWebReady
} from '@jarvis/jweb-core'
import { store } from 'src/stores'

const version = '1.1.0'
let appInstanceId: string | undefined = undefined
let platform: string | undefined = undefined
let country: string | undefined = undefined
let EventService: EventServicePlugin

const ensureJWeb = async () => {
  const response: JWebPromise = await WhenJWebReady
  platform = response.JWeb.platform
  EventService = response.Plugins.EventService
  if (!country) {
    const getInfo = await response.Plugins.Device.getInfo()
    country = getInfo.locale.country
  }
}

const getAssetUnit = () => {
  switch (platform) {
    case 'ios':
    case 'android':
      return AssetUnitType.mobile as AssetUnitType
    case 'mac':
    case 'windows':
    default:
      return AssetUnitType.desktop as AssetUnitType
  }
}

const getAppInstanceId = () => {
  const value = store.getState().application.appInstanceId

  if (!value) {
    console.error(`Missing appInstanceId query parameter`)
    return
  }

  return value?.toLowerCase()
}

const getValveControllerMetadata = (): ValveControllerMetadata => {
  appInstanceId = getAppInstanceId()
  return {
    assetUnit: getAssetUnit(),
    appInstanceId: appInstanceId,
    country: country
  }
}

const getPublisher = async (
  eventService: EventServicePlugin,
  publisherId: string,
  options: CreatePublisherOptions
) => {
  const publisher = await eventService.createPublisher(publisherId, options)

  if (hasEventServicePluginError(publisher as EventServicePluginError))
    console.error(
      'get publisher failed',
      (publisher as EventServicePluginError).errorType
    )
  else return publisher as Publisher
}

const hasEventServicePluginError = (
  subscriber: Subscriber | EventServicePluginError
) => {
  const hasError =
    (subscriber as EventServicePluginError).errorType !== undefined
  if (hasError) {
    console.error(
      'has event service plugin error',
      (subscriber as EventServicePluginError).errorType
    )
  }
  return hasError
}

const publish = async (
  publisher: Publisher,
  eventName: string,
  eventData: DataValveCDMEventData // Review type
) => {
  const response = await publisher.publish(eventName, eventData)
  if (
    response &&
    hasEventServicePluginError(response as EventServicePluginError)
  ) {
    console.error(
      `Publishing data collection ${eventName} Failed`,
      (response as EventServicePluginError).errorType,
      (response as EventServicePluginError).reason
    )
  }
  // eslint-disable-next-line no-console
  else console.info(`Publishing data collecion ${eventName} Success`)
}

const sendUiEventWithEventService = async (simpleUiEvent: SimpleUiEvent) => {
  const eventName = DataCollectionEventNames.cdmEvent

  const publisherId = `com.hp.cloudscans.datacollection.publisher-${Math.random()
    .toString(36)
    .substring(2)}`

  const createPublisherOptions: CreatePublisherOptions = {
    allowEventingFallback: true
  }

  const sendUiPublisher = await getPublisher(
    EventService,
    publisherId,
    createPublisherOptions
  )

  const cdmEventData: CDMEventData = {
    dateTime: new Date().toISOString(),
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    version: '1.4.0',
    eventDetail: simpleUiEvent
  }

  const valveControllerMetadata = getValveControllerMetadata()
  const eventData: DataValveCDMEventData = {
    events: [cdmEventData],
    valveControllerMetadata
  }

  if (sendUiPublisher) {
    await publish(sendUiPublisher, eventName, eventData)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sendUiEvent = async (uiData: SimpleUiEvent) => {
  await ensureJWeb()
  const simpleUiEvent: SimpleUiEvent = {
    ...uiData
  }
  await sendUiEventWithEventService(simpleUiEvent)
}

export const uiData = {
  JobsList: {
    version,
    jobsListScreen: {
      version,
      action: Action.screenDisplayed,
      screenName: 'JobsListScreen',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01',
      actionAuxParams: ''
    },
    btnDeleteJob: {
      version,
      action: Action.controlButtonClicked,
      controlName: 'Delete',
      screenName: 'JobsListScreen',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01'
    },
    btnDownloadJob: {
      version,
      action: Action.controlButtonClicked,
      controlName: 'Download',
      screenName: 'JobsListScreen',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01',
      actionAuxParams: ''
    },
    btnRefreshJobList: {
      version,
      action: Action.controlButtonClicked,
      controlName: 'RefreshJobs',
      screenName: 'JobsListScreen',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01'
    },
    btnShowPrinterList: {
      version,
      action: Action.controlButtonClicked,
      controlName: 'ListPrinters',
      screenName: 'JobsListScreen',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01'
    },
    backBtn: {
      version,
      action: Action.controlButtonClicked,
      controlName: 'BackButton',
      screenName: 'JobsListScreen',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01'
    }
  },
  NoJobsList: {
    version,
    noJobsListScreen: {
      version,
      action: Action.screenDisplayed,
      screenName: 'JobsListScreen',
      screenMode: 'NoJobs',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01',
      actionAuxParams: 'totalJobsCount=0&failedJobsCount=0'
    },
    btnShowPrinterList: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'JobsListScreen',
      controlName: 'ListPrinters',
      screenMode: 'NoJobs',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01',
      actionAuxParams: ''
    },
    backBtn: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'JobsListScreen',
      controlName: 'BackButton',
      screenMode: 'NoJobs',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01',
      actionAuxParams: ''
    }
  },
  SessionSync: {
    version,
    sessionSyncScreen: {
      version,
      action: Action.screenDisplayed,
      screenName: 'SessionSyncScreen',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01'
    },
    btnShowPrinterList: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'SessionSyncScreen',
      controlName: 'ListPrinters',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01'
    },
    btnStartSession: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'SessionSyncScreen',
      controlName: 'StartSession',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01'
    },
    backBtn: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'SessionSyncScreen',
      controlName: 'BackButton',
      screenPath: '/CloudscansReactWebapp/',
      activity: 'CloudScans-v01'
    }
  },
  PrintersList: {
    version,
    printersListPanel: {
      version,
      action: Action.moduleDisplayed,
      screenName: 'PrintersListSection',
      screenPath: '',
      activity: 'CloudScans-v01',
      actionAuxParams: ''
    },
    btnSetupSession: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'PrintersListSection',
      controlName: 'UpdateSession',
      controlDetail: '',
      screenPath: '',
      activity: 'CloudScans-v01'
    },
    btnClosePrintersList: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'PrintersListSection',
      controlName: 'Close',
      screenPath: '',
      activity: 'CloudScans-v01'
    }
  },
  SetupSession: {
    version,
    setupSessionModal: {
      version,
      action: Action.modalWindowDisplayed,
      screenName: 'SessionSetupModal',
      controlName: 'UpdateSession',
      screenPath: '/CloudscansReactWebapp/JobsListScreen/',
      activity: 'CloudScans-v01'
    },
    btnSaveSession: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'SessionSetupModal',
      controlName: 'Save',
      controlDetail: '',
      screenPath: '/CloudscansReactWebapp/JobsListScreen/',
      activity: 'CloudScans-v01'
    },
    btnCloseSessionModal: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'SessionSetupModal',
      controlName: 'Exit',
      screenPath: '/CloudscansReactWebapp/JobsListScreen/',
      activity: 'CloudScans-v01',
      actionAuxParams: ''
    }
  },
  SessionCreationSuccess: {
    version,
    successModal: {
      version,
      action: Action.modalWindowDisplayed,
      screenName: 'SessionSuccessfullyCreatedModal',
      screenPath: '/CloudscansReactWebapp/JobsListScreen/',
      activity: 'CloudScans-v01'
    },
    btnCloseSuccessModal: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'SessionSuccessfullyCreatedModal',
      controlName: 'Close',
      screenPath: '/CloudscansReactWebapp/JobsListScreen/',
      activity: 'CloudScans-v01'
    }
  },
  SessionExpiry: {
    version,
    sessionExpiryBanner: {
      version,
      action: Action.moduleDisplayed,
      screenName: 'SessionExpiryBanner',
      screenPath: '/CloudscansReactWebapp/JobsListScreen/',
      activity: 'CloudScans-v01'
    },
    btnRenewSession: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'SessionExpiryBanner',
      controlName: 'Renew',
      screenPath: '/CloudscansReactWebapp/JobsListScreen/',
      activity: 'CloudScans-v01'
    },
    btnCloseBanner: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'SessionExpiryBanner',
      controlName: 'Close',
      screenPath: '/CloudscansReactWebapp/JobsListScreen/',
      activity: 'CloudScans-v01'
    }
  },
  DeleteJob: {
    version,
    deleteJobConfirmation: {
      version,
      action: Action.modalWindowDisplayed,
      screenName: 'DeleteJobModal',
      screenPath: '/CloudscansReactWebapp/JobsListScreen/',
      activity: 'CloudScans-v01'
    },
    btnYes: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'DeleteJobModal',
      controlName: 'Yes',
      screenPath: '/CloudscansReactWebapp/JobsListScreen/',
      activity: 'CloudScans-v01'
    },
    btnNo: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'DeleteJobModal',
      controlName: 'No',
      screenPath: '/CloudscansReactWebapp/JobsListScreen/',
      activity: 'CloudScans-v01'
    }
  }
}
