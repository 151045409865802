import { ButtonGroup, GenericCard, Modal } from '@clientos/ui-toolkit'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import MobileScroll from 'src/components/MobileScroll'
import PageTemplate from 'src/pages/PageTemplate'
import { i18n } from '../../assets/locale'
import Header from '../../components/Header'
import PrintOptions from '../../components/PrintOptions'
import { useDestinationsContext } from '../../contexts/destinations/DestinationsContext'
import { useEditContext } from '../../contexts/edit/EditContext'
import { useSettingsContext } from '../../contexts/settings/SettingsContext'
import { useToggleContext } from '../../contexts/toggle/ToggleContext'
import { PageProps } from '../../data/schemas/app'
import { CopiesConfigOptionsList } from '../../data/schemas/destinations'
import { LocationState } from '../../data/schemas/location'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import { sendUiEvent, uiData } from '../../plugins/jweb/DataCollection'
import { MainContainer } from './styles'

export const AddPrint = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()
  const location = useLocation<LocationState>()

  const [showCancelModal, setShowCancelModal] = useState(false)
  const { destinationsState, destinationsActions } = useDestinationsContext()
  const { editState } = useEditContext()
  const { toggleState, toggleActions } = useToggleContext()
  const { settingsActions } = useSettingsContext()

  const onBackButtonPress = () => {
    if (location.state?.template) {
      if (location.state?.template.index === 0) {
        onClickCancelButton()
      } else {
        const index = location.state?.template.index - 1
        history.push({
          pathname: `${base}/${location.state?.template.actions[index].route}`,
          state: {
            template: { ...location.state?.template, index }
          }
        })
      }
    } else {
      const checkpoint = destinationsState.checkpointState.print
      destinationsActions.updatePrint(checkpoint)
      history.push(`${base}/destinations`)
    }
  }

  const onCopiesSettingChange = (value: number) => {
    destinationsActions.updatePrint({
      ...destinationsState.currentState.print,
      numberOfCopies: value
    })
  }

  const onColorSettingChange = (value: 1 | 0) => {
    destinationsActions.updatePrint({
      ...destinationsState.currentState.print,
      color: value === 1 ? 'true' : 'false'
    })
  }

  const onTwoSidedSettingChange = (
    value: 'one-sided' | 'two-sided-short-edge' | 'two-sided-long-edge'
  ) => {
    destinationsActions.updatePrint({
      ...destinationsState.currentState.print,
      printDuplex: value
    })
  }

  const onContinueButtonPress = () => {
    destinationsActions.updatePrint({
      ...destinationsState.currentState.print,
      set: true
    })

    if (location.state?.template) {
      if (
        location.state?.template.index ===
        location.state?.template.actions.length - 1
      ) {
        history.push({
          pathname: `${base}/creation_settings`,
          state: {
            template: location.state?.template
          }
        })
      } else {
        const index = location.state?.template.index + 1
        history.push({
          pathname: `${base}/${location.state?.template.actions[index].route}`,
          state: {
            template: { ...location.state?.template, index }
          }
        })
      }
    } else {
      destinationsActions.updateCheckpointState({
        ...destinationsState.currentState,
        print: { ...destinationsState.currentState.print, set: true }
      })
      if (toggleState.email) {
        history.push(`${base}/email`)
      }

      if (!toggleState.email && toggleState.save) {
        history.push(`${base}/repositories`)
      }

      if (!toggleState.email && !toggleState.save) {
        history.push(`${base}/creation_settings`)
      }
    }
  }

  const onClickCancelButton = () => {
    setShowCancelModal(!showCancelModal)
    sendUiEvent(uiData.screenCancelEdit)
  }

  const onConfirmCancelModal = () => {
    onClickCancelButton()
    toggleActions.resetState()
    if (location.state?.template) {
      if (location.state?.template.index === 0) {
        destinationsActions.resetState()
        settingsActions.resetState()
        history.push(`${base}/add`)
      } else {
        const index = location.state?.template.index - 1
        history.push({
          pathname: `${base}/${location.state?.template.actions[index].route}`,
          state: {
            template: { ...location.state?.template, index }
          }
        })
      }
    } else {
      const checkpoint = destinationsState.checkpointState.print
      destinationsActions.updatePrint(checkpoint)
      history.push(`${base}/destinations`)
    }
  }

  useNativeBackButton(onBackButtonPress)

  return (
    <PageTemplate
      desktopMode={props.desktopMode}
      header={
        <Header
          backButtonLabel={i18n.t('common.contextAction.cancel')}
          onPressIconLeft={onBackButtonPress}
        />
      }
      content={
        <MainContainer id="main-container-add-print">
          <GenericCard>
            <GenericCard.Header
              title={
                editState.editMode
                  ? i18n.t('pages.AddPrint.edit')
                  : i18n.t('pages.AddPrint.header')
              }
              borderBottom={false}
            />
            <GenericCard.Body paddingBodyTop={false}>
              <MobileScroll offset="275px">
                <div
                  id="intro-text-add-print"
                  style={{
                    paddingBottom: '32px',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#212121'
                  }}
                >
                  {/* //TODO: Replace when batch translation is done:
              i18n.t('pages.AddPrint.introText')  */}
                  Print your scanned files to the selected printer when running
                  this Shortcut.
                </div>
                <div
                  id="instruction-text-add-print"
                  style={{
                    paddingBottom: '16px',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#525365'
                  }}
                >
                  {i18n.t('pages.AddPrint.instructions')}
                </div>
                <PrintOptions
                  desktopMode={props.desktopMode}
                  copiesFieldOptions={CopiesConfigOptionsList}
                  copiesFieldLabel={i18n.t('pages.AddPrint.copies')}
                  numberOfCopies={[
                    destinationsState.currentState.print.numberOfCopies
                  ]}
                  onChangeCopies={(value: any) => onCopiesSettingChange(value)}
                  colorFieldOptions={[
                    { value: 1, label: i18n.t('pages.AddPrint.color') },
                    { value: 0, label: i18n.t('pages.AddPrint.grayscale') }
                  ]}
                  colorFieldLabel={i18n.t('pages.AddPrint.color')}
                  colorFieldValue={[
                    Number(
                      destinationsState.currentState.print.color === 'true'
                    )
                  ]}
                  onChangeColor={(value: any) => onColorSettingChange(value)}
                  twoSidedFieldOptions={[
                    {
                      value: 'one-sided',
                      label: i18n.t('pages.AddPrint.off')
                    },
                    {
                      value: 'two-sided-short-edge',
                      label: i18n.t('pages.AddPrint.sedge')
                    },
                    {
                      value: 'two-sided-long-edge',
                      label: i18n.t('pages.AddPrint.ledge')
                    }
                  ]}
                  twoSidedFieldLabel={i18n.t('pages.AddPrint.two-sided')}
                  twoSidedFieldValue={[
                    destinationsState.currentState.print.printDuplex
                  ]}
                  onChangeTwoSided={(value: any) =>
                    onTwoSidedSettingChange(value)
                  }
                />
              </MobileScroll>
            </GenericCard.Body>
          </GenericCard>
          <Modal
            id="confirmation-modal"
            aria-label="confirmation-modal"
            onClose={onClickCancelButton}
            closeOnBlur={false}
            isOpen={showCancelModal}
            /* TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalTitleHPX') */
            title={'Cancel this Shortcut?'}
            align="center"
            buttonGroup={{
              // TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalCancelEditsButtonHPX')
              textPrimaryButton: 'Yes, cancel',
              primaryButtonType: 'negative',
              // TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalContinueEditsButtonHPX')
              textSecondaryButton: 'Go back',
              onClickPrimaryButton: () => {
                onConfirmCancelModal()
              },
              onClickSecondaryButton: () => {
                onClickCancelButton()
              },
              size: 'large',
              orientation: 'vertical'
            }}
          >
            {/* TODO: Replace when batch translation is done:
        i18n.t('pages.Destinations.modalDescriptionHPX') */}
            <p>{'Any edits you made will be lost.'}</p>
          </Modal>
        </MainContainer>
      }
      mobileFooter={
        <ButtonGroup
          id="add-print-destination-btn"
          textPrimaryButton={i18n.t('common.contextAction.continue')}
          size="large"
          orientation="horizontal-full"
          onClickPrimaryButton={() => onContinueButtonPress()}
        />
      }
    />
  )
}
