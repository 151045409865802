import { ShortcutSettings } from '../../data/schemas/shortcutSettings'

export const initialState: ShortcutSettings = {
  presentation: {
    color: ''
  },
  fileType: 'jpeg',
  relativeFolderName: '',
  jobName: '',
  quickRun: false,
  smartFileName: false,
  ocrLanguage: '',
  scanDestinations: false,
  currentConnectorId: '',
  currentConnectorType: '',
  selectedFolder: '',
  editSelectedFolder: '',
  foldersList: [],
  folderTree: []
}

export const settingsReducer = (
  state: any,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case 'presentation':
      return {
        ...state,
        presentation: action.payload
      }
    case 'fileType':
      return {
        ...state,
        fileType: action.payload
      }
    case 'fileName':
      return {
        ...state,
        fileName: action.payload
      }
    case 'relativeFolderName':
      return {
        ...state,
        relativeFolderName: action.payload
      }
    case 'jobName':
      return {
        ...state,
        jobName: action.payload
      }
    case 'quickRun':
      return {
        ...state,
        quickRun: action.payload
      }
    case 'smartFileName':
      return {
        ...state,
        smartFileName: action.payload
      }
    case 'ocrLanguage':
      return {
        ...state,
        ocrLanguage: action.payload
      }
    case 'scanDestinations':
      return {
        ...state,
        scanDestinations: action.payload
      }
    case 'currentConnectorId':
      return {
        ...state,
        currentConnectorId: action.payload
      }
    case 'currentConnectorType':
      return {
        ...state,
        currentConnectorType: action.payload
      }
    case 'selectedFolder':
      return {
        ...state,
        selectedFolder: action.payload
      }
    case 'editSelectedFolder':
      return {
        ...state,
        editSelectedFolder: action.payload
      }
    case 'foldersList':
      return {
        ...state,
        foldersList: action.payload
      }
    case 'folderTree':
      return {
        ...state,
        folderTree: action.payload
      }
    case 'reset':
      return {
        ...initialState
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
