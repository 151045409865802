import styled from 'styled-components'

export const StyledSectionListContainer = styled.div`
  .clientos-ui-toolkit-section-list-container__content__header {
    padding: 0 !important;
  }
`

export const MainListContainer = styled.div`
  .clientos-ui-toolkit-list-container
    > .clientos-ui-toolkit-list-container__item {
    padding: 24px 12px;
  }
`
