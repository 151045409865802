import { Loader } from '@clientos/ui-toolkit'
import React, { Suspense } from 'react'
import { StyledLoader } from './styles'

const LazyImage = React.lazy(() => import('../../components/LazyImage'))

type ImageProps = {
  src: string
  id: string
  ariaLabel: string
  width: string
  height: string
  alt: string
}

const LoaderImage = () => {
  return (
    <StyledLoader>
      <Loader />
    </StyledLoader>
  )
}

const Image = (props: ImageProps) => {
  return (
    <Suspense fallback={<LoaderImage />}>
      <LazyImage
        id={props.id}
        src={props.src}
        width={props.width}
        height={props.height}
        ariaLabel={props.ariaLabel}
        alt={props.alt}
      />
    </Suspense>
  )
}

export default Image
