import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'container'
    'singleFooter';
`
export const ContentContainer = styled.div`
  padding: 0px 7px 40px 7px;
  grid-area: 'container';
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1,
  p,
  button,
  a,
  img {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  svg {
    color: #08a622;
  }

  h1 {
    font-size: 22px;
    line-height: 26px;
    color: #515151;
    text-align: center;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    color: #515151;
    text-align: center;
  }
  @media (min-width: 900px) {
    svg {
      margin-bottom: 15px;
    }
    h1 {
      font-size: 30px;
    }
  }
`
export const DesktopButtonsContainer = styled.div<{ desktopMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    justify-content: center;
    width: 175px;
  }

  a {
    font-size: 16px !important;
    line-height: 24px !important;
    color: #027aae !important;
  }

  button {
    display: flex;
    justify-content: center;
    width: 175px;
  }
  @media (min-width: 700px) {
    button {
      width: 210px;
      margin-right: 0px;
    }
  }
  @media (min-width: 960px) {
    margin-top: 15px;
    padding-bottom: 75px;
    ${(props) =>
      props.desktopMode
        ? 'flex-direction: row-reverse;'
        : 'flex-direction: column;'}

    button {
      ${(props) =>
        props.desktopMode ? 'margin-left: 15px;' : 'margin-left: 0px;'}
    }
  }
`

export const LinkButton = styled.button`
  font-family: 'HP Simplified Light' !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #027aae !important;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -o-tap-highlight-color: transparent;
`
