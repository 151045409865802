import { JarvisWebHttpClient } from '@jarvis/web-http'
import { authProvider } from '../../auth'
import Config from '../../config'
import {
  ConnectorLoginSpecs,
  ConnectorUpdateSpecs,
  FolderId
} from '../../data/schemas/connector'
import { DeviceSession } from '../../data/schemas/session'
import { ShortcutItem } from '../../data/schemas/shortcut'
import { UserStates } from '../../data/schemas/userStates'

const client: JarvisWebHttpClient = new JarvisWebHttpClient({
  defaultBaseURL: Config.SHORTCUTS_MANAGER_URL,
  defaultAuthProvider: authProvider,
  defaultRetries: 0
})

export const getShortcuts = async () => {
  const response = await client.get({
    url: '/shortcuts',
    timeout: 30000
  })

  return response.data
}

export const createShortcut = async (
  item: ShortcutItem,
  validateDuplicateName: boolean = false
) => {
  const response = await client.post({
    url: `/shortcuts?validateDuplicateName=${validateDuplicateName}`,
    data: item,
    timeout: 30000
  })

  return response.data
}

export const updateShortcut = async (
  item: ShortcutItem,
  id: string,
  validateDuplicateName: boolean = false
) => {
  const response = await client.put({
    url: `/shortcuts/${id}?validateDuplicateName=${validateDuplicateName}`,
    data: item,
    timeout: 30000
  })

  return response.data
}

export const deleteShortcut = async (shortcutUuid: string) => {
  const response = await client.delete({
    url: `/shortcuts/${shortcutUuid}`,
    timeout: 30000
  })

  return response.data
}

export const getCloudStoragesList = async () => {
  const response = await client.get({
    url: '/connectors/capabilities',
    timeout: 30000
  })

  return response.data
}

export const generateCloudStorageLoginURL = async (
  specifications: ConnectorLoginSpecs
) => {
  const response = await client.post({
    url: '/connectors/authorization',
    data: specifications,
    timeout: 30000
  })

  return response
}

export const getFoldersList = async (connectorId: string) => {
  const response = await client.get({
    url: `/connectors/folders/${connectorId}`,
    timeout: 30000
  })

  return response.data
}

export const getFoldersName = async (folderIds: FolderId[]) => {
  const response = await client.post({
    url: `/connectors/folders/name`,
    data: { folderIds },
    timeout: 30000
  })

  return response.data
}

export const getSessionByDeviceUuid = async (deviceUuid: string) => {
  const response = await client.get({
    url: `/devicesessions/${deviceUuid}`,
    timeout: 30000
  })

  return response.data
}

export const createSession = async (deviceSession: DeviceSession) => {
  const response = await client.post({
    url: '/devicesessions',
    data: deviceSession,
    timeout: 30000
  })

  return response.data
}

export const deleteSession = async (deviceUuid: string) => {
  const response = await client.delete({
    url: `/devicesessions/${deviceUuid}`,
    timeout: 30000
  })

  return response.data
}

export const deviceLookup = async (deviceUuid: string) => {
  const response = await client.get({
    url: `/deviceinfo/${deviceUuid}`,
    timeout: 30000
  })

  return response.data
}

export const getUserStates = async () => {
  const response = await client.get({
    url: '/userstates',
    timeout: 30000
  })

  return response.data
}

export const saveUserStates = async (state: UserStates) => {
  const response = await client.post({
    url: '/userstates',
    data: state,
    timeout: 30000
  })

  return response.data
}

export const getEntitlements = async (locale: string, modelName: string) => {
  const response = await client.get({
    url: `/entitlements?locale=${locale}&modelName=${modelName}`,
    timeout: 30000
  })

  return response.data
}

export const editConnectorName = async (
  authId: string,
  connectorUpdate: ConnectorUpdateSpecs
) => {
  const response = await client.patch({
    url: `/connectors/authorization/${authId}`,
    data: connectorUpdate,
    timeout: 30000
  })
  return response.data
}
