export type UserContextState = {
  isEntitled: boolean
  givenName: string
  emailAddress: string
}

export const initialState: UserContextState = {
  isEntitled: false,
  givenName: '',
  emailAddress: ''
}

export const userReducer = (
  state: UserContextState,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case 'entitled':
      return {
        ...state,
        isEntitled: action.payload
      }
    case 'name':
      return {
        ...state,
        givenName: action.payload
      }
    case 'email':
      return {
        ...state,
        emailAddress: action.payload
      }
    case 'reset':
      return {
        ...initialState
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
