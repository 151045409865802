import { useCallback, useState } from 'react'
import { getFoldersList } from '../../clients/shortcuts'
import { FolderItem, FolderStructure } from '../../data/schemas/folders'
import {
  mapTreeData,
  modifyIfCharactersExists,
  searchNode
} from '../../modules/destinations/treeViewMapping/helpers'

const characteres = '%21'
const symbol = '!'

const useTreeViewNodes = () => {
  const [isLoadingFolders, setFoldersLoading] = useState(false)
  const [folderTree, setTree] = useState<FolderItem[]>([])
  const [errorOnFetchFolder, setErrorOnFetchFolder] = useState(null)

  const mapNewNode = useCallback(
    (folder: FolderStructure) => {
      setTree((previousTree) => {
        return !folderTree.length
          ? [
              {
                ...folder.parent,
                loaded: true,
                isValid: true,
                data: folder.data
              }
            ]
          : mapTreeData(previousTree, folder)
      })
    },
    [folderTree]
  )

  const getFoldersByParentId = useCallback(
    async (parentId: string, showLoader: boolean) => {
      try {
        setFoldersLoading(showLoader)
        const folderNode = await getFoldersList(parentId)
        // TODO interim measure until Tenzing modifies return of OneDrive related ID in child folders
        modifyIfCharactersExists(characteres, symbol)(folderNode)
        mapNewNode(folderNode)
      } catch (error: any) {
        setErrorOnFetchFolder(error)
      } finally {
        setFoldersLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getFoldersList, mapNewNode]
  )
  const onNodeExpand = (nodeId: string) => {
    const nodeData = searchNode(folderTree, nodeId)
    if (nodeData && !nodeData.loaded && !nodeData?.data?.length) {
      getFoldersByParentId(nodeId, false)
    }
  }

  const onFolderListingOpen = useCallback(
    (connectorId: string) => {
      if (!folderTree.length) {
        getFoldersByParentId(connectorId, true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderTree.length, getFoldersByParentId]
  )

  const cleanFolderTree = () => {
    setTree([])
  }

  return {
    isLoadingFolders,
    folderTree,
    errorOnFetchFolder,
    onFolderListingOpen,
    onNodeExpand,
    getFoldersList,
    cleanFolderTree,
    setErrorOnFetchFolder
  }
}

export default useTreeViewNodes
