import React, { createContext, useContext, useReducer } from 'react'
import {
  DestinationsConfig,
  EmailConfigState,
  PrintConfigState,
  RepositoryConfigState
} from '../../data/schemas/destinations'
import {
  destinationsReducer,
  DestinationsState,
  initialState
} from './DestinationsReducer'

type ShortcutActions = {
  updateEmail: (emailConfig: EmailConfigState) => void
  updatePrint: (printConfig: PrintConfigState) => void
  updateRepository: (repositoryConfig: RepositoryConfigState) => void
  updateCheckpointState: (destinationsConfig: DestinationsConfig) => void
  resetState: () => void
}

type DestinationsContextType = {
  destinationsState: DestinationsState
  destinationsActions: ShortcutActions
}

type DestinationsContextProviderProps = { children: React.ReactNode }

const defaultContextValue: DestinationsContextType = {
  destinationsState: {} as DestinationsState,
  destinationsActions: {} as ShortcutActions
}

export const DestinationsContext = createContext(defaultContextValue)

export const DestinationsContextProvider = ({
  children
}: DestinationsContextProviderProps) => {
  const [destinationsState, dispatch] = useReducer(
    destinationsReducer,
    initialState
  )

  const destinationsActions = {
    updateEmail: (emailConfig: EmailConfigState) => {
      dispatch({ type: 'email', payload: emailConfig })
    },
    updatePrint: (printConfig: PrintConfigState) => {
      dispatch({ type: 'print', payload: printConfig })
    },
    updateRepository: (repositoryConfig: RepositoryConfigState) => {
      dispatch({ type: 'repository', payload: repositoryConfig })
    },
    updateCheckpointState: (destinationsConfig: DestinationsConfig) => {
      dispatch({ type: 'checkpoint', payload: destinationsConfig })
    },
    resetState: () => {
      dispatch({ type: 'reset' })
    }
  }

  return (
    <DestinationsContext.Provider
      value={{ destinationsState, destinationsActions }}
    >
      {children}
    </DestinationsContext.Provider>
  )
}

export const useDestinationsContext = () => useContext(DestinationsContext)
