import React from 'react'
import SettingsPanel from './SettingsPanel'
import { GenericCard } from '@clientos/ui-toolkit'
import './ConnectedPrinters.scss'
import { useSelector } from 'react-redux'
import { RootState } from 'src/stores'
import MobileScroll from 'src/pages/Template/MobileScroll'

const ConnectedPrinters = () => {
  const mobileMode: boolean = useSelector(
    (state: RootState) => state.screens.mobileMode
  )

  return (
    <>
      <div className="connected-printers-component">
        <GenericCard>
          {/* TODO: when new batch translation comes, replace per {i18n.t('application.settingsPanel.titleHPX')} */}
          <GenericCard.Header title="My cloud connected printers" />
          <GenericCard.Body
            paddingBodyBottom={true}
            paddingBodyTop={false}
            paddingBodyHorizontal={true}
          >
            {mobileMode ? (
              <MobileScroll offset="220px">
                <SettingsPanel />
              </MobileScroll>
            ) : (
              <SettingsPanel />
            )}
          </GenericCard.Body>
        </GenericCard>
      </div>
    </>
  )
}

export default ConnectedPrinters
