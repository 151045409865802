import {
  BackNavigationButton,
  Button,
  IconArrowLeft,
  IconGear,
  IconHelp
} from '@clientos/ui-toolkit'
import React, { ReactElement, useContext } from 'react'
import { DeviceContext } from '../../contexts/devices/DeviceContext'
import { useDebounceLeading } from '../../hooks/useDebounceLeading'
import { MainContainer, StyledHeader } from './styles'

interface IconsDictionary {
  back: ReactElement
  settings: ReactElement
  help: ReactElement
  [key: string]: ReactElement
}

const icons: IconsDictionary = {
  back: <IconArrowLeft />,
  settings: <IconGear />,
  help: <IconHelp />
}

type HeaderProps = {
  title?: string
  backButtonLabel?: string
  onPressIconLeft?: { (): void }
  iconRight?: string[]
  onPressIconRight?: { (): void }[]
}

const Header = (props: HeaderProps) => {
  const { deviceState } = useContext(DeviceContext)

  const handleClick = useDebounceLeading((props, index) => {
    props.onPressIconRight && props.onPressIconRight[index]()
  })

  return (
    <MainContainer id="main-container-header">
      <StyledHeader
        id="header"
        position="relative"
        customStyle={{ background: 'transparent' }}
        leadingArea={
          <div id="left-container-header">
            <BackNavigationButton
              buttonText={
                props.backButtonLabel === ''
                  ? ''
                  : props.backButtonLabel
                    ? props.backButtonLabel
                    : deviceState.modelName
                      ? deviceState.modelName
                      : 'Back'
              }
              onClick={props.onPressIconLeft}
            />
          </div>
        }
        centerArea={props.title ? props.title : ''}
        trailingArea={
          <>
            {props.iconRight && (
              <div>
                {props.iconRight.map((action, index) => {
                  if (action !== 'settings' || deviceState.supportsSession) {
                    return (
                      <Button
                        id={`${action}-btn`}
                        key={action}
                        icon={icons[action]}
                        aria-label={action}
                        type="tertiary"
                        size={'medium'}
                        onClick={() => handleClick(props, index)}
                      />
                    )
                  }

                  return false
                })}
              </div>
            )}
          </>
        }
      />
    </MainContainer>
  )
}

export default Header
