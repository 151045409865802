export const scrollSelectionIntoView = () => {
  const selection = window.getSelection()
  if (!selection) return
  if (!selection.rangeCount) {
    return
  }
  const firstRange = selection.getRangeAt(0)
  if (firstRange.commonAncestorContainer === document) {
    return
  }
  const tempAnchorEl = document.createElement('br')
  firstRange.insertNode(tempAnchorEl)
  tempAnchorEl.scrollIntoView({
    block: 'end'
  })
  tempAnchorEl.remove()
}
