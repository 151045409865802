import { createSlice } from '@reduxjs/toolkit'

import { DeviceDescription, DeviceLists } from '../services/Shortcuts/Type'

export interface PrinterDataType {
  createSessionDeviceInfo: DeviceDescription | unknown
  allDeviceList: DeviceLists | unknown
  selectedDevice: DeviceDescription | unknown
}

export const initialDeviceState: PrinterDataType = {
  createSessionDeviceInfo: {},
  allDeviceList: {},
  selectedDevice: undefined
}

export const deviceSlice = createSlice({
  name: 'device',
  initialState: initialDeviceState,
  reducers: {
    updateCreateSessionDeviceInfo: (state, action) => {
      state.createSessionDeviceInfo = action.payload
    },
    updateDeviceList: (state, action) => {
      state.allDeviceList = action.payload
      state.selectedDevice = (
        state.allDeviceList as DeviceLists
      )?.devices?.find((obj: DeviceDescription) => obj.selected === true)
    }
  }
})

export const { updateDeviceList, updateCreateSessionDeviceInfo } =
  deviceSlice.actions

export default deviceSlice.reducer
