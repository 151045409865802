import { createSlice } from '@reduxjs/toolkit'

export interface ApplicationData {
  appInstanceId: string
}

export const initialApplicationState: ApplicationData = {
  appInstanceId: ''
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState: initialApplicationState,
  reducers: {
    updateAppInstanceId: (state, action) => {
      state.appInstanceId = action.payload
    }
  }
})

export const { updateAppInstanceId } = applicationSlice.actions

export default applicationSlice.reducer
