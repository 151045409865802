// eslint-disable-next-line no-restricted-imports
import { AccessToken, TokenType, WhenJWebReady } from '@jarvis/jweb-core'
import { JarvisAuthProvider } from '@jarvis/web-http'

export type AuthTokenResponse = {
  tokenValue: string
  emailAddress?: string
}

const getAuthTokenFromCookie = (): string | undefined => {
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('stratus-access-token'))
    ?.split('=')[1]

  if (cookie) sessionStorage.setItem('accessToken', cookie)
  return cookie
}

export const getAuthTokenFromJWeb = async (
  freshToken: boolean
): Promise<AuthTokenResponse> => {
  if (!freshToken) {
    const tokenValue = sessionStorage.getItem('accessToken') || ''
    return { tokenValue }
  }

  const tokenProviderOptions = {
    allowUserInteraction: true,
    requireFreshToken: freshToken,
    tokenType: TokenType.user
  }

  const jweb = await WhenJWebReady
  const accessToken: AccessToken = (await jweb.Plugins.Auth.getToken({
    tokenProviderOptions
  })) as AccessToken

  sessionStorage.setItem('accessToken', accessToken.tokenValue)

  return {
    tokenValue: accessToken.tokenValue,
    emailAddress: accessToken.account?.emailAddress
  }
}

export const authProvider: JarvisAuthProvider = {
  getAccessToken: async (forceRefresh: boolean) => {
    let token: string | undefined

    try {
      const result = await getAuthTokenFromJWeb(
        sessionStorage.getItem('accessToken') === null ? true : forceRefresh
      )
      token = result.tokenValue
    } catch (e) {
      token = getAuthTokenFromCookie()
    }

    if (!token) {
      throw new Error('Unable to get access token')
    }

    return token
  }
}
