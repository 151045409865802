import { IconCheckmark } from '@veneer/core'
import { ListContainer } from '@clientos/ui-toolkit'
import React, {
  Fragment,
  FunctionComponent,
  SyntheticEvent,
  useState
} from 'react'
import { colors } from '../../data/enum/colors'
import ShortcutName from '../ShortcutName'
import {
  ColorList,
  ColorListCard,
  ColorListItem,
  ColorListSelectedCard,
  MainContainer
} from './styles'
import { RadioButton } from '@clientos/ui-toolkit'
import FileTypeForm from '../FileTypeForm'
import { i18n } from 'src/assets/locale'
import { ocrFileTypes } from 'src/data/enum/ocrFileTypes'
import FileSettingsForm from '../FileSettingsForm'

type ShortcutSettingsFormProps = {
  nameFieldValue: string
  nameFieldLabel: string
  onChangeName: { (value: string): void }
  quickRunFieldLabel: string
  quickRunDescription: string
  isQuickRunOn: boolean
  onQuickRunClick: { (): void }
  tileColorFieldLabel: string
  selectedColorCardIndex: number
  onColorCardPress: { (index: number): void }
  desktopMode: boolean
  nameErrorMessage: string
  showFileType?: boolean
  onClickFileType?: { (_e: SyntheticEvent, value: string): void }
  isEntitled?: boolean
  fileType?: string
  onSmartFileNameClick?: { (): void }
  isSmartNameOn?: boolean
  showOcrConfigs?: boolean
  ocrLanguage?: string
  ocrSelectLabel?: string
  onChangeOcrLanguage?: { (value: any): void }
}

const Content: FunctionComponent<ShortcutSettingsFormProps> = (props) => {
  const [quickRunState, setQuickRunState] = useState(props.isQuickRunOn)

  return (
    <MainContainer>
      {!props.desktopMode && (
        <ShortcutName
          fieldLabel={props.nameFieldLabel}
          value={props.nameFieldValue}
          onChange={props.onChangeName}
          errorMessage={props.nameErrorMessage}
          desktopMode={props.desktopMode}
        />
      )}
      <ListContainer
        items={[
          {
            label: props.quickRunFieldLabel,
            description: props.quickRunDescription,
            end: (
              <RadioButton
                checked={quickRunState}
                onClick={() => {
                  setQuickRunState(!quickRunState)
                  props.onQuickRunClick()
                }}
              ></RadioButton>
            ),
            alignEnd: 'center',
            paddingStart: false,
            paddingEnd: false
          }
        ]}
      />
      <FileSettingsForm
        fileTypeLabel={i18n.t('pages.ShortcutSettings.fileType')}
        fileTypeButtonText={props.fileType}
        smartFileLabel={i18n.t('pages.ShortcutSettings.smartFileName')}
        smartFileDescription={i18n.t(
          'pages.ShortcutSettings.smartFileDescription'
        )}
        onSmartFileNameClick={props.onSmartFileNameClick}
        isSmartNameOn={props.isSmartNameOn}
        showOcrConfigs={props.showOcrConfigs}
        ocrLanguage={props.ocrLanguage}
        ocrSelectLabel={i18n.t('pages.ShortcutSettings.language')}
        onChangeOcrLanguage={props.onChangeOcrLanguage}
        desktopMode={props.desktopMode}
      />
      {props.showFileType && (
        <>
          <div
            style={{ fontSize: '18px', lineHeight: '24px', color: '#191a22' }}
          >
            {i18n.t('pages.ShortcutSettings.fileType')}
          </div>
          <FileTypeForm
            selectedItem={props.fileType}
            image={i18n.t('pages.FileType.image')}
            pdf={i18n.t('pages.FileType.basicPdf')}
            searchablePdf={i18n.t('pages.FileType.searchablePdf')}
            searchableDescription={i18n.t('pages.FileType.pdfDescription')}
            wordDoc={i18n.t('pages.FileType.wordDoc')}
            wordDescription={i18n.t('pages.FileType.wordDescription')}
            plainText={i18n.t('pages.FileType.plainText')}
            textDescription={i18n.t('pages.FileType.textDescription')}
            desktopMode={props.desktopMode}
            entitled={props.isEntitled || ocrFileTypes.includes(props.fileType)}
            onClickFileType={props.onClickFileType}
          />
        </>
      )}
      <div style={{ fontSize: '18px', lineHeight: '24px', color: '#191a22' }}>
        {props.tileColorFieldLabel}
      </div>
      <ColorList
        id="colors-list"
        aria-label="colors-list"
      >
        {colors.map((color, index) => (
          <Fragment key={index}>
            <ColorListItem
              id="color-list-item"
              centerArea={
                props.selectedColorCardIndex === index ? (
                  <ColorListSelectedCard
                    id="colors-selected-card"
                    leadingIcon={<IconCheckmark />}
                    color={color}
                    onClick={() => props.onColorCardPress(index)}
                  />
                ) : (
                  <ColorListCard
                    id="color-list-card"
                    color={color}
                    onClick={() => props.onColorCardPress(index)}
                  />
                )
              }
              leadingArea={<></>}
              trailingArea={<></>}
            />
          </Fragment>
        ))}
      </ColorList>
    </MainContainer>
  )
}

const ShortcutSettingsForm: FunctionComponent<ShortcutSettingsFormProps> = (
  props
) => Content(props)

export default ShortcutSettingsForm
