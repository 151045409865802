import React, { createContext, useContext, useReducer } from 'react'
import { ConnectorFolders, FolderItem } from '../../data/schemas/folders'
import { Presentation } from '../../data/schemas/shortcut'
import { ShortcutSettings } from '../../data/schemas/shortcutSettings'
import { initialState, settingsReducer } from './SettingsReducer'

type SettingsActions = {
  updateColor: (presentation: Presentation) => void
  updateFileType: (fileType: string) => void
  updateFileName: (fileName: string) => void
  updateRelativeFolderName: (relativeFolderName: string) => void
  updateJobName: (jobName: string) => void
  updateQuickRun: (quickRun: boolean) => void
  updateSmartFileName: (smartFileName: boolean) => void
  updateOcrLanguage: (ocrLanguage: string) => void
  updateScanDestinations: (scanDestinations: boolean) => void
  updateFoldersList: (foldersList: ConnectorFolders[]) => void
  resetState: () => void
  updateCurrentConnectorId: (currentConnectorId: string) => void
  updateCurrentConnectorType: (currentConnectorType: string) => void
  updateSelectedFolder: (selectedFolder: string) => void
  updateFolderTree: (folderTree: FolderItem[]) => void
  updateEditSelectedFolder: (editSelectedFolder: string) => void
}

type SettingsContextType = {
  settingsState: ShortcutSettings
  settingsActions: SettingsActions
}

type SettingsContextProviderProps = { children: React.ReactNode }

const defaultContextValue: SettingsContextType = {
  settingsState: {} as ShortcutSettings,
  settingsActions: {} as SettingsActions
}

export const SettingsContext = createContext(defaultContextValue)

export const SettingsContextProvider = ({
  children
}: SettingsContextProviderProps) => {
  const [settingsState, dispatch] = useReducer(settingsReducer, initialState)

  const settingsActions: SettingsActions = {
    updateColor: (presentation: Presentation) => {
      dispatch({ type: 'presentation', payload: presentation })
    },
    updateFileType: (fileType: string) => {
      dispatch({ type: 'fileType', payload: fileType })
    },
    updateFileName: (fileName: string) => {
      dispatch({ type: 'fileName', payload: fileName })
    },
    updateRelativeFolderName: (relativeFolderName: string) => {
      dispatch({
        type: 'relativeFolderName',
        payload: relativeFolderName
      })
    },
    updateJobName: (jobName: string) => {
      dispatch({ type: 'jobName', payload: jobName })
    },
    updateQuickRun: (quickRun: boolean) => {
      dispatch({ type: 'quickRun', payload: quickRun })
    },
    updateSmartFileName: (smartFileName: boolean) => {
      dispatch({ type: 'smartFileName', payload: smartFileName })
    },
    updateOcrLanguage: (ocrLanguage: string) => {
      dispatch({ type: 'ocrLanguage', payload: ocrLanguage })
    },
    updateScanDestinations: (scanDestinations: boolean) => {
      dispatch({ type: 'scanDestinations', payload: scanDestinations })
    },
    updateCurrentConnectorId: (currentConnectorId: string) => {
      dispatch({ type: 'currentConnectorId', payload: currentConnectorId })
    },
    updateCurrentConnectorType: (currentConnectorType: string) => {
      dispatch({ type: 'currentConnectorType', payload: currentConnectorType })
    },
    updateSelectedFolder: (selectedFolder: string) => {
      dispatch({ type: 'selectedFolder', payload: selectedFolder })
    },
    updateEditSelectedFolder: (editSelectedFolder: string) => {
      dispatch({ type: 'editSelectedFolder', payload: editSelectedFolder })
    },
    updateFoldersList: (foldersList: ConnectorFolders[]) => {
      dispatch({ type: 'foldersList', payload: foldersList })
    },
    updateFolderTree: (folderTree: FolderItem[]) => {
      dispatch({ type: 'folderTree', payload: folderTree })
    },
    resetState: () => {
      dispatch({ type: 'reset' })
    }
  }

  return (
    <SettingsContext.Provider value={{ settingsState, settingsActions }}>
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettingsContext = () => useContext(SettingsContext)
