import { ButtonGroup, GenericCard } from '@clientos/ui-toolkit'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import MobileScroll from 'src/components/MobileScroll'
import PageTemplate from 'src/pages/PageTemplate'
import { i18n } from '../../assets/locale'
import EmailForm from '../../components/EmailForm'
import Header from '../../components/Header'
import { useDestinationsContext } from '../../contexts/destinations/DestinationsContext'
import { useEditContext } from '../../contexts/edit/EditContext'
import { useSettingsContext } from '../../contexts/settings/SettingsContext'
import { useToggleContext } from '../../contexts/toggle/ToggleContext'
import { useUserContext } from '../../contexts/users/UserContext'
import { PageProps } from '../../data/schemas/app'
import { LocationState } from '../../data/schemas/location'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import { isValidEmail } from '../../modules/destinations/helpers'
import { sendUiEvent, uiData } from '../../plugins/jweb/DataCollection'
import { MainContainer } from './styles'

export const AddEmail = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()
  const location = useLocation<LocationState>()

  const deviceModel = props.deviceModel

  const { destinationsState, destinationsActions } = useDestinationsContext()
  const { settingsActions } = useSettingsContext()
  const { editState } = useEditContext()
  const { userContextState } = useUserContext()
  const { toggleState } = useToggleContext()
  const [showFooter, setShowFooter] = useState(true)
  const [customHeight, setCustomHeight] = useState(window.innerHeight)

  const [emailValue, setEmailValue] = useState(
    destinationsState.currentState.email.tos.join(', ')
  )
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [height] = useState(window.innerHeight)
  const [suggestionLoaded, setSuggestionLoaded] = useState(false)

  const onBackButtonPress = () => {
    if (location.state?.template) {
      if (location.state?.template.index === 0) {
        destinationsActions.resetState()
        settingsActions.resetState()
        history.push(`${base}/print`)
      } else {
        const index = location.state?.template.index - 1
        history.push({
          pathname: `${base}/${location.state?.template.actions[index].route}`,
          state: {
            template: { ...location.state?.template, index }
          }
        })
      }
    } else {
      const checkpoint = destinationsState.checkpointState.email
      destinationsActions.updateEmail(checkpoint)
      if (toggleState.print) {
        history.push(`${base}/print`)
      } else {
        history.push(`${base}/destinations`)
      }
    }
  }

  const validateEmailEntries = () => {
    const { tos } = destinationsState.currentState.email
    const invalidEntries = tos.filter((value) => value && !isValidEmail(value))
    let messageError = ''

    if (invalidEntries.length) {
      messageError = i18n.t('pages.AddEmail.invalidEmailMessage', {
        emails: invalidEntries.join(', ')
      })
    } else if (tos.length > 20) {
      messageError = i18n.t('pages.AddEmail.invalidQuantityEmailMessage')
    }

    setEmailErrorMessage(messageError)
  }

  const getFormattedEmailValue = (value: string) => {
    return value
      .split(',')
      .map((string) => string.trim())
      .join(', ')
  }

  const onChangeEmail = (value: string) => {
    setEmailValue(value)

    if (value.endsWith(',')) {
      validateEmailEntries()
    }

    if (/,\S/.test(value)) {
      setEmailValue(getFormattedEmailValue(value))
    }

    if (!value) {
      setEmailErrorMessage('')
    }

    destinationsActions.updateEmail({
      ...destinationsState.currentState.email,
      tos: value
        .trim()
        .split(/\s*,\s*/)
        .filter((value) => value)
    })
  }

  const onBlurEmail = () => {
    validateEmailEntries()
  }

  const onChangeSubject = (value: string) => {
    destinationsActions.updateEmail({
      ...destinationsState.currentState.email,
      subject: value
    })
  }

  const onChangeBody = (value: string) => {
    destinationsActions.updateEmail({
      ...destinationsState.currentState.email,
      message: value
    })
  }

  const onContinueButtonPress = () => {
    sendUiEvent(uiData.btnAddToShortcutEmail)
    destinationsActions.updateEmail({
      ...destinationsState.currentState.email,
      set: true
    })

    if (location.state?.template) {
      if (
        location.state?.template.index ===
        location.state?.template.actions.length - 1
      ) {
        history.push({
          pathname: `${base}/creation_settings`,
          state: {
            template: location.state?.template
          }
        })
      } else {
        const index = location.state?.template.index + 1
        history.push({
          pathname: `${base}/${location.state?.template.actions[index].route}`,
          state: { template: { ...location.state?.template, index } }
        })
      }
    } else {
      destinationsActions.updateCheckpointState({
        ...destinationsState.currentState,
        email: { ...destinationsState.currentState.email, set: true }
      })
      if (toggleState.save) {
        history.push(`${base}/repositories`)
      } else {
        history.push(`${base}/creation_settings`)
      }
    }
  }

  useEffect(() => {
    if (
      !suggestionLoaded &&
      (!destinationsState.currentState.email.set || location.state?.template) &&
      !destinationsState.currentState.email.subject &&
      !destinationsState.currentState.email.message
    ) {
      destinationsActions.updateEmail({
        tos: [],
        subject: i18n.t('pages.AddEmail.subjectSuggestion', {
          userFirstName: userContextState?.givenName ?? ''
        }),
        //TODO: Replace once translation batch is available i18n.t('pages.AddEmail.bodySuggestionHPX', {userFirstName: userContextState?.givenName ?? '',userEmail: userContextState?.emailAddress ?? ''}),
        message: `The attached files have been sent to you from ${userContextState.givenName ?? ''} (${userContextState.emailAddress ?? ''}). Use the HP app to edit, print, and share them!`,
        set: false
      })
      setSuggestionLoaded(true)
    }
  }, [
    destinationsActions,
    destinationsState.currentState.email.message,
    destinationsState.currentState.email.set,
    destinationsState.currentState.email.subject,
    location.state?.template,
    userContextState?.emailAddress,
    userContextState?.givenName,
    suggestionLoaded
  ])

  useEffect(() => {
    const updateSize = () => {
      if (!deviceModel.includes('ipad') || deviceModel.includes('iphone')) {
        setShowFooter(window.innerHeight === height)
        setCustomHeight(window.innerHeight)
      }
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [customHeight, deviceModel, height])

  useNativeBackButton(onBackButtonPress)

  return (
    <PageTemplate
      desktopMode={props.desktopMode}
      header={
        <Header
          backButtonLabel={i18n.t('common.contextAction.cancel')}
          onPressIconLeft={onBackButtonPress}
        />
      }
      content={
        <MainContainer>
          <GenericCard>
            <GenericCard.Header
              title={
                editState.editMode
                  ? i18n.t('pages.AddEmail.edit')
                  : i18n.t('pages.AddEmail.header')
              }
              borderBottom={false}
            />
            <GenericCard.Body paddingBodyTop={false}>
              <MobileScroll offset="275px">
                <div
                  id="intro-text-add-mail"
                  style={{
                    paddingBottom: '32px',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#212121'
                  }}
                >
                  {i18n.t('pages.AddEmail.intro')}
                </div>
                <div
                  id="instruction-text-add-email"
                  style={{
                    paddingBottom: '16px',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#525365'
                  }}
                >
                  {i18n.t('pages.AddEmail.instructions')}
                </div>
                <EmailForm
                  toFieldLabel={i18n.t('pages.AddEmail.to')}
                  subjectFieldLabel={i18n.t('pages.AddEmail.subject')}
                  bodyFieldLabel={i18n.t('pages.AddEmail.body')}
                  onChangeEmailCallback={onChangeEmail}
                  onBlurEmailCallback={onBlurEmail}
                  onChangeSubjectCallback={onChangeSubject}
                  onChangeBodyCallback={onChangeBody}
                  emailValue={emailValue}
                  subjectValue={destinationsState.currentState.email?.subject}
                  bodyValue={destinationsState.currentState.email?.message}
                  emailErrorMessage={emailErrorMessage}
                />
              </MobileScroll>
            </GenericCard.Body>
          </GenericCard>
        </MainContainer>
      }
      mobileFooter={
        showFooter && (
          <ButtonGroup
            id="add-email-destination-btn"
            textPrimaryButton={i18n.t('common.contextAction.continue')}
            size="large"
            orientation="horizontal-full"
            onClickPrimaryButton={() => onContinueButtonPress()}
            disabled={
              destinationsState.currentState.email.tos.length &&
              !emailErrorMessage &&
              destinationsState.currentState.email.subject.length &&
              destinationsState.currentState.email.subject.trim()
                ? false
                : true
            }
          />
        )
      }
    />
  )
}
