import { ConfirmationModal } from '@veneer/core'
import styled from 'styled-components'

export const StyledModal = styled(ConfirmationModal)`
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.vn-modal--dialog {
    width: 80%;
    border-radius: 20px;
    display: flex;
    justify-content: center;

    button {
      display: flex;
      justify-content: center;
      span {
        white-space: normal;
      }
    }

    h2 {
      font-size: 22px;
      line-height: 23px;
      color: #515151;
    }

    p {
      font-size: 15px !important;
      line-height: 24px !important;
    }

    @media (min-width: 570px) {
      button {
        width: 210px;
        margin-right: 0px;
      }

      button:nth-child(1) {
        margin-bottom: 10px;
      }
    }
  }
`

export const DestinationsSubtitleArea = styled.div`
  display: flex;
  flex-direction: column;
`

export const MainContainer = styled.div`
  .clientos-ui-toolkit-generic-card__content__header {
    padding-bottom: 16px !important;
    line-height: 28px !important;
    color: #191a22;
  }
`

export const SaveSubtitleFolderArea = styled.div`
  p {
    display: flex !important;
  }
`
