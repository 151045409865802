import { Scrollbar } from '@clientos/ui-toolkit'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/stores'
import {
  DesktopContent,
  MobileContent,
  PageContainer,
  PageContent,
  PageHeader
} from './styles'

type PageTemplateProps = {
  header?: React.ReactNode
  content: React.ReactNode
}

const PageTemplate = (props: PageTemplateProps) => {
  const mobileMode: boolean = useSelector(
    (state: RootState) => state.screens.mobileMode
  )

  return (
    <PageContainer>
      {props.header && (
        <PageHeader className="cloudscans-header">{props.header}</PageHeader>
      )}
      {props.content && (
        <PageContent className="cloudscans-content">
          {!mobileMode ? (
            <Scrollbar
              height={
                props.header ? 'calc(100vh - 120px)' : 'calc(100vh - 60px)'
              }
              marginRight={false}
            >
              <DesktopContent>{props.content}</DesktopContent>
            </Scrollbar>
          ) : (
            <MobileContent>{props.content}</MobileContent>
          )}
        </PageContent>
      )}
    </PageContainer>
  )
}

export default PageTemplate
