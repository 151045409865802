import { useToast } from '@veneer/core'
import { Button, GenericCard } from '@clientos/ui-toolkit'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { i18n } from '../../assets/locale'
import { postShortcutConfiguration } from '../../clients/devices'
import { deviceLookup } from '../../clients/shortcuts'
import Header from '../../components/Header'
import { useAnimationContext } from '../../contexts/animations/AnimationContext'
import { useDeviceContext } from '../../contexts/devices/DeviceContext'
import { useSettingsContext } from '../../contexts/settings/SettingsContext'
import { PageProps } from '../../data/schemas/app'
import { LocationState } from '../../data/schemas/location'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import { toastMessages } from '../../modules/common/helpers'
import { sendUiEvent, uiData } from '../../plugins/jweb/DataCollection'
import PageTemplate from '../PageTemplate'

export const ScanDestinationsSettings = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()
  const location = useLocation<LocationState>()
  const { addToast } = useToast()

  const { setAnimationDirection } = useAnimationContext()
  const { deviceActions, deviceState } = useDeviceContext()
  const { settingsActions } = useSettingsContext()
  const deviceUuid = props.selectedPrinterUuid
  const rootPath = location.state?.session?.context?.rootPath

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!deviceState.modelName) {
          let cloudId = deviceState.cloudId

          if (!cloudId) {
            const deviceLookupResponse = await deviceLookup(
              deviceUuid as string
            )
            deviceActions.setCloudId(deviceLookupResponse.cloud_id)
            deviceActions.setSupportsSession(
              deviceLookupResponse.supportsSession
            )
            cloudId = deviceLookupResponse.cloud_id
          }
        }
      } catch (e) {
        console.error(
          `Exception while getting device info for deviceUuid: ${deviceUuid}`
        )
        sendUiEvent(uiData.toastDeviceFetchError)
        addToast?.({
          type: 'warning',
          ...toastMessages('deviceFetchError')
        })
      }
    }

    fetchData()
  }, [
    addToast,
    deviceActions,
    deviceState.cloudId,
    deviceState.modelName,
    deviceUuid
  ])

  const onBackButtonPress = async () => {
    setAnimationDirection('left')
    history.push(`${base}/printer_settings`, {
      session: {
        context: {
          rootPath: rootPath
        }
      }
    })
  }

  const onClickScanDestinations = async () => {
    history.push(`${base}/printer_settings`, {
      session: {
        context: {
          rootPath: rootPath
        }
      }
    })
    const requestData = {
      state: {
        desired: {
          cdmData: {
            cloudShortcuts: 'true'
          }
        }
      }
    }
    await postShortcutConfiguration(deviceState.cloudId, requestData)
    settingsActions.updateScanDestinations(true)
  }

  useNativeBackButton(onBackButtonPress)

  return (
    <>
      <PageTemplate
        header={
          <Header
            backButtonLabel={'Settings'}
            onPressIconLeft={onBackButtonPress}
          />
        }
        content={
          <GenericCard>
            <GenericCard.Header
              /* TODO: Replace when batch translation is done: i18n.t('pages.PrinterSettings.scanDestinationsPageTitleHPX') */
              title={'Enable Scan destinations'}
            />
            <GenericCard.Body>
              <div>
                {i18n.t(
                  'pages.PrinterSettings.scanDestinationsFullDescription'
                )}
              </div>
              {props.desktopMode ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingTop: '32px'
                  }}
                >
                  <Button
                    id="scan-destinations-button"
                    aria-label="scan-destinations-button"
                    onClick={onClickScanDestinations}
                  >
                    {i18n.t('common.contextAction.enable')}
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    paddingTop: '24px'
                  }}
                >
                  <Button
                    id="scan-destinations-button"
                    aria-label="scan-destinations-button"
                    onClick={onClickScanDestinations}
                    expanded
                  >
                    {i18n.t('common.contextAction.enable')}
                  </Button>
                </div>
              )}
            </GenericCard.Body>
          </GenericCard>
        }
        desktopMode={true}
      />
    </>
  )
}
