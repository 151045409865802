// eslint-disable-next-line no-restricted-imports
import {
  DocProviderPlugin,
  DocSet,
  NewDocData,
  Doc,
  WhenJWebReady,
  DocProviderPluginError
} from '@jarvis/jweb-core'

import type { File } from '../../services/Tenzing/Type'
import { launchJarvisService } from './ServiceRouting'

let DocProvider: DocProviderPlugin

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ensureDocProvider = async () => {
  const res = await WhenJWebReady
  if (res && res.Plugins && res.Plugins.DocProvider) {
    DocProvider = res.Plugins.DocProvider as DocProviderPlugin
  } else throw new Error('JWeb plugins not available')
}

const isDocProviderPluginError = (
  value: unknown | DocProviderPluginError
): value is DocProviderPluginError => {
  return (value as DocProviderPluginError)?.errorType !== undefined
}

export const getResultDocSet = async (): Promise<DocSet> => {
  await ensureDocProvider()
  const docSet = await DocProvider.getResultDocSet()
  if (isDocProviderPluginError(docSet)) {
    console.error(
      `Error while getting result DocSet: ${docSet.errorType} - ${docSet.reason}`
    )
    throw 'Error while getting result DocSet'
  } else {
    return docSet
  }
}

export const addDocToResultDocSet = async (
  options: NewDocData
): Promise<Doc> => {
  await ensureDocProvider()
  const doc = await DocProvider.addDocToResultDocSet(options)
  if (isDocProviderPluginError(doc)) {
    console.error(
      `Error while adding doc to result DocSet: ${doc.errorType} - ${doc.reason}`
    )
    throw 'Error while adding doc to result DocSet'
  } else {
    return doc
  }
}

const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onerror = () => reject(fileReader.error)
    fileReader.onloadend = () => {
      const dataUrl = fileReader.result as string
      // remove "data:mime/type;base64," prefix from data url
      const base64 = dataUrl.substring(dataUrl.indexOf(',') + 1)
      resolve(base64)
    }
    fileReader.readAsDataURL(blob)
  })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const downloadFilesFromDocSet = async (files: File[]) => {
  await ensureDocProvider()
  const docSet = await getResultDocSet()
  DocProvider.removeDocsFromResultDocSet({
    docIds: docSet.docs.map(({ id }) => id)
  })

  Promise.all(
    files.map(async (file) => {
      const base64 = await blobToBase64(file.blob)
      return addDocToResultDocSet({
        data: base64,
        fileName: file.outputName,
        mediaType: 'image/jpeg'
      })
    })
  ).then(() => {
    launchJarvisService(docSet.id)
  })
}
