import React from 'react'
import useAssetsProvider from '../../../hooks/useAssetsProvider'
import { ChildHeadProps } from '..'
import DoneButton from '../DoneButton'
import { Button } from '@clientos/ui-toolkit'

const MobileHeader = ({ handleCancel }: ChildHeadProps) => {
  const assetsProvider = useAssetsProvider()
  if (!assetsProvider) {
    console.error('Does not have assets provider')
    return null
  }
  const getText = (subkey: string) => {
    const assetKey = `application.${subkey}`
    return assetsProvider.getText(assetKey)
  }
  return (
    <div className={'header'}>
      <Button
        id="cancelButton"
        type="tertiary"
        size="medium"
        onClick={handleCancel}
      >
        {getText('header.cancel')}
      </Button>
      <p className="subtitle-large">{getText('header.title')}</p>
      <DoneButton />
    </div>
  )
}

export default MobileHeader
