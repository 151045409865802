import document from './document.png'
import paperIcon from './paper-icon.svg'
import printer from './printer.png'
import startSessionPrinter from './printer-start-session.svg'
import arrowBack from './arrow-back.svg'

const Images = {
  document,
  paperIcon,
  printer,
  startSessionPrinter,
  arrowBack
}

export default Images
