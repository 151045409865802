import React from 'react'
import './FullScreenLoading.scss'
import { Loader } from '@clientos/ui-toolkit'

const FullScreenLoading = () => (
  <div className="fullScreenLoading-container">
    <Loader type="circular" />
  </div>
)

export default FullScreenLoading
