import React, { SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './SettingsPanel.scss'

import { i18n } from 'src/assets/locale'
import ToastNotification from 'src/components/ToastNotification'
import { screenNames } from 'src/pages/CloudScan/screens'
import { sendUiEvent, uiData } from 'src/plugins/jweb/DataCollection'
import { RootState } from 'src/stores'
import { updateRefreshSessionEvent } from 'src/stores/event'
import { deleteSession } from '../../../services/Shortcuts/index'
import { DeviceDescription } from '../../../services/Shortcuts/Type'
import { updateCreateSessionDeviceInfo } from '../../../stores/device'
import { updateCurrentScreen } from '../../../stores/screen'
import { useToast } from '@veneer/core'
import { Button, Toggle, colors } from '@clientos/ui-toolkit'
import { useDevicesSessionList } from 'src/hooks/useDevicesSessionList'

type PrinterProps = {
  DeviceDescription: DeviceDescription
  setLoading: React.Dispatch<SetStateAction<boolean>>
  isLastItem: boolean
}

const Printers = ({
  DeviceDescription,
  setLoading,
  isLastItem
}: PrinterProps) => {
  const { modelName } = DeviceDescription.deviceIdentity
  const deviceImage =
    DeviceDescription.deviceImages.find((image) =>
      image.endsWith('300x245.png')
    ) || ''
  const printerIPAddress = DeviceDescription.deviceIdentity?.ipAddress
  const [expiryTime] = useState(DeviceDescription.expires)
  const [toggleStatus, setToggleStatus] = useState(!!DeviceDescription.expires)
  const [isLast, setIsLast] = useState(false)
  const dispatch = useDispatch()
  const currentScreenName = useSelector(
    (state: RootState) => state.screens.currentScreenName
  )
  const toast = useToast()

  const devicesSessionList = useDevicesSessionList()

  const showCreateSession = () => {
    dispatch(updateCreateSessionDeviceInfo(DeviceDescription))
    dispatch(updateCurrentScreen(screenNames.CREATE_SESSION))
  }

  const onChangeToggle = async () => {
    if (!toggleStatus) {
      showCreateSession()
      sendUiEvent({
        ...uiData.PrintersList.btnSetupSession,
        screenPath:
          currentScreenName === screenNames.START_SESSION
            ? '/CloudscansReactWebapp/SessionSyncScreen/'
            : '/CloudscansReactWebapp/JobsListScreen/',
        controlName: 'UpdateSession',
        controlDetail: DeviceDescription.deviceIdentity.modelNumber
      })
    } else {
      try {
        setLoading(true)
        setToggleStatus(!toggleStatus)
        await deleteSession(DeviceDescription.deviceIdentity.deviceUuid)
        await devicesSessionList()
      } catch (error) {
        console.error(error)
        ToastNotification(toast)
      } finally {
        dispatch(updateRefreshSessionEvent(true))
      }
    }
  }

  useEffect(() => {
    setIsLast(isLastItem)
  }, [isLastItem])

  return (
    <>
      <div className="Setting-block">
        <div className="list-items">
          <div className={`${isLast ? 'last-item' : 'item'}`}>
            <div className="Printer-block">
              <img
                data-testid="Printer-block"
                id="printer-image-after-save"
                alt="Printer Image"
                src={deviceImage}
                width={56}
                height={44.41}
              />
            </div>
            <div className="label-block">
              <p
                data-testid="main-header"
                className="main-header"
                style={{ color: colors.inkPrimary }}
              >
                {modelName}
              </p>
              {printerIPAddress && (
                <p className="sub-header">{printerIPAddress}</p>
              )}
              {!!expiryTime && (
                <>
                  <Button
                    id="edit-duration-btn"
                    type="ghost-flush"
                    onClick={showCreateSession}
                  >
                    {/* TODO: when new batch translation comes, replace per {i18n.t('application.settingsPanel.updatesessionHPX')} */}
                    Edit duration
                  </Button>
                  <div>
                    <p
                      style={{
                        color:
                          expiryTime <= 3
                            ? colors.inkSentimentWarning
                            : colors.inkSecondary
                      }}
                      data-testid="sub-header"
                      className={'sub-header'}
                    >
                      {/* TODO: when new batch translation comes, replace per {i18n.t('application.settingsPanel.sessionExpireHPX')}*/}
                      Connection expires in{' '}
                      {expiryTime < 1 ? (
                        <>
                          {i18n.t('application.settingsPanel.lessThanOneDay')}
                        </>
                      ) : (
                        <>
                          {expiryTime}{' '}
                          {i18n.t('application.settingsPanel.daysText')}
                        </>
                      )}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="input-block">
              <Toggle
                data-testid="auto-enhancement"
                id="auto-enhancement"
                on={toggleStatus}
                onChange={onChangeToggle}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Printers
