import React, { createContext, useContext, useReducer } from 'react'
import { editReducer, EditState, initialState } from './EditReducer'

type EditActions = {
  setUpdateId: (id: string) => void
  setEditMode: (isEdit: boolean) => void
  resetState: () => void
}

type EditContextType = {
  editState: EditState
  editActions: EditActions
}

type EditContextProviderProps = { children: React.ReactNode }

const defaultContextValue: EditContextType = {
  editState: {} as EditState,
  editActions: {} as EditActions
}

export const EditContext = createContext(defaultContextValue)

export const EditContextProvider = ({ children }: EditContextProviderProps) => {
  const [editState, dispatch] = useReducer(editReducer, initialState)

  const editActions: EditActions = {
    setUpdateId: (id: string) => {
      dispatch({ type: 'id', payload: id })
    },
    setEditMode: (editMode: boolean) => {
      dispatch({ type: 'editMode', payload: editMode })
    },
    resetState: () => {
      dispatch({ type: 'reset' })
    }
  }

  return (
    <EditContext.Provider value={{ editState, editActions }}>
      {children}
    </EditContext.Provider>
  )
}

export const useEditContext = () => useContext(EditContext)
