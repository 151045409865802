// See https://github.azc.ext.hp.com/shortcuts/dev-utils/tree/master/cors-proxy for cors proxy configuration.
const configs = {
  common: {
    //TODO MOCK: process.env.REACT_APP_MOCK === 'true'
    MOCK: false
  },
  proxy_android: {
    SHORTCUTS_MANAGER_URL: 'http://10.0.2.2:4000/manager',
    STRATUS_DEVICES_URL: 'http://10.0.2.2:4000',
    HPSMART_URL: 'http://10.0.2.2:4000',
    TENANTS_URL: 'http://10.0.2.2:4000/v2/tenantmgtsvc'
  },
  proxy: {
    SHORTCUTS_MANAGER_URL: 'http://localhost:4000/manager',
    STRATUS_DEVICES_URL: 'http://localhost:4000',
    HPSMART_URL: 'http://localhost:4000',
    TENANTS_URL: 'http://localhost:4000/v2/tenantmgtsvc'
  },
  dev: {
    SHORTCUTS_MANAGER_URL: 'https://dev-us1.api.ws-hp.com/shortcuts/manager',
    STRATUS_DEVICES_URL: 'https://devices.pie-us1.api.ws-hp.com',
    HPSMART_URL: 'https://www.hpsmartdev.com',
    TENANTS_URL: 'https://dev-us1.api.ws-hp.com/v2/tenantmgtsvc'
  },
  pie: {
    SHORTCUTS_MANAGER_URL: 'https://pie-us1.api.ws-hp.com/shortcuts/manager',
    STRATUS_DEVICES_URL: 'https://devices.pie-us1.api.ws-hp.com',
    HPSMART_URL: 'https://www.hpsmartpie.com',
    TENANTS_URL: 'https://pie-us1.api.ws-hp.com/v2/tenantmgtsvc'
  },
  stg: {
    SHORTCUTS_MANAGER_URL: 'https://stage-us1.api.ws-hp.com/shortcuts/manager',
    STRATUS_DEVICES_URL: 'https://devices.stage-us1.api.ws-hp.com',
    HPSMART_URL: 'https://www.hpsmartstage.com',
    TENANTS_URL: 'https://stage-us1.api.ws-hp.com/v2/tenantmgtsvc'
  },
  pro: {
    SHORTCUTS_MANAGER_URL: 'https://us1.api.ws-hp.com/shortcuts/manager',
    STRATUS_DEVICES_URL: 'https://devices.us1.api.ws-hp.com',
    HPSMART_URL: 'https://www.hpsmart.com',
    TENANTS_URL: 'https://us1.api.ws-hp.com/v2/tenantmgtsvc'
  }
}

const environments = {
  dev: 'dev',
  pie: 'pie',
  stage: 'stg',
  hpsmart: 'pro',
  'local-hpc3': 'pie',
  localhost: 'proxy',
  local: 'proxy',
  10: 'proxy_android'
}

// TODO: Fetch stack using App interface:
// https://pages.github.azc.ext.hp.com/jarvis-shell/shell-docs/docs/guides-and-tutorials/jshell-core/interfaces/app
const domain = (url) => url.replace(/(?:hpx\.|consumer\.)?/, '').split('.')[0]

const Config = {
  ...configs.common,
  ...configs[environments[domain(window.location.hostname)]]
}

export default Config
