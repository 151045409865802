import { Scrollbar } from '@clientos/ui-toolkit'
import React from 'react'
import { ScrollContainer } from './styles'

type MobileScrollProps = {
  children?: React.ReactNode
  offset?: string
}

const MobileScroll = (props: MobileScrollProps) => {
  return (
    <>
      <ScrollContainer>
        <Scrollbar
          height={`calc(100vh - ${props.offset ? props.offset : '0px'})`}
          marginRight={false}
        >
          {props.children}
        </Scrollbar>
      </ScrollContainer>
    </>
  )
}

export default MobileScroll
