import {
  Button,
  DatePicker,
  DateRange,
  GenericCard,
  IconCheckCircle,
  ListContainer,
  Modal,
  RadioButton,
  useToast
} from '@clientos/ui-toolkit'
import { DateTime } from 'luxon'
import moment, { Moment } from 'moment'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { i18n } from 'src/assets/locale'
import ToastNotification from 'src/components/ToastNotification'
import { screenNames } from 'src/pages/CloudScan/screens'
import { AuthTokenResponse, getAuthTokenFromJWeb } from 'src/plugins/jweb/Auth'
import { sendUiEvent, uiData } from 'src/plugins/jweb/DataCollection'
import { RootState } from 'src/stores'
import {
  updateAnySessionCreated,
  updateRefreshJobsEvent,
  updateRefreshSessionEvent
} from 'src/stores/event'
import { createSession, deleteSession } from '../../services/Shortcuts'
import {
  DeviceDescription,
  DeviceSession,
  SessionState
} from '../../services/Shortcuts/Type'
import { updateCurrentScreen } from '../../stores/screen'
import './CreateSession.scss'
import MobileScroll from 'src/pages/Template/MobileScroll'

enum DurationType {
  NONE = 'none',
  ONEDAY = 'day',
  ONEYEAR = 'year',
  CUSTOM = 'custom'
}

const CreateSession = (deviceInfo: DeviceDescription) => {
  const selectedPrinterUUID = deviceInfo.deviceIdentity.deviceUuid
  const [loader, setLoader] = useState(false)
  const [syncedModalState, setSyncedModalState] = useState(false)
  const [selectedDuration, setSelectedDuration] = useState<string>(
    DurationType.NONE
  )
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [endDate, setEndDate] = useState<Moment>(
    moment(DateTime.local().toISO())
  )
  const dispatch = useDispatch()
  const toast = useToast()

  const currentScreenName: string = useSelector(
    (state: RootState) => state.screens.currentScreenName
  )

  const mobileMode: boolean = useSelector(
    (state: RootState) => state.screens.mobileMode
  )

  useEffect(() => {
    sendUiEvent({
      ...uiData.SetupSession.setupSessionModal
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnDateChange = (date?: Moment | DateRange) => {
    const newDate = date as Moment
    setEndDate(newDate)
  }

  const defaultDate = DateTime.local()

  const getExpirationSeconds = () => {
    const ONEDAYSECONDS = 60 * 60 * 24
    const ONEYEARSECONDS = 60 * 60 * 24 * 365
    if (selectedDuration === DurationType.ONEYEAR) {
      return ONEYEARSECONDS
    } else if (selectedDuration === DurationType.ONEDAY) {
      return ONEDAYSECONDS
    } else {
      endDate?.endOf('day')
      const selectedDate = moment(endDate).toISOString()
      return Math.round(
        DateTime.fromISO(selectedDate).diffNow().valueOf() / 1000
      )
    }
  }

  const createDeviceSession = async (
    deviceSessionItem: DeviceSession
  ): Promise<SessionState> => {
    return await createSession(deviceSessionItem)
  }

  const startSession = async () => {
    dispatch(updateAnySessionCreated(true))
    setLoader(true)
    const expiration = getExpirationSeconds()
    try {
      const result: AuthTokenResponse = await getAuthTokenFromJWeb(true)
      const deviceSessionItem: DeviceSession = {
        target: selectedPrinterUUID,
        targetFriendlyName: result.emailAddress || '',
        expiration: expiration
      }
      if (selectedDuration === DurationType.NONE) {
        await deleteSession(deviceSessionItem.target)
        showDeviceSyncScreen()
        setLoader(false)
        return
      }
      const durationInDays = Math.round(expiration / (24 * 3600))
      const session = await createDeviceSession(deviceSessionItem)
      if (session) {
        sendUiEvent({
          ...uiData.SetupSession.btnSaveSession,
          controlDetail: `sessionDurationDays=${durationInDays.toString()}`
        })
        showDeviceSyncScreen()
        setLoader(false)
      }
    } catch (error) {
      console.error(error)
      ToastNotification(toast)
      setLoader(false)
    }
  }

  const showDeviceSyncScreen = () => {
    setSyncedModalState(true)
    sendUiEvent({
      ...uiData.SessionCreationSuccess.successModal
    })
  }

  const gotoNextScreen = () => {
    sendUiEvent({
      ...uiData.SessionCreationSuccess.btnCloseSuccessModal
    })
    dispatch(updateRefreshSessionEvent(true))
    setSyncedModalState(false)
    if (currentScreenName === screenNames.START_SESSION) {
      dispatch(updateRefreshJobsEvent(true))
    }
    dispatch(updateCurrentScreen(screenNames.DOCUMENT_LIST))
  }

  const onChangeDurationType = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedDuration = e.target.value
    setSelectedDuration(selectedDuration)
    setShowDatePicker(selectedDuration === DurationType.CUSTOM)
  }
  const end_date_label: string = i18n.t('application.utils.date.end-date')

  const setReadOnlyAttribute = () => {
    const datePickerInput = document.getElementById('date_picker')
    if (datePickerInput) {
      datePickerInput.setAttribute('readonly', 'readonly')
    }
  }
  useEffect(() => {
    if (showDatePicker) {
      setReadOnlyAttribute()
    }
  }, [showDatePicker])

  const durationOptions = [
    {
      value: DurationType.NONE,
      // TODO: Replace when batch translation is done: i18n.t('application.session-duration.session-radio-button-none.label')
      label: 'None'
    },
    {
      value: DurationType.ONEYEAR,
      // TODO: Replace when batch translation is done: i18n.t('application.session-duration.session-radio-button-one-year-HPX.label')
      label: '1 year',
      // TODO Replace when batch translation is done: i18n.t('application.session-duration.session-radio-button-one-year-HPX.content')
      content: 'Recommended for use on a trusted network.'
    },
    {
      value: DurationType.ONEDAY,
      // Replace when batch translation is done: i18n.t('application.session-duration.session-radio-button-one-day-HPX.label')
      label: '1 day',
      // TODO Replace when batch translation is done: i18n.t('application.session-duration.session-radio-button-one-day-HPX.content')
      content: 'Recommended for temporary use on an untrusted network.'
    },
    {
      value: DurationType.CUSTOM,
      label: i18n.t(
        'application.session-duration.session-radio-button-custom.label'
      ),
      // TODO Replace when batch translation is done: i18n.t('application.session-duration.session-radio-button-custom.content-HPX')
      content: 'Select your own end date.'
    }
  ]

  const CardContent = () => {
    return (
      <section className="align-center create-session-message-block">
        <div>
          <p
            className="body-regular"
            style={{
              borderBottom: '2px solid #edeefe',
              padding: '24px 0px'
            }}
          >
            {i18n.t('application.session-duration.sync-content-HPX')}
          </p>
          <ListContainer
            items={durationOptions.map((option) => ({
              end: (
                <RadioButton
                  data-testid={`session-radio-button-${option.value}.label`}
                  value={option.value}
                  onChange={onChangeDurationType}
                  checked={selectedDuration === option.value}
                />
              ),
              label: option.label,
              description: option.content,
              paddingStart: false,
              paddingEnd: false
            }))}
          />
          {showDatePicker && (
            <div className="session-duration-datepicker">
              <DatePicker
                blockPastDates
                label={end_date_label}
                onChange={handleOnDateChange}
                value={endDate}
                yearsRange={{
                  start: defaultDate.year,
                  end: defaultDate.year + 5
                }}
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: mobileMode ? 'center' : 'flex-end',
              paddingTop: mobileMode ? '24px' : '32px'
            }}
          >
            <Button
              type="primary"
              size="large"
              data-testid="save-session-button"
              onClick={startSession}
              disabled={loader}
              style={{ width: mobileMode ? '100%' : 'auto' }}
            >
              {i18n.t('application.utils.button.save')}
            </Button>
          </div>
        </div>
      </section>
    )
  }

  return (
    <div className="create-session-component">
      <GenericCard>
        <GenericCard.Header
          // TODO: Replace when batch translation is done: i18n.t('application.session-duration.title-HPX')
          title="Set your cloud connection duration"
        />
        <GenericCard.Body
          paddingBodyBottom={true}
          paddingBodyTop={false}
          paddingBodyHorizontal={true}
        >
          {mobileMode ? (
            <MobileScroll offset="200px">{CardContent()}</MobileScroll>
          ) : (
            CardContent()
          )}
        </GenericCard.Body>
      </GenericCard>
      <Modal
        className="regular-modal"
        leadingArea={
          <IconCheckCircle
            size={72}
            color="hunterGreen7"
          />
        }
        title={i18n.t('application.session-duration.session-synced.title')}
        buttonGroup={{
          textPrimaryButton: 'Done',
          onClickPrimaryButton: () => {
            gotoNextScreen()
          },
          size: 'large',
          orientation: 'horizontal-full'
        }}
        isOpen={syncedModalState}
        align="center"
      >
        <div className="synced-modal">
          <p
            data-testid="synced-modal-content"
            className="synced-modal-content"
          >
            {i18n.t('application.session-duration.session-synced.content')}
          </p>
        </div>
      </Modal>
    </div>
  )
}

export default CreateSession
