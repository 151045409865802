import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }

  div.clientos-ui-toolkit-inline-alert__content--variant > .descriptions svg {
    color: #ff8900 !important;
  }
`
