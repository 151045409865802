import React, { useEffect, useState } from 'react'
import './CopyToClipboard.scss'
import useText from '../../../hooks/useText'
import { copyToClipboard } from './helper'
import useAssetsProvider from '../../../hooks/useAssetsProvider'
// eslint-disable-next-line no-restricted-imports
import { SimpleUiEvent } from '@jarvis/jweb-core'
import DataCollection, { uiEvents } from '../../../services/DataCollection'
import { Button, IconClipboard, Modal } from '@clientos/ui-toolkit'
import useIsDesktop from 'src/hooks/useIsDesktop'

export type CopyToClipboardProps = {
  disabled?: boolean
}
const CopyToClipboard = ({ disabled = false }: CopyToClipboardProps) => {
  const [modalState, setModalState] = useState({
    show: false,
    message: ''
  })

  const isDesktop = useIsDesktop()

  /* istanbul ignore next */
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (modalState.show) {
      timeout = global.setTimeout(() => {
        setModalState({ show: false, message: '' })
      }, 5000)
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [modalState])

  const text = useText()
  const assetsProvider = useAssetsProvider()
  if (!assetsProvider) return null
  const getText = (subkey: string) => {
    const assetKey = `application.${subkey}`
    return assetsProvider.getText(assetKey)
  }
  // TODO: Apply TM or request new translation for changing to sentence case
  // and replace by getText('bottomBar.modal.successfulCopyToClipboardHPX')
  const successfulCopyText = 'Text copied to clipboard'
  const unsuccessfulCopyText = getText(
    'bottomBar.modal.unsuccessfulCopyToClipboard'
  )

  if (unsuccessfulCopyText) {
    console.error('Unable to copy the text')
  }

  const handleAnalytics = (event: SimpleUiEvent) => {
    DataCollection.sendUIEvent(event)
  }

  const handleCopyAll = () => {
    handleAnalytics(uiEvents.copyButton)
    copyToClipboard(text as string)
      .then(showModal(true, successfulCopyText))
      .catch(showModal(true, unsuccessfulCopyText))
  }
  const showModal =
    (show: boolean, message = '') =>
    () => {
      if (show) handleAnalytics(uiEvents.textCopiedModal)
      if (message == successfulCopyText)
        handleAnalytics(uiEvents.successfulCopyTextScreenDisplayed)
      setModalState({ show, message })
    }

  return (
    <>
      <div
        className="button-with-text"
        aria-label="Copy all"
      >
        {isDesktop ? (
          <Button
            id="copy-to-clipboard-button-desktop"
            icon={<IconClipboard />}
            type="primary"
            size="large"
            onClick={handleCopyAll}
            disabled={disabled}
          >
            {/* TODO: Apply TM or request new translation for changing to sentence case and replace by getText('bottomBar.copyAllHPX')*/}
            {'Copy all'}
          </Button>
        ) : (
          <>
            <Button
              id={`copy-to-clipboard-button${disabled ? '-disabled' : ''}`}
              icon={<IconClipboard />}
              type="secondary"
              onClick={handleCopyAll}
              disabled={disabled}
            />
            <p className={`text-below-button${disabled ? '-disabled' : ''}`}>
              {getText('bottomBar.copyAll')}
            </p>
          </>
        )}
      </div>
      <Modal
        align="center"
        onClose={showModal(false)}
        isOpen={modalState.show}
      >
        <span>{modalState.message}</span>
      </Modal>
    </>
  )
}

export default CopyToClipboard
