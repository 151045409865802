import { DateTime } from 'luxon'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const calculateExpirationDate = (expires: number) => {
  const now = DateTime.utc().toSeconds()
  const diff = expires - now
  if (diff < 0) {
    return 0 // session expired
  }

  const diffDays = diff / 86400
  if (diffDays >= 1) {
    return Math.round(diffDays)
  }

  return diffDays
}
