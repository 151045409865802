export const colors = [
  '#C40052',
  '#2BB08F',
  '#C749E7',
  '#7C37EF',
  '#1A91AB',
  '#45C7FF',
  '#FE9E2E',
  '#4759F5'
]

export const randomColor = (): string => {
  return colors[Math.floor(Math.random() * colors.length)]
}
