import styled from 'styled-components'

export const MainContainer = styled.div`
  > button {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
`
