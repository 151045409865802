import { Scrollbar, useMediaQuery } from '@clientos/ui-toolkit'
import React from 'react'
import { ScrollContainer } from './styles'
import { breakpoints } from '@clientos/ui-toolkit'

type MobileScrollProps = {
  editMode?: boolean
  children?: React.ReactNode
  offset?: string
}

const MobileScroll = (props: MobileScrollProps) => {
  const breakPointMd = useMediaQuery(`(max-width: ${breakpoints.md})`)
  const editMoveOffset = breakPointMd
    ? 'calc(100vh - 250px)'
    : 'calc(100vh - 290px)'
  return (
    <>
      <ScrollContainer>
        <Scrollbar
          height={
            props.editMode
              ? editMoveOffset
              : `calc(100vh - ${props.offset ? props.offset : '0px'})`
          }
          marginRight={false}
        >
          {props.children}
        </Scrollbar>
      </ScrollContainer>
    </>
  )
}

export default MobileScroll
