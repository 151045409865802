import React, { createContext, useContext, useReducer } from 'react'
import { toggleReducer, ToggleState, initialState } from './ToggleReducer'

type ToggleActions = {
  setPrintToggle: (state: boolean) => void
  setEmailToggle: (state: boolean) => void
  setSaveToggle: (state: boolean) => void
  resetState: () => void
}

type ToggleContextType = {
  toggleState: ToggleState
  toggleActions: ToggleActions
}

type ToggleContextProviderProps = { children: React.ReactNode }

const defaultContextValue: ToggleContextType = {
  toggleState: {} as ToggleState,
  toggleActions: {} as ToggleActions
}

export const ToggleContext = createContext(defaultContextValue)

export const ToggleProvider = ({ children }: ToggleContextProviderProps) => {
  const [toggleState, dispatch] = useReducer(toggleReducer, initialState)

  const toggleActions: ToggleActions = {
    setPrintToggle: (state: boolean) => {
      dispatch({ type: 'print', payload: state })
    },
    setEmailToggle: (state: boolean) => {
      dispatch({ type: 'email', payload: state })
    },
    setSaveToggle: (state: boolean) => {
      dispatch({ type: 'save', payload: state })
    },
    resetState: () => {
      dispatch({ type: 'reset' })
    }
  }

  return (
    <ToggleContext.Provider value={{ toggleState, toggleActions }}>
      {children}
    </ToggleContext.Provider>
  )
}

export const useToggleContext = () => useContext(ToggleContext)
