import boxTile from './box-tile.svg'
import box from './box.svg'
import cameraAction from './cameraAction.svg'
import cloudFolder from './cloud-folder.svg'
import dropBoxTile from './dropbox-tile.svg'
import dropBox from './dropbox.svg'
import email from './email.svg'
import emailAction from './emailAction.svg'
import evernoteTile from './evernote-tile.svg'
import evernote from './evernote.svg'
import googleDriveTile from './googleDrive-tile.svg'
import googleDrive from './googleDrive.svg'
import oneDriveTile from './one-drive-tile.svg'
import oneDrive from './one-drive.svg'
import phone from './phone.svg'
import print from './print.svg'
import printAction from './printAction.svg'
import quickbooksTile from './quickbooks-tile.svg'
import quickBooks from './quickbooks.svg'
import save from './save.svg'
import smartTasks from './smart-tasks.svg'
import uploadAction from './uploadAction.svg'
import noShortcuts from './no-shortcuts.svg'

const Images = {
  box,
  cloudFolder,
  dropBox,
  email,
  evernote,
  googleDrive,
  oneDrive,
  print,
  quickBooks,
  save,
  smartTasks,
  cameraAction,
  printAction,
  emailAction,
  uploadAction,
  googleDriveTile,
  dropBoxTile,
  oneDriveTile,
  boxTile,
  evernoteTile,
  quickbooksTile,
  phone,
  noShortcuts
}

export default Images
