// eslint-disable-next-line no-restricted-imports
import { JWebPromise, WhenJWebReady } from '@jarvis/jweb-core'
export default class JWeb {
  static async getInstance(): Promise<JWebPromise> {
    const res = await WhenJWebReady
    if (res) {
      return res
    } else {
      console.info('JWeb plugins not available')
      throw new Error('JWeb plugins not available')
    }
  }
  static async getLanguageCode(): Promise<string> {
    const jWeb = await this.getInstance()
    const devicePlugin = jWeb.Plugins.Device
    const isNative = jWeb.JWeb.isNative
    if (devicePlugin && isNative) {
      const locale = (await devicePlugin.getInfo()).locale
      return locale.languageTag
    }
    return window.navigator.language
  }
}
