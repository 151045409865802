// eslint-disable-next-line no-restricted-imports
import {
  CreatePublisherOptions,
  EventServicePlugin,
  EventServicePluginError,
  JWebPromise,
  Publisher,
  Subscriber,
  WhenJWebReady
} from '@jarvis/jweb-core'

let EventService: EventServicePlugin

const ensureJWeb = async () => {
  const response: JWebPromise = await WhenJWebReady
  if (response && response.Plugins && response.Plugins.EventService) {
    EventService = response.Plugins.EventService
  } else throw new Error('JWeb plugins not available')
}

const getPublisher = async (
  eventService: EventServicePlugin,
  publisherId: string,
  options: CreatePublisherOptions
) => {
  const publisher = await eventService.createPublisher(publisherId, options)
  if (hasEventServicePluginError(publisher as EventServicePluginError))
    console.error(
      'get publisher in dispatch event failed',
      (publisher as EventServicePluginError).errorType
    )
  else return publisher as Publisher
}

const hasEventServicePluginError = (
  subscriber: Subscriber | EventServicePluginError
) => {
  const hasError =
    (subscriber as EventServicePluginError).errorType !== undefined
  if (hasError) {
    console.error(
      'has event service plugin while dispatch event error',
      (subscriber as EventServicePluginError).errorType
    )
  }
  return hasError
}

const publish = async (publisher: Publisher, eventName: string) => {
  const response = await publisher.publish(eventName, {})
  if (
    response &&
    hasEventServicePluginError(response as EventServicePluginError)
  ) {
    console.error(
      `Publishing dispatch event ${eventName} Failed`,
      (response as EventServicePluginError).errorType,
      (response as EventServicePluginError).reason
    )
  } else console.info(`Publishing dispatch event ${eventName} Success`)
}

const dispatchEventWithEventService = async (eventName: string) => {
  const publisherId = `com.hp.cloudScans.dispatchevent.publisher-${Math.random()
    .toString(36)
    .substring(2)}`
  const createPublisherOptions: CreatePublisherOptions = {
    allowEventingFallback: true
  }

  const dispatchPublisher = await getPublisher(
    EventService,
    publisherId,
    createPublisherOptions
  )
  if (dispatchPublisher) {
    await publish(dispatchPublisher, eventName)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const dispatchEvent = async (eventName: string) => {
  await ensureJWeb()
  await dispatchEventWithEventService(eventName)
}
