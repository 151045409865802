import { OptionInterface } from '@veneer/core'
import { i18n } from '../../assets/locale'

export const getDestinationOptions = (): OptionInterface[] => {
  return [
    {
      value: 'edit',
      label: i18n.t('common.contextAction.edit')
    },
    {
      value: 'remove',
      label: i18n.t('common.contextAction.remove')
    }
  ]
}

export const getShortcutOptions = (): OptionInterface[] => {
  return [
    {
      value: 'start',
      label: i18n.t('common.contextAction.start')
    },
    {
      value: 'edit',
      label: i18n.t('common.contextAction.edit')
    },
    {
      value: 'delete',
      label: i18n.t('common.contextAction.delete')
    }
  ]
}

export const getAddSaveOptions = (): OptionInterface[] => {
  return [
    {
      value: 'add-destination',
      // TODO: Replace when batch translation is done: title={i18n.t('common.contextAction.addDestinationHPX')}
      label: 'Add destination'
    },
    {
      value: 'remove-access',
      label: i18n.t('common.contextAction.remove')
    },
    {
      value: 'edit-account-name',
      // TODO: Replace when batch translation is done: title={i18n.t('common.contextAction.editAccountNameHPX')}
      label: 'Edit name'
    }
  ]
}
