/* eslint-disable camelcase */
import { JarvisAssetsProvider } from '@jarvis/web-assets-provider'
import { Locales, SpecificLocales, SupportedLanguages } from './locales'

/*
 * Based on: UCDE Portal's i18n.js
 * Date: 02/2021
 */

const DEFAULT_COUNTRY = 'US'
const DEFAULT_LANGUAGE = 'en'

const stringParser = (text: string, keys: { [x: string]: string }) => {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g
  return text.replace(templateMatcher, (_, idx) => keys[idx])
}

class I18n {
  country: string
  language: string
  rawTextProvider: JarvisAssetsProvider

  private static instance: I18n

  constructor() {
    this.country = DEFAULT_COUNTRY
    this.language = DEFAULT_LANGUAGE
    this.rawTextProvider = new JarvisAssetsProvider(
      Locales,
      this.language,
      this.country
    )
  }

  public static get Instance() {
    return this.instance || (this.instance = new this())
  }

  locale() {
    return `${this.language}_${this.country}`
  }

  setSupportedLocale(locale: string) {
    if (locale in Locales) {
      const [language, country] = locale.split('_')
      this.language = language
      this.country = country
      this.rawTextProvider = new JarvisAssetsProvider(
        Locales,
        language,
        country
      )
    } else {
      this.country = DEFAULT_COUNTRY
      this.language = DEFAULT_LANGUAGE
    }
  }

  t(id: string, keys: any = null) {
    try {
      const rawString = this.rawTextProvider.getText(id)
      if (keys) {
        return stringParser(rawString, keys)
      }
      return rawString
    } catch (err) {
      return id
    }
  }
}

export const i18n = I18n.Instance

export const resolveLocale = (locale: string) => {
  const separators = ['-', '_']
  const localeArray = locale.split(new RegExp(separators.join('|'), 'g'))
  const isLanguageSupported = SupportedLanguages.includes(localeArray[0])
  if (isLanguageSupported) {
    const language = localeArray[0]
    const country = resolveCountry(
      localeArray[0],
      localeArray.length >= 2 ? localeArray[localeArray.length - 1] : undefined
    )
    return { country, language }
  }
  return { country: 'US', language: 'en' }
}

const resolveCountry = (language: string, country?: string) => {
  const locale = getBestMatch(language, country)
  return locale.split(new RegExp(`${language}_`, 'g'))[1]
}

const getBestMatch = (language: string, country?: string) => {
  const locale = `${language}_${country}`
  if (country) {
    if (locale in Locales) {
      return locale
    }

    if (locale in SpecificLocales) {
      return SpecificLocales[locale]
    }
  }

  return Object.keys(Locales).filter((locale) =>
    locale.split('_')[0].includes(language)
  )[0]
}
