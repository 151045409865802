import { useContext } from 'react'
import { Text, BaseElement } from 'slate'
import { TextEditContext } from '../../contexts/textEditContext'
const useText = () => {
  const { textEditState } = useContext(TextEditContext)
  if (!textEditState) {
    console.error('Does not have text in edit state')
    return null
  }
  return (textEditState.text as BaseElement[])
    .map((descendant: BaseElement) =>
      (descendant.children as Text[])
        .map((child: Text) => child.text)
        .join('\n')
    )
    .join('\n')
}

export default useText
