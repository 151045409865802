import { IconCheckCircle } from '@veneer/core'
import React from 'react'
import { SessionState } from 'src/data/schemas/session'
import { StyledModal } from './styles'
import { i18n } from 'src/assets/locale'

type ModalSetupSessionProps = {
  show: boolean
  onClose: { (): void }
  modelName: string
  enabled: SessionState
  expiryDate: string
}

const ModalSetupSession = (props: ModalSetupSessionProps) => {
  return (
    <StyledModal
      title={
        //TODO: Replace by i18n.t('pages.AccessShortcuts.accessDisabledModalTitleHPX')
        props.enabled
          ? i18n.t('pages.AccessAfterSave.accessEnabled')
          : 'Shortcuts access is off'
      }
      onClose={() => props.onClose()}
      leadingArea={
        props.enabled ? (
          <IconCheckCircle
            size={72}
            color="green5"
          />
        ) : null
      }
      align="center"
      closeOnBlur={false}
      isOpen={props.show}
      aria-label="ModalSetupSession"
      buttonGroup={{
        textPrimaryButton: 'Done',
        onClickPrimaryButton: () => {
          props.onClose()
        },
        size: 'medium',
        orientation: 'vertical'
      }}
    >
      {props.enabled ? (
        <>
          {/*	TODO: Replace by i18n.t('pages.AccessShortcuts.accessEnabledModalMessage1HPX')*/}
          <p
            style={{ paddingBottom: '15px' }}
          >{`You will have Shortcut access on your ${props.modelName} until ${props.expiryDate}.`}</p>
          <p>
            {/*	TODO: Replace by i18n.t('pages.AccessShortcuts.accessEnabledModalMessage2HPX')*/}
            {
              'You can manage your access in the Shortcuts section of this printer settings.'
            }
          </p>
        </>
      ) : (
        <>
          {/*	TODO: Replace by i18n.t('pages.AccessShortcuts.accessDisabledModalMessage1HPX')*/}
          <p
            style={{ paddingBottom: '18px' }}
          >{`Shortcuts access is off on your ${props.modelName}.`}</p>
          {/*	TODO: Replace by i18n.t('pages.AccessShortcuts.accessDisabledModalMessage2HPX')*/}
          <p>
            {
              "You can manage Shortcuts access from this printer's settings in the HP app."
            }
          </p>
        </>
      )}
    </StyledModal>
  )
}

export default ModalSetupSession
