// eslint-disable-next-line no-restricted-imports
import {
  Action,
  AssetUnitType,
  CDMEventData,
  CreatePublisherOptions,
  DataCollectionEventNames,
  DataValveCDMEventData,
  EventServicePlugin,
  EventServicePluginError,
  JWebPromise,
  Publisher,
  SimpleUiEvent,
  Subscriber,
  ValveControllerMetadata,
  WhenJWebReady
} from '@jarvis/jweb-core'

const version = '1.1.0'
let appInstanceId: string | undefined = undefined
let platform: string | undefined = undefined
let country: string | undefined = undefined
let EventService: EventServicePlugin

const ensureJWeb = async () => {
  const response: JWebPromise = await WhenJWebReady
  platform = response.JWeb.platform
  EventService = response.Plugins.EventService
  if (!country) {
    const getInfo = await response.Plugins.Device.getInfo()
    country = getInfo.locale.country
  }
}

const getAssetUnit = () => {
  switch (platform) {
    case 'ios':
    case 'android':
      return AssetUnitType.mobile as AssetUnitType
    case 'mac':
    case 'windows':
    default:
      return AssetUnitType.desktop as AssetUnitType
  }
}

const getAppInstanceId = () => {
  const query = new URLSearchParams(window.location.search)
  const value = query.get('appInstanceId')

  if (!value) {
    console.error(`Missing appInstanceId query parameter`)
    return
  }

  return value?.toLowerCase()
}

appInstanceId = getAppInstanceId()

const getValveControllerMetadata = (): ValveControllerMetadata => {
  return {
    assetUnit: getAssetUnit(),
    appInstanceId: appInstanceId,
    country: country
  }
}

const getPublisher = async (
  eventService: EventServicePlugin,
  publisherId: string,
  options: CreatePublisherOptions
) => {
  const publisher = await eventService.createPublisher(publisherId, options)

  if (hasEventServicePluginError(publisher as EventServicePluginError))
    console.error(
      'get publisher failed',
      (publisher as EventServicePluginError).errorType
    )
  else return publisher as Publisher
}

const hasEventServicePluginError = (
  subscriber: Subscriber | EventServicePluginError
) => {
  const hasError =
    (subscriber as EventServicePluginError).errorType !== undefined
  if (hasError) {
    console.error(
      'has event service plugin error',
      (subscriber as EventServicePluginError).errorType
    )
  }
  return hasError
}

const publish = async (
  publisher: Publisher,
  eventName: string,
  eventData: object
) => {
  const response = await publisher.publish(eventName, eventData)
  if (
    response &&
    hasEventServicePluginError(response as EventServicePluginError)
  ) {
    console.error(
      `Publishing data collection ${eventName} Failed`,
      (response as EventServicePluginError).errorType,
      (response as EventServicePluginError).reason
    )
  }
  // eslint-disable-next-line no-console
  else console.info(`Publishing data collecion ${eventName} Success`)
}

const sendUiEventWithEventService = async (simpleUiEvent: SimpleUiEvent) => {
  const eventName = DataCollectionEventNames.cdmEvent

  const publisherId = `com.hp.shortcuts.datacollection.publisher-${Math.random()
    .toString(36)
    .substring(2)}`

  const createPublisherOptions: CreatePublisherOptions = {
    allowEventingFallback: true
  }

  const sendUiPublisher = await getPublisher(
    EventService,
    publisherId,
    createPublisherOptions
  )

  const cdmEventData: CDMEventData = {
    dateTime: new Date().toISOString(),
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    version: '1.4.0',
    eventDetail: simpleUiEvent
  }

  const valveControllerMetadata = getValveControllerMetadata()
  const eventData: DataValveCDMEventData = {
    events: [cdmEventData],
    valveControllerMetadata
  }

  if (sendUiPublisher) {
    await publish(sendUiPublisher, eventName, eventData)
  }
}

export const sendUiEvent = async (uiData: SimpleUiEvent) => {
  await ensureJWeb()
  const simpleUiEvent: SimpleUiEvent = {
    ...uiData
  }
  await sendUiEventWithEventService(simpleUiEvent)
}

export const uiData = {
  btnAddShortcut: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutsMain',
    screenPath: '/Shortcuts/',
    controlName: 'AddShortcutsButton',
    activity: 'Shortcuts-v01'
  },
  btnFileTypeDesktop: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'FileType',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'FileTypeSelectButton',
    controlDetail: ''
  },
  btnFileTypeMobile: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'FileType',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'FileTypeSelectButton',
    controlDetail: ''
  },
  btnSignUpAccountSaveDestinationDesktop: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'CreateShortcut',
    controlName: 'AccountSetupButton',
    screenPath: '/Shortcuts/',
    controlDetail: '',
    activity: 'Shortcuts-v01'
  },
  btnSignUpAccountSaveDestinationMobile: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'AddSave',
    controlName: 'AccountSetupButton',
    screenPath: '/Shortcuts/',
    controlDetail: '',
    activity: 'Shortcuts-v01'
  },
  btnStartShortcut: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutEditOverlay',
    screenPath: '/Shortcuts/',
    controlName: 'StartButton',
    activity: 'Shortcuts-v01'
  },
  btnYesModalDelete: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutDeleteOverlay',
    screenPath: '/Shortcuts/ShortcutEditOverlay/',
    controlName: 'DeleteButton',
    activity: 'Shortcuts-v01'
  },
  cardPrebuiltPrintSaveEmail: {
    version,
    action: Action.controlTileClicked,
    screenName: 'AddShortcutBase',
    screenPath: '/Shortcuts/',
    controlName: 'PrebuiltPesTile',
    activity: 'Shortcuts-v01'
  },
  cardPrebuiltSaveToGoogleDrive: {
    version,
    action: Action.controlTileClicked,
    screenName: 'AddShortcutBase',
    screenPath: '/Shortcuts/',
    controlName: 'PrebuiltSaveToGdriveTile',
    activity: 'Shortcuts-v01'
  },
  cardCustomShortcut: {
    version,
    action: Action.controlTileClicked,
    screenName: 'AddShortcutBase',
    screenPath: '/Shortcuts/',
    controlName: 'CreateYourOwnShortcutTile',
    activity: 'Shortcuts-v01'
  },
  executeShortcutFromCard: {
    version,
    action: Action.controlButtonClicked,
    controlName: 'ExecuteShortcutTile',
    controlDetail: '',
    screenName: 'ShortcutsMain',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01'
  },
  executeShortcutFromFlyout: {
    version,
    action: Action.controlTileClicked,
    controlName: 'ExecuteShortcutTile',
    controlDetail: '',
    screenName: 'ShortcutsSignedInPreviewOverlay',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01'
  },
  firstShortcutSaved: {
    version,
    action: Action.screenDisplayed,
    screenName: 'ShortcutSavedFirstTime',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01'
  },
  systemShortcutAdded: {
    version,
    action: Action.screenDisplayed,
    screenName: 'SystemSrtcAdded',
    screenPath: '/Shortcuts/ShortcutsMain/'
  },
  listOfShortcutOnFlyout: {
    version,
    action: Action.screenDisplayed,
    screenName: 'ShortcutsSignedInPreviewOverlay',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01'
  },
  modalDeleteShortcut: {
    version,
    action: Action.screenDisplayed,
    screenName: 'ShortcutDeleteOverlay',
    screenPath: '/Shortcuts/ShortcutEditOverlay/',
    activity: 'Shortcuts-v01'
  },
  noShortcutsOnFlyout: {
    version,
    action: Action.screenDisplayed,
    screenName: 'ShortcutsNotSignedInPreviewOverlay',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01'
  },
  SettingsShortcutDesktop: {
    version,
    toggleAddEmail: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'CreateShortcut',
      screenPath: '/Shortcuts/',
      controlName: 'EmailToggle',
      controlDetail: '',
      activity: 'Shortcuts-v01'
    },
    toggleAddPrint: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'CreateShortcut',
      screenPath: '/Shortcuts/',
      controlName: 'PrintToggle',
      controlDetail: '',
      activity: 'Shortcuts-v01'
    },
    toggleAddSave: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'CreateShortcut',
      screenPath: '/Shortcuts/',
      controlName: 'SaveToggle',
      controlDetail: '',
      activity: 'Shortcuts-v01'
    },
    btnYesCancelCreate: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'CancelShortcut',
      screenPath: '/Shortcuts/CreateShortcut/',
      controlName: 'YesCancelButton',
      activity: 'Shortcuts-v01'
    }
  },
  SettingsShortcutMobile: {
    version,
    btnAddEmail: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'AddShortcut',
      screenPath: '/Shortcuts/',
      controlName: 'AddEmailButton',
      activity: 'Shortcuts-v01'
    },
    btnAddPrint: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'AddShortcut',
      screenPath: '/Shortcuts/',
      controlName: 'AddPrintButton',
      activity: 'Shortcuts-v01'
    },
    btnAddSave: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'AddShortcut',
      screenPath: '/Shortcuts/',
      controlName: 'AddSaveButton',
      activity: 'Shortcuts-v01'
    },
    btnYesCancelCreate: {
      version,
      action: Action.controlButtonClicked,
      screenName: 'CancelShortcutCreation',
      screenPath: '/Shortcuts/AddShortcut/',
      controlName: 'YesCancelButton',
      activity: 'Shortcuts-v01'
    }
  },
  shortcutSaved: {
    version,
    action: Action.screenDisplayed,
    screenName: 'ShortcutSaved',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01'
  },
  shortcutEditedSaved: {
    version,
    action: Action.screenDisplayed,
    screenName: 'SaveEditShortcut',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01'
  },
  toastDeviceFetchError: {
    version,
    action: Action.screenDisplayed,
    screenName: 'ContentNotLoadedNotification',
    screenPath: '/Shortcuts/ShortcutsAccess/',
    activity: 'Shortcuts-v01'
  },
  toastEnableAccessShortcutError: {
    version,
    action: Action.screenDisplayed,
    screenName: 'FailedToEnableShortcutsNotification',
    screenPath: '/Shortcuts/ShortcutsAccess/',
    activity: 'Shortcuts-v01'
  },
  toggleQuickRunDesktop: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'CreateShortcut',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'QuickRunToggle',
    controlDetail: ''
  },
  toggleQuickRunMobile: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutsSettings',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'QuickRunToggle',
    controlDetail: ''
  },
  btnSmartFileNameMobile: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutsSettings',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'SmartFileNameToggle',
    controlDetail: ''
  },
  btnSmartFileNameDesktop: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'CreateShortcut',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'SmartFileNameToggle',
    controlDetail: ''
  },
  screenCancelEdit: {
    version,
    action: Action.screenDisplayed,
    screenName: 'CancelEditShortcut',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01'
  },
  screenRemoveAccessDesktop: {
    version,
    action: Action.screenDisplayed,
    screenName: 'DestinationAccessOverlay',
    screenPath: '/Shortcuts/CreateShortcut/',
    activity: 'Shortcuts-v01'
  },
  screenRemoveAccessMobile: {
    version,
    action: Action.screenDisplayed,
    screenName: 'DestinationAccessOverlay',
    screenPath: '/Shortcuts/AddSave/',
    activity: 'Shortcuts-v01'
  },
  btnYesCancelEdit: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'CancelEditShortcut',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'YesButton'
  },
  btnOptionAccess: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutsAccess',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'TimeSelectButton',
    controlDetail: ''
  },
  btnSaveAccess: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutsAccess',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'SaveButton'
  },
  inlineNotificationExpiring: {
    version,
    action: Action.screenDisplayed,
    screenName: 'AccessExpireNotification',
    screenPath: '/Shortcuts/'
  },
  inlineNotificationEnable: {
    version,
    action: Action.screenDisplayed,
    screenName: 'EnableNotification',
    screenPath: '/Shortcuts/'
  },
  btnEnableDisableInlineNotification: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'EnableNotification',
    screenPath: '/Shortcuts/',
    controlName: 'EnableButton'
  },
  btnContinueDestinations: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'CreateShortcut',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01'
  },
  addPrintScreen: {
    version,
    action: Action.screenDisplayed,
    screenName: 'ShortcutsPrintOption',
    screenPath: '/Shortcuts/'
  },
  addEmailScreen: {
    version,
    action: Action.screenDisplayed,
    screenName: 'ShortcutsEmailOption',
    screenPath: '/Shortcuts/'
  },
  addSaveScreen: {
    version,
    action: Action.screenDisplayed,
    screenName: 'ShortcutsSaveOption',
    screenPath: '/Shortcuts/'
  },
  copiesButton: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutsPrintOption',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'CopiesButton',
    controlDetail: ''
  },
  colorsButton: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutsPrintOption',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'ColorButton',
    controlDetail: ''
  },
  twoSidedButton: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutsPrintOption',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'TwoSidedButton',
    controlDetail: ''
  },
  btnAddToShortcutPrint: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutsPrintOption',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'AddToShortcutButton'
  },
  btnAddToShortcutEmail: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutsEmailOption',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'AddToShortcutButton'
  },
  checkboxAddSave: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutSaveOption',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'AccountSelectedButton',
    controlDetail: ''
  },
  signInButtonAddSave: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'ShortcutSaveOption',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'AddAccountsButton',
    controlDetail: ''
  },
  modalRemoveAccessAddSave: {
    version,
    action: Action.screenDisplayed,
    screenName: 'RemoveAcctOverlay',
    screenPath: '/Shortcuts/RemoveAcct/'
  },
  btnRemoveAccessAddSave: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'RemoveAcctOverlay',
    screenPath: '/Shortcuts/RemoveAcct/',
    activity: 'Shortcuts-v01',
    controlName: 'Remove Button'
  },
  btnCancelDestinationsDesktop: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'CreateShortcut',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'CancelButton'
  },
  btnHelpEditShortcut: {
    version,
    action: Action.controlButtonClicked,
    screenName: 'EditShortcut',
    screenPath: '/Shortcuts/',
    activity: 'Shortcuts-v01',
    controlName: 'HelpButton'
  }
}
