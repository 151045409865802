import { Header } from '@veneer/core'
import styled from 'styled-components'

export const MainContainer = styled.div`
  grid-area: 'header';
  width: 100% !important;
`

export const StyledHeader = styled(Header)`
  padding-bottom: 24px;
  gap: 16px;
  div {
    &.vn-header__leading-area {
      justify-self: start;
    }
    &.vn-header__center-area {
      justify-self: center;
    }
    &.vn-header__trailing-area {
      justify-self: end;
    }
  }
  .clientos-ui-toolkit-back-navigation-button {
    span:last-child {
      width: 100%;
      white-space: normal;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      word-break: break-word;
    }
  }
`
