import { useToast } from '@veneer/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DeleteDocumentModal from 'src/components/DocumentList/DeleteDocumentModal'
import Loader from 'src/components/Loader'
import NoFiles from 'src/components/NoFiles'
import NotificationBanner from 'src/components/NotificationBanner'
import ToastNotification from 'src/components/ToastNotification'
import { screenNames } from 'src/pages/CloudScan/screens'
import { sendUiEvent, uiData } from 'src/plugins/jweb/DataCollection'
import {
  updateInteractNotificationBanner,
  updateRefreshJobsEvent,
  updateReloadJobList,
  updateShowExpirationBanner
} from 'src/stores/event'
import { updateCurrentScreen } from 'src/stores/screen'
import { DeviceDescription } from 'src/services/Shortcuts/Type'
import { getCloudScanCompletedOrErrorJobs } from 'src/services/Tenzing'
import type { Job } from 'src/services/Tenzing/Type'
import type { RootState } from 'src/stores'
import Document from './Document/document'
import './DocumentList.scss'
import { GenericCard } from '@clientos/ui-toolkit'
import { i18n } from 'src/assets/locale'
import MobileScroll from 'src/pages/Template/MobileScroll'
import { useDevicesSessionList } from 'src/hooks/useDevicesSessionList'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const DocList = () => {
  const dispatch = useDispatch()
  const toast = useToast()
  const [jobsList, setJobsList] = useState<Job[]>([])

  const [pageNo, setPageNo] = useState(1)
  const [jobCount, setJobCount] = useState(0)
  const [failedJobCount, setFailedJobCount] = useState(0)
  const [isFetching, setIsFetching] = useState(false)
  const [loading, setLoading] = useState(true)
  const [jobsLoaded, setJobsLoaded] = useState(false)
  const itemPerPage = 10
  const deviceList: DeviceDescription[] = useSelector(
    (state: RootState) => state.device.allDeviceList.devices
  )
  const selectedDevice = useSelector(
    (state: RootState) => state.device.selectedDevice
  )
  const refreshJobsEvent: number = useSelector(
    (state: RootState) => state.event.refreshJobsEvent
  )
  const reloadJobList: boolean = useSelector(
    (state: RootState) => state.event.reloadJobList
  )
  const devicesSessionLoaded: boolean = useSelector(
    (state: RootState) => state.event.devicesSessionLoaded
  )
  const showExpirationBanner: boolean = useSelector(
    (state: RootState) => state.event.showExpirationBanner
  )
  const interactNotificationBanner: boolean = useSelector(
    (state: RootState) => state.event.interactNotificationBanner
  )
  const anySessionCreated = useSelector(
    (state: RootState) => state.event.anySessionCreated
  )
  const mobileMode: boolean = useSelector(
    (state: RootState) => state.screens.mobileMode
  )
  const deleteModalEnabled = useSelector(
    (state: RootState) => state.screens.deleteModalEnabled
  )

  const devicesSessionList = useDevicesSessionList()

  const handleManageButton = () => {
    dispatch(updateShowExpirationBanner(false))
    dispatch(updateInteractNotificationBanner(true))
    sendUiEvent({
      ...uiData.SessionExpiry.btnRenewSession
    })
    dispatch(updateCurrentScreen(screenNames.CONNECTED_PRINTERS))
  }

  useEffect(() => {
    const getFilesList = async () => {
      try {
        setLoading(true)
        await devicesSessionList()
        let currentFailedJobs = 0
        const response = await getCloudScanCompletedOrErrorJobs(
          pageNo,
          itemPerPage
        )
        if (response.status) {
          currentFailedJobs = getFailedJobsCount(response.jobs)
          setJobCount(response.jobs.length + jobCount)
          setFailedJobCount(currentFailedJobs + failedJobCount)
          setJobsList([...jobsList, ...response.jobs])
          setIsFetching(false)
          setJobsLoaded(true)
        }
        response.jobs.length
          ? sendUiEvent({
              ...uiData.JobsList.jobsListScreen,
              actionAuxParams: `totalJobsCount=${
                response.jobs.length + jobCount
              }&failedJobsCount=${currentFailedJobs + failedJobCount}`
            })
          : sendUiEvent({ ...uiData.NoJobsList.noJobsListScreen })
      } catch (error) {
        console.error(error)
        ToastNotification(toast)
        setJobCount(0)
        setJobsList([])
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
    if (reloadJobList) {
      getFilesList()
      dispatch(updateReloadJobList(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadJobList, dispatch, devicesSessionList])

  useEffect(() => {
    if (!anySessionCreated && jobsLoaded && devicesSessionLoaded) {
      setJobsLoaded(false)
      if (selectedDevice && !selectedDevice.expires && !jobCount) {
        dispatch(updateCurrentScreen(screenNames.START_SESSION))
      } else if (!jobCount && !deviceList?.length) {
        dispatch(updateCurrentScreen(screenNames.NO_SUPPORTED_PRINTER))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, jobsLoaded, devicesSessionLoaded])

  useEffect(() => {
    if (refreshJobsEvent) {
      setPageNo(1)
      setJobCount(0)
      setJobsList([])
      dispatch(updateReloadJobList(true))
      dispatch(updateRefreshJobsEvent(false))
    }
  }, [dispatch, refreshJobsEvent])

  useEffect(() => {
    const expiringDevices = deviceList?.some((printer) => {
      return printer.expires === undefined || printer.expires <= 3
    })

    if (expiringDevices) {
      if (!showExpirationBanner && !interactNotificationBanner) {
        dispatch(updateShowExpirationBanner(true))
        sendUiEvent({
          ...uiData.SessionExpiry.sessionExpiryBanner
        })
      }
    } else {
      dispatch(updateShowExpirationBanner(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceList])

  useEffect(() => {
    if (!isFetching || jobCount < pageNo * itemPerPage) {
      setIsFetching(false)
      return
    }
    setPageNo(pageNo + 1)
    dispatch(updateReloadJobList(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])

  const getFailedJobsCount = (jobsList: Job[]) => {
    let failedJobs = 0
    jobsList.forEach((job) => {
      if (job.jobStatus.jobState === 'error') {
        failedJobs++
      }
    })
    return failedJobs
  }

  const handleScroll = () => {
    const element: HTMLElement = document.querySelectorAll<HTMLElement>(
      '.clientos-ui-toolkit-scrollbar > div'
    )[0]
    if (element.offsetHeight + element.scrollTop + 2 >= element.scrollHeight) {
      setIsFetching(true)
    }
  }

  useEffect(() => {
    if (!loading && devicesSessionLoaded) {
      const scrollContent = document.querySelectorAll<HTMLElement>(
        '.clientos-ui-toolkit-scrollbar > div'
      )[0]

      if (scrollContent) {
        scrollContent.addEventListener('scrollend', handleScroll)
        return () =>
          scrollContent.removeEventListener('scrollend', handleScroll)
      }
    }
  }, [loading, devicesSessionLoaded])

  const CardContent = () => {
    return (
      <>
        {!loading && (
          <NotificationBanner
            showBanner={showExpirationBanner}
            onClick={handleManageButton}
            deviceQt={deviceList?.length}
          />
        )}
        <p
          data-testid="joblist-title-content"
          className="joblist-title-content body-regular"
          style={{
            padding: mobileMode ? '24px' : '32px'
          }}
        >
          {i18n.t('application.joblist.title')}
        </p>
        {jobsList.map((job: Job) => {
          return (
            <>
              <div className="document-divider" />
              <Document
                key={job.id}
                {...job}
              />
            </>
          )
        })}
      </>
    )
  }

  return (
    <>
      <div className="document-list-component">
        {deleteModalEnabled && <DeleteDocumentModal />}
        {loading || !devicesSessionLoaded ? (
          <Loader height="80vh" />
        ) : jobsList?.length ? (
          <GenericCard
            className="joblist-container"
            style={{
              overflow: mobileMode ? 'hidden' : 'auto',
              width: '80%'
            }}
          >
            <GenericCard.Header title={i18n.t('application.header.title')} />
            <GenericCard.Body>
              {mobileMode ? (
                <MobileScroll offset="200px">{CardContent()}</MobileScroll>
              ) : (
                CardContent()
              )}
            </GenericCard.Body>
          </GenericCard>
        ) : (
          !loading && <NoFiles />
        )}
      </div>
    </>
  )
}

export default DocList
