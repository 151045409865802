/* eslint-disable no-restricted-imports */
import React, { useEffect, useRef, useState } from 'react'
import {
  PluginListenerHandle,
  ReservedValueStoreKeys,
  WhenJWebReady
} from '@jarvis/jweb-core'
import { ToastProvider } from '@veneer/core'
import { asyncWithLDProvider, useFlags } from 'launchdarkly-react-client-sdk'
import { Provider, useDispatch } from 'react-redux'
import { i18n, resolveLocale } from 'src/assets/locale'
import Loader from 'src/components/Loader'
import { DependencyManagerProvider } from 'src/contexts/dependencyManager'
import { nativeBackButtonHandler } from 'src/hooks/useNativeBackButton'
import { getValuesFromValueStore } from 'src/plugins/jweb/ValueStore'
import Routes from 'src/routes'
import JWeb from 'src/services/JWeb'
import { LocalPrinterInterface } from 'src/services/JWeb/Type'
import {
  getAllDevicesFromOrg,
  getDeviceByModel
} from 'src/services/Shortcuts/device'
import { DeviceDescription } from 'src/services/Shortcuts/Type'
import { store } from 'src/stores'
import { updateAppInstanceId } from 'src/stores/application'
import { updateDeviceList } from 'src/stores/device'
import { updateRefreshSessionEvent } from 'src/stores/event'
import { enableLoader, updateMobileMode } from 'src/stores/screen'
import * as T from './types'
import { getLaunchDarklyClientId } from 'src/config'
import { EmptyPage } from '@clientos/ui-toolkit'
import { GlobalStyle } from 'src/styles/global'

const AppComponent: React.FC<T.AppPropsType> = () => {
  const dispatch = useDispatch()

  const [jWebReady, setJWebReady] = useState(false)
  const [valueStoreReady, setValueStoreReady] = useState(false)
  const [deviceInfoReady, setDeviceInfoReady] = useState(false)

  const selectedPrinterUuid = useRef<string | undefined | null>()
  const selectedPrinterModelNumber = useRef<string | undefined | null>()
  const appInstanceId = useRef<string | undefined | null>()

  const featureFlags = useFlags()

  useEffect(() => {
    dispatch(enableLoader(true))
    const getSelectedPrinterAndAppIdFromValueStore = async () => {
      if (!valueStoreReady) {
        const valueStoreData = await getValuesFromValueStore([
          ReservedValueStoreKeys.selectedPrinterUuid,
          ReservedValueStoreKeys.selectedPrinterModelNumber,
          ReservedValueStoreKeys.applicationInstanceId
        ])

        selectedPrinterUuid.current = valueStoreData?.get(
          ReservedValueStoreKeys.selectedPrinterUuid
        )

        selectedPrinterModelNumber.current = valueStoreData?.get(
          ReservedValueStoreKeys.selectedPrinterModelNumber
        )

        appInstanceId.current = valueStoreData?.get(
          ReservedValueStoreKeys.applicationInstanceId
        )

        console.log(
          `selectedPrinterUuid: ${selectedPrinterUuid.current}, selectedPrinterModelNumber: ${selectedPrinterModelNumber.current}, applicationInstanceId: ${appInstanceId.current}`
        )

        setValueStoreReady(true)
      }
    }

    getSelectedPrinterAndAppIdFromValueStore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const setAppInfo = () => {
      dispatch(updateAppInstanceId(appInstanceId.current))
    }
    setAppInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueStoreReady])

  useEffect(() => {
    dispatch(enableLoader(true))
    const getDeviceInfo = async () => {
      if (!deviceInfoReady) {
        const isMobileMode = await JWeb.isMobileMode()

        dispatch(updateMobileMode(isMobileMode))
        setDeviceInfoReady(true)
      }
    }

    getDeviceInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(enableLoader(true))
    // TODO: This will be replaced with the the method getLocalPrinters() from the ServiceRouting.ts file.
    const localPrinters: LocalPrinterInterface = {
      localPrinters: []
    }

    const getAllDevices = async () => {
      try {
        const allDeviceDetails = await getAllDevicesFromOrg()
        if (localPrinters && localPrinters.localPrinters.length !== 0) {
          const localDevices = await Promise.all(
            localPrinters.localPrinters.map(async (localPrinter) => {
              const deviceDetails = await getDeviceByModel(
                localPrinter.printerModelNumber
              )

              return {
                deviceIdentity: {
                  deviceUuid: localPrinter.printerUuid,
                  modelNumber: localPrinter.printerModelNumber,
                  ipAddress: localPrinter.printerIPAddress,
                  modelName: deviceDetails.productShortName
                },
                bizModel: deviceDetails.mfgBizModel,
                platformIdentifier: deviceDetails.platformIdentifier,
                benefitsControlModel: deviceDetails.benefitsControlModel,
                deviceImages: deviceDetails.images
              }
            })
          )

          allDeviceDetails?.devices.push(...localDevices)
        }

        if (allDeviceDetails?.devices) {
          await Promise.all(
            allDeviceDetails.devices.map(async (device: DeviceDescription) => {
              const result = await getDeviceByModel(
                device.deviceIdentity.modelNumber
              )
              if (result) {
                return (device.isCloudScansCompatible = result.solutions?.some(
                  (item: string) => item === 'scanToHPCloud'
                ))
              }
            })
          )

          const { skipPlsSolutionCheck } = featureFlags
          if (!skipPlsSolutionCheck) {
            allDeviceDetails.devices = allDeviceDetails.devices.filter(
              (device) => device.isCloudScansCompatible
            )
          }

          if (selectedPrinterUuid.current) {
            allDeviceDetails.devices.forEach((device) => {
              if (
                device.deviceIdentity.deviceUuid === selectedPrinterUuid.current
              ) {
                device.selected = true
              }
            })
          }

          dispatch(updateDeviceList({}))
          dispatch(updateDeviceList(allDeviceDetails))
          dispatch(updateRefreshSessionEvent(true))
        }
      } catch (e) {
        console.log(e)
      } finally {
        dispatch(enableLoader(false))
      }
    }

    getAllDevices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let listener: PluginListenerHandle
    async function doDeviceConfig() {
      if (!jWebReady) {
        const jweb = await WhenJWebReady
        const getInfo = await jweb.Plugins.Device.getInfo()
        const locale = getInfo.locale.languageTag
        if (locale) {
          const processedLocale = resolveLocale(locale)
          i18n.setSupportedLocale(
            `${processedLocale.language}_${processedLocale.country}`
          )
        }
        try {
          if (jweb.JWeb.platform === 'android') {
            listener = jweb.Plugins.App.addListener('backButton', () => {
              const callBack = nativeBackButtonHandler.getBackButtonCallBack()
                ? nativeBackButtonHandler.getBackButtonCallBack()
                : ''
              if (callBack) callBack()
            })
          }
        } catch (error) {
          console.error('Error at adding native listener: ', error)
        }
        setJWebReady(true)
        return () => {
          if (listener) {
            listener.remove()
          }
        }
      }
    }
    doDeviceConfig()
  }, [jWebReady])
  return jWebReady && valueStoreReady ? <Routes /> : <Loader height="80vh" />
}

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = () => {
  const [LDProvider, setLDProvider] = useState(null)

  const getLDProvider = async () => {
    return await asyncWithLDProvider({
      clientSideID: getLaunchDarklyClientId(),
      options: {
        bootstrap: 'localStorage',
        streaming: false
      },
      reactOptions: {
        useCamelCaseFlagKeys: true
      }
    })
  }

  useEffect(() => {
    const setProvider = async () => {
      const Provider = await getLDProvider()

      setLDProvider(() => Provider)
    }
    setProvider()
  }, [])

  return (
    <>
      <GlobalStyle />
      <DependencyManagerProvider>
        {LDProvider ? (
          <LDProvider>
            <Provider store={store}>
              <ToastProvider position="top">
                <EmptyPage>
                  <AppComponent />
                </EmptyPage>
              </ToastProvider>
            </Provider>
          </LDProvider>
        ) : (
          <Loader height="80vh" />
        )}
      </DependencyManagerProvider>
    </>
  )
}

export default App
