import styled from 'styled-components'

export const StyledDiv = styled.div`
  div[role='alert'] {
    margin-top: 0 !important;
  }
  margin: 20px 0px 0 0px;
  padding-bottom: 15px;
`

export default StyledDiv
