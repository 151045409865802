// eslint-disable-next-line no-restricted-imports
import {
  WhenJWebReady,
  ConsolePlugin,
  LogLevel,
  ConsoleOptions
} from '@jarvis/jweb-core'
let Console: ConsolePlugin | undefined = undefined
async function ensureJWeb() {
  const res = await WhenJWebReady
  if (res) {
    Console = res.Plugins.Console as ConsolePlugin
    console.log(Console)
  } else {
    console.info('JWeb plugins not available')
    throw new Error('JWeb plugins not available')
  }
}

export async function LogToNative(msg: string) {
  await ensureJWeb()
  if (Console) {
    const log: ConsoleOptions = {
      logLevel: LogLevel.Log,
      message: msg,
      tag: 'Log'
    }
    Console.log(log)
  } else {
    console.log(msg)
  }
}

export async function InfoToNative(msg: string) {
  await ensureJWeb()
  if (Console) {
    const log: ConsoleOptions = {
      logLevel: LogLevel.Info,
      message: msg,
      tag: 'Info'
    }
    Console.log(log)
  } else {
    console.info(msg)
  }
}

export async function ErrorToNative(msg: string) {
  await ensureJWeb()
  if (Console) {
    const log: ConsoleOptions = {
      logLevel: LogLevel.Error,
      message: msg,
      tag: 'Error'
    }
    Console.log(log)
  } else {
    console.error(msg)
  }
}
