import { useEffect, useState } from 'react'
import { handleLocale } from '../../assets/localizationHelper'
import JWeb from '../../services/JWeb'
import { AssetsProvider } from '@jarvis/web-assets-provider'
import AssetsProviderFactory from '../../assets/AssetsProviderFactory'
const useAssetsProvider = () => {
  const [assetsProvider, setAssetsProvider] = useState<AssetsProvider | null>()
  useEffect(() => {
    const listener = async () => {
      try {
        await JWeb.getLanguageCode().then((languageCode) => {
          const [language, country] = handleLocale(languageCode).split('_')
          setAssetsProvider(() =>
            AssetsProviderFactory.create(language, country)
          )
        })
      } catch (error) {
        console.info('Error while getting JWeb language code', error)
        throw error
      }
    }
    listener()
  }, [])
  return assetsProvider
}
export default useAssetsProvider
