import { CustomImage } from '@clientos/ui-toolkit'
import React from 'react'

const SpellCheck = () => {
  return (
    <CustomImage
      title="spellcheck-icon"
      viewBox="0 0 40 30"
      size={40}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M9.8418 14H11.1338L7.49707 4.13574H6.28027L2.64355 14H3.93555L4.92676 11.1768H8.85059L9.8418 14ZM6.83398 5.72852H6.94336L8.48828 10.1309H5.28906L6.83398 5.72852Z"
          fill="#404040"
        />
        <path
          d="M16.7119 14C18.8105 14 20.0547 12.9678 20.0547 11.2314C20.0547 9.92578 19.2002 9.00293 17.8535 8.83203V8.72266C18.8037 8.55176 19.5557 7.62207 19.5557 6.59668C19.5557 5.08594 18.4482 4.13574 16.6367 4.13574H12.8633V14H16.7119ZM14.0938 5.22266H16.377C17.6006 5.22266 18.3115 5.80371 18.3115 6.80859C18.3115 7.85449 17.5527 8.3877 16.0625 8.3877H14.0938V5.22266ZM14.0938 12.9131V9.44727H16.4111C17.9834 9.44727 18.79 10.0283 18.79 11.1699C18.79 12.3115 18.0107 12.9131 16.5273 12.9131H14.0938Z"
          fill="#404040"
        />
        <path
          d="M26.0361 14.2324C28.1348 14.2324 29.6934 13.0635 30.0215 11.252H28.7705C28.4561 12.3936 27.4102 13.0977 26.0361 13.0977C24.1357 13.0977 22.9463 11.5459 22.9463 9.07129C22.9463 6.59668 24.1357 5.03809 26.0293 5.03809C27.3896 5.03809 28.4424 5.83789 28.7705 7.10938H30.0215C29.7275 5.20898 28.1074 3.90332 26.0293 3.90332C23.3496 3.90332 21.6816 5.88574 21.6816 9.07129C21.6816 12.25 23.3564 14.2324 26.0361 14.2324Z"
          fill="#404040"
        />
        <path
          d="M25.1999 26.1375C24.6499 26.1375 24.0999 26 23.6874 25.5875L19.0124 20.9125C18.8749 20.6375 18.8749 20.225 19.0124 19.95C19.2874 19.675 19.6999 19.675 19.9749 19.95L24.6499 24.4875C24.9249 24.7625 25.3374 24.7625 25.6124 24.4875L36.4749 13.7625C36.7499 13.625 37.1624 13.625 37.4374 13.7625C37.7124 14.0375 37.7124 14.45 37.4374 14.725L26.5749 25.45C26.1624 25.8625 25.7499 26.1375 25.1999 26.1375Z"
          fill="#404040"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="40"
            height="30"
            fill="white"
          />
        </clipPath>
      </defs>
    </CustomImage>
  )
}
export default SpellCheck
