import { IconArrowRight, IconPlus } from '@veneer/core'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const Container = styled.div`
  overflow-y: auto;
  height: 100vh;
`

export const IconsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`

export const StyledIconArrowRight = styled(IconArrowRight)<{ id: string }>`
  align-self: center;
  margin-left: 5px;
  margin-right: 5px;
`

export const StyledIconPlus = styled(IconPlus)<{ id: string }>`
  align-self: center;
  margin-left: 5px;
  margin-right: 5px;
`

export const NoShortcutsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`

export const NoShortcutsMessage = styled.p`
  text-align: center;
  font-family: ${tokens.typography.family0};
  line-height: 20px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 5px;
`

export const ShortcutsMessageContainer = styled.div`
  align-items: left;
  margin: 20px 10px;
`

export const ShortcutsMessageTitle = styled.h1`
  text-align: left;
  font-family: ${tokens.typography.family0};
  font-size: 20px;
  line-height: 40px;
`

export const ShortcutsMessage = styled.p`
  text-align: left;
  font-family: ${tokens.typography.family0};
  font-size: 16px;
`

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`

export const ErrorMessage = styled.p`
  text-align: center;
  font-family: ${tokens.typography.family0};
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 5px;
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`

export const DescriptionItem = styled.p`
  text-align: left;
  color: white;
  font-family: ${tokens.typography.family0};
  font-size: 16px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  max-width: 195px;
`

export const MainContainerHPX = styled.div`
  padding: 24px 24px 0px 0px;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 24px;
`

export const ContainerGenericCard = styled.div<{ desktopMode: boolean }>`
  div {
    .vn-tooltip {
      padding: 12px;
      border-radius: 8px;
    }
  }
  button {
    margin-top: 24px;
  }
  .clientos-ui-toolkit-button__ghost-flush {
    margin-top: 0px;
    padding: 0px !important;
  }
  .clientos-ui-toolkit-button.clientos-ui-toolkit-button__ghost-flush
    > span
    > svg {
    fill: #d1d2e4 !important;
  }

  .clientos-ui-toolkit-generic-card__content__header__title {
    font-size: 24px !important;
    text-align: center;
  }

  .clientos-ui-toolkit-list-container__start-content {
    ${(props) => (props.desktopMode ? 'width: 60%;' : 'width: 50%;')};
  }

  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__label {
    color: ${tokens.color.royalBlue7} !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block !important;
    max-width: 100%;
  }

  .clientos-ui-toolkit-generic-card {
    .clientos-ui-toolkit-generic-card__content__body__padding-horizontal {
      padding-right: 0px !important;
    }
  }

  .clientos-ui-toolkit-list-container
    > .clientos-ui-toolkit-list-container__item {
    padding: 24px 12px;
  }

  #no-shortcuts-message-flyout {
    text-align: center;
  }
  #shortcuts-message-content {
    margin-top: 16px;
    padding-bottom: 12px;
  }
  #shortcuts-message-content-mobile {
    text-align: center;
  }
  #shortcut-items-mobile {
    padding: 12px 12px !important;
    > div > svg {
      width: 32px;
      height: 32px;
    }
    > div > img {
      width: 32px;
      height: 32px;
    }
  }
  #generic-card-mobile {
    > div > div {
      padding-bottom: 12px;
    }
    /* this is to eliminate a gap that was left at the bottom of the flyout */
    > div > div > div > div > div {
      height: 100vh;
    }
  }

  #no-shortcuts {
    > div > :nth-child(3) {
      padding-right: 24px !important;
    }
  }
`
