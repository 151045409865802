import { toJpeg } from 'html-to-image'
import { sliceLastWord } from './helper'
const defaultStyle: Partial<CSSStyleDeclaration> = {
  padding: '72px',
  whiteSpace: 'pre-line',
  margin: '0',
  background: 'white',
  fontFamily: 'Calibri',
  fontSize: '12pt',
  textAlign: 'justify',
  boxSizing: 'border-box'
}
const requiredStyle: Partial<CSSStyleDeclaration> = {
  boxSizing: 'border-box',
  whiteSpace: 'pre-line',
  margin: '0'
}
const createDummyElement = (
  style: Partial<CSSStyleDeclaration>
): HTMLParagraphElement => {
  const dummyElement = document.createElement('p')
  const newStyle = { ...style, ...requiredStyle }
  Object.entries(newStyle).forEach(([key, value]: any[]) => {
    dummyElement.style[key] = value
  })
  document.body.appendChild(dummyElement)
  dummyElement.innerHTML = ''
  return dummyElement
}
const cleanDummyElement = (element: HTMLParagraphElement): void => {
  document.body.removeChild(element)
}
const split = (arrayText: string[]): string[] => {
  // simple method to run throught every entry of an Array and split the content
  let ex: string[] = []
  arrayText.forEach((e) => {
    ex = ex.concat(e.split(' '))
  })
  return ex
}
export const getTextSplitByPages = async (
  text: string,
  [width, height]: number[],
  style: Partial<CSSStyleDeclaration> = defaultStyle
): Promise<string[]> => {
  if (text.length <= 0) return []
  style.width = width + 'px'
  const splittedLines = text.split(/\r/)
  const splittedText = split(splittedLines)
  const pageElement = createDummyElement(style)
  let wordsWritten = 0
  while (
    wordsWritten < splittedText.length &&
    pageElement.offsetHeight < height
  ) {
    pageElement.innerHTML += ` ${splittedText[wordsWritten]}`
    wordsWritten++
  }
  if (pageElement.offsetHeight > height) {
    pageElement.innerHTML = sliceLastWord(pageElement.innerHTML)
    wordsWritten--
  }
  pageElement.style.minHeight = height + 'px'
  const img = await toJpeg(pageElement)
  cleanDummyElement(pageElement)
  if (wordsWritten === splittedText.length) return [img]
  return [
    img,
    ...(await getTextSplitByPages(
      splittedText.slice(wordsWritten).join(' '),
      [width, height],
      style
    ))
  ]
}
