import { calculateExpirationDate } from 'src/modules/common/helpers'
import { getSessionByDeviceUuid } from 'src/services/Shortcuts'
import { updateDeviceList } from 'src/stores/device'
import {
  updateDevicesSessionLoaded,
  updateRefreshJobsEvent,
  updateRefreshSessionEvent
} from 'src/stores/event'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DeviceDescription, DeviceLists } from 'src/services/Shortcuts/Type'
import { RootState } from 'src/stores'

export const useDevicesSessionList = () => {
  const dispatch = useDispatch()
  const deviceList: DeviceLists = useSelector(
    (state: RootState) => state.device.allDeviceList
  )

  const refreshSessionEvent: number = useSelector(
    (state: RootState) => state.event.refreshSessionEvent
  )

  const fetchDevicesSessionList = useCallback(async () => {
    if (!deviceList) {
      console.error('deviceList is undefined')
      return
    }
    const localDeviceList = JSON.parse(JSON.stringify(deviceList))
    const activeSessionSku: string[] = []
    const inactiveSessionSku: string[] = []

    await Promise.all(
      localDeviceList.devices.map(async (device: DeviceDescription) => {
        try {
          const result = await getSessionByDeviceUuid(
            device.deviceIdentity.deviceUuid
          )
          if (result && result?.state !== 'inactive') {
            device.expires = calculateExpirationDate(result.expires)
            activeSessionSku.push(device.deviceIdentity.modelNumber)
          } else {
            device.expires = 0
            inactiveSessionSku.push(device.deviceIdentity.modelNumber)
          }
        } catch (error) {
          console.error(
            `Failed to fetch session for device ${device.deviceIdentity.deviceUuid}:`,
            error
          )
          device.expires = 0
          inactiveSessionSku.push(device.deviceIdentity.modelNumber)
        }
        return device
      })
    )

    dispatch(updateDeviceList(localDeviceList))
    dispatch(updateDevicesSessionLoaded(true))
  }, [deviceList, dispatch])

  useEffect(() => {
    if (refreshSessionEvent) {
      fetchDevicesSessionList().then(() => {
        dispatch(updateRefreshJobsEvent(true))
        dispatch(updateRefreshSessionEvent(false))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return fetchDevicesSessionList
}
