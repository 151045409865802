import { i18n } from '../../assets/locale'

export const isValidEmail = (email: string) => {
  /* eslint no-control-regex: "off" */
  const emailRegex =
    /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
  return emailRegex.test(email)
}

export const isValidShortcutTitle = (title: string) => {
  return (
    !/[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(title) &&
    /^[^%#/:+=~|()[\]\\*?"@!&$<>{}`´^]+$/.test(title) &&
    !/(^[.])|([.]$)/.test(title)
  )
}

export const resolveLabel = (value: string) => {
  switch (value) {
    case 'one-sided':
      return i18n.t('pages.AddPrint.off')
    case 'two-sided-short-edge':
      return i18n.t('pages.AddPrint.sedge')
    case 'two-sided-long-edge':
      return i18n.t('pages.AddPrint.ledge')
  }
}

export const resolveRepositoryType = (type: string) =>
  ({
    googledrive: 'Google Drive',
    onedrive: 'OneDrive',
    dropbox: 'Dropbox',
    box: 'Box',
    quickbooksonline: 'Quickbooks',
    evernote: 'Evernote'
  })[type]

export const resolveFileType = (type: string) =>
  ({
    standardpdf: i18n.t('pages.FileType.basicPdf'),
    jpeg: i18n.t('pages.FileType.image'),
    searchablepdf: i18n.t('pages.FileType.searchablePdf'),
    docx: i18n.t('pages.FileType.wordDoc'),
    txt: i18n.t('pages.FileType.plainText')
  })[type]
