import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Images from 'src/assets/images'
import type { RootState } from 'src/stores'
import './NoSupportedPrinter.scss'
import PrinterSupportsModal from './PrinterSupportsModal'
import { colors } from '@clientos/ui-toolkit'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const NoSupportedPrinter = () => {
  const [showPrinterSupportsModal, setShowPrinterSupportsModal] =
    useState(false)

  const mobileMode: boolean = useSelector(
    (state: RootState) => state.screens.mobileMode
  )

  return (
    <>
      <section
        className="align-center no-supported-printer-message-block"
        // TODO: Review marginTop once header component is ready
        style={{
          marginTop: mobileMode ? '70px' : '120px'
        }}
      >
        <div className="align-center no-supported-printer-container">
          <div data-testid="Icon-document">
            <img
              style={{
                height: mobileMode ? '38px' : '64px',
                width: mobileMode ? '38px' : '64px'
              }}
              data-testid="paper-icon"
              src={Images.startSessionPrinter}
              alt="paper-icon"
            />
          </div>
          <div className="align-center no-supported-printer-messages">
            <div
              data-testid="no-supported-printer-message-title"
              className="title-small--medium"
            >
              {/* TODO: when new batch translation comes, replace per i18n.assetsProvider.getText("application.no-supported-printer.title") */}
              An HP Cloud Scans supported printer hasn’t been added yet
            </div>
            <div
              data-testid="no-supported-printer-message-content"
              className="body-large"
            >
              {/* TODO: when new batch translation comes, replace per i18n.assetsProvider.getText("application.no-supported-printer.content") */}
              To use HP Cloud Scans, add a printer that supports this feature
            </div>
            <button
              className="no-supported-printer-button"
              onClick={() => {
                setShowPrinterSupportsModal(true)
              }}
              style={{
                color: colors.interactiveLinkBase
              }}
            >
              {/* TODO: when new batch translation comes, replace per i18n.assetsProvider.getText("application.no-supported-printer.button") */}
              Check if my printer supports HP Cloud Scans
            </button>
          </div>
        </div>
      </section>
      <PrinterSupportsModal
        show={showPrinterSupportsModal}
        onClose={() => setShowPrinterSupportsModal(false)}
      />
    </>
  )
}

export default NoSupportedPrinter
