import { ListItem } from '@veneer/core'
import styled from 'styled-components'

export const CenterContainer = styled.div`
  display: inline-grid;
  white-space: pre-wrap;
`

export const StyledListItem = styled(ListItem)`
  align-items: center !important;
  padding: 16px 0 !important;
  border-top: 1px solid #edeefe !important;
  border-bottom: none !important;
  border-radius: 0 !important;

  div:nth-child(2) {
    padding: 0 !important;
  }
`
