export type EditState = {
  id: string
  editMode: boolean
}

export const initialState: EditState = {
  id: '',
  editMode: false
}

export const editReducer = (
  state: EditState,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case 'id':
      return {
        ...state,
        id: action.payload
      }
    case 'editMode':
      return {
        ...state,
        editMode: action.payload
      }
    case 'reset':
      return {
        ...initialState
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
