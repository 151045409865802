import { useRef } from 'react'

export const useDebounceLeading = (
  callback: (...args: any) => void,
  timeout = 1000
) => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>()

  function debounce(...args: any) {
    if (!timerRef.current) callback(...args)

    clearTimeout(timerRef.current as NodeJS.Timeout)

    timerRef.current = setTimeout(() => {
      timerRef.current = undefined
    }, timeout)
  }

  return debounce
}
