import { useEffect, useRef, useState } from 'react'
import useDeviceHeight from '../useDeviceHeight'
const Window: any = window as any

const useIsEditing = () => {
  const heightRef = useRef<number>()
  const [isEditing, setIsEditing] = useState(false)
  const height = useDeviceHeight()
  useEffect(() => {
    heightRef.current = Window.screen.height
  }, [])
  useEffect(() => {
    const initialHeight = heightRef.current
    if (initialHeight && height !== undefined) {
      const diffHeight = Math.round(
        (Math.abs(initialHeight - height) / initialHeight) * 100
      )
      setIsEditing(diffHeight > 30)
    }
  }, [height])
  return isEditing
}

export default useIsEditing
