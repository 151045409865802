import { useToast } from '@veneer/core'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Images from '../../assets/images'
import { i18n } from '../../assets/locale'
import {
  editConnectorName,
  getCloudStoragesList,
  updateShortcut
} from '../../clients/shortcuts'
import Header from '../../components/Header'
import Image from '../../components/Image'
import Loader from '../../components/Loader'
import { useAnimationContext } from '../../contexts/animations/AnimationContext'
import { PageProps } from '../../data/schemas/app'
import { Connector } from '../../data/schemas/connector'
import { LocationState } from '../../data/schemas/location'
import { RepositoryConfig, ShortcutItem } from '../../data/schemas/shortcut'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import {
  resolveConnectorName,
  toastMessages
} from '../../modules/common/helpers'
import { dispatchEvent } from '../../plugins/jweb/EventService'
import {
  ContentContainer,
  MainContainer,
  UpgradeDescription,
  UpgradeTitle
} from './styles'

export const UpgradeShortcuts = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()
  const location = useLocation<LocationState>()
  const { addToast } = useToast()
  const [showContainer, setShowContainer] = useState(true)

  const { setAnimationDirection } = useAnimationContext()

  const onBackButtonPress = () => {
    setAnimationDirection('left')
    dispatchEvent('com.hp.jarvis.servicerouting.serviceinstance.closed')
    dispatchEvent('Close')
  }

  function getError(error: any) {
    addToast?.({
      type: 'negative',
      ...toastMessages('fetchShortcutsError'),
      timeout: 300,
      action: (
        <a
          href={window.location.href}
          onClick={() => window.location.reload()}
        >
          {i18n.t('pages.ListShortcuts.updatePageMessage')}
        </a>
      )
    })
    setShowContainer(false)
    throw error
  }

  useEffect(() => {
    const upgradeShortcuts = async (shortcuts: ShortcutItem[]) => {
      const response = await getCloudStoragesList()
      const repositories: Connector[] = response.repositories

      const authorizedConnectors = repositories.filter(
        (connector) => connector.isAuthorized
      )
      const upgradedConnectors = authorizedConnectors.filter(
        (connector) => connector.connectorName
      )
      const connectorsWithoutName = authorizedConnectors.filter(
        (connector) => !connector.connectorName
      )

      await Promise.all(
        connectorsWithoutName.map((connector) => {
          const connectorName = resolveConnectorName(upgradedConnectors)
          upgradedConnectors.push({
            ...connector,
            connectorName
          })
          return editConnectorName(connector.authorizationId!, {
            connectorId: connector.connectorId!,
            newConnectorName: connectorName,
            oldConnectorName: ''
          })
        })
      ).catch(getError)

      const shortcutsToUpgrade = shortcuts.filter((shortcut) => {
        return shortcut.smartTask.smartTaskConfig?.repositories?.some(
          (repository) => !repository.connectorName
        )
      })
      await Promise.all(
        shortcutsToUpgrade.map((shortcut) => {
          shortcut.smartTask.smartTaskConfig.repositories =
            shortcut.smartTask.smartTaskConfig
              .repositories!.filter((repository) => {
                return (
                  upgradedConnectors.some((connector) => {
                    return connector.connectorId === repository.connectorId
                  }) ||
                  upgradedConnectors.find((connector) => {
                    return connector.type === repository.type
                  })
                )
              })
              .map((repository) => {
                const connector =
                  upgradedConnectors.find((connector) => {
                    return connector.connectorId === repository.connectorId
                  }) ||
                  upgradedConnectors.find((connector) => {
                    return connector.type === repository.type
                  })
                const repositoryConfig: RepositoryConfig = {
                  ...repository,
                  type: connector!.type,
                  connectorId: connector!.connectorId,
                  connectorName: connector!.connectorName!
                }
                return repositoryConfig
              })
          return updateShortcut(shortcut, shortcut.id!)
        })
      ).catch(getError)
      history.push(`${base}/list`)
    }

    upgradeShortcuts(location.state?.shortcuts ?? [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useNativeBackButton(onBackButtonPress)

  return (
    <MainContainer id="main-container-upgrade-shortcuts">
      <Header
        title={i18n.t('pages.UpgradeShortcuts.header')}
        backButtonLabel={'back'}
        onPressIconLeft={onBackButtonPress}
      />
      {showContainer && (
        <ContentContainer desktopMode={props.desktopMode}>
          <Image
            id="phone-image"
            src={Images.phone}
            width="128"
            height="204"
            ariaLabel="Phone"
            alt={''}
          />
          <UpgradeTitle>{i18n.t('pages.UpgradeShortcuts.title')}</UpgradeTitle>
          <UpgradeDescription>
            {i18n.t('pages.UpgradeShortcuts.loading')}
          </UpgradeDescription>
          <Loader position={'relative'} />
        </ContentContainer>
      )}
    </MainContainer>
  )
}
