import { List } from '@veneer/core'
import { ListContainer, RadioButton, SelectInput } from '@clientos/ui-toolkit'
import React, { FunctionComponent, useState } from 'react'
import { ocrLanguageConfigOptionsList } from '../../data/schemas/settings'
import { MainContainer } from './styles'
import { i18n } from 'src/assets/locale'

type FileSettingsFormProps = {
  smartFileLabel: string
  smartFileDescription: string
  isSmartNameOn: boolean
  fileTypeLabel: string
  fileTypeButtonText: string
  onClickFileTypeButton?: { (): void }
  showOcrConfigs: boolean
  ocrLanguage: string
  ocrSelectLabel: string
  onChangeOcrLanguage: { (value: any): void }
  onSmartFileNameClick: { (): void }
  desktopMode: boolean
}

const Content: FunctionComponent<FileSettingsFormProps> = (props) => {
  const [smartFileState, setSmartFileState] = useState(props.isSmartNameOn)

  return (
    <>
      <MainContainer
        id="main-container-file-settings"
        style={{ marginBottom: props.showOcrConfigs ? '32px' : '0' }}
      >
        <List id="list-file-settings">
          {props.showOcrConfigs && (
            <>
              <ListContainer
                items={[
                  {
                    label: props.smartFileLabel,
                    description: props.smartFileDescription,
                    end: (
                      <RadioButton
                        checked={smartFileState}
                        onClick={() => {
                          setSmartFileState(!smartFileState)
                          props.onSmartFileNameClick()
                        }}
                      ></RadioButton>
                    ),
                    alignEnd: 'center',
                    paddingStart: false,
                    paddingEnd: false
                  }
                ]}
              />
              <ListContainer
                items={[
                  {
                    label: i18n.t(
                      'pages.PrinterSettings.ocrLanguageSettingTitle'
                    ),
                    description: i18n.t(
                      'pages.PrinterSettings.ocrLanguageSettingDescription'
                    ),
                    end: (
                      <SelectInput
                        items={ocrLanguageConfigOptionsList}
                        id="select-ocrlanguage-setting"
                        label={props.ocrSelectLabel}
                        value={[props.ocrLanguage]}
                        onValueChange={props.onChangeOcrLanguage}
                      />
                    ),
                    alignEnd: 'center',
                    paddingStart: false,
                    paddingEnd: false
                  }
                ]}
              />
            </>
          )}
        </List>
      </MainContainer>
    </>
  )
}

const FileSettingsForm: FunctionComponent<FileSettingsFormProps> = (props) =>
  Content(props)

export default FileSettingsForm
