import { Modal } from '@clientos/ui-toolkit'
import React from 'react'
import './PrinterSupportsModal.scss'

type PrinterSupportsModalProps = {
  show: boolean
  onClose: { (): void }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PrinterSupportsModal = ({ show, onClose }: PrinterSupportsModalProps) => {
  return (
    <Modal
      className="printer-supports-modal"
      onClose={onClose}
      isOpen={show}
      /* TODO: when new batch translation comes, replace per i18n.assetsProvider.getText("application.no-supported-printer.printer-supports-modal.title") */
      title="Check if your printer supports HP Cloud Scans"
      buttonGroup={{
        /* TODO: when new batch translation comes, replace per i18n.assetsProvider.getText("application.no-supported-printer.printer-supports-modal.button") */
        textPrimaryButton: 'Close',
        onClickPrimaryButton: onClose,
        size: 'large',
        orientation: 'horizontal-full'
      }}
    >
      <div
        className="printer-supports-modal-description"
        aria-label="printer-supports-modal-description"
      >
        <ol>
          {/* TODO: when new batch translation comes, replace per  {i18n.t('application.no-supported-printer.printer-supports-modal.content-item1')} */}
          <li>Go to the home screen on the printer control panel.</li>
          {/* TODO: when new batch translation comes, replace per  {i18n.t('application.no-supported-printer.printer-supports-modal.content-item2')} */}
          <li>Select Scan. Then, select Scan to Cloud.</li>
          {/* TODO: when new batch translation comes, replace per  {i18n.t('application.no-supported-printer.printer-supports-modal.content-item3')} */}
          <li>
            If you see an HP Cloud option, the printer supports HP Cloud Scans.
          </li>
          {/* TODO: when new batch translation comes, replace per  {i18n.t('application.no-supported-printer.printer-supports-modal.content-item4')} */}
          <li>
            Add the supported printer in the HP app to start using HP Cloud
            Scans.
          </li>
        </ol>
      </div>
    </Modal>
  )
}

export default PrinterSupportsModal
