import { EmailConfig, RepositoryConfig } from '../schemas/shortcut'

export type DestinationsConfig = {
  email: EmailConfigState
  print: PrintConfigState
  repositories: RepositoryConfigState
}

export type EmailConfigState = EmailConfig & { set: boolean }

export type PrintConfigState = {
  numberOfCopies: number
  color: 'true' | 'false'
  printDuplex: 'one-sided' | 'two-sided-short-edge' | 'two-sided-long-edge'
  set: boolean
}

export type RepositoryConfigState = {
  selected: RepositoryConfig[]
  set: boolean
}

export type copiesConfigOptions = {
  value: number
  label: string
}

export const CopiesConfigOptionsList: copiesConfigOptions[] = Array.from(
  { length: 50 },
  (_, i) => ({
    value: i + 1,
    label: String(i + 1)
  })
)

export type colorConfigOptions = {
  value: 1 | 0
  label: string
}

export type twoSidedConfigOptions = {
  value: 'one-sided' | 'two-sided-short-edge' | 'two-sided-long-edge'
  label: string
}
