import styled from 'styled-components'
import { colors } from '@clientos/ui-toolkit'

export const GenericCardContainer = styled.div`
  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__end-content
    svg {
    color: ${colors.royalBlue7} !important;
  }

  .clientos-ui-toolkit-list-container
    > .clientos-ui-toolkit-list-container__item.selected
    .clientos-ui-toolkit-list-container__label {
    color: ${colors.black} !important;
  }
`
