import { Button, GenericCard } from '@clientos/ui-toolkit'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Images from 'src/assets/images'
import { i18n } from 'src/assets/locale'
import { screenNames } from 'src/pages/CloudScan/screens'
import MobileScroll from 'src/pages/Template/MobileScroll'
import { sendUiEvent, uiData } from 'src/plugins/jweb/DataCollection'
import type { RootState } from 'src/stores'
import { updateCreateSessionDeviceInfo } from 'src/stores/device'
import { updateCurrentScreen } from 'src/stores/screen'
import './StartSession.scss'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StartSession = () => {
  const startSessionTitle = i18n.t('application.start-session.title')
  // TODO: when new batch translation comes, replace per i18n.t('application.start-session.header')
  const startSessionHeader = 'HP Cloud Scans'
  const startSessionContentOne = i18n.t('application.start-session.content-one')
  const startSessionContentTwo = i18n.t('application.start-session.content-two')
  const startSessionBtnText = i18n.t('application.utils.button.start-session')

  const selectedDevice = useSelector(
    (state: RootState) => state.device.selectedDevice
  )

  const deviceImage =
    selectedDevice?.deviceImages.find((image: string) =>
      image.endsWith('300x245.png')
    ) || ''

  const mobileMode: boolean = useSelector(
    (state: RootState) => state.screens.mobileMode
  )
  const dispatch = useDispatch()

  useEffect(() => {
    sendUiEvent({
      ...uiData.SessionSync.sessionSyncScreen
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showCreateSession = () => {
    sendUiEvent({
      ...uiData.SessionSync.btnStartSession
    })
    dispatch(updateCreateSessionDeviceInfo(selectedDevice))
    dispatch(updateCurrentScreen(screenNames.CREATE_SESSION))
  }

  const CardContent = () => {
    return (
      <section className="align-center start-session-message-block">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <div>
            <p
              style={{
                padding: mobileMode ? '32px 0px' : '32px 8px'
              }}
              data-testid="start-session-message-title"
              className={`text-center  ${mobileMode ? 'subtitle-large' : 'subtitle-regular--medium'}`}
            >
              {startSessionTitle}
            </p>
            <img
              data-testid="startSession-printer"
              src={deviceImage ? deviceImage : Images.startSessionPrinter}
              width={mobileMode ? 100 : 167}
              height={mobileMode ? 58.72 : 98}
              alt="start-session-printer"
              style={{ display: 'block', margin: '0 auto' }}
            />
          </div>
          <div>
            <p
              style={{
                padding: mobileMode ? '32px 0px' : '32px 8px'
              }}
              className="text-center caption-large"
              data-testid="start-session-content"
            >
              {startSessionContentOne} {startSessionContentTwo}
            </p>
          </div>
          <div
            style={{
              padding: mobileMode ? '24px 0px 0px' : '0px 0px 8px',
              width: mobileMode ? '100%' : 'auto'
            }}
          >
            <Button
              type="primary"
              size="large"
              data-testid="start-session-button"
              onClick={showCreateSession}
              style={{ width: '100%' }}
            >
              {startSessionBtnText}
            </Button>
          </div>
        </div>
      </section>
    )
  }

  return (
    <div className="start-session-component">
      <GenericCard>
        <GenericCard.Header title={startSessionHeader} />
        <GenericCard.Body
          paddingBodyBottom={true}
          paddingBodyTop={false}
          paddingBodyHorizontal={true}
        >
          {mobileMode ? (
            <MobileScroll offset="200px">{CardContent()}</MobileScroll>
          ) : (
            CardContent()
          )}
        </GenericCard.Body>
      </GenericCard>
    </div>
  )
}

export default StartSession
