// eslint-disable-next-line no-restricted-imports
import {
  OpenKeyOption,
  ReservedKeyOption,
  ValueStoreError,
  ValueStoreGetResult,
  ValueStoreGetSuccessResult,
  WhenJWebReady
} from '@jarvis/jweb-core'

export type ValueStoreData = Map<string, string | null>

// FIXME: This is a temporary workaround as
// xamarin-value-store is returning an array
// of Key/Value instead of key/value.
const caseInsensitive = (target: ValueStoreGetSuccessResult) => {
  return new Proxy(target, {
    get: (obj, key) => {
      const newKey =
        (key as string).charAt(0).toUpperCase() + (key as string).slice(1)
      if (newKey in obj) {
        return obj[newKey as keyof ValueStoreGetSuccessResult]
      }

      return obj[key as keyof ValueStoreGetSuccessResult]
    }
  })
}

export const getValuesFromValueStore = async (
  keys: (OpenKeyOption | ReservedKeyOption)[]
): Promise<ValueStoreData | undefined> => {
  const jweb = await WhenJWebReady
  if (jweb.Plugins.ValueStore) {
    const getResult = await jweb.Plugins.ValueStore.get({ keys })
    if ((getResult as ValueStoreError).errorType) {
      return undefined
    }

    // Converts an array of objects (key/value) to a map
    return new Map(
      (
        (getResult as ValueStoreGetResult)
          .result as Array<ValueStoreGetSuccessResult>
      ).map((valueStoreGetSuccessResult) => {
        const obj = caseInsensitive(valueStoreGetSuccessResult)
        return [obj.key, obj.value]
      })
    )
  } else {
    console.error('ValueStore plugin is not available')
    return undefined
  }
}
