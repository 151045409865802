import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import TextExtract from '../pages/TextExtract'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory({
  basename: window.location.pathname
})

const Routes = () => {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/"
          >
            <TextExtract />
          </Route>
        </Switch>
      </Router>
    </>
  )
}

export default Routes
