import styled from 'styled-components'

export const MainContainerHPX = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .clientos-ui-toolkit-section-list-container__content__header {
    padding: 0;
  }

  .clientos-ui-toolkit-list-container
    > .clientos-ui-toolkit-list-container__item.padding-end {
    padding-top: 0;
  }

  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__start-content {
    gap: 0;
  }

  .clientos-ui-toolkit-list-container
    > .clientos-ui-toolkit-list-container__item {
    margin-right: 15px;
  }

  .clientos-ui-toolkit-date-picker .vn-input {
    width: unset;
  }

  .clientos-ui-toolkit-date-picker-disabled .vn-input {
    width: unset;
  }

  .css-1cjszy0 {
    width: unset;
  }

  .css-ri7bf5-FormControl {
    width: 100%;
    max-width: 676px;
  }

  .css-1jxu0dk-FormControl {
    width: 100%;
  }
  @media (max-width: 770px) {
    .clientos-ui-toolkit-list-container
      > .clientos-ui-toolkit-list-container__item.padding-end {
      margin-top: -30px;
    }
  }
`

export const Paragraph = styled.p`
  text-align: start;
  line-height: 20px;
  color: #525365;
`

export const StyledDatePicker = styled.div`
  @media (min-width: 770px) {
    margin-top: -15px;
  }
`

export const StyledButtonGroup = styled.div`
  margin-top: 24px !important;
  margin-bottom: 24px !important;
`
