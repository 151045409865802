import { Accordion } from '@veneer/core'
import styled from 'styled-components'

export const CenterContainer = styled.div`
  display: inline-grid;
  white-space: pre-wrap;
`

export const StyledAccordion = styled(Accordion)`
  margin-bottom: 8px;

  > div {
    > div:first-child {
      padding: 12px 0;
    }
  }

  > div {
    border-width: 0 0 1px 0;
    border-radius: 0px !important;
    border-color: #edeefe;
  }

  svg {
    display: none;
  }

  .clientos-ui-toolkit-select-input {
    svg {
      display: block;
    }
  }

  #printItem,
  #emailItem,
  #saveItem {
    border: none;
    margin: 0;
    padding-right: 8px;
  }
`
