import { ToastProvider } from '@veneer/core'
import React, { useEffect, useState } from 'react'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { i18n } from './assets/locale'
import { getDeviceById, getDeviceByModel } from './clients/devices'
import { deviceLookup, getEntitlements } from './clients/shortcuts'
import { getTenantById } from './clients/tenants'
import Loader from './components/Loader'
import { useAnimationContext } from './contexts/animations/AnimationContext'
import { DestinationsContextProvider } from './contexts/destinations/DestinationsContext'
import { useDeviceContext } from './contexts/devices/DeviceContext'
import { EditContextProvider } from './contexts/edit/EditContext'
import { SettingsContextProvider } from './contexts/settings/SettingsContext'
import { useUserContext } from './contexts/users/UserContext'
import { consumerPrinterModels } from './data/enum/consumerPrinterModels'
import { AppProps } from './data/schemas/app'
import { AccessShortcuts } from './pages/AccessShortcuts'
import { AddShortcut } from './pages/AddShortcut'
import { Destinations } from './pages/Destinations'
import { Flyout } from './pages/Flyout'
import ListShortcuts from './pages/ListShortcuts'
import { OcrSettings } from './pages/OcrSettings'
import { PrinterSettings } from './pages/PrinterSettings'
import { ScanDestinationsSettings } from './pages/ScanDestinationsSettings'
import { SavedShortcut } from './pages/SavedShortcut'
import { UpgradeShortcuts } from './pages/UpgradeShortcuts'
import { getAuthToken } from './plugins/jweb/Auth'
import './styles/animationDirection.css'
import { AddEmail } from './pages/AddEmail'
import { AddPrint } from './pages/AddPrint'
import { AddSave } from './pages/AddSave'
import { ShortcutSettings } from './pages/ShortcutSettings'
import { FileType } from './pages/FileType'
import { FolderListing } from './pages/FolderListing'
import { getTenantIdFromToken } from './modules/common/helpers'

export const JarvisShortcuts = (props: AppProps) => {
  let { url } = useRouteMatch()
  url = url.replace(/\/$/, '')
  const location = useLocation()

  const { animationDirection } = useAnimationContext()
  const { userContextState, userContextActions } = useUserContext()
  const { deviceActions } = useDeviceContext()

  const [appStatesReady, setAppStatesReady] = useState(false)

  useEffect(() => {
    const entitlementSetup = async (modelName: string) => {
      try {
        const entitlementsResponse = await getEntitlements(
          `${i18n.language}_${i18n.country}`,
          modelName
        )
        if (entitlementsResponse.isEntitled) {
          userContextActions.setEntitledStatus(true)
        }
        setAppStatesReady(true)
      } catch (e) {
        console.error(e)
        setAppStatesReady(true)
      }
    }

    const populateStates = async () => {
      if (!userContextState.emailAddress && !appStatesReady) {
        try {
          const token = await getAuthToken()
          userContextActions.setGivenName(token.account?.givenName || '')
          userContextActions.setEmailAddress(token.account?.emailAddress || '')
        } catch (e) {
          console.error(e)
        }
      }

      try {
        // There's no need to get printer details if there's no printer selected
        if (props.selectedPrinterUuid) {
          const deviceLookupResponse = await deviceLookup(
            props.selectedPrinterUuid
          )
          deviceActions.setCloudId(deviceLookupResponse.cloud_id)
          deviceActions.setSupportsSession(deviceLookupResponse.supportsSession)

          // FIXME: For now, let's continue getting the device details
          // as we may have customers with old versions of the
          // smart app, where the value store is not available.
          const deviceModel: string =
            props.selectedPrinterModelNumber ||
            (await getDeviceById(deviceLookupResponse.cloud_id))
              .deviceDescription.deviceIdentity.modelNumber

          console.log('printerUuid: ', props.selectedPrinterUuid)
          console.log('printerModelNumber: ', deviceModel)

          const deviceModelsResponse = await getDeviceByModel(deviceModel)
          deviceActions.setModelName(deviceModelsResponse.modelName)
          deviceActions.setModelImage(deviceModelsResponse.images[0] ?? '')

          console.log('printerModelName: ', deviceModelsResponse.modelName)

          const token = await getAuthToken()
          // FIXME: remove JWT parsing when tenantId value is correct for mobile

          const tenantId = getTenantIdFromToken(token)

          const tenantResponse = await getTenantById(tenantId)
          const typeToken = tenantResponse.type

          // FIXME: We need to implement a better solution for this.
          // - remove this hard-coded list of printers
          // - update and check supported solutions in PLS
          if (consumerPrinterModels.includes(deviceModel)) {
            deviceActions.setAllowSessions(true)
          } else {
            const deviceModelsResponse = await getDeviceByModel(deviceModel)
            const hasSolutions = deviceModelsResponse?.solutions
            if (hasSolutions) {
              // FIXME: Logic to derive if a printer is from SMB segment until PLS gets updated
              if (
                !hasSolutions.some(
                  (item: string) => item === 'personalShortcuts'
                )
              ) {
                deviceActions.setAllowSessions(false)
              } else {
                const isScanDestinationSupported =
                  hasSolutions.some(
                    (item: string) => item === 'personalShortcuts'
                  ) && typeToken === 'Personal'
                deviceActions.setIsScanDestinationSupported(
                  isScanDestinationSupported
                )
                // For cases such as Marconi/Kebin devices onboarded on SMB flow
                // Or if added via network, but using a SMB org
                const isTenantConsumer = typeToken === 'Personal' ? true : false
                deviceActions.setAllowSessions(isTenantConsumer)
              }
            } else {
              const isPrinterConsumer =
                deviceModelsResponse?.deviceCapabilities === undefined &&
                deviceLookupResponse.supportsSession
                  ? true
                  : false

              deviceActions.setAllowSessions(isPrinterConsumer)
            }
          }

          await entitlementSetup(encodeURI(deviceModelsResponse.modelName))
        } else {
          await entitlementSetup('')
        }
      } catch (e) {
        console.error('Error getting device information: ', e)
        await entitlementSetup('')
      }
    }

    populateStates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return appStatesReady ? (
    <>
      <ToastProvider position="top">
        <DestinationsContextProvider>
          <SettingsContextProvider>
            <EditContextProvider>
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={350}
                  classNames={
                    !props.desktopMode
                      ? animationDirection === 'right'
                        ? 'slideRight'
                        : 'slideLeft'
                      : ''
                  }
                >
                  {/*
                  we need to pass `location` to `Switch` so it
                  can match the old location as it animates out.
                  https://reactrouter.com/web/example/animated-transitions
                  */}
                  <Switch location={location}>
                    <Route path={`${url}/add`}>
                      <AddShortcut
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/destinations`}>
                      <Destinations
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/saved`}>
                      <SavedShortcut
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/flyout`}>
                      <Flyout
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/upgrade`}>
                      <UpgradeShortcuts
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/email`}>
                      <AddEmail
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/print`}>
                      <AddPrint
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/repositories`}>
                      <AddSave
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/creation_settings`}>
                      <ShortcutSettings
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/filetype`}>
                      <FileType
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/folder`}>
                      <FolderListing
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/access_shortcuts`}>
                      <AccessShortcuts
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/printer_settings`}>
                      <PrinterSettings
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/ocr_settings`}>
                      <OcrSettings
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={`${url}/scan_destinations_settings`}>
                      <ScanDestinationsSettings
                        base={url}
                        props={props}
                      />
                    </Route>

                    <Route path={[`${url}/list`, `${url}/`]}>
                      <ListShortcuts
                        base={url}
                        props={props}
                      />
                    </Route>
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </EditContextProvider>
          </SettingsContextProvider>
        </DestinationsContextProvider>
      </ToastProvider>
    </>
  ) : (
    <Loader />
  )
}

export default React.memo(JarvisShortcuts)
