import React from 'react'
import { PageProps } from '../../data/schemas/app'
import { DestinationsMobileMode } from './mobileMode'
import { DestinationsDesktopMode } from './desktopMode'

export const Destinations = ({ base, props }: PageProps) => {
  const { desktopMode } = props
  return desktopMode ? (
    <DestinationsDesktopMode
      base={base}
      props={props}
    />
  ) : (
    <DestinationsMobileMode
      base={base}
      props={props}
    />
  )
}
