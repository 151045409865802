/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  configureStore,
  Store,
  combineReducers,
  PreloadedState
} from '@reduxjs/toolkit'
import screenReducer from './screen'
import deviceReducer from './device'
import eventReducer from './event'
import applicationReducer from './application'

const rootReducer = combineReducers({
  application: applicationReducer,
  screens: screenReducer,
  device: deviceReducer,
  event: eventReducer
})

export const configureReduxStore = (
  preloadedState?: PreloadedState<RootState>
) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

export const store: Store = configureReduxStore()

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type AppStore = ReturnType<typeof configureReduxStore>
