import { createSlice } from '@reduxjs/toolkit'
import { screenNames } from '../pages/CloudScan/screens'
import { Job } from 'src/services/Tenzing/Type'

export interface screenDataType {
  currentScreenName: string
  deleteModalEnabled: boolean
  loaderEnabled: boolean
  currentJob: Job['id']
  mobileMode: boolean
}

export const initialScreenState: screenDataType = {
  currentScreenName: screenNames.DOCUMENT_LIST,
  deleteModalEnabled: false,
  loaderEnabled: false,
  currentJob: '',
  mobileMode: false
}

export const screenSlice = createSlice({
  name: 'screen',
  initialState: initialScreenState,
  reducers: {
    updateCurrentScreen: (state, action) => {
      state.currentScreenName = action.payload
    },
    enableDeleteModal: (state, action) => {
      state.deleteModalEnabled = action.payload
    },
    enableLoader: (state, action) => {
      state.loaderEnabled = action.payload
    },
    updateCurrentJob: (state, action) => {
      state.currentJob = action.payload
    },
    updateMobileMode: (state, action) => {
      state.mobileMode = action.payload
    }
  }
})

export const {
  updateCurrentScreen,
  enableDeleteModal,
  enableLoader,
  updateCurrentJob,
  updateMobileMode
} = screenSlice.actions

export default screenSlice.reducer
