import { Card, List } from '@veneer/core'
import { Toggle } from '@clientos/ui-toolkit'
import React from 'react'
import { CenterContainer, StyledListItem } from './styles'
import { useToggleContext } from '../../contexts/toggle/ToggleContext'

type ContentProps = {
  printTitle: string
  saveTitle: string
  emailTitle: string
  printSubtitle: React.ReactElement
  emailSubtitle: React.ReactElement
  saveSubtitle: React.ReactElement
  enablePrintToggle: boolean
  enableEmailToggle: boolean
  enableSaveToggle: boolean
  isPrintDestinationSet: boolean
  isEmailDestinationSet: boolean
  isSaveDestinationSet: boolean
}

type DestinationItemProps = {
  id: string
  title: string
  subtitle: React.ReactElement
  isDestinationSet: boolean
  toggle: boolean
  onToggle: () => void
}

const DestinationItem = (props: DestinationItemProps) => {
  return (
    <StyledListItem
      id="list-item-destinations"
      centerArea={
        <CenterContainer>
          <div
            id="list-item-title-destinations"
            className="vn-list-item__title"
            style={{
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: '500',
              color: '#191a22'
            }}
          >
            {props.title}
          </div>
          <div
            id="list-item-subtitle-destinations"
            className="vn-list-item__subtitle"
            style={{ fontSize: '14px', lineHeight: '20px', color: '#525365' }}
          >
            {props.subtitle}
          </div>
        </CenterContainer>
      }
      trailingArea={
        <Toggle
          id={`${props.id}-toggle`}
          aria-label="ContextualMenuButton"
          on={props.toggle}
          onChange={props.onToggle}
        />
      }
    />
  )
}

const Content = (props: ContentProps) => {
  const { toggleState, toggleActions } = useToggleContext()

  return (
    <List id="list-destinations">
      <DestinationItem
        id="print-destination-item"
        title={props.printTitle}
        subtitle={props.printSubtitle}
        isDestinationSet={props.isPrintDestinationSet}
        toggle={toggleState.print}
        onToggle={() => toggleActions.setPrintToggle(!toggleState.print)}
      />
      <DestinationItem
        id="email-destination-item"
        title={props.emailTitle}
        subtitle={props.emailSubtitle}
        isDestinationSet={props.isEmailDestinationSet}
        toggle={toggleState.email}
        onToggle={() => toggleActions.setEmailToggle(!toggleState.email)}
      />
      <DestinationItem
        id="save-destination-item"
        title={props.saveTitle}
        subtitle={props.saveSubtitle}
        isDestinationSet={props.isSaveDestinationSet}
        toggle={toggleState.save}
        onToggle={() => toggleActions.setSaveToggle(!toggleState.save)}
      />
    </List>
  )
}

const DestinationsList = (props: ContentProps) => (
  <Card content={Content(props)} />
)

export default DestinationsList
