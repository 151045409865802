import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CloudScan from 'src/pages/CloudScan'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/">
            <CloudScan />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default Routes
