import React, { useEffect, useRef, useState } from 'react'
import { DependencyManagerProvider } from 'src/contexts/dependencyManager'
import * as T from './types'
// eslint-disable-next-line no-restricted-imports
import {
  PluginListenerHandle,
  ReservedValueStoreKeys,
  WhenJWebReady
} from '@jarvis/jweb-core'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { i18n } from '../assets/locale'
import { resolveLocale } from '../assets/locale/index'
import Loader from '../components/Loader'
import Config from '../config'
import { AnimationContextProvider } from '../contexts/animations/AnimationContext'
import { DeviceContextProvider } from '../contexts/devices/DeviceContext'
import { UserContextProvider } from '../contexts/users/UserContext'
import nativeBackButtonHandler from '../data/handlers/nativeBackButtonHandler'
import { JarvisDeviceSession } from '../JarvisDeviceSession'
import { JarvisShortcuts } from '../JarvisShortcuts'
import { getValuesFromValueStore } from '../plugins/jweb/ValueStore'
import { useQuery } from '../util/query'
import { EmptyPage } from '@clientos/ui-toolkit'
import { ToggleProvider } from 'src/contexts/toggle/ToggleContext'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { LocationState } from 'src/data/schemas/location'
import { GlobalStyle } from 'src/styles/global'

const AppComponent = () => {
  const query = useQuery()
  const deviceModel = useRef('')
  const redirectUri =
    useRef(query.get('redirectUri')).current ?? //TODO get from APP url
    'https://hpsmartpie.com/app/auth-callback/' //TODO change to the correct redirectUri
  const selectedPrinterUuid = useRef<string | undefined | null>()
  const selectedPrinterModelNumber = useRef<string | undefined | null>()
  const isCatalyst = useRef(false)

  const [desktopMode, setDesktopMode] = useState(false)
  const [deviceInfoReady, setDeviceInfoReady] = useState(false)
  const [valueStoreReady, setValueStoreReady] = useState(false)
  const [appMode, setAppMode] = useState(query.get('mode'))

  const history = useHistory<LocationState>()
  let { url } = useRouteMatch()
  url = url.replace(/\/$/, '')

  useEffect(() => {
    const getSelectedPrinterDataFromValueStore = async () => {
      if (!valueStoreReady) {
        const valueStoreData = await getValuesFromValueStore([
          ReservedValueStoreKeys.selectedPrinterUuid,
          ReservedValueStoreKeys.selectedPrinterModelNumber
        ])

        selectedPrinterUuid.current =
          valueStoreData?.get(ReservedValueStoreKeys.selectedPrinterUuid) ||
          query.get('deviceUuid')

        selectedPrinterModelNumber.current = valueStoreData?.get(
          ReservedValueStoreKeys.selectedPrinterModelNumber
        )

        console.log('selectedPrinterUuid: ', selectedPrinterUuid.current)
        console.log(
          'selectedPrinterModelNumber: ',
          selectedPrinterModelNumber.current
        )

        setValueStoreReady(true)
      }
    }

    getSelectedPrinterDataFromValueStore()
  })

  useEffect(() => {
    let listener: PluginListenerHandle

    async function getDeviceInfo() {
      if (!deviceInfoReady) {
        const jweb = await WhenJWebReady
        const getInfo = await jweb.Plugins.Device.getInfo()
        const locale = getInfo.locale.languageTag
        deviceModel.current = getInfo.model.toLocaleLowerCase()
        const osVersion = getInfo.osVersion
        const buildPlatform = jweb.JWeb.getPlatform()

        if (locale) {
          const processedLocale = resolveLocale(locale)
          i18n.setSupportedLocale(
            `${processedLocale.language}_${processedLocale.country}`
          )
        }

        const devicePlatform = getInfo.platform.toString().toLowerCase()

        setDesktopMode(
          devicePlatform === 'windows' ||
            devicePlatform === 'mac' ||
            (devicePlatform === 'ios' &&
              deviceModel.current.indexOf('iphone') === -1)
        )

        console.info('Platform: ', devicePlatform)
        console.info('Device Info: ', JSON.stringify(getInfo, null, 2))

        if (devicePlatform === 'mac') {
          const catalystVersion = () => {
            const osVersionParts = osVersion.match(/\d+/g)

            if (osVersionParts === null) return buildPlatform !== devicePlatform

            const osVersionMajor = parseInt(osVersionParts[0])
            const osVersionMinor = parseInt(osVersionParts[1])

            return (
              osVersionMajor >= 11 ||
              (osVersionMajor === 10 && osVersionMinor >= 15)
            )
          }
          isCatalyst.current = catalystVersion()
        }

        try {
          if (devicePlatform === 'android') {
            listener = jweb.Plugins.App.addListener(
              'backButton',
              (_data: object, _eventName: string) => {
                const callBack =
                  nativeBackButtonHandler.getBackButtonCallBack()!
                callBack()
              }
            )
          }
        } catch (error) {
          console.error('Error at adding native listener: ', error)
        }

        setDeviceInfoReady(true)
      }
    }

    getDeviceInfo()

    return () => {
      if (listener) {
        listener.remove()
      }
    }
  }, [deviceInfoReady])

  useEffect(() => {
    const loadShortcutPageMode = () => {
      const appMode = query.get('mode')
      setAppMode(appMode)

      if (appMode === 'flyout') {
        console.log('Loading flyout page by query parameter')
        history.push(`${url}/flyout`)
      } else if (appMode === 'printer_settings') {
        console.log('Loading printer_settings page by query parameter')
        history.push(`${url}/printer_settings`)
      } else if (appMode === 'access_shortcuts') {
        console.log('Loading access_shortcuts page by query parameter')
        history.push(`${url}/access_shortcuts`)
      }
    }

    loadShortcutPageMode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, url])

  useEffect(() => {
    const preventZoom = (event: WheelEvent) => {
      if (event.ctrlKey) {
        event.preventDefault()
      }
    }

    const preventPinchZoom = (event: TouchEvent) => {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    }

    const preventDoubleClickZoom = (event: MouseEvent) => {
      if (event.detail > 1) {
        event.preventDefault()
      }
    }

    window.addEventListener('wheel', preventZoom, { passive: false })
    window.addEventListener('touchstart', preventPinchZoom, { passive: false })
    window.addEventListener('touchmove', preventPinchZoom, { passive: false })
    window.addEventListener('dblclick', preventDoubleClickZoom, {
      passive: false
    })

    return () => {
      window.removeEventListener('wheel', preventZoom)
      window.removeEventListener('touchstart', preventPinchZoom)
      window.removeEventListener('touchmove', preventPinchZoom)
      window.removeEventListener('dblclick', preventDoubleClickZoom)
    }
  }, [])

  return (deviceInfoReady && valueStoreReady) || Config.MOCK ? (
    <>
      <GlobalStyle appMode={appMode} />
      <AnimationContextProvider>
        <UserContextProvider>
          <DeviceContextProvider>
            <ToggleProvider>
              <Switch>
                <EmptyPage>
                  {/* TODO /management */}
                  <Route path="/">
                    <JarvisShortcuts
                      connectorRedirectUri={redirectUri}
                      desktopMode={desktopMode}
                      selectedPrinterUuid={selectedPrinterUuid.current}
                      selectedPrinterModelNumber={
                        selectedPrinterModelNumber.current
                      }
                      deviceModel={deviceModel.current}
                      isCatalyst={isCatalyst.current}
                    />
                  </Route>
                  {/* TODO ['/session', '/'] */}
                  <Route path={['/session']}>
                    <JarvisDeviceSession
                      connectorRedirectUri={redirectUri}
                      desktopMode={desktopMode}
                      selectedPrinterUuid={selectedPrinterUuid.current}
                      selectedPrinterModelNumber={
                        selectedPrinterModelNumber.current
                      }
                      deviceModel={deviceModel.current}
                      isCatalyst={isCatalyst.current}
                    />
                  </Route>
                </EmptyPage>
              </Switch>
            </ToggleProvider>
          </DeviceContextProvider>
        </UserContextProvider>
      </AnimationContextProvider>
    </>
  ) : (
    <>
      <GlobalStyle appMode={appMode} />
      <Loader />
    </>
  )
}

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = () => {
  //TODO change the basename to the correct path
  return (
    <DependencyManagerProvider>
      <BrowserRouter basename="shortcuts">
        <AppComponent />
      </BrowserRouter>
    </DependencyManagerProvider>
  )
}

export default App
