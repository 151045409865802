import { DateTime } from 'luxon'
import { createSession, getSessionByDeviceUuid } from '../../clients/shortcuts'
import { DeviceSession, SessionState } from '../../data/schemas/session'

const calculateExpirationDate = (expires: number) => {
  let diffDays = 0
  const now = DateTime.local().toSeconds()
  const diff = expires - now

  if (diff > 0) {
    diffDays = diff / 86400
  }

  return {
    isActive: true,
    expirationDate: expires,
    remainingDays: diffDays >= 1 ? Math.round(diffDays) : diffDays
  }
}

export const calculateSecondsRemaining = (selectedDate: DateTime) => {
  return Math.round(selectedDate.diffNow().valueOf() / 1000)
}

export const createDeviceSession = async (
  deviceSessionItem: DeviceSession
): Promise<SessionState> => {
  const result = await createSession(deviceSessionItem)
  return calculateExpirationDate(result.expires) as SessionState
}

export const getDeviceSessionByDeviceId = async (
  deviceUuid: string
): Promise<SessionState> => {
  try {
    const result = await getSessionByDeviceUuid(deviceUuid)
    return result.state === 'inactive'
      ? { isActive: true, isExpired: true, remainingDays: 0 }
      : (calculateExpirationDate(result.expires) as SessionState)
  } catch (e) {
    return { isActive: false }
  }
}
