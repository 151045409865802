import React, { createContext, useContext, useReducer } from 'react'
import { DeviceInfo } from '../../data/schemas/devices'
import { deviceReducer, initialState } from './DeviceReducer'

type DeviceActions = {
  setCloudId: (cloudId: string) => void
  setSupportsSession: (supportsSession: boolean) => void
  setModelName: (modelName: string) => void
  setIsScanDestinationSupported: (supportsScanDestinations: boolean) => void
  setAllowSessions: (allowSessions: boolean) => void
  setModelImage: (modelImage: string) => void
  setModelNumber: (modelNumber: string) => void
  resetState: () => void
}

type DeviceContextType = {
  deviceState: DeviceInfo
  deviceActions: DeviceActions
}

type DeviceContextProviderProps = { children: React.ReactNode }

const defaultContextValue: DeviceContextType = {
  deviceState: {} as DeviceInfo,
  deviceActions: {} as DeviceActions
}

export const DeviceContext = createContext(defaultContextValue)

export const DeviceContextProvider = ({
  children
}: DeviceContextProviderProps) => {
  const [deviceState, dispatch] = useReducer(deviceReducer, initialState)

  const deviceActions: DeviceActions = {
    setCloudId: (cloudId: string) => {
      dispatch({ type: 'cloudId', payload: cloudId })
    },
    setSupportsSession: (supportsSession?: boolean) => {
      dispatch({ type: 'supportsSession', payload: supportsSession })
    },
    setModelName: (modelName: string) => {
      dispatch({ type: 'modelName', payload: modelName })
    },
    setModelImage: (modelImage: string) => {
      dispatch({ type: 'modelImage', payload: modelImage })
    },
    setModelNumber: (modelNumber: string) => {
      dispatch({ type: 'modelNumber', payload: modelNumber })
    },
    setAllowSessions: (allowSessions: boolean) => {
      dispatch({ type: 'allowSessions', payload: allowSessions })
    },
    setIsScanDestinationSupported: (supportsScanDestinations: boolean) => {
      dispatch({
        type: 'supportsScanDestinations',
        payload: supportsScanDestinations
      })
    },
    resetState: () => {
      dispatch({ type: 'reset' })
    }
  }

  return (
    <DeviceContext.Provider value={{ deviceState, deviceActions }}>
      {children}
    </DeviceContext.Provider>
  )
}

export const useDeviceContext = () => useContext(DeviceContext)
