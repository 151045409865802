// eslint-disable-next-line no-restricted-imports
import { AuthBrowserErrorType } from '@jarvis/jweb-core'
import { ButtonGroup, GenericCard, Modal } from '@clientos/ui-toolkit'
import { OptionInterface, useToast } from '@veneer/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import MobileScroll from 'src/components/MobileScroll'
import { useToggleContext } from 'src/contexts/toggle/ToggleContext'
import { i18n } from '../../assets/locale'
import {
  generateCloudStorageLoginURL,
  getCloudStoragesList
} from '../../clients/shortcuts'
import CustomFooter from '../../components/CustomFooter'
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import ModalEditAccountName from '../../components/ModalEditAccountName'
import ModalRemoveAccess from '../../components/ModalRemoveAccess'
import SaveOptions from '../../components/SaveOptions'
import { useDestinationsContext } from '../../contexts/destinations/DestinationsContext'
import { useEditContext } from '../../contexts/edit/EditContext'
import { useSettingsContext } from '../../contexts/settings/SettingsContext'
import { getAddSaveOptions } from '../../data/enum/menuOptions'
import { PageProps } from '../../data/schemas/app'
import { Connector, ConnectorLoginSpecs } from '../../data/schemas/connector'
import { FolderItem } from '../../data/schemas/folders'
import { LocationState } from '../../data/schemas/location'
import useTreeViewNodes from '../../hooks/helpers/useTreeViewNodes'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import {
  resolveAccountSaveDestination,
  resolveConnectorName,
  toastMessages
} from '../../modules/common/helpers'
import { resolveRepositoryType } from '../../modules/destinations/helpers'
import { flattenTree } from '../../modules/destinations/treeViewMapping/helpers'
import {
  getAuthBrowserResponseError,
  openAuthBrowser
} from '../../plugins/jweb/AuthBrowser'
import { sendUiEvent, uiData } from '../../plugins/jweb/DataCollection'
import PageTemplate from '../PageTemplate'
import { CardContainer } from './styles'

type FailedToFetchProps = {
  desktopMode: boolean
}

export type SelectedConnectorType = {
  currentConnectorId: string
  currentConnectorType: string
}

export const FailedToFetch = (props: FailedToFetchProps) => {
  return (
    <>
      <div />
      <CustomFooter
        desktopMode={props.desktopMode}
        buttons={[
          {
            id: 'add-save-destination-btn',
            text: i18n.t('pages.AddSave.button'),
            disabled: true
          }
        ]}
      />
    </>
  )
}

export const AddSave = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()
  const location = useLocation<LocationState>()
  const { addToast } = useToast()

  const { destinationsState, destinationsActions } = useDestinationsContext()
  const { settingsActions, settingsState } = useSettingsContext()
  const { editState } = useEditContext()
  const { toggleState, toggleActions } = useToggleContext()

  const [providersList, setProvidersList] = useState<Connector[]>([])
  const [authorizedProvidersList, setAuthorizedProvidersList] = useState<
    Connector[]
  >([])

  const [newConnectorName, setNewConnectorName] = useState('')
  const [recentlyLoggedInConnector, setRecentLoggedInConnector] =
    useState<Connector>()
  const [fetchProvidersListFailed, setFetchProvidersListFailed] =
    useState(false)
  const [updatingLists, setUpdatingLists] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [templateTriggered, setTemplateTriggered] = useState(false)
  const [checkboxSelected, setCheckboxSelected] = useState(false)
  const [loadingSignInButton, setLoadingSignInButton] = useState(false)
  const [updatingAccountsLoader, setUpdatingAccountsLoader] = useState(false)
  const [selectedConnector, setSelectedConnector] =
    useState<SelectedConnectorType>({
      currentConnectorId: '',
      currentConnectorType: ''
    })
  const [editConnectorNameSuccess, setEditConnectorNameSuccess] =
    useState(false)

  const fallBackFolder = useRef<FolderItem>({
    id: 'HP app',
    name: 'HP app',
    type: 'folder',
    isValid: true
  })

  const { cleanFolderTree, onFolderListingOpen } = useTreeViewNodes()

  const onClickAddDestination = (
    connectorId: string,
    connectorType: string
  ) => {
    settingsActions.updateCurrentConnectorId(connectorId)
    settingsActions.updateCurrentConnectorType(connectorType)
    history.push(`${base}/folder`)
  }

  const onClickDeleteSelectedFolder = (
    providerItem: Connector,
    folderId: string
  ) => {
    const currentConnectorFolderIndex = settingsState.foldersList.findIndex(
      (item) => item.connectorId === providerItem.connectorId
    )
    settingsState.foldersList[currentConnectorFolderIndex].folders =
      settingsState.foldersList[currentConnectorFolderIndex].folders.filter(
        (item) => item.id !== folderId
      )
    settingsActions.updateFoldersList([...settingsState.foldersList])
  }

  const onClickEditSelectedFolder = (
    providerItem: Connector,
    folderId?: string
  ) => {
    setSelectedConnector({
      currentConnectorId: providerItem.connectorId!,
      currentConnectorType: providerItem.type
    })
    !!folderId && settingsActions.updateEditSelectedFolder(folderId)
    settingsActions.updateCurrentConnectorId(providerItem.connectorId!)
    settingsActions.updateCurrentConnectorType(providerItem.type)
    history.push(`${base}/folder`)
  }

  //This useEffect will guarantee if the currentConnectorId has not changed then the tree will not be cleaned
  useEffect(() => {
    cleanFolderTree()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedConnector.currentConnectorId,
    selectedConnector.currentConnectorType
  ])

  useEffect(() => {
    const { currentConnectorId } = selectedConnector
    if (currentConnectorId) {
      onFolderListingOpen(currentConnectorId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFolderListingOpen, selectedConnector])

  useEffect(() => {
    const nodeId = settingsState.selectedFolder
    if (nodeId && settingsState.folderTree?.length) {
      const currentConnectorFoldersIndex = settingsState.foldersList.findIndex(
        (item) => item.connectorId === settingsState.currentConnectorId
      )

      const isFolderAlreadySelected = settingsState.foldersList[
        currentConnectorFoldersIndex
      ]?.folders.some((folder) => folder.id === nodeId)

      if (isFolderAlreadySelected) {
        if (
          settingsState.editSelectedFolder &&
          settingsState.editSelectedFolder !== nodeId &&
          !isFolderAlreadySelected
        ) {
          settingsState.foldersList[currentConnectorFoldersIndex].folders =
            settingsState.foldersList[
              currentConnectorFoldersIndex
            ].folders.filter((folder) => folder.id !== nodeId)
          settingsActions.updateFoldersList([...settingsState.foldersList])
        }
      } else {
        const selectedNode = flattenTree(settingsState.folderTree).find(
          (node) => node.id === nodeId
        )
        if (currentConnectorFoldersIndex < 0) {
          settingsState.foldersList.push({
            connectorId: settingsState.currentConnectorId!,
            folders: [selectedNode!]
          })
          settingsActions.updateFoldersList([...settingsState.foldersList])
          return
        }

        if (
          settingsState.foldersList[currentConnectorFoldersIndex].folders[0]
            .name === fallBackFolder.current.name &&
          selectedNode?.name === fallBackFolder.current.name
        ) {
          settingsState.foldersList[currentConnectorFoldersIndex].folders[0] =
            selectedNode!
          settingsActions.updateFoldersList([...settingsState.foldersList])
          return
        }

        if (
          settingsState.editSelectedFolder &&
          settingsState.editSelectedFolder !== nodeId
        ) {
          settingsState.foldersList[currentConnectorFoldersIndex].folders =
            settingsState.foldersList[
              currentConnectorFoldersIndex
            ].folders.filter(
              (folder) => folder.id !== settingsState.editSelectedFolder
            )
          settingsActions.updateEditSelectedFolder('')
        }
        settingsState.foldersList[currentConnectorFoldersIndex].folders =
          settingsState.foldersList[currentConnectorFoldersIndex].folders
            .filter((folderItem) => folderItem.id !== fallBackFolder.current.id)
            .concat(selectedNode!)
        settingsActions.updateFoldersList([...settingsState.foldersList])
      }
      settingsActions.updateSelectedFolder('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    settingsState.currentConnectorId,
    settingsState.selectedFolder,
    settingsState.folderTree,
    settingsState.editSelectedFolder,
    settingsState.foldersList
  ])

  const [modalsContextualInfo, setModalsContextualInfo] = useState<{
    repositories: Connector[]
    connector?: Connector
    showModalRemoveAccess: boolean
    showModalEditName: boolean
  }>({
    repositories: [],
    connector: undefined,
    showModalRemoveAccess: false,
    showModalEditName: false
  })

  const fetchData = useCallback(async () => {
    try {
      setUpdatingLists(true)
      const result = await getCloudStoragesList()
      const repositories: Connector[] = result.repositories
      const seen = new Set()
      const filteredRepositories = repositories
        .filter((repository) => {
          const duplicate = seen.has(repository.type)
          seen.add(repository.type)
          const isOneDriveBusiness = repository.type === 'onedrivebusiness'
          const isSharepoint = repository.type === 'sharepoint'
          return !duplicate && !isOneDriveBusiness && !isSharepoint
        })
        .map((repository) => {
          return {
            ...Object.assign({}, repository),
            isAuthorized: false,
            connectorName: ''
          }
        })
      const authorizedList = repositories.filter((c) => {
        return c.isAuthorized
      })
      if (authorizedList.length) setAuthorizedProvidersList(authorizedList)
      setProvidersList(filteredRepositories)
      setUpdatingAccountsLoader(false)
      setUpdatingLists(false)
    } catch (e) {
      setFetchProvidersListFailed(true)
      setUpdatingLists(false)
      setUpdatingAccountsLoader(false)

      addToast?.({
        type: 'negative',
        ...toastMessages('fetchCloudStoragesListError'),
        timeout: 300,
        action: (
          <a onClick={() => window.location.reload()}>
            {i18n.t('pages.ListShortcuts.updatePageMessage')}
          </a>
        )
      })
    }
  }, [addToast])

  const onBackButtonPress = () => {
    if (location.state?.template) {
      if (location.state?.template.index === 0) {
        onClickCancelButton()
      } else {
        const index = location.state?.template.index - 1
        history.push({
          pathname: `${base}/${location.state?.template.actions[index].route}`,
          state: {
            template: { ...location.state?.template, index }
          }
        })
      }
    } else {
      const checkpoint = destinationsState.checkpointState.repositories
      destinationsActions.updateRepository(checkpoint)
      if (toggleState.email) {
        history.push(`${base}/email`)
      } else if (toggleState.print) {
        history.push(`${base}/print`)
      } else {
        history.push(`${base}/destinations`)
      }
    }
  }

  const isChecked = (providerItem: Connector) => {
    return destinationsState.currentState.repositories.selected.some(
      (repository) => providerItem.connectorId === repository.connectorId
    )
  }

  const handleContextualMenuOptionClick = (
    providerItem: Connector,
    option: OptionInterface
  ) => {
    const { value } = option
    switch (value) {
      case 'remove-access':
        sendUiEvent(uiData.modalRemoveAccessAddSave)
        sendUiEvent(uiData.screenRemoveAccessMobile)
        setModalsContextualInfo((prev) => ({
          ...prev,
          connector: providerItem,
          repositories: [...authorizedProvidersList],
          showModalRemoveAccess: true
        }))
        break
      case 'add-destination':
        onClickAddDestination(providerItem.connectorId!, providerItem.type)
        break
      case 'edit-account-name':
        setModalsContextualInfo((prev) => ({
          ...prev,
          connector: providerItem,
          repositories: [...authorizedProvidersList],
          showModalEditName: true
        }))
    }
  }

  const onClickRemoveAccessButton = () => {
    sendUiEvent(uiData.btnRemoveAccessAddSave)
    setModalsContextualInfo((prev) => ({
      ...prev,
      showModalRemoveAccess: !prev.showModalRemoveAccess
    }))
  }

  const onClickEditAcccountNameCancel = () => {
    setModalsContextualInfo((prev) => ({
      ...prev,
      showModalEditName: !prev.showModalEditName
    }))
  }

  const selectCheckbox = useCallback(
    (providerItem: Connector) => {
      if (!checkboxSelected) {
        const updatedList = [
          ...destinationsState.currentState.repositories.selected
        ]
        updatedList.push(providerItem)
        destinationsActions.updateRepository({
          ...destinationsState.currentState.repositories,
          selected: updatedList
        })
      }
    },
    [
      checkboxSelected,
      destinationsActions,
      destinationsState.currentState.repositories
    ]
  )

  const onCheckBoxPress = (providerItem: Connector) => {
    const filteredList =
      destinationsState.currentState.repositories.selected.filter(
        (repository) => {
          return providerItem.connectorId === repository.connectorId
        }
      )
    if (filteredList.length) {
      const updatedList =
        destinationsState.currentState.repositories.selected.filter(
          (repository) => {
            return repository !== filteredList[0]
          }
        )
      destinationsActions.updateRepository({
        ...destinationsState.currentState.repositories,
        selected: updatedList
      })
    } else {
      const updatedList = [
        ...destinationsState.currentState.repositories.selected
      ]
      updatedList.push(providerItem)
      destinationsActions.updateRepository({
        ...destinationsState.currentState.repositories,
        selected: updatedList
      })
    }
  }

  const updateCheckedRepositoriesList = useCallback(
    (providerItem: Connector) => {
      const updatedList = [
        ...destinationsState.currentState.repositories!.selected
      ]

      if (!updatedList.includes(providerItem)) {
        updatedList.push(providerItem)
        destinationsActions.updateRepository({
          ...destinationsState.currentState.repositories,
          selected: updatedList
        })
      }
    },
    [destinationsState.currentState.repositories, destinationsActions]
  )

  const onSignInPress = useCallback(
    async (providerItem: Connector) => {
      try {
        setLoadingSignInButton(true)
        const scheme = props.connectorRedirectUri.split('://')[0]
        const infos: ConnectorLoginSpecs = {
          redirectUri: props.connectorRedirectUri,
          storage: providerItem.type,
          connectorName: newConnectorName
        }
        const response = await generateCloudStorageLoginURL(infos)

        if (response.status === 201) {
          const signinURL = response.data.signinURL
          const responseOpenAuthBrowser = await openAuthBrowser(
            signinURL,
            scheme
          )
          setLoadingSignInButton(false)
          const error = getAuthBrowserResponseError(responseOpenAuthBrowser)

          if (!error) {
            providerItem.connectorName = infos.connectorName
            setRecentLoggedInConnector(providerItem)
            setUpdatingAccountsLoader(true)
            fetchData()
            sendUiEvent({
              ...uiData.btnSignUpAccountSaveDestinationMobile,
              controlDetail: resolveAccountSaveDestination(providerItem.type)
            })
          }

          if (error && error !== AuthBrowserErrorType.browserClosedByUser) {
            addToast?.({
              type: 'warning',
              ...toastMessages('cloudStorageLoginError')
            })
            return
          }
        }
        return
      } catch (e) {
        setLoadingSignInButton(false)
        console.error(e)
        addToast?.({
          type: 'warning',
          ...toastMessages('cloudStorageLoginError')
        })
      }
    },
    [addToast, fetchData, newConnectorName, props.connectorRedirectUri]
  )

  const onContinueButtonPress = () => {
    destinationsActions.updateRepository({
      ...destinationsState.currentState.repositories,
      set: true
    })
    if (location.state?.template) {
      if (
        location.state?.template.index ===
        location.state?.template.actions.length - 1
      ) {
        history.push({
          pathname: `${base}/creation_settings`,
          state: {
            template: location.state?.template
          }
        })
      } else {
        const index = location.state?.template.index + 1
        history.push({
          pathname: `${base}/${location.state?.template.actions[index].route}`,
          state: { template: { ...location.state?.template, index } }
        })
      }
    } else {
      destinationsActions.updateCheckpointState({
        ...destinationsState.currentState,
        repositories: {
          ...destinationsState.currentState.repositories,
          set: true
        }
      })

      history.push(`${base}/creation_settings`)
    }
  }

  const onClickCancelButton = () => {
    setShowCancelModal(!showCancelModal)
  }

  const onConfirmCancelModal = () => {
    onClickCancelButton()
    toggleActions.resetState()
    if (location.state?.template) {
      if (location.state?.template.index === 0) {
        destinationsActions.resetState()
        settingsActions.resetState()
        history.push(`${base}/add`)
      } else {
        const index = location.state?.template.index - 1
        history.push({
          pathname: `${base}/${location.state?.template.actions[index].route}`,
          state: {
            template: { ...location.state?.template, index }
          }
        })
      }
    } else {
      const checkpoint = destinationsState.checkpointState.repositories
      destinationsActions.updateRepository(checkpoint)
      history.push(`${base}/destinations`)
    }
  }

  useEffect(() => {
    if (recentlyLoggedInConnector) {
      const connector = authorizedProvidersList.find((connector) => {
        return (
          connector.connectorName === recentlyLoggedInConnector.connectorName
        )
      })
      if (connector) {
        updateCheckedRepositoriesList(connector)
        setRecentLoggedInConnector(undefined)
      } else {
        fetchData()
      }
    }
  }, [
    authorizedProvidersList,
    fetchData,
    recentlyLoggedInConnector,
    updateCheckedRepositoriesList
  ])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    async function updateData() {
      await fetchData()
    }

    if (editConnectorNameSuccess) {
      updateData()
      setEditConnectorNameSuccess(false)
    }
  }, [editConnectorNameSuccess, fetchData])

  useEffect(() => {
    setNewConnectorName(resolveConnectorName(authorizedProvidersList))
  }, [authorizedProvidersList])

  useEffect(() => {
    if (
      location.state?.template &&
      (authorizedProvidersList.length > 0 || providersList.length > 0) &&
      !templateTriggered
    ) {
      const action =
        location.state?.template.actions[location.state?.template.index]
      if (action.triggerSignIn) {
        if (
          authorizedProvidersList.length === 0 ||
          !authorizedProvidersList.some(
            (repository) => action.signInTargetType?.type === repository.type
          )
        ) {
          onSignInPress(action.signInTargetType!)
        } else {
          const matchingConnectors = authorizedProvidersList.filter(
            (item) => item.type === action.signInTargetType?.type
          )
          selectCheckbox(matchingConnectors[0])
          setCheckboxSelected(true)
        }
      }
      setTemplateTriggered(true)
    }
  }, [
    authorizedProvidersList,
    location.state?.template,
    onSignInPress,
    selectCheckbox,
    templateTriggered,
    providersList
  ])

  useNativeBackButton(onBackButtonPress)

  //This useEffect will guarantee  that whenever the user remove all his folders that fallback folder will be added
  useEffect(() => {
    if (settingsState.currentConnectorId) {
      const currentConnectorIndex = settingsState.foldersList.findIndex(
        (connectorItem) =>
          connectorItem.connectorId === settingsState.currentConnectorId
      )

      if (
        currentConnectorIndex >= 0 &&
        settingsState.foldersList[currentConnectorIndex].folders.length === 0
      ) {
        settingsState.foldersList[currentConnectorIndex].folders = [
          fallBackFolder.current
        ]
        settingsActions.updateFoldersList([...settingsState.foldersList])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsState.foldersList, settingsState.currentConnectorId])

  return (
    <PageTemplate
      desktopMode={props.desktopMode}
      header={
        <Header
          backButtonLabel={i18n.t('common.contextAction.cancel')}
          onPressIconLeft={onBackButtonPress}
        />
      }
      content={
        <>
          {(providersList.length > 0 || authorizedProvidersList.length > 0) &&
          !updatingLists ? (
            <>
              <CardContainer>
                <GenericCard>
                  <GenericCard.Header
                    borderBottom={false}
                    title={
                      editState.editMode
                        ? // TODO: Replace when batch translation is done: title={i18n.t('pages.AddSave.editHPX')}
                          'Edit save'
                        : // TODO: Replace when batch translation is done: title={i18n.t('pages.AddSave.headerHPX')}
                          'Add save'
                    }
                  />
                  <GenericCard.Body
                    paddingBodyBottom={false}
                    paddingBodyTop={false}
                    paddingBodyHorizontal={true}
                  >
                    <MobileScroll offset="265px">
                      <SaveOptions
                        authorizedProvidersList={authorizedProvidersList}
                        providersList={providersList}
                        desktopMode={props.desktopMode}
                        isChecked={isChecked}
                        onCheckBoxPress={onCheckBoxPress}
                        onSignInPress={onSignInPress}
                        contextualMenuOptions={getAddSaveOptions()}
                        onContextualMenuOptionClick={(providerItem, option) =>
                          handleContextualMenuOptionClick(providerItem, option)
                        }
                        loadingSignInButton={loadingSignInButton}
                        updatingAccountsLoader={updatingAccountsLoader}
                        onDestinationEditClick={onClickEditSelectedFolder}
                        onDestinationDeleteClick={onClickDeleteSelectedFolder}
                        destinationFolder={settingsState.foldersList}
                        currentConnectorId={settingsState.currentConnectorId}
                      />
                    </MobileScroll>
                  </GenericCard.Body>
                </GenericCard>
              </CardContainer>
              <ModalEditAccountName
                success={() => setEditConnectorNameSuccess(true)}
                connector={modalsContextualInfo.connector}
                repositories={modalsContextualInfo.repositories}
                show={modalsContextualInfo.showModalEditName}
                onCancel={onClickEditAcccountNameCancel}
                desktopMode={props.desktopMode}
              />
              <ModalRemoveAccess
                show={modalsContextualInfo.showModalRemoveAccess}
                onConfirm={onClickRemoveAccessButton}
                providerType={resolveRepositoryType(
                  modalsContextualInfo?.connector?.type || ''
                )}
              />
              <Modal
                id="confirmation-modal"
                aria-label="confirmation-modal"
                onClose={onClickCancelButton}
                closeOnBlur={false}
                isOpen={showCancelModal}
                /* TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalTitleHPX') */
                title={'Cancel this Shortcut?'}
                align="center"
                buttonGroup={{
                  // TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalCancelEditsButtonHPX')
                  textPrimaryButton: 'Yes, cancel',
                  primaryButtonType: 'negative',
                  // TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalContinueEditsButtonHPX')
                  textSecondaryButton: 'Go back',
                  onClickPrimaryButton: () => {
                    onConfirmCancelModal()
                  },
                  onClickSecondaryButton: () => {
                    onClickCancelButton()
                  },
                  size: 'large',
                  orientation: 'vertical'
                }}
              >
                {/* TODO: Replace when batch translation is done:
        i18n.t('pages.Destinations.modalDescriptionHPX') */}
                <p>{'Any edits you made will be lost.'}</p>
              </Modal>
            </>
          ) : fetchProvidersListFailed ? (
            <></>
          ) : (
            <Loader position="relative" />
          )}
        </>
      }
      mobileFooter={
        !props.desktopMode && (
          <ButtonGroup
            textPrimaryButton={i18n.t('common.contextAction.continue')}
            onClickPrimaryButton={() => onContinueButtonPress()}
            disabled={
              destinationsState.currentState.repositories.selected.length ===
                0 || fetchProvidersListFailed
            }
            size="large"
            orientation="vertical"
          ></ButtonGroup>
        )
      }
    ></PageTemplate>
  )
}
