import React, { useEffect, useReducer } from 'react'
import Routes from '../routes'
import { initialTextEditState, textEditReducer } from '../reducers/textEdit'
import { TextEditContext } from '../contexts/textEditContext'
import useJWeb from '../hooks/useJWeb'
import FullScreenLoading from '../components/FullScreenLoading'
import 'get-root-node-polyfill/implement'
import 'core-js'
import { getTextBrokeByLine } from '../services/DocProvider/helper'
import { TextEditActionType } from '../actions/textEdit'
import DataCollection, { uiEvents } from '../services/DataCollection'
import { LogToNative, ErrorToNative, InfoToNative } from '../services/Console'
import { DependencyManagerProvider } from '../../src/contexts/dependencyManager'
import * as T from './types'
import { Helmet } from 'react-helmet'

// This file is your main component, it's the one that will be rendered by default.
// We recommend to create small components inside the components folder
// and import them here based on your need to make this file easier to understand.
// const AppComponent: React.FC<T.AppPropsType> = ({ properties }) => {
//   const { translate } = useDependencyManagerContext()

//   const text = useMemo(() => {
//     if (typeof properties?.text === 'string') {
//       return properties?.text
//     }
//     return translate('text-example', 'This is your Microfrontend!')
//   }, [properties?.text, translate])

//   return (
//     <S.Container>
//       <ComponentExample text={text}>
//         <S.Img src={HPLogo} />
//       </ComponentExample>
//     </S.Container>
//   )
// }

const AppComponent = () => {
  const [initialStateLoaded, setInitialStateLoaded] = React.useState(false)
  const [textEditState, dispatch] = useReducer(
    textEditReducer,
    initialTextEditState
  )
  const handleAnalytics = () => {
    DataCollection.sendUIEvent(uiEvents.textEditScreen)
  }
  useEffect(() => {
    try {
      handleAnalytics()
      getTextBrokeByLine().then((text) => {
        dispatch({ type: TextEditActionType.SET_INITIAL_TEXT, data: { text } })
        setInitialStateLoaded(true)
      })
    } catch (error) {
      console.info('Error while getting text broke by line', error)
    }
    const userAgent = window.navigator.userAgent
    InfoToNative(`Mac Investigation INFO -- user-agent: ${userAgent}`)
    LogToNative(`Mac Investigation LOG -- user-agent: ${userAgent}`)
    ErrorToNative(`Mac Investigation ERROR -- user-agent: ${userAgent}`)
  }, [])

  const jWeb = useJWeb()
  if (jWeb === undefined || !initialStateLoaded) return <FullScreenLoading />
  return (
    <TextEditContext.Provider value={{ textEditState, dispatch }}>
      <Routes />
    </TextEditContext.Provider>
  )
}

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = () => {
  return (
    <DependencyManagerProvider>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, inicial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <AppComponent />
    </DependencyManagerProvider>
  )
}

export default App
