import {
  BackNavigationButton,
  Button,
  Header,
  IconGear,
  IconUpdate
} from '@clientos/ui-toolkit'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useNativeBackButton from 'src/hooks/useNativeBackButton'
import { screenNames } from 'src/pages/CloudScan/screens'
import { sendUiEvent, uiData } from 'src/plugins/jweb/DataCollection'
import { dispatchEvent } from 'src/plugins/jweb/EventService'
import type { RootState } from 'src/stores'
import { updateRefreshJobsEvent } from 'src/stores/event'
import { updateCurrentScreen } from 'src/stores/screen'
import './AppHeader.scss'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AppHeader = () => {
  const dispatch = useDispatch()
  const currentScreenName = useSelector(
    (state: RootState) => state.screens.currentScreenName
  )
  const selectedDevice = useSelector(
    (state: RootState) => state.device.selectedDevice
  )
  const mobileMode: boolean = useSelector(
    (state: RootState) => state.screens.mobileMode
  )

  const [showButtons, setShowButtons] = useState(true)

  useEffect(() => {
    setShowButtons(
      currentScreenName !== screenNames.CONNECTED_PRINTERS &&
        currentScreenName !== screenNames.CREATE_SESSION &&
        currentScreenName !== screenNames.START_SESSION
    )
  }, [currentScreenName])

  const handleReload = () => {
    sendUiEvent({
      ...uiData.JobsList.btnRefreshJobList
    })
    dispatch(updateRefreshJobsEvent(true))
    dispatch(updateCurrentScreen(screenNames.DOCUMENT_LIST))
  }

  const handleGearClick = () => {
    dispatch(updateCurrentScreen(screenNames.CONNECTED_PRINTERS))
  }

  const onBackButtonPress = () => {
    currentScreenName === screenNames.START_SESSION
      ? sendUiEvent({
          ...uiData.SessionSync.backBtn,
          controlName: 'BackButton'
        })
      : sendUiEvent({
          ...uiData.JobsList.backBtn,
          controlName: 'BackButton'
        })

    if (
      currentScreenName === screenNames.CONNECTED_PRINTERS ||
      currentScreenName === screenNames.CREATE_SESSION
    ) {
      dispatch(updateRefreshJobsEvent(true))
      dispatch(updateCurrentScreen(screenNames.DOCUMENT_LIST))
    } else {
      dispatchEvent('com.hp.jarvis.servicerouting.serviceinstance.closed')
      dispatchEvent('Close')
      dispatchEvent('jarvisEventFinished')
    }
  }

  useNativeBackButton(onBackButtonPress)

  return (
    <>
      <Header
        position="relative"
        customStyle={{ background: 'transparent' }}
        leadingArea={
          <>
            <BackNavigationButton
              buttonText={
                selectedDevice
                  ? selectedDevice.deviceIdentity.modelName
                  : // TODO: Replace when batch translation is done: i18n.t('application.header.backHPX')
                    'Back'
              }
              onClick={onBackButtonPress}
            />
          </>
        }
        trailingArea={
          showButtons && (
            <div
              className={
                mobileMode
                  ? 'right-buttons-container-mobile'
                  : 'right-buttons-container'
              }
            >
              <Button
                type="tertiary"
                size={'medium'}
                data-testid="icon-reload"
                icon={<IconUpdate size={24} />}
                onClick={handleReload}
              />
              <Button
                type="tertiary"
                size={'medium'}
                data-testid="icon-settings"
                icon={<IconGear size={24} />}
                onClick={handleGearClick}
              />
            </div>
          )
        }
      />
    </>
  )
}

export default AppHeader
