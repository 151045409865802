// eslint-disable-next-line no-restricted-imports
import { SimpleUiEvent, Action } from '@jarvis/jweb-core'

const simpleUiEventVersion = '1.1.0'

export const uiEvents = {
  copyButton: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'Scan-v01',
    screenName: 'TxtEdit',
    controlName: 'CopyAllButton'
  } as SimpleUiEvent,
  textCopiedModal: {
    version: simpleUiEventVersion,
    action: Action.screenDisplayed,
    activity: 'Scan-v01',
    screenName: 'TxtCopiedToClipboard',
    screenPath: 'TxtEdit/'
  } as SimpleUiEvent,
  spellCheckButton: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'Scan-v01',
    screenName: 'TxtEdit',
    controlName: 'SpellCheckButton',
    screenPath: 'TxtEdit/'
  } as SimpleUiEvent,
  doneButton: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'Scan-v01',
    screenName: 'TxtEdit',
    controlName: 'DoneButton'
  } as SimpleUiEvent,
  cancelButton: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'Scan-v01',
    screenName: 'TxtEdit',
    controlName: 'CancelButton'
  } as SimpleUiEvent,
  textEditScreen: {
    version: simpleUiEventVersion,
    action: Action.screenDisplayed,
    activity: 'Scan-v01',
    screenName: 'TxtEdit'
  } as SimpleUiEvent,
  noTextModal: {
    version: simpleUiEventVersion,
    action: Action.screenDisplayed,
    activity: 'Scan-v01',
    screenName: 'AreYouSure',
    screenPath: 'TxtEdit/'
  } as SimpleUiEvent,
  noTextModalRestoreTextButton: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'Scan-v01',
    screenName: 'AreYouSure',
    controlName: 'RestoreText',
    screenPath: 'TxtEdit/'
  } as SimpleUiEvent,
  noTextModalStartNewScanButton: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'Scan-v01',
    screenName: 'AreYouSure',
    controlName: 'StartNewScan',
    screenPath: 'TxtEdit/'
  } as SimpleUiEvent,
  successfulCopyTextScreenDisplayed: {
    version: simpleUiEventVersion,
    action: Action.screenDisplayed,
    activity: 'Scan-v01',
    screenName: 'TxtExtractSuccess'
  } as SimpleUiEvent
}
