import { ListItem } from '@veneer/core'
import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  gap: 10px;
  > div {
    width: 100%;
  }
`

export const StyledListItem = styled(ListItem)`
  display: flex !important;
  flex-direction: row !important;
  padding: 8px 0px !important;
  border: none !important;
  > div {
    display: inline !important;
  }
  div {
    &:nth-child(2) {
      padding: 0px !important;
    }
  }
`
