// eslint-disable-next-line no-restricted-imports
import {
  ContainerCloseBehavior,
  ContainerViewType,
  LaunchServiceOptions,
  Service,
  ServiceId,
  ServiceRoutingError,
  WhenJWebReady
} from '@jarvis/jweb-core'
import { dispatchEvent } from './EventService'

const OPEN_URL_SERVICE = 'openUrl'
const launchServiceOptions: LaunchServiceOptions = {
  serviceId: OPEN_URL_SERVICE,
  containerOptions: {
    containerViewOptions: {
      containerViewType: ContainerViewType.fullScreen,
      belowNavBar: true
    },
    containerCloseBehaviorOptions: {
      closeBehavior: ContainerCloseBehavior.returnToCaller
    }
  }
}

export const isServiceRoutingError = (
  value: any | ServiceRoutingError
): value is ServiceRoutingError => {
  return (value as ServiceRoutingError).errorType !== undefined
}

export const getAvailableServices = async (): Promise<Service[]> => {
  const jweb = await WhenJWebReady
  const serviceList = await jweb.Plugins.ServiceRouting.getServices()

  return isServiceRoutingError(serviceList)
    ? ([] as Service[])
    : serviceList.services
}

export const getServiceIfAvailable = async (
  serviceId: ServiceId,
  containerViewType: ContainerViewType
): Promise<Service | null> => {
  const services = await getAvailableServices()
  const service = services?.find((service: Service) => service.id === serviceId)

  if (service) {
    return service as Service
  }

  return null
}

export const launchUrlInJarvisWebView = async (url: string) => {
  const service = await getServiceIfAvailable(
    OPEN_URL_SERVICE,
    ContainerViewType.fullScreen
  )

  if (service) {
    const jweb = await WhenJWebReady
    const serviceInstance = await jweb.Plugins.ServiceRouting.launchService({
      ...launchServiceOptions,
      serviceOptions: {
        url
      }
    })

    if (isServiceRoutingError(serviceInstance)) {
      await dispatchEvent('launchService', {
        message: `Error while launching service: ${serviceInstance.errorType} - ${serviceInstance.reason}`
      })
    } else {
      await dispatchEvent('launchService', {
        message: `Service ${serviceInstance.serviceId} launched as instance ${serviceInstance.instanceId}`
      })
    }
  }
}
