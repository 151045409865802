import { A4_AT_72DPI } from './../TextToImage/helper'
import { getTextSplitByPages } from '../TextToImage'
import { saveImagesOnDocset } from '../DocProvider'
import { ServiceOptions } from '../../types'
// eslint-disable-next-line no-restricted-imports
import {
  LaunchServiceOptions,
  ServiceRoutingPlugin,
  WhenJWebReady
} from '@jarvis/jweb-core'

type CloseServiceInstanceSource =
  | 'doneButton'
  | 'backButton'
  | 'startNewScanButton'

let ServiceRouting: ServiceRoutingPlugin
async function ensureJWeb() {
  const res = await WhenJWebReady
  if (res) {
    ServiceRouting = res.Plugins.ServiceRouting as ServiceRoutingPlugin
  } else {
    console.info('JWeb plugins not available')
    throw new Error('JWeb plugins not available')
  }
}
export const returnToPreviousService = async (
  source: CloseServiceInstanceSource
) => {
  await ensureJWeb()
  ServiceRouting.closeServiceInstance({ resultData: { source: source } })
}
export const sendTextToNextService = async (
  text: string,
  source: CloseServiceInstanceSource
) => {
  await ensureJWeb()
  const imagesArray = await getTextSplitByPages(text, A4_AT_72DPI)
  const prefix = 'base64,'
  await saveImagesOnDocset(
    imagesArray.map((img) => img.slice(img.indexOf(prefix) + prefix.length))
  )
  ServiceRouting.closeServiceInstance({
    resultData: { source: source }
  })
}
export const getServiceOptions = async (): Promise<
  ServiceOptions | undefined
> => {
  await ensureJWeb()
  const options: LaunchServiceOptions =
    (await ServiceRouting.getServiceInstanceLaunchOptions()) as LaunchServiceOptions
  if (options && options.serviceOptions) {
    return options.serviceOptions as ServiceOptions
  }
  return undefined
}
