import { colors } from '@clientos/ui-toolkit'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Images from 'src/assets/images'
import { i18n } from 'src/assets/locale'
import type { RootState } from 'src/stores/'
import NotificationBanner from 'src/components/NotificationBanner'
import './NoFiles.scss'
import {
  updateInteractNotificationBanner,
  updateShowExpirationBanner
} from 'src/stores/event'
import { sendUiEvent, uiData } from 'src/plugins/jweb/DataCollection'
import { updateCurrentScreen } from 'src/stores/screen'
import { screenNames } from 'src/pages/CloudScan/screens'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function NoFiles() {
  const mobileMode: boolean = useSelector(
    (state: RootState) => state.screens.mobileMode
  )
  const showExpirationBanner = useSelector(
    (state: RootState) => state.event.showExpirationBanner
  )
  const deviceList = useSelector(
    (state: RootState) => state.device.allDeviceList.devices
  )
  const dispatch = useDispatch()

  const handleManageButton = () => {
    dispatch(updateShowExpirationBanner(false))
    dispatch(updateInteractNotificationBanner(true))
    sendUiEvent({
      ...uiData.SessionExpiry.btnRenewSession
    })
    dispatch(updateCurrentScreen(screenNames.CONNECTED_PRINTERS))
  }

  return (
    <>
      <section
        className="align-center nofile-message-block"
        style={{
          padding: showExpirationBanner
            ? '0px 24px 24px 24px'
            : '104px 24px 24px 24px'
        }}
      >
        <NotificationBanner
          showBanner={showExpirationBanner}
          onClick={handleManageButton}
          deviceQt={deviceList?.length}
        />
        <div
          className="align-center nofiles-container"
          style={{ paddingTop: showExpirationBanner ? '32px' : '0px' }}
        >
          <div data-testid="Icon-document">
            <img
              data-testid="paper-icon"
              src={Images.paperIcon}
              alt="paper-icon"
              style={{
                width: mobileMode ? '48px' : '80px',
                height: mobileMode ? '48px' : '80px'
              }}
            />
          </div>
          <div
            className="align-center noFiles-messages"
            style={{
              gap: mobileMode ? '24px' : '56px',
              paddingTop: mobileMode ? '24px' : '56px'
            }}
          >
            <p
              data-testid="nofiles-message-title"
              className="text-center nofiles-message-title title-small--medium"
              style={{
                color: mobileMode ? colors.inkPrimary : colors.listHeadline
              }}
            >
              {/* TODO: Replace when batch translation is done: i18n.t('application.nofiles.title-nofiles-available') */}
              No files available on HP Cloud
            </p>
            <p
              data-testid="nofiles-message-content"
              className="text-center nofiles-message-content"
              style={{
                width: mobileMode ? '64%' : '85%'
              }}
            >
              {i18n.t('application.nofiles.content-two')}
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default NoFiles
