// eslint-disable-next-line no-restricted-imports
import { AccessToken, TokenType, WhenJWebReady } from '@jarvis/jweb-core'
import { JarvisAuthProvider } from '@jarvis/web-http'

/* istanbul ignore next: Only used for development/testing */
const getAuthTokenFromCookie = (): string | undefined => {
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('stratus-access-token'))
    ?.split('=')[1]

  if (cookie) sessionStorage.setItem('accessToken', cookie)
  return cookie
}

const getAuthTokenFromJWeb = async (freshToken: boolean) => {
  if (!freshToken) return sessionStorage.getItem('accessToken')!

  const tokenProviderOptions = {
    allowUserInteraction: true,
    requireFreshToken: freshToken,
    tokenType: TokenType.user
  }

  const jweb = await WhenJWebReady
  const accessToken: AccessToken = (await jweb.Plugins.Auth.getToken({
    tokenProviderOptions
  })) as AccessToken

  sessionStorage.setItem('accessToken', accessToken.tokenValue)

  return accessToken.tokenValue
}

export const authProvider: JarvisAuthProvider = {
  getAccessToken: async (forceRefresh: boolean) => {
    let token: string | undefined

    try {
      token = await getAuthTokenFromJWeb(
        sessionStorage.getItem('accessToken') === null ? true : forceRefresh
      )
    } catch (e) {
      token = getAuthTokenFromCookie()
    }

    if (!token) {
      throw new Error('Unable to get access token')
    }

    return token
  }
}
