import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { colors } from '@clientos/ui-toolkit'
import Loader from 'src/components/Loader'

import { sendUiEvent, uiData } from 'src/plugins/jweb/DataCollection'

import {
  DeviceDescription,
  DeviceLists
} from '../../../services/Shortcuts/Type'
import type { RootState } from '../../../stores'
import Printers from './Printers'

const SettingsPanel = () => {
  const [loading, setLoading] = useState(false)
  const currentScreenName = useSelector(
    (state: RootState) => state.screens.currentScreenName
  )

  const deviceList: DeviceLists = useSelector(
    (state: RootState) => state.device.allDeviceList
  )

  const totalDevices = deviceList?.devices?.length || 0

  useEffect(() => {
    const getDevicesSessionList = async () => {
      try {
        const activeSessionSku: string[] = []
        const inactiveSessionSku: string[] = []
        setLoading(true)
        sendUiEvent({
          ...uiData.PrintersList.printersListPanel,
          screenPath:
            currentScreenName === 'start-session'
              ? '/CloudscansReactWebapp/SessionSyncScreen/'
              : '/CloudscansReactWebapp/JobsListScreen/',
          actionAuxParams: `totalPrinterCount=${deviceList.devices.length}&printerSkuListEnabled=[${activeSessionSku}]&printerSkuListDisabled=[${inactiveSessionSku}]`
        })
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    if (deviceList.devices) {
      getDevicesSessionList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScreenName, deviceList.devices])

  return (
    <>
      <p
        style={{ color: colors.inkSecondary }}
        id="subtitle"
        className="body-regular"
      >
        {/* TODO: when new batch translation comes, replace per {i18n.t('application.settingsPanel.subtitleHPX')} */}
        View and manage your cloud connection settings.
      </p>
      {!loading ? (
        totalDevices > 0 &&
        deviceList.devices.map((item: DeviceDescription, index: number) => {
          const isLastItem = index === totalDevices - 1
          return (
            <Printers
              setLoading={setLoading}
              DeviceDescription={item}
              data-testid="printers"
              key={item.deviceIdentity.deviceUuid}
              isLastItem={isLastItem}
            />
          )
        })
      ) : (
        <div className="connected-printers-loader">
          <Loader />
        </div>
      )}
    </>
  )
}

export default SettingsPanel
