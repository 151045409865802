import styled from 'styled-components'

export const MainContainer = styled.div`
  .clientos-ui-toolkit-generic-card__content__header {
    padding-bottom: 16px !important;
    font-size: 20px !important;
    line-height: 28px !important;
    color: #292a35;
  }
`
