import { ProgressIndicator } from '@veneer/core'
import React, { useEffect, useState } from 'react'
import { i18n } from '../../../../assets/locale'
import { Connector } from '../../../../data/schemas/connector'
import { resolveRepositoryType } from '../../../../modules/destinations/helpers'
import { SaveTitle } from '../../styles'
import { AccountProviderIcon } from '../AccountProviderIcon'
import { Button, ListContainer } from '@clientos/ui-toolkit'
import { ListItemProps } from '@clientos/ui-toolkit/dist/types/components/list-item/types'
import { DesktopGrid } from './styles'

type ListAddAccountProviderProps = {
  providersList: Connector[]
  desktopMode: boolean
  onSignInPress: (providerItem: Connector) => void
  loadingSignInButton: boolean
}

const ListAddAccountProvider = (props: ListAddAccountProviderProps) => {
  const [typeAccountChoose, setTypeAccountChoose] = useState<string>('')

  useEffect(() => {
    if (!props.loadingSignInButton) {
      setTypeAccountChoose('')
    }
  }, [props.loadingSignInButton])

  const listProviders = props.providersList.map((providerItem, index) => ({
    start: AccountProviderIcon[providerItem.type](false),
    label: resolveRepositoryType(providerItem.type),
    end:
      props.loadingSignInButton && typeAccountChoose === providerItem.type ? (
        <div>
          <ProgressIndicator color="royalBlue7" />
        </div>
      ) : (
        <Button
          id={`sign-in-btn-${providerItem.type}-${index}`}
          type="ghost-flush"
          size="large"
          disabled={typeAccountChoose ? true : false}
          onClick={() => {
            setTypeAccountChoose(providerItem.type)
            props.onSignInPress(providerItem)
          }}
        >
          {i18n.t('pages.AddSave.signIn')}
        </Button>
      ),
    alignStart: 'center',
    alignEnd: 'center',
    paddingStart: true,
    paddingEnd: true
  }))

  return (
    <>
      <div>
        <SaveTitle className="body-large">
          {i18n.t('pages.AddSave.addAccounts')}
        </SaveTitle>
        <DesktopGrid>
          <ListContainer
            items={listProviders as ListItemProps[]}
          ></ListContainer>
        </DesktopGrid>
      </div>
    </>
  )
}

export default ListAddAccountProvider
