import { useEffect, useState } from 'react'
import { ensureVisualViewPort } from '../../utils/polyfills/visualViewport'
const visualViewport = ensureVisualViewPort()
const useDeviceHeight = () => {
  const [height, setHeight] = useState(visualViewport?.height)
  useEffect(() => {
    const listener = () => {
      setHeight(() => visualViewport?.height)
    }
    visualViewport?.addEventListener('resize', listener)
    return () => {
      visualViewport?.removeEventListener('resize', listener)
    }
  }, [])
  return height
}

export default useDeviceHeight
