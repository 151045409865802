import React from 'react'
import CopyToClipboard from '../CopyToClipboard'
import IconButton from '../../IconButton'
import SpellCheck from '../SpellCheckIcon'
import { ChildBottomProps } from '..'
import DoneButton from '../../Header/DoneButton'
import useAssetsProvider from '../../../hooks/useAssetsProvider'
import useIsDesktop from '../../../hooks/useIsDesktop'

export type ChildHeadProps = {
  handleCancel(): void
}

export default function DesktopBottomBar({ hasText }: ChildBottomProps) {
  const assetsProvider = useAssetsProvider()
  const isDesktop = useIsDesktop()
  if (!assetsProvider) {
    console.error('Does not have assets provider')
    return null
  }
  const getText = (subkey: string) => {
    const assetKey = `application.${subkey}`
    return assetsProvider.getText(assetKey)
  }

  return (
    <div className="bottomBar-container sideBar-container-desktop">
      <h4
        style={{ cursor: 'default' }}
        className="title"
      >
        Options
      </h4>
      <div className="actions">
        <div
          className="button-with-text"
          aria-label="Copy all"
        >
          <CopyToClipboard disabled={!hasText} />
        </div>
        <IconButton
          icon={<SpellCheck />}
          label={getText('bottomBar.spellCheck')}
          disabled={!hasText}
          id="desktop-done-button"
          render={false}
        />
        <div className="sideBar-footer-desktop">
          {isDesktop ? <DoneButton /> : ''}
        </div>
      </div>
    </div>
  )
}
