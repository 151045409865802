/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line no-restricted-imports
import { WhenJWebReady } from '@jarvis/jweb-core'
import { useEffect } from 'react'

let callBackRef: { (): void } | null = null

export const nativeBackButtonHandler = {
  getBackButtonCallBack: () => callBackRef,
  setBackButtonCallBack: (backButtonCallBack: { (): void }) => {
    callBackRef = backButtonCallBack
  }
}

const useNativeBackButton = (backButtonCallBack: { (): void }) => {
  useEffect(() => {
    const backButton = async () => {
      const jweb = await WhenJWebReady
      if (jweb.JWeb.platform === 'android') {
        nativeBackButtonHandler.setBackButtonCallBack(backButtonCallBack)
      }
    }
    backButton()
  }, [backButtonCallBack])
}

export default useNativeBackButton
