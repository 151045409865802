// eslint-disable-next-line no-restricted-imports
import { WhenJWebReady } from '@jarvis/jweb-core'

export const getPlatform = async () => {
  const jweb = await WhenJWebReady
  return jweb.JWeb.platform
}

export const getHelpUrlPlatform = async () => {
  const jweb = await WhenJWebReady
  let platform = jweb.JWeb.platform
  if (platform === 'windows' || platform === 'mac') {
    platform = 'web'
  }
  return platform
}

export const isNative = async () => {
  const jweb = await WhenJWebReady
  return jweb.JWeb.isNative
}
