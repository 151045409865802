// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import packageJson from 'src/../package.json'

const packageName = packageJson.name
const [orgName, projectName] = packageName.substring(1).split(/\/(.+)/)
const sanitizedPackageName = packageName.replace(/@/g, '').replace(/\//g, '-')

const projectNames = {
  orgName,
  projectName,
  sanitizedPackageName,
  packageName
}

export default projectNames
