import {
  Button,
  IconDocument,
  IconDownload,
  IconTrash,
  Tooltip
} from '@clientos/ui-toolkit'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { i18n } from 'src/assets/locale'
import 'src/components/DocumentsAction/DocumentsAction.scss'
import { sendUiEvent, uiData } from 'src/plugins/jweb/DataCollection'
import { downloadFilesFromDocSet } from 'src/plugins/jweb/DocProvider'
import { isServiceRoutingAvailable } from 'src/plugins/jweb/ServiceRouting'
import {
  downloadFileFromTenzing,
  JOB_DURATION_SECONDS
} from 'src/services/Tenzing'
import type { File, Job } from 'src/services/Tenzing/Type'
import { RootState } from 'src/stores'
import { enableDeleteModal, updateCurrentJob } from 'src/stores/screen'
import '../DocumentList.scss'

enum ActionType {
  DOWNLOAD,
  DELETE
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Document = ({ id: jobId, jobStatus, taskResponse }: Job) => {
  const dispatch = useDispatch()
  const { outputInfoList: files } = taskResponse.saveResponse
  const { stateChangedAt: submittedTime } = jobStatus

  const downloadingLabel: string = i18n.t('application.download.title')
  //TODO: Uncomment when open preview is enabled
  // const openPreviewLabel: string = i18n.t('application.joblist-preview.title')
  const hoursLabel: string = i18n.t('application.joblist-duration.content-two')
  const minutesLabel: string = i18n.t(
    'application.joblist-duration.content-three'
  )
  const pageText: string = i18n.t('application.joblist-fileSize.one-page')
  const pagesText: string = i18n.t(
    'application.joblist-fileSize.multiple-pages'
  )
  const downloadLabel: string = i18n.t('application.document-action.label-one')
  const deleteLabel: string = i18n.t('application.document-action.label-two')
  // TODO: Replace when batch translation is done: i18n.t('application.joblist-errors.processing-error-message')
  const processingErrorMessage: string = 'Processing error. Try scanning again.'

  const [expiry, setExpiry] = useState(0)
  const [size, setSize] = useState<string | null>(null)
  const [downloadingStatus, setDownloadingStatus] = useState(false)
  const [currentDownloadIndex, setCurrentDownloadIndex] = useState(1)
  const [expiryTimeLabel, setExpiryTimeLabel] = useState(hoursLabel)

  const mobileMode: boolean = useSelector(
    (state: RootState) => state.screens.mobileMode
  )

  useEffect(() => {
    setExpiryTimeLabel(hoursLabel)

    const expiryHours = JOB_DURATION_SECONDS / 3600
    const jobExpiryTime = moment(submittedTime).add(expiryHours, 'hours')
    const currentTime = moment(new Date())

    if (jobExpiryTime.isAfter(currentTime)) {
      let remainingJobTime = jobExpiryTime.diff(currentTime, 'hours')
      if (remainingJobTime === 0) {
        remainingJobTime = jobExpiryTime.diff(currentTime, 'minutes')
        setExpiryTimeLabel(minutesLabel)
      }

      setExpiry(remainingJobTime)
    }
  }, [submittedTime, expiryTimeLabel, hoursLabel, minutesLabel])

  useEffect(() => {
    if (files) {
      const fileSize = calculateJobFilesSize(files)
      setSize(fileSize)
    }
  }, [files])

  const calculateJobFilesSize = (files: File[]) => {
    const DECIMALS = 2
    const totalJobFilesSize = files.reduce(
      (accumulator, obj) => accumulator + obj.outputSize,
      0
    )

    if (totalJobFilesSize === 0) return '0 Bytes'
    const k = 1024
    const dm = DECIMALS < 0 ? 0 : DECIMALS
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(totalJobFilesSize) / Math.log(k))
    return (
      parseFloat((totalJobFilesSize / Math.pow(k, i)).toFixed(dm)) +
      ' ' +
      sizes[i]
    )
  }

  const pageLabel = files?.length > 1 ? pagesText : pageText

  const actionClicked = (action: number | undefined) => {
    if (action === ActionType.DOWNLOAD) {
      downloadFilesJob()
      sendUiEvent({
        ...uiData.JobsList.btnDownloadJob,
        actionAuxParams: `jobId=${jobId}&filesTotalCount=${taskResponse.saveResponse.outputInfoList.length.toString()}`
      })
    } else if (action === ActionType.DELETE) {
      sendUiEvent({
        ...uiData.JobsList.btnDeleteJob
      })
      dispatch(updateCurrentJob(jobId))
      dispatch(enableDeleteModal(true))
    }
  }

  const downloadFilesJob = async () => {
    setDownloadingStatus(true)
    const jarvisServiceAvailable = await isServiceRoutingAvailable()

    let downloadIndex = 1
    for (const file of files) {
      file.blob = await downloadFileFromTenzing(jobId, file.outputName)

      if (!jarvisServiceAvailable) {
        triggerDownloadWebView(file)
      }

      downloadIndex++
      setCurrentDownloadIndex(downloadIndex)
    }

    if (jarvisServiceAvailable) {
      await downloadFilesFromDocSet(files)
    }

    setDownloadingStatus(false)
    setCurrentDownloadIndex(1)
  }

  const triggerDownloadWebView = (file: File) => {
    if (file.blob) {
      const link = document.createElement('a')
      const fileURL = URL.createObjectURL(
        new Blob([file.blob], { type: 'image/jpeg' })
      )

      link.setAttribute('target', '_parent')
      link.setAttribute('download', file.outputName)
      link.setAttribute('href', fileURL)
      document.body.appendChild(link)

      link.click()
      link.parentNode?.removeChild(link)
      URL.revokeObjectURL(fileURL)
    }
  }

  return (
    <>
      <div
        className="joblist-card"
        style={{ padding: mobileMode ? '24px' : '16px 32px' }}
      >
        <div className="joblist-container-1">
          <IconDocument
            size={56}
            data-testid="joblist-document"
            className="joblist-document"
          />
          <div className="joblist-content">
            <>
              <div className="joblist-container-2">
                <div className="joblist-content-card">
                  <p className="paragraph jobList-filename body-large">
                    {taskResponse.saveResponse.saveConfig.fileName}
                  </p>
                  {jobStatus.jobState === 'completed' && mobileMode && (
                    <p
                      data-testid="joblist-filesize-mobile"
                      className="paragraph joblist-filesize-mobile"
                    >
                      {files.length} {pageLabel} {' | '} {size}
                    </p>
                  )}
                </div>{' '}
                {jobStatus.jobState === 'completed' && (
                  <>
                    <p
                      data-testid="joblist-duration"
                      className="paragraph joblist-duration"
                      style={{
                        display: downloadingStatus && 'none'
                      }}
                    >
                      {/* TODO: Replace when batch translation is done: i18n.t('application.joblist-duration.content-one-HPX') */}
                      {'Expires in'} {expiry} {expiryTimeLabel}
                    </p>
                    {downloadingStatus && (
                      <div className="paragraph download-progress-container">
                        <div className="label caption-large">
                          {downloadingLabel} ({currentDownloadIndex}/
                          {files.length})
                        </div>
                      </div>
                      //Todo: Uncomment when open preview is enabled
                      // ) : (
                      // <a
                      //   data-testid="joblist-open-preview"
                      //   className="paragraph joblist-open-preview"
                      // >
                      //   {openPreviewLabel}
                      // </a>
                    )}
                  </>
                )}
                {jobStatus.jobState === 'error' && (
                  <div
                    className="paragraph joblist-error"
                    style={{ alignItems: mobileMode ? 'baseline' : 'center' }}
                  >
                    <div className="red-icon" />
                    <p
                      className="joblist-duration"
                      data-testid="joblist-processing-error-message"
                    >
                      {processingErrorMessage}
                    </p>
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
        <div
          data-testid="joblist-buttons-container"
          className="joblist-buttons-container"
        >
          {jobStatus.jobState === 'completed' && !mobileMode && (
            <p
              data-testid="joblist-filesize"
              className="paragraph joblist-filesize"
            >
              {files.length} {pageLabel}
              {', '} {size}
            </p>
          )}
          <div
            className="joblist-buttons"
            data-testid="joblist-buttons"
            style={
              jobStatus.jobState !== 'completed'
                ? { gap: '0' }
                : { gap: '16px' }
            }
          >
            <Tooltip
              placement="bottom-end"
              content={<>{deleteLabel}</>}
              contentHideDelayOnHover={20}
            >
              <Button
                data-testid="deleteButton"
                className="deleteButton"
                appearance="ghost"
                leadingIcon={<IconTrash />}
                onClick={() => actionClicked(ActionType.DELETE)}
              />
            </Tooltip>
            <Tooltip
              placement="bottom-end"
              content={<>{downloadLabel}</>}
              contentHideDelayOnHover={20}
            >
              <Button
                className="downloadButton"
                appearance="ghost"
                leadingIcon={<IconDownload />}
                disabled={jobStatus.jobState !== 'completed'}
                onClick={() => actionClicked(ActionType.DOWNLOAD)}
                customStyle={
                  jobStatus.jobState !== 'completed' && { display: 'none' }
                }
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  )
}

export default Document
