import React, { createContext, useContext, useState } from 'react'

type AnimationContextType = {
  animationDirection: string
  setAnimationDirection: (direction: string) => void
}

type AnimationContextProviderProps = { children: React.ReactNode }

export const AnimationContext = createContext<AnimationContextType>(
  {} as AnimationContextType
)

export const AnimationContextProvider = ({
  children
}: AnimationContextProviderProps) => {
  const [animationDirection, setAnimationDirection] = useState('right')

  return (
    <AnimationContext.Provider
      value={{
        animationDirection,
        setAnimationDirection
      }}
    >
      {children}
    </AnimationContext.Provider>
  )
}

export const useAnimationContext = () => useContext(AnimationContext)
