import { Loader as LoaderToolkit } from '@clientos/ui-toolkit'
import React from 'react'
import { Container } from './styles'

type LoaderProps = {
  message?: string
  position?: 'absolute' | 'relative'
}

const Loader = (props: LoaderProps) => (
  <Container
    id="loader-container"
    position={props.position}
  >
    <LoaderToolkit type="circular" />
    {props.message && (
      <>
        <br />
        <p id="loader-message">{props.message}</p>
      </>
    )}
  </Container>
)

export default Loader
