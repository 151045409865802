import { Loader as LoaderToolkit } from '@clientos/ui-toolkit'
import React from 'react'
import Container from './styles'

type LoaderProps = {
  message?: string
  height?: string
}

const Loader = (props: LoaderProps) => (
  <Container
    id="loader-container"
    height={props.height}
    role="progressbar"
  >
    <LoaderToolkit type="circular" />
  </Container>
)

export default Loader
