import React from 'react'
import { useSelector } from 'react-redux'

import DocList from 'src/components/DocumentList'
import AppHeader from 'src/components/Header'
import NoSupportedPrinter from 'src/components/NoSupportedPrinter'
import StartSession from 'src/components/StartSession'
import type { RootState } from 'src/stores'

import CreateSession from 'src/components/CreateSession'
import Loader from 'src/components/Loader'
import ConnectedPrinters from 'src/components/ConnectedPrinters'
import { DeviceDescription } from 'src/services/Shortcuts/Type'
import { screenNames } from './screens'
import PageTemplate from '../Template'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CloudScan = () => {
  const currentScreenName = useSelector(
    (state: RootState) => state.screens.currentScreenName
  )
  const CreateSessionData: DeviceDescription = useSelector(
    (state: RootState) => state.device.createSessionDeviceInfo
  )
  const loaderEnabled: boolean = useSelector(
    (state: RootState) => state.screens.loaderEnabled
  )

  const renderComponent = () => {
    switch (currentScreenName) {
      case screenNames.START_SESSION:
        return <StartSession />
      case screenNames.DOCUMENT_LIST:
        return <DocList />
      case screenNames.NO_SUPPORTED_PRINTER:
        return <NoSupportedPrinter />
      case screenNames.CONNECTED_PRINTERS:
        return <ConnectedPrinters />
      case screenNames.CREATE_SESSION:
        return CreateSessionData && <CreateSession {...CreateSessionData} />
      default:
        return <StartSession />
    }
  }

  return (
    <>
      {loaderEnabled ? (
        <Loader height="80vh" />
      ) : (
        <PageTemplate
          content={renderComponent()}
          header={<AppHeader />}
        />
      )}
    </>
  )
}

export default CloudScan
