// eslint-disable-next-line no-restricted-imports
import {
  AuthBrowserError,
  AuthBrowserOpenResponse,
  WhenJWebReady
} from '@jarvis/jweb-core'

export const getAuthBrowserResponseError = (
  response: AuthBrowserOpenResponse | AuthBrowserError
): string | undefined => {
  if (response && (response as AuthBrowserError).errorType !== undefined) {
    return (response as AuthBrowserError).errorType.replace(/^\w/, (c) =>
      c.toLowerCase()
    )
  }
}
export const openAuthBrowser = async (url: string, scheme: string) => {
  const jweb = await WhenJWebReady
  return await jweb.Plugins.AuthBrowser.open({
    url,
    scheme
  })
}
