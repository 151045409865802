import { useToast } from '@veneer/core'
import React, { useState } from 'react'
import { i18n } from '../../assets/locale'
import { deleteShortcut } from '../../clients/shortcuts'
import { useEditContext } from '../../contexts/edit/EditContext'
import { toastMessages } from '../../modules/common/helpers'
import { sendUiEvent, uiData } from '../../plugins/jweb/DataCollection'
import { Modal } from '@clientos/ui-toolkit'

type ModalDeleteProps = {
  show: boolean
  onClose: { (value?: string): void }
}

const ModalDelete = (props: ModalDeleteProps) => {
  const { addToast } = useToast()

  const [isDeletingShortcut, setIsDeletingShortcut] = useState(false)
  const { editState } = useEditContext()

  const onDeleteShortcut = async () => {
    try {
      setIsDeletingShortcut(true)
      const id = editState.id

      await deleteShortcut(id)

      sendUiEvent(uiData.btnYesModalDelete)

      setIsDeletingShortcut(false)
      props.onClose(id)
    } catch (e) {
      setIsDeletingShortcut(false)
      addToast?.({
        type: 'negative',
        ...toastMessages('deleteShortcutError')
      })
      props.onClose()
    }
  }

  return (
    <Modal
      /* TODO: Replace when batch translation is done: i18n.t('pages.ListShortcuts.deleteModalTitleHPX') */
      title={'Delete this Shortcut?'}
      onClose={() => props.onClose()}
      align="center"
      closeOnBlur={false}
      isOpen={props.show}
      aria-label="ModalDelete"
      buttonGroup={{
        textPrimaryButton: i18n.t('common.contextAction.delete'),
        primaryButtonType: 'negative',
        textSecondaryButton: i18n.t('common.contextAction.cancel'),
        onClickPrimaryButton: onDeleteShortcut,
        onClickSecondaryButton: props.onClose,
        size: 'large',
        orientation: 'vertical',
        disabled: isDeletingShortcut
      }}
    >
      {/* TODO: Replace when batch translation is done:
      i18n.t('pages.ListShortcuts.deleteModalTextHPX') */}
      <div className="body-regular">{'You can’t undo this.'}</div>
    </Modal>
  )
}

export default ModalDelete
