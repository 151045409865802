// eslint-disable-next-line no-restricted-imports
import { AccessToken, TokenType, WhenJWebReady } from '@jarvis/jweb-core'

export const getAuthToken = async () => {
  const tokenProviderOptions = {
    allowUserInteraction: true,
    tokenType: TokenType.user
  }

  const jweb = await WhenJWebReady
  const accessToken: AccessToken = (await jweb.Plugins.Auth.getToken({
    tokenProviderOptions
  })) as AccessToken

  return accessToken
}
