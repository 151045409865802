import { FolderItem, FolderStructure } from '../../../data/schemas/folders'

export const mapTreeData = (
  currentTree: FolderItem[],
  folder: FolderStructure
): FolderItem[] => {
  const treeData = currentTree.map((node) => {
    if (node.id === folder.parent.id) {
      return {
        ...node,
        id: node.id,
        type: node.type,
        name: node.name,
        loaded: true,
        isValid: true,
        data: folder.data
      }
    }

    return {
      ...node,
      id: node.id,
      type: node.type,
      name: node.name,
      isValid: true,
      data: node.data && mapTreeData(node.data, folder)
    }
  })
  return treeData
}

export const flattenTree = (tree: FolderItem[]): FolderItem[] =>
  tree.reduce((flattened: FolderItem[], current) => {
    if (!tree || !tree.length) {
      return flattened.concat(current)
    }

    return flattened.concat(
      current,
      Array.isArray(current.data) ? flattenTree(current.data) : []
    )
  }, [])

export const searchNode = (
  tree: FolderItem[],
  nodeId: string
): FolderItem | undefined =>
  flattenTree(tree).find((node: FolderItem) => node.id === nodeId)

export const modifyIfCharactersExists = (
  characteres: string,
  symbol: string
) => {
  // TODO interim measure until Tenzing modifies return of OneDrive related ID in child folders
  return function (folder: FolderStructure) {
    if (folder.parent.id.includes(characteres))
      folder.parent.id = folder.parent.id.replace(characteres, symbol)
  }
}
