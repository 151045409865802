import {
  IconFolder,
  IconFolderOpen,
  IconInfo,
  ProgressIndicator,
  TreeViewId
} from '@veneer/core'
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { i18n } from '../../assets/locale'
import { useSettingsContext } from '../../contexts/settings/SettingsContext'
import { FolderItem } from '../../data/schemas/folders'
import { resolveFolderLabel } from '../../modules/common/helpers'
import { LoaderContainer, StyledModal, StyledTreeView } from './styles'

type FolderListingModalProps = {
  isVisible: boolean
  isLoading: boolean
  folderTree: FolderItem[]
  editSelectedFolder: string
  currentConnector: string
  onNodeSelect?: { (id: string): void }
  onNodeExpand: { (nodeId: string): void }
  onClose: { (): void }
  onConfirm: { (nodeId: string): void }
}

type Node = {
  id: string
  label: string
  icon: JSX.Element
  nodes?: Node[]
}

const DialogModal = (props: FolderListingModalProps) => {
  const [expandedNodes, setExpandedNodes] = useState<TreeViewId[]>([])
  const [selectedNodes, setSelectedNodes] = useState<TreeViewId[]>([])
  const { settingsState } = useSettingsContext()

  /*
    This useEffect will guarantee that only when the currentConnectorId propertie changed
    the expandedNodes state will be reseted.
  */

  useEffect(() => {
    setExpandedNodes([])
  }, [settingsState.currentConnectorId])

  const getNodeIcon = useCallback(
    (nodeId: string) => {
      if (expandedNodes.includes(nodeId)) {
        return <IconFolderOpen />
      }

      return <IconFolder />
    },
    [expandedNodes]
  )

  const getNodeChildren = useCallback(
    (nodeData: FolderItem[]): Node[] => {
      if (!nodeData) {
        return []
      }

      return nodeData.map((folder) => {
        let statusNode = null

        if (!folder.loaded) {
          statusNode = {
            icon: null,
            id: '',
            label: ''
          }
        }

        if (folder.loaded && !folder.data) {
          statusNode = {
            icon: <IconInfo />,
            id: '',
            label: i18n.t('pages.FetchFolderModal.emptyFolder')
          }
        }

        return {
          icon:
            expandedNodes.includes(folder.id) && !folder.loaded ? (
              <ProgressIndicator color="royalBlue7" />
            ) : (
              getNodeIcon(folder.id)
            ),
          id: folder.id,
          label: folder.name,
          nodes: statusNode ? [statusNode] : getNodeChildren(folder?.data || [])
        }
      })
    },
    [expandedNodes, getNodeIcon]
  )

  const onNodeExpand = (_: SyntheticEvent, newExpandedNodes: TreeViewId[]) => {
    setExpandedNodes(newExpandedNodes)
    props.onNodeExpand(
      newExpandedNodes[newExpandedNodes.length - 1]?.toString()
    )
  }

  const onNodeSelect = (_: SyntheticEvent, id: TreeViewId | TreeViewId[]) => {
    if (id && !Array.isArray(id)) {
      setSelectedNodes(new Array(id))
    }
  }

  const nodes: Node[] = useMemo(
    () =>
      props.folderTree.map((node) => ({
        id: node.id,
        label: resolveFolderLabel(
          node.id,
          settingsState.currentConnectorType!,
          node.name
        ),
        icon: getNodeIcon(node.id),
        nodes: getNodeChildren(node.data || [])
      })),
    [props, getNodeIcon, getNodeChildren, settingsState.currentConnectorType]
  )

  const handleSaveButton = () => {
    props.onConfirm(
      selectedNodes[0]?.toString() ?? settingsState.editSelectedFolder
    )
    setSelectedNodes([])
  }

  const handleCancelButton = () => {
    setSelectedNodes([])
    props.onClose()
  }

  return (
    <StyledModal
      title={i18n.t('pages.AddSave.addDestinationModalText')}
      isOpen={props.isVisible}
      align="start"
      alignFooter="end"
      buttonGroup={{
        textPrimaryButton: i18n.t('common.contextAction.save'),
        textSecondaryButton: i18n.t('common.contextAction.cancel'),
        onClickPrimaryButton: () => {
          handleSaveButton()
        },
        onClickSecondaryButton: () => {
          handleCancelButton()
        },
        disabledPrimary: !selectedNodes.length,
        size: 'medium',
        orientation: 'horizontal-hug'
      }}
    >
      {props.isLoading ? (
        <LoaderContainer>
          <ProgressIndicator
            color="royalBlue7"
            aria-label="folders loader"
          />
        </LoaderContainer>
      ) : (
        <StyledTreeView
          nodes={nodes}
          expandedNodes={expandedNodes}
          selectedNodes={
            settingsState.editSelectedFolder && !selectedNodes.length
              ? Array(settingsState.editSelectedFolder)
              : selectedNodes
          }
          onToggle={onNodeExpand}
          onChange={onNodeSelect}
        />
      )}
    </StyledModal>
  )
}

export default DialogModal
