import { colors, Modal, TreeView } from '@clientos/ui-toolkit'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  .modal-content {
    width: 100%;
  }
  .clientos-ui-toolkit-group-right {
    justify-content: end !important;
  }
`

export const StyledTreeView = styled(TreeView)`
  li {
    > div:first-child:hover {
      background-color: ${colors.interfaceSurfaceElevataed};
    }
  }

  li[aria-selected='true'] {
    > div:first-child {
      background-color: ${colors.interfaceSurfaceElevataed};
    }

    > div:first-child {
      svg[aria-label='Folder'],
      svg[aria-label='Folder Open'] {
        color: ${colors.royalBlue7};
      }
    }

    > div {
      > span {
        color: ${colors.royalBlue7};
      }
    }
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
