import React from 'react'
import './BottomBar.scss'
import useText from '../../hooks/useText'
import useAssetsProvider from '../../hooks/useAssetsProvider'
import useIsDesktop from '../../hooks/useIsDesktop'
import MobileBottomBar from './MobileBottomBar/MobileBottomBar'
import DesktopBottomBar from './DesktopBottomBar/DesktopBottomBar'
export type ChildBottomProps = {
  hasText: number | undefined
}

const BottomBar = () => {
  const assetsProvider = useAssetsProvider()
  const text = useText()
  const isDesktop = useIsDesktop()
  if (!assetsProvider) return null
  const hasText = text?.length
  return (
    <>
      {isDesktop ? (
        <DesktopBottomBar hasText={hasText} />
      ) : (
        <MobileBottomBar hasText={hasText} />
      )}
    </>
  )
}
export default BottomBar
