import {
  IconFolder,
  IconFolderOpen,
  IconInfo,
  ProgressIndicator,
  TreeViewId,
  useToast
} from '@veneer/core'
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import { i18n } from '../../assets/locale'
import Header from '../../components/Header'
import { useAnimationContext } from '../../contexts/animations/AnimationContext'
import { useSettingsContext } from '../../contexts/settings/SettingsContext'
import { PageProps } from '../../data/schemas/app'
import { FolderItem } from '../../data/schemas/folders'
import { LocationState } from '../../data/schemas/location'
import useTreeViewNodes from '../../hooks/helpers/useTreeViewNodes'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import { resolveFolderLabel, toastMessages } from '../../modules/common/helpers'
import PageTemplate from '../PageTemplate'
import { GenericCard } from '@clientos/ui-toolkit'
import MobileScroll from 'src/components/MobileScroll'
import {
  LoaderContainer,
  StyledTreeView
} from 'src/components/FolderListingModal/styles'
import { ContentContainer, FolderContainer } from './styles'
import Loader from 'src/components/Loader'

type Node = {
  id: string
  label: string
  icon: JSX.Element
  nodes?: Node[]
}

export const FolderListing = ({ base }: PageProps) => {
  const history = useHistory<LocationState>()
  const { addToast } = useToast()
  const { setAnimationDirection } = useAnimationContext()
  const [expandedNodes, setExpandedNodes] = useState<TreeViewId[]>([])
  const [selectedNodes, setSelectedNodes] = useState<TreeViewId[]>([])
  const { settingsActions, settingsState } = useSettingsContext()
  const {
    isLoadingFolders,
    folderTree,
    onNodeExpand,
    onFolderListingOpen,
    errorOnFetchFolder,
    setErrorOnFetchFolder
  } = useTreeViewNodes()

  useEffect(() => {
    onFolderListingOpen(settingsState.currentConnectorId!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsState.currentConnectorId])

  useEffect(() => {
    settingsActions.updateFolderTree(folderTree)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderTree])

  // This useEffect will close the modal and show a toast whenever an error happen when fetching folders.
  useEffect(() => {
    if (errorOnFetchFolder) {
      history.push(`${base}/repositories`)
      addToast?.({
        type: 'warning',
        ...toastMessages('fetchFoldersError')
      })
      setErrorOnFetchFolder(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorOnFetchFolder])

  const getNodeIcon = useCallback(
    (nodeId: string) => {
      if (expandedNodes.includes(nodeId)) {
        return <IconFolderOpen />
      }

      return <IconFolder />
    },
    [expandedNodes]
  )

  const getNodeChildren = useCallback(
    (nodeData: FolderItem[]): Node[] => {
      if (!nodeData) {
        return
      }

      return nodeData.map((folder) => {
        let statusNode = null

        if (!folder.loaded) {
          statusNode = {
            icon: null,
            id: '',
            label: ''
          }
        }

        if (folder.loaded && !folder.data) {
          statusNode = {
            icon: <IconInfo />,
            id: '',
            label: i18n.t('pages.FetchFolderModal.emptyFolder')
          }
        }

        return {
          icon:
            expandedNodes.includes(folder.id) && !folder.loaded ? (
              <ProgressIndicator color="royalBlue7" />
            ) : (
              getNodeIcon(folder.id)
            ),
          id: folder.id,
          label: folder.name,
          nodes: statusNode ? [statusNode] : getNodeChildren(folder?.data)
        }
      })
    },
    [expandedNodes, getNodeIcon]
  )

  const handleNodeExpand = (
    _: SyntheticEvent,
    newExpandedNodes: TreeViewId[]
  ) => {
    setExpandedNodes(newExpandedNodes)
    onNodeExpand(newExpandedNodes[newExpandedNodes.length - 1]?.toString())
  }

  const onNodeSelect = (_: SyntheticEvent, id: TreeViewId | TreeViewId[]) => {
    if (id && !Array.isArray(id)) {
      if (
        selectedNodes.some((item) => {
          return item === id
        })
      ) {
        setSelectedNodes([])
      } else {
        setSelectedNodes(new Array(id))
      }
    }
  }

  const nodes = useMemo(
    () =>
      folderTree.map((node) => ({
        id: node.id,
        label: resolveFolderLabel(
          node.id,
          settingsState.currentConnectorType!,
          node.name
        ),
        icon: getNodeIcon(node.id),
        nodes: getNodeChildren(node.data)
      })),
    [
      folderTree,
      getNodeIcon,
      getNodeChildren,
      settingsState.currentConnectorType
    ]
  )

  const onBackButtonPress = () => {
    setAnimationDirection('left')
    history.push(`${base}/repositories`)
  }

  useNativeBackButton(onBackButtonPress)

  useEffect(() => {
    settingsActions.updateSelectedFolder(selectedNodes[0]?.toString())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodes])

  return (
    <PageTemplate
      desktopMode={false}
      header={
        <Header
          // TODO: Replace when batch translation is done: i18n.t('pages.AddSave.headerButtonHPX')
          backButtonLabel={'Back'}
          onPressIconLeft={onBackButtonPress}
        />
      }
      content={
        <>
          <ContentContainer>
            <GenericCard>
              <GenericCard.Header
                borderBottom={false}
                title={i18n.t('pages.AddSave.addDestinationModalText')}
              />
              <GenericCard.Body
                paddingBodyBottom={false}
                paddingBodyTop={false}
                paddingBodyHorizontal={true}
              >
                <FolderContainer>
                  <MobileScroll offset="200px">
                    {isLoadingFolders ? (
                      <LoaderContainer>
                        <Loader
                          aria-label="folders loader"
                          position="relative"
                        />
                      </LoaderContainer>
                    ) : (
                      <StyledTreeView
                        nodes={nodes}
                        expandedNodes={expandedNodes}
                        selectedNodes={
                          settingsState.editSelectedFolder &&
                          !selectedNodes.length
                            ? Array(settingsState.editSelectedFolder)
                            : selectedNodes
                        }
                        onToggle={handleNodeExpand}
                        onChange={onNodeSelect}
                      />
                    )}
                  </MobileScroll>
                </FolderContainer>
              </GenericCard.Body>
            </GenericCard>
          </ContentContainer>
        </>
      }
    ></PageTemplate>
  )
}
