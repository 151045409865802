import React from 'react'
import { i18n } from '../../assets/locale'
import { StyledModal } from './styles'

type ModalShortcutsLimitProps = {
  show: boolean
  onConfirm: () => void
}

const ModalShortcutsLimit = (props: ModalShortcutsLimitProps) => {
  return (
    <StyledModal
      aria-label="modal-shortcuts-limit"
      title={i18n.t('pages.ModalShortcutsLimit.shortcutsLimitTitle')}
      onClose={props.onConfirm}
      closeOnBlur={false}
      isOpen={props.show}
      align="center"
      buttonGroup={{
        textPrimaryButton: i18n.t('common.contextAction.ok'),
        onClickPrimaryButton: () => {
          props.onConfirm()
        },
        size: 'medium',
        orientation: 'vertical'
      }}
    >
      <p id="modal-shortcuts-limit-text1">
        {i18n.t(
          'pages.ModalShortcutsLimit.shortcutsLimitMessageFirstParagraph'
        )}
      </p>
      <p id="modal-shortcuts-limit-text2">
        {i18n.t(
          'pages.ModalShortcutsLimit.shortcutsLimitMessageSecondParagraph'
        )}
      </p>
    </StyledModal>
  )
}

export default ModalShortcutsLimit
