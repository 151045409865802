import { ButtonGroup, GenericCard, Modal } from '@clientos/ui-toolkit'
import { IconFolder, IconWarning } from '@veneer/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import MobileScroll from 'src/components/MobileScroll'
import PageTemplate from 'src/pages/PageTemplate'
import { i18n } from '../../../assets/locale'
import DestinationsList from '../../../components/DestinationsList'
import Header from '../../../components/Header'
import ModalDelete from '../../../components/ModalDelete'
import ModalRemove from '../../../components/ModalRemove'
import { useAnimationContext } from '../../../contexts/animations/AnimationContext'
import { useDestinationsContext } from '../../../contexts/destinations/DestinationsContext'
import { useEditContext } from '../../../contexts/edit/EditContext'
import { useSettingsContext } from '../../../contexts/settings/SettingsContext'
import { useToggleContext } from '../../../contexts/toggle/ToggleContext'
import { PageProps } from '../../../data/schemas/app'
import { LocationState } from '../../../data/schemas/location'
import { RepositoryConfig } from '../../../data/schemas/shortcut'
import useNativeBackButton from '../../../hooks/useNativeBackButton'
import { resolveTwoSidedDetail } from '../../../modules/common/helpers'
import {
  resolveLabel,
  resolveRepositoryType
} from '../../../modules/destinations/helpers'
import { sendUiEvent, uiData } from '../../../plugins/jweb/DataCollection'
import {
  DestinationsSubtitleArea,
  MainContainer,
  SaveSubtitleFolderArea
} from './styles'

export const DestinationsMobileMode = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()

  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showPrintRemoveModal, setShowPrintRemoveModal] = useState(false)
  const [showEmailRemoveModal, setShowEmailRemoveModal] = useState(false)
  const [showSaveRemoveModal, setShowSaveRemoveModal] = useState(false)
  const { setAnimationDirection } = useAnimationContext()
  const { destinationsState, destinationsActions } = useDestinationsContext()
  const { settingsActions, settingsState } = useSettingsContext()
  const { editState, editActions } = useEditContext()
  const { toggleState, toggleActions } = useToggleContext()

  const toggleStatusModal = () => {
    setShowCancelModal(!showCancelModal)
    sendUiEvent(uiData.screenCancelEdit)
  }

  const onContinueButtonPress = () => {
    sendUiEvent(uiData.btnContinueDestinations)

    if (destinationsState.currentState.email.set) {
      sendUiEvent(uiData.SettingsShortcutMobile.btnAddEmail)
    }

    if (destinationsState.currentState.print.set) {
      sendUiEvent({
        ...uiData.copiesButton,
        controlDetail: `${destinationsState.currentState.print.numberOfCopies}CopiesButton`
      })

      sendUiEvent({
        ...uiData.colorsButton,
        controlDetail: `${destinationsState.currentState.print.color}Button`
      })

      sendUiEvent({
        ...uiData.twoSidedButton,
        controlDetail: resolveTwoSidedDetail(
          destinationsState.currentState.print.printDuplex
        )
      })

      sendUiEvent(uiData.SettingsShortcutMobile.btnAddPrint)
    }

    if (destinationsState.currentState.repositories.set) {
      sendUiEvent(uiData.SettingsShortcutMobile.btnAddSave)
    }

    if (toggleState.print) {
      history.push(`${base}/print`)
    }

    if (!toggleState.print && toggleState.email) {
      history.push(`${base}/email`)
    }

    if (!toggleState.print && !toggleState.email && toggleState.save) {
      history.push(`${base}/repositories`)
    }
  }

  const onCloseDeleteModal = (id: string | undefined) => {
    setShowDeleteModal(!showDeleteModal)
    setAnimationDirection('left')

    if (typeof id === 'string' || !id) {
      history.push(`${base}/list`)
      destinationsActions.resetState()
      settingsActions.resetState()
      editActions.resetState()
    }
  }

  const onClickCancel = () => {
    setShowCancelModal(!showCancelModal)
    if (editState.editMode) {
      sendUiEvent(uiData.btnYesCancelEdit)
      editActions.resetState()
    } else {
      sendUiEvent(uiData.SettingsShortcutMobile.btnYesCancelCreate)
    }
    history.push(`${base}`)
    destinationsActions.resetState()
    settingsActions.resetState()
    toggleActions.resetState()
  }

  const onClickRemoveButton = (configName: string) => {
    switch (configName) {
      case 'print':
        setShowPrintRemoveModal(!showPrintRemoveModal)
        toggleActions.setPrintToggle(true)
        break
      case 'email':
        setShowEmailRemoveModal(!showEmailRemoveModal)
        toggleActions.setEmailToggle(true)
        break
      case 'save':
        setShowSaveRemoveModal(!showSaveRemoveModal)
        toggleActions.setSaveToggle(true)
        break
    }
  }

  useEffect(() => {
    if (destinationsState.currentState.print.set && !toggleState.print) {
      onClickRemoveButton('print')
    }
    if (destinationsState.currentState.email.set && !toggleState.email) {
      onClickRemoveButton('email')
    }
    if (destinationsState.currentState.repositories.set && !toggleState.save) {
      onClickRemoveButton('save')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    destinationsState.currentState.email.set,
    destinationsState.currentState.print.set,
    destinationsState.currentState.repositories.set,
    toggleState.email,
    toggleState.print,
    toggleState.save
  ])

  const onConfirmRemoveModal = (configName: string) => {
    switch (configName) {
      case 'print':
        destinationsActions.updateCheckpointState({
          ...destinationsState.checkpointState,
          print: {
            numberOfCopies: 1,
            color: 'true',
            printDuplex: 'one-sided',
            set: false
          }
        })
        destinationsActions.updatePrint({
          numberOfCopies: 1,
          color: 'true',
          printDuplex: 'one-sided',
          set: false
        })
        toggleActions.setPrintToggle(false)
        setShowPrintRemoveModal(!showPrintRemoveModal)
        break
      case 'email':
        destinationsActions.updateCheckpointState({
          ...destinationsState.checkpointState,
          email: { tos: [], subject: '', message: '', set: false }
        })
        destinationsActions.updateEmail({
          tos: [],
          subject: '',
          message: '',
          set: false
        })
        toggleActions.setEmailToggle(false)
        setShowEmailRemoveModal(!showEmailRemoveModal)
        break
      case 'save':
        destinationsActions.updateCheckpointState({
          ...destinationsState.checkpointState,
          repositories: { selected: [], set: false }
        })
        destinationsActions.updateRepository({ selected: [], set: false })
        toggleActions.setSaveToggle(false)
        setShowSaveRemoveModal(!showSaveRemoveModal)
        break
    }
  }

  const foldersLength = useCallback(
    (providerItem: RepositoryConfig) => {
      const folders = settingsState?.foldersList?.find(
        (item) => item.connectorId === providerItem.connectorId
      )?.folders
      return folders && folders.length > 0
    },
    [settingsState]
  )

  useNativeBackButton(toggleStatusModal)

  return (
    <PageTemplate
      desktopMode={props.desktopMode}
      header={
        <Header
          backButtonLabel={i18n.t('common.contextAction.cancel')}
          onPressIconLeft={toggleStatusModal}
        />
      }
      content={
        <MainContainer>
          <GenericCard>
            <GenericCard.Header
              title={
                editState.editMode
                  ? i18n.t('pages.Destinations.editShortcut')
                  : i18n.t('pages.AddShortcut.header')
              }
              borderBottom={false}
            />
            <GenericCard.Body
              paddingBodyBottom={false}
              paddingBodyTop={false}
            >
              <MobileScroll offset="250px">
                <div
                  id="intro-text-add-card"
                  style={{
                    paddingBottom: '20px',
                    color: '#212121',
                    fontSize: '16px',
                    lineHeight: '24px'
                  }}
                >
                  {i18n.t('pages.Destinations.addCard')}
                </div>
                <div
                  id="destinations-title"
                  style={{
                    paddingBottom: '16px',
                    fontSize: '20px',
                    lineHeight: '28px',
                    fontWeight: '500',
                    color: '#191a22'
                  }}
                >
                  {i18n.t('pages.Destinations.destinations')}
                </div>
                <DestinationsList
                  printTitle={i18n.t('pages.Destinations.printTitle')}
                  emailTitle={i18n.t('pages.Destinations.emailTitle')}
                  saveTitle={i18n.t('pages.Destinations.saveTitle')}
                  printSubtitle={
                    destinationsState.currentState.print.set ? (
                      <DestinationsSubtitleArea>
                        <p>
                          {`${i18n.t('pages.AddPrint.copies')}: ${
                            destinationsState.currentState.print.numberOfCopies
                          }`}
                        </p>
                        <p>
                          {`${i18n.t('pages.AddPrint.color')}: ${
                            destinationsState.currentState.print.color ===
                            'true'
                              ? i18n.t('pages.AddPrint.color')
                              : i18n.t('pages.AddPrint.grayscale')
                          }`}
                        </p>
                        <p>
                          {`${i18n.t('pages.AddPrint.two-sided')}: ${resolveLabel(
                            destinationsState.currentState.print.printDuplex
                          )}`}
                        </p>
                      </DestinationsSubtitleArea>
                    ) : (
                      <p>{i18n.t('pages.Destinations.printSubtitle')}</p>
                    )
                  }
                  emailSubtitle={
                    destinationsState.currentState.email.set ? (
                      <p>
                        {`${i18n.t(
                          'pages.AddEmail.to'
                        )}: ${destinationsState.currentState.email.tos.join(', ')}`}
                      </p>
                    ) : (
                      <p>{i18n.t('pages.Destinations.emailSubtitle')}</p>
                    )
                  }
                  saveSubtitle={
                    destinationsState.currentState.repositories.set ? (
                      <div
                        key={`${destinationsState.currentState.repositories.set}saveSubtitle`}
                      >
                        {destinationsState.currentState.repositories.selected.map(
                          (providerItem) => (
                            <div key={providerItem.connectorId}>
                              <p>
                                {providerItem.connectorName ??
                                  providerItem.type}
                              </p>
                              <SaveSubtitleFolderArea>
                                {(foldersLength(providerItem) &&
                                  settingsState?.foldersList
                                    ?.find(
                                      (item) =>
                                        item.connectorId ===
                                        providerItem.connectorId
                                    )
                                    ?.folders.map((item) => (
                                      <p key={item.id}>
                                        {
                                          // isValid has been used to not cause issues in the previousTree.
                                          item.isValid === undefined ||
                                          item.isValid ? (
                                            <IconFolder size={22} />
                                          ) : (
                                            <IconWarning size={22} />
                                          )
                                        }
                                        :{' '}
                                        {item.id.includes('root')
                                          ? resolveRepositoryType(
                                              providerItem.type
                                            )
                                          : item.name}
                                      </p>
                                    ))) || (
                                  <p>
                                    <IconFolder size={22} />: HP app
                                  </p>
                                )}
                              </SaveSubtitleFolderArea>
                              <br />
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <p>{i18n.t('pages.Destinations.saveSubtitle')}</p>
                    )
                  }
                  enablePrintToggle={toggleState.print}
                  enableEmailToggle={toggleState.email}
                  enableSaveToggle={toggleState.save}
                  isPrintDestinationSet={
                    destinationsState.currentState.print.set
                  }
                  isEmailDestinationSet={
                    destinationsState.currentState.email.set
                  }
                  isSaveDestinationSet={
                    destinationsState.currentState.repositories.set
                  }
                />
              </MobileScroll>
            </GenericCard.Body>
          </GenericCard>
          <ModalDelete
            show={showDeleteModal}
            onClose={onCloseDeleteModal}
          />
          <ModalRemove
            show={showPrintRemoveModal}
            onClose={() => onClickRemoveButton('print')}
            onConfirm={() => onConfirmRemoveModal('print')}
          />
          <ModalRemove
            show={showEmailRemoveModal}
            onClose={() => onClickRemoveButton('email')}
            onConfirm={() => onConfirmRemoveModal('email')}
          />
          <ModalRemove
            show={showSaveRemoveModal}
            onClose={() => onClickRemoveButton('save')}
            onConfirm={() => onConfirmRemoveModal('save')}
          />
          <Modal
            id="confirmation-modal"
            aria-label="confirmation-modal"
            onClose={toggleStatusModal}
            closeOnBlur={false}
            isOpen={showCancelModal}
            /* TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalTitleHPX') */
            title={'Cancel this Shortcut?'}
            align="center"
            buttonGroup={{
              // TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalCancelEditsButtonHPX')
              textPrimaryButton: 'Yes, cancel',
              primaryButtonType: 'negative',
              // TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalContinueEditsButtonHPX')
              textSecondaryButton: 'Go back',
              onClickPrimaryButton: () => {
                onClickCancel()
              },
              onClickSecondaryButton: () => {
                toggleStatusModal()
              },
              size: 'large',
              orientation: 'vertical'
            }}
          >
            {/* TODO: Replace when batch translation is done:
        i18n.t('pages.Destinations.modalDescriptionHPX') */}
            <p>{'Any edits you made will be lost.'}</p>
          </Modal>
        </MainContainer>
      }
      mobileFooter={
        <ButtonGroup
          textPrimaryButton={i18n.t('common.contextAction.continue')}
          size="large"
          orientation="horizontal-full"
          onClickPrimaryButton={onContinueButtonPress}
          disabled={
            !(toggleState.print || toggleState.email || toggleState.save)
          }
        />
      }
    />
  )
}
