import React from 'react'
import { i18n } from '../../assets/locale'
import { StyledModal } from './styles'

type ModalRemoveProps = {
  show: boolean
  onClose: { (value?: string): void }
  onConfirm: { (): void }
}

const ModalRemove = (props: ModalRemoveProps) => {
  return (
    <StyledModal
      id="modal-remove"
      /* TODO: Replace when batch translation is done: i18n.t('pages.Destinations.removeModalTextHPX') */
      title="Remove this destination?"
      onClose={() => props.onClose()}
      closeOnBlur={false}
      isOpen={props.show}
      align="center"
      aria-label="ModalRemove"
      paddingContentBottom={false}
      modalSize="large"
      buttonGroup={{
        textPrimaryButton: i18n.t('common.contextAction.remove'),
        textSecondaryButton: i18n.t('common.contextAction.cancel'),
        onClickPrimaryButton: props.onConfirm,
        onClickSecondaryButton: () => props.onClose(),
        size: 'large',
        orientation: 'vertical'
      }}
    />
  )
}

export default ModalRemove
