import React from 'react'
import { i18n } from '../../assets/locale'
import { Modal } from '@clientos/ui-toolkit'

type ModalRemoveAccessProps = {
  show: boolean
  onConfirm: { (): void }
  providerType: string
}

const ModalRemoveAccess = (props: ModalRemoveAccessProps) => {
  return (
    <Modal
      aria-label="modal-remove-access"
      // TODO: Replace when batch translation is done:
      // i18n.t('pages.AddSave.removeAccessModalTitleHPX')
      title={'Remove HP access'}
      isOpen={props.show}
      align="center"
      alignFooter="center"
      buttonGroup={{
        textPrimaryButton: i18n.t('common.contextAction.ok'),
        onClickPrimaryButton: () => {
          props.onConfirm()
        },
        size: 'large',
        orientation: 'vertical'
      }}
    >
      <div className="body-regular">{
        // TODO: Replace when batch translation is done:
        //   i18n.t('pages.AddSave.removeAccessModalTextHPX', {
        //   // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        //   repository: resolveRepositoryType(currentRepository?.type!)
        // })
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        `You must go to your ${props.providerType} connection settings to remove HP access.`
      }</div>
    </Modal>
  )
}

export default ModalRemoveAccess
