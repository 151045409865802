// eslint-disable-next-line no-restricted-imports
import { DocSet } from '@jarvis/jweb-core'
import TextDoc from './docs/TextDoc'

export default class TextEditDocSet implements DocSet {
  readonly id: string
  readonly docs: TextDoc[]
  constructor(id: string, docs: TextDoc[]) {
    this.id = id
    this.docs = docs
  }
  getDocs(): TextDoc[] {
    return this.docs
  }
}
