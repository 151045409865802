/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { AxiosResponse } from 'axios'
import type { Jobs } from './Type'
import Config from '../../config'
import { JarvisWebHttpClient } from '@jarvis/web-http'
import { authProvider } from 'src/plugins/jweb/Auth'

const client: JarvisWebHttpClient = new JarvisWebHttpClient({
  defaultBaseURL: Config.TENZING_URL,
  defaultAuthProvider: authProvider,
  defaultRetries: 0
})

export const JOB_DURATION_SECONDS = 259200 // 72 hours

export const downloadFileFromTenzing = async (
  JOB_ID: string,
  FILE_ID: string
) => {
  try {
    const result: AxiosResponse = await client.get({
      url: `/v1/jobs/object/download/${JOB_ID}/${FILE_ID}/`,
      responseType: 'blob',
      timeout: 30000
    })

    return result.data
  } catch (error) {
    return null
  }
}

export async function getCloudScanCompletedOrErrorJobs(
  pageNo: number,
  itemPerPage: number
) {
  try {
    const response: Jobs = await getCloudScanJobs(
      pageNo,
      itemPerPage,
      JOB_DURATION_SECONDS
    )

    response.jobs = response.jobs.filter(
      (job) =>
        job.jobStatus.jobState === 'completed' ||
        job.jobStatus.jobState === 'error'
    )
    response.status = true

    return response
  } catch (error) {
    throw new Error(
      "Error: We weren't able to fetch your files. Please try again. If the problem persists, contact HP Support"
    )
  }
}

export async function getCloudScanJobs(
  OFFSET: number,
  LIMIT: number,
  EXPIRY: number
) {
  try {
    const result: AxiosResponse = await client.get({
      url: `/v1/jobs/cloudscan/all?offset=${OFFSET}&limit=${LIMIT}&orderBy=desc&expiry=${EXPIRY}`,
      timeout: 30000
    })

    return result.data
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function deleteJob(JOB_ID: string) {
  try {
    const result: AxiosResponse = await client.delete({
      url: `/v1/jobs/cloudscan/${JOB_ID}`,
      timeout: 30000
    })

    return result.data
  } catch (error) {
    throw new Error(
      "It wasn't possible to delete your file. Please try again. If the problem persists, contact HP Support."
    )
  }
}
