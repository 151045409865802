// eslint-disable-next-line no-restricted-imports
import { AccessToken } from '@jarvis/jweb-core'
import { i18n } from '../../assets/locale'
import { getUserStates } from '../../clients/shortcuts'
import Config from '../../config'
import { Connector } from '../../data/schemas/connector'
import { ocrLanguageConfigOptionsList } from '../../data/schemas/settings'
import { UserStates } from '../../data/schemas/userStates'
import { resolveRepositoryType } from '../../modules/destinations/helpers'
import { getHelpUrlPlatform } from '../../plugins/jweb/Platform'
import { launchUrlInJarvisWebView } from '../../plugins/jweb/ServiceRouting'

export const toastMessages = (key: string) =>
  ({
    deviceFetchError: {
      id: 0,
      text: i18n.t('pages.AccessShortcuts.deviceFetchErrorTitle'),
      subtitle: i18n.t('pages.AccessShortcuts.deviceFetchErrorMessage')
    },
    createSessionError: {
      id: 1,
      text: i18n.t('pages.AccessShortcuts.createSessionErrorTitle'),
      subtitle: i18n.t('pages.AccessShortcuts.createSessionErrorMessage')
    },
    deleteSessionError: {
      id: 2,
      text: i18n.t('pages.AccessShortcuts.deleteSessionErrorTitle'),
      subtitle: i18n.t('pages.AccessShortcuts.createSessionErrorMessage')
    },
    fetchShortcutsError: {
      id: 3,
      text: i18n.t('pages.AccessShortcuts.deviceFetchErrorTitle'),
      subtitle: i18n.t('pages.AccessShortcuts.deviceFetchErrorMessage')
    },
    fetchCloudStoragesListError: {
      id: 4,
      text: i18n.t('pages.AccessShortcuts.deviceFetchErrorTitle'),
      subtitle: i18n.t('pages.AccessShortcuts.deviceFetchErrorMessage')
    },
    cloudStorageLoginError: {
      id: 5,
      text: i18n.t('pages.AccessShortcuts.deviceFetchErrorTitle'),
      subtitle: i18n.t('pages.AccessShortcuts.createSessionErrorMessage')
    },
    createShortcutError: {
      id: 6,
      text: i18n.t('pages.ShortcutSettings.createShortcutErrorTitle'),
      subtitle: i18n.t('pages.ShortcutSettings.createShortcutErrorMessage')
    },
    deleteShortcutError: {
      id: 7,
      text: i18n.t('pages.ModalDelete.deleteShortcutErrorTitle'),
      subtitle: i18n.t('pages.ModalDelete.deleteShortcutErrorMessage')
    },
    fetchFoldersError: {
      id: 8,
      text: i18n.t(
        'pages.FetchFolderModal.fetchFoldersError.fetchFoldersErrorMessage'
      ),
      subtitle: i18n.t(
        'pages.FetchFolderModal.fetchFoldersError.fetchFoldersErrorTitle'
      )
    }
  })[key] as { id: number; text: string; subtitle: string }

export const onHelpButtonPress = async () => {
  const platform = await getHelpUrlPlatform()

  launchUrlInJarvisWebView(
    `${Config.HPSMART_URL}/${i18n.country.toLowerCase()}/${
      i18n.language
    }/in-app-help/${platform}/getting-to-know-hp-smart`
  )
}

export const determineOcrLanguage = () => {
  const matchingLanguages = ocrLanguageConfigOptionsList.filter(
    (option) => option.value === i18n.language
  )
  const matchingLocales = ocrLanguageConfigOptionsList.filter(
    (option) => option.value === `${i18n.language}-${i18n.country}`
  )
  if (matchingLocales.length > 0) {
    return matchingLocales[0].value
  }
  if (matchingLanguages.length > 0) {
    return matchingLanguages[0].value
  } else {
    return 'en-US'
  }
}

export const getPrinterDefaultOcrLanguage = async (deviceUuid: string) => {
  const userStateFromDynamo: UserStates = await getUserStates()

  const preferences = userStateFromDynamo.printersPreferences ?? []
  const existingPreferencesIndex = preferences.findIndex(
    (printer) => printer.uuid === deviceUuid
  )
  const configuredLanguage =
    existingPreferencesIndex >= 0
      ? preferences[existingPreferencesIndex].defaultSettings?.ocrLanguage
      : undefined

  return configuredLanguage
}

export const resolveTwoSidedDetail = (value: string) =>
  ({
    'one-sided': 'OneSidedButton',
    'two-sided-short-edge': 'TwoSidedShortEdgeButton',
    'two-sided-long-edge': 'TwoSidedLongEdgeButton'
  })[value]

export const resolveProviderDetail = (type: string) =>
  ({
    googledrive: 'GDriveButton',
    onedrive: 'OneDriveButton',
    dropbox: 'DropBoxButton',
    box: 'BoxButton',
    quickbooksonline: 'QuickBooksButton',
    evernote: 'EvernoteButton'
  })[type]

export const resolveAccessTimeDetail = (value: string) =>
  ({
    year: 'OneYearButton',
    day: 'OneDayButton',
    custom: 'CustomButton',
    off: 'AccessOffButton'
  })[value]
export const resolveFileTypeDetail = (fileType: string) =>
  ({
    jpeg: 'ImageButton',
    standardpdf: 'StandardPdfButton',
    searchablepdf: 'SearchablePdfButton',
    docx: 'DocxButton',
    txt: 'PlainTxtButton'
  })[fileType]

export const resolveAccountSaveDestination = (nameService: string) =>
  ({
    dropbox: 'DropBoxSetupButton',
    box: 'BoxSetupButton',
    quickbooksonline: 'QuickBooksOnlineSetupButton',
    evernote: 'EvernoteSetupButton',
    googledrive: 'GdriveSetupButton',
    onedrive: 'OneDriveSetupButton'
  })[nameService]

export const resolveConnectorName = (connectorsList: Connector[]) => {
  let connectorRealName = `Account-1`
  let newNameFound = false
  let count = 1

  while (!newNameFound) {
    // eslint-disable-next-line no-loop-func
    const matches = connectorsList.filter((connector: Connector) => {
      return connector.connectorName === connectorRealName
    })
    if (matches.length) {
      connectorRealName = `Account-${count++}`
    } else {
      newNameFound = true
    }
  }

  return connectorRealName
}

export const resolveFolderLabel = (
  folderId: string,
  connectorType: string,
  folderName: string = ''
) => {
  const folderLabel = folderId.includes('root')
    ? resolveRepositoryType(connectorType)
    : folderName

  return folderLabel
}

export const getTenantIdFromToken = (token: AccessToken) => {
  const tokenObj = JSON.parse(atob(token.tokenValue.split('.')[1]))

  // this flag check if the token is a Stratus token
  if (tokenObj.stratus_id) {
    return token.tenantId ?? tokenObj.tenant_id
    //this flag check if the token is a OneCloud token
  } else if (tokenObj.externalOrgRefIDs) {
    return tokenObj.externalOrgRefIDs[0].value
  } else {
    console.error('Unrecognized token:', token)
    throw new Error('Token type not recognized')
  }
}
