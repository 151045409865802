const oldBrowserCopyToClipboard = (text: string) => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    const successful = document.execCommand('copy')
    return successful
  } finally {
    document.body.removeChild(textArea)
  }
}

const newBrowserCopyToClipboard = async (text: string) => {
  try {
    return await navigator.clipboard.writeText(text)
  } catch (error) {
    if (!oldBrowserCopyToClipboard(text)) {
      console.error('Does not have old browser', error)
      throw error
    }
  }
}

export const copyToClipboard = async (text: string): Promise<boolean> => {
  if (!navigator.clipboard) {
    return oldBrowserCopyToClipboard(text)
  }
  await newBrowserCopyToClipboard(text)
  return true
}
