// eslint-disable-next-line no-restricted-imports
import { WhenJWebReady } from '@jarvis/jweb-core'
import { useEffect, useState } from 'react'
import tokens from '@veneer/tokens'
import { ensureVisualViewPort } from '../../utils/polyfills/visualViewport'

export const DESKTOP_WIDTH_BREAKPOINT = parseInt(
  tokens.mdMin.substring(0, tokens.mdMin.length - 'px'.length)
)

const visualViewport = ensureVisualViewPort()
const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    const checkIsDesktop = async () => {
      try {
        const jweb = await WhenJWebReady
        const getInfo = await jweb.Plugins.Device.getInfo()
        const osVersion = getInfo.osVersion
        const buildPlatform = jweb.JWeb.getPlatform()
        const devicePlatform = getInfo.platform.toString().toLowerCase()

        const isCatalyst = () => {
          const osVersionParts = osVersion.match(/\d+/g)
          if (osVersionParts === null) return buildPlatform !== devicePlatform

          const osVersionMajor = parseInt(osVersionParts[0])
          const osVersionMinor = parseInt(osVersionParts[1])

          return (
            osVersionMajor >= 11 ||
            (osVersionMajor === 10 && osVersionMinor >= 15)
          )
        }

        let desktopView = true
        switch (devicePlatform) {
          case 'ios':
          case 'android':
            desktopView = false
            break
          case 'mac':
            desktopView = isCatalyst()
            break
          case 'windows':
            desktopView = true
            break
          case 'web':
            /* istanbul ignore next */
            if (process.env.NODE_ENV === 'development') {
              if (visualViewport)
                desktopView = visualViewport.width > DESKTOP_WIDTH_BREAKPOINT
            }
            break
          default:
            desktopView = true
        }
        setIsDesktop(desktopView)
      } catch (error) {
        console.error('Error while checking if device is desktop:', error)
        setIsDesktop(true)
      }
    }

    checkIsDesktop()
  }, [])
  return isDesktop
}

export default useIsDesktop
