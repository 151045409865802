import styled from 'styled-components'

export const MainContainer = styled.div`
  .clientos-ui-toolkit-generic-card__content__header {
    padding: 32px 32px 0 32px !important;
    .clientos-ui-toolkit-generic-card__content__header__title {
      line-height: 28px !important;
    }
  }
  .clientos-ui-toolkit-generic-card__content__body {
    padding: 32px 0 32px 32px !important;
    .clientos-ui-toolkit-list-container__label {
      color: #191a22 !important;
      font-weight: 500 !important;
    }
    .clientos-ui-toolkit-scrollbar__content {
      padding-right: 32px !important;
    }
  }
`
