import React from 'react'
import { TextField } from '@clientos/ui-toolkit'

type ShortcutNameProps = {
  fieldLabel: string
  duplicateNameWarningText?: string
  value: string
  onChange: { (value: string): void }
  errorMessage: string
  desktopMode: boolean
}

const ShortcutName = (props: ShortcutNameProps) => {
  return (
    <TextField
      id="shortcut-name-box"
      name="shortcut-name-box"
      required={true}
      label={props.fieldLabel}
      value={props.value}
      onChange={props.onChange}
      maxLength={255}
      error={props.errorMessage.length > 0}
      supportiveText={props.errorMessage || props.duplicateNameWarningText}
      helperTextVisibility={
        props.errorMessage || props.duplicateNameWarningText
          ? 'visible'
          : 'auto'
      }
    />
  )
}

export default ShortcutName
