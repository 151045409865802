// eslint-disable-next-line no-restricted-imports
import { JWebPromise } from '@jarvis/jweb-core'
import { useState } from 'react'
import JWeb from '../../services/JWeb'
export default function useJWeb(): JWebPromise | undefined {
  const [jWebState, setJWeb] = useState<JWebPromise | undefined>(undefined)
  const listener = async () => {
    try {
      await JWeb.getInstance().then((jWeb) => {
        setJWeb(jWeb)
      })
    } catch (error) {
      console.info('Error while getting JWeb instance', error)
    }
  }
  listener()

  return jWebState
}
