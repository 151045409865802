import bg_BG from './bg_BG.json'
import cs_CZ from './cs_CZ.json'
import da_DK from './da_DK.json'
import de_DE from './de_DE.json'
import el_GR from './el_GR.json'
import en_GB from './en_GB.json'
import en_US from './en_US.json'
import es_ES from './es_ES.json'
import et_EE from './et_EE.json'
import fi_FI from './fi_FI.json'
import fr_CA from './fr_CA.json'
import fr_FR from './fr_FR.json'
import hr_HR from './hr_HR.json'
import hu_HU from './hu_HU.json'
import it_IT from './it_IT.json'
import ja_JP from './ja_JP.json'
import ko_KR from './ko_KR.json'
import lt_LT from './lt_LT.json'
import lv_LV from './lv_LV.json'
import nb_NO from './nb_NO.json'
import nl_NL from './nl_NL.json'
import pl_PL from './pl_PL.json'
import pt_BR from './pt_BR.json'
import pt_PT from './pt_PT.json'
import ro_RO from './ro_RO.json'
import ru_RU from './ru_RU.json'
import sk_SK from './sk_SK.json'
import sl_SI from './sl_SI.json'
import sv_SE from './sv_SE.json'
import tr_TR from './tr_TR.json'
import zh_CN from './zh_CN.json'
import zh_HK from './zh_HK.json'
import zh_TW from './zh_TW.json'

/**
 * Languages/locales with distinct databases. Others should fallback to these.
 * Ordered first by ISO639-1 language code, then by order of preference.
 */
const Locales = {
  bg_BG,
  cs_CZ,
  da_DK,
  de_DE,
  el_GR,
  en_US,
  en_GB,
  es_ES,
  et_EE,
  fi_FI,
  fr_FR,
  fr_CA,
  hr_HR,
  it_IT,
  ja_JP,
  hu_HU,
  ko_KR,
  lt_LT,
  lv_LV,
  nb_NO,
  nl_NL,
  pl_PL,
  pt_PT,
  pt_BR,
  ro_RO,
  ru_RU,
  sk_SK,
  sl_SI,
  sv_SE,
  tr_TR,
  zh_CN,
  zh_HK,
  zh_TW
}

const SpecificLocales: { [key: string]: string } = {
  zh_MO: 'zh_TW'
}

const SupportedLanguages = [
  'en',
  'de',
  'bg',
  'hr',
  'cs',
  'da',
  'nl',
  'et',
  'fi',
  'fr',
  'el',
  'hu',
  'it',
  'ja',
  'lv',
  'lt',
  'pl',
  'pt',
  'ro',
  'sk',
  'sl',
  'es',
  'sv',
  'zh',
  'ko',
  'ru',
  'tr',
  'nb'
]

export { Locales, SpecificLocales, SupportedLanguages }
