import styled from 'styled-components'

export const Container = styled.div<{ position?: string }>`
  ${(props) =>
    props.position ? `position: ${props.position}` : 'position: absolute;'}
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default Container
