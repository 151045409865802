import Config from '../../config'
import type { AxiosResponse } from 'axios'
import { DeviceSession } from './Type'
import { JarvisWebHttpClient } from '@jarvis/web-http'
import { authProvider } from 'src/plugins/jweb/Auth'

const client: JarvisWebHttpClient = new JarvisWebHttpClient({
  defaultBaseURL: Config.SHORTCUTS_MANAGER_URL,
  defaultAuthProvider: authProvider,
  defaultRetries: 0
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSessionByDeviceUuid = async (deviceUuid: string) => {
  try {
    const result: AxiosResponse = await client.get({
      url: `/devicesessions/${deviceUuid}`,
      timeout: 30000
    })

    return result.data
  } catch (error) {
    console.error(error)
    return null
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createSession = async (deviceSession: DeviceSession) => {
  try {
    const result: AxiosResponse = await client.post({
      url: '/devicesessions',
      data: deviceSession,
      timeout: 30000
    })

    return result.data
  } catch (error) {
    throw new Error(
      'Unable to create the session. Please try again. If the problem persists, contact HP Support.'
    )
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteSession = async (deviceUuid: string) => {
  try {
    const result: AxiosResponse = await client.delete({
      url: `/devicesessions/${deviceUuid}`,
      timeout: 30000
    })

    return result.data
  } catch (error) {
    throw new Error(
      'Unable to delete the session. Please try again. If the problem persists, contact HP Support.'
    )
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deviceLookup = async (deviceUuid: string) => {
  try {
    const result: AxiosResponse = await client.get({
      url: `/deviceinfo/${deviceUuid}`,
      timeout: 30000
    })

    return result.data
  } catch (error) {
    console.error(error)
    return null
  }
}
