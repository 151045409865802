import { DeviceInfo } from '../../data/schemas/devices'

export const initialState: DeviceInfo = {
  cloudId: '',
  supportsSession: false,
  modelName: '',
  modelImage: '',
  supportsScanDestinations: false,
  allowSessions: false
}

export const deviceReducer = (
  state: DeviceInfo,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case 'cloudId':
      return {
        ...state,
        cloudId: action.payload
      }
    case 'supportsSession':
      return {
        ...state,
        supportsSession: action.payload
      }
    case 'modelName':
      return {
        ...state,
        modelName: action.payload
      }
    case 'modelImage':
      return {
        ...state,
        modelImage: action.payload
      }
    case 'allowSessions':
      return {
        ...state,
        allowSessions: action.payload
      }
    case 'supportsScanDestinations':
      return {
        ...state,
        supportsScanDestinations: action.payload
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
