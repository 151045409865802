import { Coachmark, OptionInterface, useToast } from '@veneer/core'
import React, { Ref, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Images from '../../assets/images'
import { i18n } from '../../assets/locale'
import {
  createShortcut,
  getCloudStoragesList,
  getShortcuts,
  getUserStates,
  updateShortcut
} from '../../clients/shortcuts'
import Card from '../../components/Card'
import CustomFooter from '../../components/CustomFooter'
import Header from '../../components/Header'
import InlineAlert from '../../components/InlineNotification'
import Loader from '../../components/Loader'
import ModalDelete from '../../components/ModalDelete'
import ModalShortcutsLimit from '../../components/ModalShortcutsLimit'
import { useAnimationContext } from '../../contexts/animations/AnimationContext'
import { useDestinationsContext } from '../../contexts/destinations/DestinationsContext'
import { useDeviceContext } from '../../contexts/devices/DeviceContext'
import { useEditContext } from '../../contexts/edit/EditContext'
import { useSettingsContext } from '../../contexts/settings/SettingsContext'
import { useToggleContext } from '../../contexts/toggle/ToggleContext'
import { useUserContext } from '../../contexts/users/UserContext'
import { listShortcutsCoachmarks } from '../../data/enum/coachmarks'
import { colors } from '../../data/enum/colors'
import { getShortcutOptions } from '../../data/enum/menuOptions'
import { PageProps } from '../../data/schemas/app'
import { listShortcutsCoachmarkType } from '../../data/schemas/coachmarks'
import { Connector } from '../../data/schemas/connector'
import { ConnectorFolders } from '../../data/schemas/folders'
import { ShortcutItem } from '../../data/schemas/shortcut'
import { UserStates } from '../../data/schemas/userStates'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import { toastMessages } from '../../modules/common/helpers'
import { isValidShortcutTitle } from '../../modules/destinations/helpers'
import { getDeviceSessionByDeviceId } from '../../modules/devices/helpers'
import {
  getActionsFromData,
  handleFolderNames,
  isEmailType,
  isPrintType,
  isSaveType,
  prebuiltEmailToMe,
  resolveProviderIcon,
  resolveRepositoryObject
} from '../../modules/shortcuts/helpers'
import { sendUiEvent, uiData } from '../../plugins/jweb/DataCollection'
import { dispatchEvent } from '../../plugins/jweb/EventService'
import {
  Container,
  DoneButtonContainer,
  MainContainer,
  MainListContainer,
  NoShortcutsContainer,
  NoShortcutsText,
  NoShortcutsTitle,
  ShortcutsContainer,
  StyledEndContent
} from './styles'
import {
  ListContainer,
  IconPlus,
  IconEnvelope,
  IconArrowRight,
  IconSmartTasks,
  IconPrinter,
  Button,
  IconPencil,
  IconTrash,
  CustomImage,
  ButtonGroup,
  GenericCard
} from '@clientos/ui-toolkit'
import { ListItemProps } from '@clientos/ui-toolkit/dist/types/components/list-item/types'
import PageTemplate from '../PageTemplate'
import MobileScroll from 'src/components/MobileScroll'

export const NoShortcutsMessage = () => {
  return (
    <NoShortcutsContainer id="no-shortcuts-container">
      <img
        src={Images.smartTasks}
        alt=""
      />
      <NoShortcutsTitle id="no-shortcuts-title">
        {i18n.t('pages.ListShortcuts.noShortcutsTitle')}
      </NoShortcutsTitle>
      <NoShortcutsText id="no-shortcuts-message">
        {i18n.t('pages.ListShortcuts.noShortcutsMessage')}
      </NoShortcutsText>
    </NoShortcutsContainer>
  )
}

const ListShortcuts = ({ base, props }: PageProps) => {
  const history = useHistory()
  const { addToast } = useToast()
  const deviceUuid = props.selectedPrinterUuid

  const { setAnimationDirection } = useAnimationContext()
  const { destinationsActions, destinationsState } = useDestinationsContext()
  const { deviceState } = useDeviceContext()
  const { editActions } = useEditContext()
  const { settingsActions } = useSettingsContext()
  const { userContextState } = useUserContext()
  const { toggleActions } = useToggleContext()

  const [shortcuts, setShortcuts] = useState<ShortcutItem[]>([])
  const [remainingDays, setRemainingDays] = useState<number>(0)
  const [shortcutsLimit, setShortcutsLimit] = useState<number>(-1)
  const [accessStatus, setAccessStatus] = useState<string>('')

  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalLimit, setShowModalLimit] = useState(false)
  const [loadingPrebuilts, setPrebuiltsLoading] = useState(false)
  const [loadingShortcuts, setShortcutsLoading] = useState(false)
  const [shortcutsChecked, setShortcutsChecked] = useState(false)
  const [fetchShortcutsFailed, setFetchShortcutsFailed] = useState(false)
  const [activeCoachmarkStep, setCoachmarkActiveStep] = useState<number>()
  const [editMode, setEditMode] = useState<boolean>(false)

  const handleCoachmarkChange = (
    _event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => setCoachmarkActiveStep(index)

  const firstShortcutCoachmark = useRef()
  const editShortcutCoachmark = useRef()
  const checkPopularCoachmark = useRef()

  const coachmarkState: listShortcutsCoachmarkType = {
    0: {
      anchorElement: firstShortcutCoachmark.current,
      placement: 'bottom'
    },
    1: {
      anchorElement: editShortcutCoachmark.current,
      placement: 'top-end'
    },
    2: {
      anchorElement: checkPopularCoachmark.current,
      placement: 'top'
    }
  }

  const coachmark = activeCoachmarkStep
    ? coachmarkState[activeCoachmarkStep]
    : coachmarkState[0]

  const shortcutsOptions = [
    {
      start: (
        <IconPlus
          filled={true}
          className="rounded-icon"
          disabled={editMode}
          customStyle={{
            backgroundColor: '#4759F5'
          }}
        />
      ),
      // TODO: Replace when batch translation is done: i18n.t('pages.ListShortcuts.addNewShortcutButton')
      label: 'Add new Shortcut',
      end: editMode ? '' : <IconArrowRight />,
      alignStart: 'center',
      alignEnd: 'center',
      paddingStart: false,
      paddingEnd: false,
      disabled: editMode,
      onClick: () => {
        onAddButtonPress()
      }
    }
  ]

  const handleNavigationToAccessShortcuts = () => {
    setAnimationDirection('right')
    history.push(`${base}/access_shortcuts`, {
      session: {
        context: {
          from: `${base}/list`
        }
      }
    })
  }

  const onSettingsButtonPress = () => {
    setAnimationDirection('right')
    history.push(`${base}/printer_settings`, {
      session: {
        context: {
          from: `${base}/list`,
          rootPath: 'ListShortcuts'
        }
      }
    })
  }

  const onEnableButtonPress = () => {
    handleNavigationToAccessShortcuts()
  }

  const onBackButtonPress = () => {
    setAnimationDirection('left')
    dispatchEvent('com.hp.jarvis.servicerouting.serviceinstance.closed')
    dispatchEvent('Close')
  }

  const onAddButtonPress = () => {
    if (shortcutsLimit > 0 && shortcuts.length >= shortcutsLimit) {
      setShowModalLimit(true)
    } else {
      setAnimationDirection('right')
      sendUiEvent(uiData.btnAddShortcut)
      history.push(`${base}/add`)
    }
  }

  const onCloseModal = (id: string | undefined) => {
    if (typeof id === 'string') {
      const filteredShortcuts = shortcuts.filter(
        (shortcut) => shortcut.id !== id
      )
      sessionStorage.setItem(
        'emptyShortcutsList',
        `${filteredShortcuts.length === 0}`
      )
      setShortcuts(filteredShortcuts)
    }

    setShowModalDelete(!showModalDelete)
    editActions.resetState()
  }

  const onConfirmLimit = () => {
    setShowModalLimit(false)
  }

  const handleContextualMenuOptionClick = async (
    option: OptionInterface,
    shortcut: ShortcutItem
  ) => {
    const { value } = option

    switch (value) {
      case 'start':
        if (option.label) {
          sendUiEvent(uiData.btnStartShortcut)
        } else {
          sendUiEvent({
            ...uiData.executeShortcutFromCard,
            controlDetail: `${shortcut.title
              ?.replace(/ /g, '')
              .toLocaleLowerCase()}TitleClicked`
          })
        }
        dispatchEvent('ExecuteShortcut', { shortcutDefinition: shortcut })
        break
      case 'edit':
        setShortcutsLoading(true)
        await addCurrentShortcutIntoContext(shortcut)
        updateEditContext(shortcut)
        setAnimationDirection('right')
        history.push(`${base}/destinations`)
        break
      case 'delete':
        editActions.setUpdateId(shortcut.id!)
        setShowModalDelete(!showModalDelete)
        sendUiEvent(uiData.modalDeleteShortcut)
        break
    }
  }

  const addCurrentShortcutIntoContext = async (shortcut: ShortcutItem) => {
    settingsActions.updateJobName(shortcut.smartTask.jobName)
    if (shortcut.smartTask.fileType) {
      shortcut.smartTask.fileType === 'pdf'
        ? settingsActions.updateFileType('standardpdf')
        : settingsActions.updateFileType(shortcut.smartTask.fileType)
    }
    if (shortcut.smartTask.quickrun) {
      settingsActions.updateQuickRun(shortcut.smartTask.quickrun)
    }
    if (shortcut.smartTask.relativeFolderName) {
      settingsActions.updateRelativeFolderName(
        shortcut.smartTask.relativeFolderName
      )
    }
    if (shortcut.smartTask.presentation) {
      settingsActions.updateColor(shortcut.smartTask.presentation)
    }
    if (shortcut.smartTask.smartTaskConfig.email) {
      destinationsActions.updateEmail({
        ...shortcut.smartTask.smartTaskConfig.email,
        set: true
      })
      toggleActions.setEmailToggle(true)
    }
    if (shortcut.smartTask.smartTaskConfig.prints) {
      destinationsActions.updatePrint({
        ...shortcut.smartTask.smartTaskConfig.prints[0],
        set: true
      })
      toggleActions.setPrintToggle(true)
    }

    if (shortcut.smartTask.smartTaskConfig.repositories) {
      const connectorFolders: ConnectorFolders[] = await handleFolderNames(
        shortcut.smartTask.smartTaskConfig.repositories
      )
      destinationsActions.updateRepository(
        resolveRepositoryObject(shortcut.smartTask.smartTaskConfig.repositories)
      )
      settingsActions.updateFoldersList(connectorFolders)
      toggleActions.setSaveToggle(true)
    }
    if (shortcut.smartTask.smartTaskConfig.ocr) {
      shortcut.smartTask.smartTaskConfig.ocr.ocrOutputFileType === 'pdf'
        ? settingsActions.updateFileType('searchablepdf')
        : settingsActions.updateFileType(
            shortcut.smartTask.smartTaskConfig.ocr.ocrOutputFileType
          )
      settingsActions.updateOcrLanguage(
        shortcut.smartTask.smartTaskConfig.ocr.ocrLanguage
      )
      settingsActions.updateSmartFileName(
        shortcut.smartTask.smartTaskConfig.ocr.smartName === 'true'
      )
    }

    destinationsActions.updateCheckpointState({
      email: shortcut.smartTask.smartTaskConfig.email
        ? {
            ...shortcut.smartTask.smartTaskConfig.email,
            set: true
          }
        : destinationsState.currentState.email,
      print: shortcut.smartTask.smartTaskConfig.prints
        ? {
            ...shortcut.smartTask.smartTaskConfig.prints[0],
            set: true
          }
        : destinationsState.currentState.print,
      repositories: shortcut.smartTask.smartTaskConfig.repositories
        ? resolveRepositoryObject(
            shortcut.smartTask.smartTaskConfig.repositories
          )
        : destinationsState.currentState.repositories
    })
  }

  const updateEditContext = (shortcut: ShortcutItem) => {
    editActions.setUpdateId(shortcut.id!)
    editActions.setEditMode(true)
  }

  const resolveShortcutIcon = (shortcut: ShortcutItem) => {
    const smartTaskConfig = shortcut.smartTask?.smartTaskConfig
    if (isEmailType(smartTaskConfig)) {
      return (
        <IconEnvelope
          filled={true}
          className="rounded-icon"
          customStyle={{
            backgroundColor: shortcut.smartTask.presentation?.color ?? colors[0]
          }}
        />
      )
    } else if (isPrintType(smartTaskConfig)) {
      return (
        <IconPrinter
          filled={true}
          className="rounded-icon"
          customStyle={{
            backgroundColor: shortcut.smartTask.presentation?.color ?? colors[0]
          }}
        />
      )
    } else if (isSaveType(smartTaskConfig)) {
      const iconPath = resolveProviderIcon(smartTaskConfig.repositories)
      if (iconPath) {
        return (
          <CustomImage
            className="rounded-icon"
            src={iconPath}
            customStyle={{
              backgroundColor:
                shortcut.smartTask.presentation?.color ?? colors[0]
            }}
          />
        )
      }
    }

    return (
      <IconSmartTasks
        filled={true}
        className="rounded-icon"
        customStyle={{
          backgroundColor: shortcut.smartTask.presentation?.color ?? colors[0]
        }}
      />
    )
  }

  const renderEditModeActions = (shortcut: ShortcutItem) => {
    return (
      <StyledEndContent>
        <Button
          size="medium"
          type="ghost"
          icon={<IconPencil />}
          onClick={async () => {
            setShortcutsLoading(true)
            await addCurrentShortcutIntoContext(shortcut)
            updateEditContext(shortcut)
            setAnimationDirection('right')
            history.push(`${base}/destinations`)
          }}
        ></Button>
        <Button
          size="medium"
          type="ghost"
          icon={<IconTrash />}
          onClick={() => {
            editActions.setUpdateId(shortcut.id!)
            setShowModalDelete(!showModalDelete)
            sendUiEvent(uiData.modalDeleteShortcut)
          }}
        ></Button>
      </StyledEndContent>
    )
  }

  useEffect(() => {
    const verifySessionStatus = async () => {
      try {
        if (deviceUuid && deviceState.supportsSession) {
          const sessionStatus = await getDeviceSessionByDeviceId(deviceUuid)
          if (sessionStatus.isActive && !sessionStatus.isExpired) {
            if (sessionStatus.remainingDays! <= 30) {
              sendUiEvent(uiData.inlineNotificationExpiring)
              setAccessStatus('expiring')
              setRemainingDays(sessionStatus.remainingDays!)
            }
          } else if (sessionStatus.isActive && sessionStatus.isExpired) {
            setAccessStatus('expired')
          } else {
            setAccessStatus('noAccess')
          }
        }
      } catch (e) {
        console.error(
          `Exception while getting printer data for deviceUuid: ${deviceUuid}`
        )
      }
    }

    verifySessionStatus()
  }, [deviceState.supportsSession, deviceUuid])

  useEffect(() => {
    const createPrebuilts = async (prebuilts: string[]) => {
      let shortcuts: ShortcutItem[] = []

      for (const prebuilt of prebuilts) {
        if (prebuilt === 'EMAIL_TO_ME') {
          const { emailAddress, givenName } = userContextState
          const shortcut = await createShortcut(
            prebuiltEmailToMe(emailAddress, givenName)
          )
          sendUiEvent(uiData.systemShortcutAdded)
          shortcuts = [shortcut, ...shortcuts]
        }
      }

      return shortcuts
    }

    const jobNameNeedUpdate = (shortcuts: ShortcutItem[]) => {
      return shortcuts.filter(
        (shortcut) => !isValidShortcutTitle(shortcut.smartTask.jobName)
      )
    }

    const replaceSpecialChars = (str: string) => {
      return str
        .replace(/[%#/:+=~|()[\]\\*?"@!&$<>{}^`´]/g, '_')
        .replace(/(^[.])|([.]$)/g, '')
    }

    const shortcutsNeedUpgrade = (shortcuts: ShortcutItem[]) => {
      return shortcuts.some((shortcut) =>
        shortcut.smartTask.smartTaskConfig?.repositories?.some(
          (repository) => !repository.connectorName
        )
      )
    }

    const relativeFolderNameNeedUpdate = (shortcuts: ShortcutItem[]) => {
      return shortcuts.filter(
        (shortcut) =>
          !isValidShortcutTitle(shortcut.smartTask.relativeFolderName!)
      )
    }

    const capabilitiesNeedUpgrade = (capabilities: Connector[]) => {
      return capabilities.some(
        (capability) => capability.isAuthorized && !capability.connectorName
      )
    }

    const fetchData = async () => {
      if (!shortcutsChecked) {
        try {
          setShortcutsLoading(true)

          let shortcuts: ShortcutItem[] = await getShortcuts()
          const { repositories } = await getCloudStoragesList()
          const capabilities: Connector[] = repositories

          const jobNameUpdate = jobNameNeedUpdate(shortcuts)
          if (jobNameUpdate.length) {
            jobNameUpdate.map(async (shortcut) => {
              shortcut.smartTask.jobName = replaceSpecialChars(
                shortcut.smartTask.jobName
              )
              await updateShortcut(shortcut, shortcut.id!)
            })
          }

          const relativeFolderNameUpdate =
            relativeFolderNameNeedUpdate(shortcuts)
          if (relativeFolderNameUpdate.length) {
            relativeFolderNameUpdate.map(async (shortcut) => {
              shortcut.smartTask.relativeFolderName = replaceSpecialChars(
                shortcut.smartTask.relativeFolderName!
              )
              await updateShortcut(shortcut, shortcut.id!)
            })
          }

          sessionStorage.setItem(
            'emptyShortcutsList',
            `${shortcuts.length === 0}`
          )

          if (
            shortcutsNeedUpgrade(shortcuts) ||
            capabilitiesNeedUpgrade(capabilities)
          ) {
            setAnimationDirection('right')
            history.push(`${base}/upgrade`, { shortcuts: shortcuts })
          } else {
            const userStates: UserStates = await getUserStates()

            let prebuilts: string[] = []
            if (shortcuts.length === 0 && userStates.prebuilts) {
              // remove prebuilts that have already been created
              prebuilts = Object.keys(userStates.prebuilts).filter(
                (key) => !userStates.prebuilts![key]
              )

              if (prebuilts.length > 0) {
                setShortcutsLoading(false)
                setPrebuiltsLoading(true)
                const prebuiltsCreated = await createPrebuilts(prebuilts)
                shortcuts = [...prebuiltsCreated, ...shortcuts]
                setPrebuiltsLoading(false)
                setCoachmarkActiveStep(0)
              }
            }

            setShortcutsLimit(userStates.shortcutsLimit || -1)
          }

          setShortcuts(shortcuts)
          setShortcutsChecked(true)
          setShortcutsLoading(false)
        } catch (e) {
          setPrebuiltsLoading(false)
          setShortcutsLoading(false)
          setFetchShortcutsFailed(true)
          addToast?.({
            type: 'negative',
            ...toastMessages('fetchShortcutsError'),
            timeout: 300,
            action: (
              <a
                href={window.location.href}
                onClick={() => window.location.reload()}
              >
                {i18n.t('pages.ListShortcuts.updatePageMessage')}
              </a>
            )
          })
        }
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useNativeBackButton(onBackButtonPress)

  const isHpx = true

  const CardContent = () => {
    return (
      <>
        {accessStatus &&
          deviceState.modelName &&
          deviceState.allowSessions &&
          sessionStorage.getItem('deviceUuid') === null && (
            <InlineAlert
              accessStatus={accessStatus}
              daysRemaining={Math.round(remainingDays)}
              printerName={deviceState.modelName}
              onClick={onEnableButtonPress}
            />
          )}
        <MainListContainer>
          <ListContainer
            items={
              loadingShortcuts
                ? []
                : (shortcutsOptions.concat(
                    shortcuts.map((s) => ({
                      start: resolveShortcutIcon(s),
                      label: s.smartTask.jobName,
                      end: editMode ? (
                        renderEditModeActions(s)
                      ) : (
                        <IconArrowRight />
                      ),
                      alignStart: 'center',
                      alignEnd: 'center',
                      paddingStart: false,
                      paddingEnd: false,
                      disabled: false,
                      onClick: () => {
                        if (!editMode) {
                          sendUiEvent({
                            ...uiData.executeShortcutFromCard,
                            controlDetail: `${s.title
                              ?.replace(/ /g, '')
                              .toLocaleLowerCase()}TitleClicked`
                          })
                          dispatchEvent('ExecuteShortcut', {
                            shortcutDefinition: s
                          })
                        }
                      }
                    }))
                  ) as ListItemProps[])
            }
          />
        </MainListContainer>
        {editMode && props.desktopMode && (
          <DoneButtonContainer>
            <Button
              disabled={false}
              type={'secondary'}
              size="large"
              onClick={() => setEditMode(false)}
            >
              {/* TODO: Replace when batch translation is done: i18n.t('pages.ShortcutSaved.doneButtonHPX') */}
              Done
            </Button>
          </DoneButtonContainer>
        )}
      </>
    )
  }

  return loadingShortcuts ? (
    <Loader />
  ) : !isHpx ? (
    <MainContainer id="main-container-list-shortcuts">
      <Header
        title={i18n.t('pages.ListShortcuts.header')}
        backButtonLabel={'back'}
        iconRight={['settings']}
        onPressIconLeft={onBackButtonPress}
        onPressIconRight={[onSettingsButtonPress]}
      />
      <Container
        id="container-list-shortcuts"
        desktopMode={props.desktopMode}
      >
        {accessStatus &&
          deviceState.modelName &&
          deviceState.allowSessions &&
          sessionStorage.getItem('deviceUuid') === null && (
            <InlineAlert
              accessStatus={accessStatus}
              daysRemaining={Math.round(remainingDays)}
              printerName={deviceState.modelName}
              onClick={onEnableButtonPress}
            />
          )}
        <ShortcutsContainer
          id="shortcuts-container-list-shortcuts"
          desktopMode={props.desktopMode}
        >
          {shortcuts.map((s) => (
            <Card
              key={s.id}
              title={s.smartTask.jobName}
              contextualMenuOptions={getShortcutOptions()}
              onCardClick={() =>
                handleContextualMenuOptionClick(
                  { value: 'start' } as OptionInterface,
                  s
                )
              }
              onContextualMenuOptionClick={(option) => {
                handleContextualMenuOptionClick(option, s)
              }}
              actions={getActionsFromData(s)}
              color={s.smartTask.presentation?.color ?? colors[0]}
              desktopMode={props.desktopMode}
              editCoachmarkRef={
                editShortcutCoachmark as unknown as Ref<HTMLButtonElement>
              }
              firstShortcutCoachmarkRef={
                firstShortcutCoachmark as unknown as Ref<HTMLElement>
              }
            />
          ))}
        </ShortcutsContainer>
        {shortcuts.length === 0 && shortcutsChecked && (
          <NoShortcutsMessage {...base} />
        )}
      </Container>
      {loadingPrebuilts && (
        <Loader message={i18n.t('pages.ListShortcuts.prebuilt.loading')} />
      )}
      {!(loadingShortcuts || loadingPrebuilts) && (
        <CustomFooter
          desktopMode={props.desktopMode}
          ref={checkPopularCoachmark as unknown as Ref<HTMLElement>}
          buttons={[
            {
              id: 'add-shortcut-btn',
              text: i18n.t('pages.ListShortcuts.button'),
              onClick: onAddButtonPress,
              disabled: fetchShortcutsFailed
            }
          ]}
        />
      )}
      <ModalDelete
        show={showModalDelete}
        onClose={onCloseModal}
      />
      <ModalShortcutsLimit
        show={showModalLimit}
        onConfirm={onConfirmLimit}
      />
      <Coachmark
        activeStep={activeCoachmarkStep}
        anchorElement={coachmark && coachmark.anchorElement}
        closeButton
        id="coachmark-pagination"
        onChange={handleCoachmarkChange}
        onClose={() => setCoachmarkActiveStep(undefined)}
        placement={coachmark ? coachmark.placement : 'top'}
        steps={listShortcutsCoachmarks()}
      />
    </MainContainer>
  ) : (
    <>
      <PageTemplate
        desktopMode={props.desktopMode}
        header={
          <Header
            iconRight={['settings']}
            onPressIconLeft={onBackButtonPress}
            onPressIconRight={[onSettingsButtonPress]}
          />
        }
        content={
          <>
            <ModalDelete
              show={showModalDelete}
              onClose={onCloseModal}
            />
            <ModalShortcutsLimit
              show={showModalLimit}
              onConfirm={onConfirmLimit}
            />
            <GenericCard>
              <GenericCard.Header
                title={i18n.t('pages.ListShortcuts.header')}
                end={
                  <Button
                    type="ghost-flush"
                    size={'medium'}
                    onClick={() => setEditMode(true)}
                    disabled={editMode}
                  >
                    {i18n.t('common.contextAction.edit')}
                  </Button>
                }
              />
              <GenericCard.Body
                paddingBodyBottom={false}
                paddingBodyTop={false}
                paddingBodyHorizontal={true}
              >
                {props.desktopMode ? (
                  CardContent()
                ) : (
                  <MobileScroll
                    editMode={editMode}
                    offset="220px"
                  >
                    {CardContent()}
                  </MobileScroll>
                )}
              </GenericCard.Body>
            </GenericCard>
          </>
        }
        mobileFooter={
          editMode &&
          !props.desktopMode && (
            <ButtonGroup
              // TODO: Replace when batch translation is done: i18n.t('pages.ShortcutSaved.doneButtonHPX')
              textPrimaryButton="Done"
              onClickPrimaryButton={() => setEditMode(false)}
              size="large"
              orientation="vertical"
            ></ButtonGroup>
          )
        }
      ></PageTemplate>
    </>
  )
}

export default React.memo(ListShortcuts)
