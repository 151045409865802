import React from 'react'
import { useHistory } from 'react-router-dom'
import { i18n } from '../../assets/locale'
import Header from '../../components/Header'
import { useAnimationContext } from '../../contexts/animations/AnimationContext'
import { useDestinationsContext } from '../../contexts/destinations/DestinationsContext'
import { useSettingsContext } from '../../contexts/settings/SettingsContext'
import { PageProps } from '../../data/schemas/app'
import { LocationState } from '../../data/schemas/location'
import { PresetTemplate, TemplateActions } from '../../data/schemas/template'
import {
  printEmailSaveTemplate,
  saveToGoogleDriveTemplate
} from '../../data/templates/creationTemplates'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import { sendUiEvent, uiData } from '../../plugins/jweb/DataCollection'
import {
  CustomImage,
  GenericCard,
  IconArrowRight,
  IconPencil,
  IconSmartTasks,
  ListContainer,
  SectionListContainer
} from '@clientos/ui-toolkit'
import Images from 'src/assets/images'
import { ListItemProps } from '@clientos/ui-toolkit/dist/types/components/list-item/types'
import { useToggleContext } from '../../contexts/toggle/ToggleContext'
import PageTemplate from '../PageTemplate'
import MobileScroll from 'src/components/MobileScroll'
import { MainListContainer, StyledSectionListContainer } from './styles'

export const AddShortcut = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()
  const { setAnimationDirection } = useAnimationContext()
  const { toggleActions } = useToggleContext()
  const { destinationsActions } = useDestinationsContext()
  const { settingsActions } = useSettingsContext()

  const onBackButtonPress = () => {
    setAnimationDirection('left')
    history.push(`${base}`)
  }

  const onClickCreateOwnShortcut = () => {
    sendUiEvent(uiData.cardCustomShortcut)
    setAnimationDirection('right')
    history.push(`${base}/destinations`)
  }

  const onClickSaveToGoogleDrive = () => {
    sendUiEvent(uiData.cardPrebuiltSaveToGoogleDrive)
    populateState(saveToGoogleDriveTemplate())
    toggleActions.setSaveToggle(true)
    history.push(
      props.desktopMode ? `${base}/destinations` : `${base}/repositories`,
      {
        template: props.desktopMode
          ? filterTemplate(saveToGoogleDriveTemplate().data)
          : saveToGoogleDriveTemplate().data
      }
    )
  }

  const onClickPrintEmailSave = () => {
    sendUiEvent(uiData.cardPrebuiltPrintSaveEmail)
    populateState(printEmailSaveTemplate())
    toggleActions.setPrintToggle(true)
    toggleActions.setEmailToggle(true)
    toggleActions.setSaveToggle(true)
    history.push(props.desktopMode ? `${base}/destinations` : `${base}/print`, {
      template: props.desktopMode
        ? filterTemplate(printEmailSaveTemplate().data)
        : printEmailSaveTemplate().data
    })
  }

  const filterTemplate = (data: TemplateActions): TemplateActions => {
    const hasTrigger = data.actions.filter((action) => action.triggerSignIn)
    if (hasTrigger.length > 0) {
      return {
        actions: [
          {
            route: 'destinations',
            triggerSignIn: true,
            signInTargetType: hasTrigger[0].signInTargetType
          }
        ],
        index: 0
      }
    } else {
      return {
        actions: [
          {
            route: 'destinations'
          }
        ],
        index: 0
      }
    }
  }

  const populateState = (template: PresetTemplate) => {
    setAnimationDirection('right')
    if (template.defaultDestinationsValues) {
      const values = template.defaultDestinationsValues
      if (values.print) {
        destinationsActions.updatePrint(values.print)
      }
      if (values.email) {
        destinationsActions.updateEmail(values.email)
      }
      if (values.repositories) {
        destinationsActions.updateRepository(values.repositories)
      }
    }
    if (template.defaultShortcutSettingsValues) {
      const values = template.defaultShortcutSettingsValues
      settingsActions.updateColor(values.presentation)
      settingsActions.updateFileType(values.fileType)
      settingsActions.updateJobName(values.jobName)
      settingsActions.updateQuickRun(values.quickRun)
      settingsActions.updateSmartFileName(values.smartFileName)
    }
  }

  const onSettingsButtonPress = () => {
    setAnimationDirection('right')
    history.push(`${base}/printer_settings`, {
      session: {
        context: {
          from: `${base}/add`,
          rootPath: 'AddShortcut'
        }
      }
    })
  }

  useNativeBackButton(onBackButtonPress)

  const defaultOption = [
    {
      start: (
        <IconPencil
          filled={true}
          className="rounded-icon"
          customStyle={{
            backgroundColor: '#4759F5'
          }}
        />
      ),
      label: i18n.t('pages.AddShortcut.addCard'),
      end: <IconArrowRight />,
      alignStart: 'center',
      alignEnd: 'center',
      paddingStart: false,
      paddingEnd: false,
      onClick: () => {
        onClickCreateOwnShortcut()
      }
    }
  ]

  const popularOptions = [
    {
      start: (
        <CustomImage
          className="rounded-icon"
          src={Images.googleDriveTile}
          customStyle={{
            backgroundColor: '#45C7FF'
          }}
        />
      ),
      label: i18n.t('pages.AddShortcut.save'),
      end: <IconArrowRight />,
      alignStart: 'center',
      alignEnd: 'center',
      paddingStart: false,
      paddingEnd: false,
      onClick: () => {
        onClickSaveToGoogleDrive()
      }
    },
    {
      start: (
        <IconSmartTasks
          filled={true}
          className="rounded-icon"
          customStyle={{
            backgroundColor: '#FE9E2E'
          }}
        />
      ),
      label: i18n.t('pages.AddShortcut.combo'),
      end: <IconArrowRight />,
      alignStart: 'center',
      alignEnd: 'center',
      paddingStart: false,
      paddingEnd: false,
      onClick: () => {
        onClickPrintEmailSave()
      }
    }
  ]
  const CardContent = () => {
    return (
      <>
        <MainListContainer>
          <ListContainer items={defaultOption as ListItemProps[]} />
        </MainListContainer>
        <StyledSectionListContainer>
          <SectionListContainer
            paddingStart={false}
            paddingEnd={false}
          >
            <SectionListContainer.Section>
              <SectionListContainer.SectionHeader
                title={i18n.t('pages.AddShortcut.popular')}
              />
              <SectionListContainer.SectionBody>
                <MainListContainer>
                  <ListContainer items={popularOptions as ListItemProps[]} />
                </MainListContainer>
              </SectionListContainer.SectionBody>
            </SectionListContainer.Section>
          </SectionListContainer>
        </StyledSectionListContainer>
      </>
    )
  }

  return (
    <>
      <PageTemplate
        desktopMode={props.desktopMode}
        header={
          <Header
            iconRight={['settings']}
            onPressIconLeft={onBackButtonPress}
            onPressIconRight={[onSettingsButtonPress]}
          />
        }
        content={
          <>
            <GenericCard>
              {/* TODO: Replace when batch translation is done: i18n.t('pages.AddShortcut.newHeader') */}
              <GenericCard.Header title={'Add new Shortcuts'} />
              <GenericCard.Body
                paddingBodyBottom={false}
                paddingBodyTop={false}
                paddingBodyHorizontal={true}
              >
                {props.desktopMode ? (
                  CardContent()
                ) : (
                  <MobileScroll offset="200px">{CardContent()}</MobileScroll>
                )}
              </GenericCard.Body>
            </GenericCard>
          </>
        }
      ></PageTemplate>
    </>
  )
}
