import { Button, List, ListItem } from '@veneer/core'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const MainContainer = styled.div`
  .clientos-ui-toolkit-list-container__description {
    font-size: 14px;
  }

  .clientos-ui-toolkit-list-container__start-content {
    gap: 0 !important;
  }
`

export const ColorList = styled(List)`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
`

export const ColorListItem = styled(ListItem)`
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0px !important;
  border-bottom: 0px !important;
  width: auto !important;

  > div:nth-child(2) {
    padding: 0px 4px 0px 0px !important;
  }
`

export const StyledListItem = styled(ListItem)`
  align-items: end !important;
`

export const ColorListCard = styled(Button)`
  && {
    background-color: ${(props) => props.color};
    padding: 15px;
    min-width: 0px !important;
    border-radius: ${tokens.layout.cornerRadius4};
  }
  &:hover {
    background-color: ${(props) => props.color} !important;
  }
`

export const ColorListSelectedCard = styled(Button)`
  && {
    background-color: ${(props) => props.color};
    border-width: 2px;
    padding: 2px !important;
    min-width: 0px !important;
    border-radius: ${tokens.layout.cornerRadius4};
    box-shadow: 0px 4px 4px 0px #00000040;
  }
  &:hover {
    background-color: ${(props) => props.color} !important;
  }

  svg {
    color: white !important;
  }
`
