let callBackRef: { (): void } | null = null

const nativeBackButtonHandler = {
  getBackButtonCallBack: () => callBackRef,
  setBackButtonCallBack: (backButtonCallBack: { (): void }) => {
    callBackRef = backButtonCallBack
  }
}

export default nativeBackButtonHandler
