export type ToggleState = {
  print: boolean
  email: boolean
  save: boolean
}

export const initialState: ToggleState = {
  print: false,
  email: false,
  save: false
}

export const toggleReducer = (
  state: ToggleState,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case 'print':
      return {
        ...state,
        print: action.payload
      }
    case 'email':
      return {
        ...state,
        email: action.payload
      }
    case 'save':
      return {
        ...state,
        save: action.payload
      }
    case 'reset':
      return {
        ...initialState
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
