import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { i18n } from '../../assets/locale'
import { getShortcutConfiguration } from '../../clients/devices'
import { getUserStates, saveUserStates } from '../../clients/shortcuts'
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import { useAnimationContext } from '../../contexts/animations/AnimationContext'
import { DeviceContext } from '../../contexts/devices/DeviceContext'
import { useSettingsContext } from '../../contexts/settings/SettingsContext'
import { PageProps } from '../../data/schemas/app'
import { LocationState } from '../../data/schemas/location'
import { ocrLanguageConfigOptionsList } from '../../data/schemas/settings'
import { UserStates } from '../../data/schemas/userStates'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import { getDeviceSessionByDeviceId } from '../../modules/devices/helpers'
import { dispatchEvent } from '../../plugins/jweb/EventService'
import PageTemplate from 'src/pages/PageTemplate'
import { PrinterSettingContent } from './styles'
import {
  GenericCard,
  IconArrowRight,
  IconChevronRight,
  ListContainer
} from '@clientos/ui-toolkit'
import MobileScroll from 'src/components/MobileScroll'
import {
  GenericCardContainerMobile,
  ScrollbarContent,
  HeaderContainerMobile
} from '../PrinterSettings/styles'

export const PrinterSettings = ({ base, props }: PageProps) => {
  const location = useLocation<LocationState>()
  const history = useHistory<LocationState>()
  const deviceModel = props.deviceModel.toLowerCase()

  const { setAnimationDirection } = useAnimationContext()
  const { settingsActions, settingsState } = useSettingsContext()
  const [userState, setUserState] = useState<UserStates>()
  const [remainingDays, setRemainingDays] = useState<number>(0)
  const [sessionActive, setSessionActive] = useState(false)
  const [showLoader, setShowLoader] = useState(true)
  const [showHeader, setShowHeader] = useState(false)
  const fromURL = location.state?.session?.context?.from || ''
  const rootPath = location.state?.session?.context?.rootPath
  const { deviceState } = useContext(DeviceContext)

  const goToAccessShortcut = () => {
    setAnimationDirection('right')
    history.push({
      pathname: `${base}/access_shortcuts`,
      state: {
        session: {
          context: {
            from: `${base}/printer_settings`,
            rootPath: rootPath
          }
        }
      }
    })
  }

  const goToScanDestinationsSettings = () => {
    setAnimationDirection('right')
    history.push({
      pathname: `${base}/scan_destinations_settings`,
      state: {
        session: {
          context: {
            from: `${base}/printer_settings`,
            rootPath: rootPath
          }
        }
      }
    })
  }

  const goToOcrSettings = () => {
    const preferences = userState?.printersPreferences ?? []
    const existingPreferencesIndex = preferences.findIndex(
      (printer) => printer.uuid === props.selectedPrinterUuid
    )
    const configuredLanguage =
      existingPreferencesIndex >= 0
        ? preferences[existingPreferencesIndex].defaultSettings?.ocrLanguage
        : undefined
    settingsActions.updateOcrLanguage(configuredLanguage ?? 'en-US')
    setAnimationDirection('right')
    history.push({
      pathname: `${base}/ocr_settings`,
      state: {
        session: {
          context: {
            rootPath: rootPath
          }
        }
      }
    })
  }

  useEffect(() => {
    if (
      !props.desktopMode ||
      rootPath === 'AccessShortcuts' ||
      rootPath === 'ListShortcuts' ||
      deviceModel.indexOf('ipad') !== -1 ||
      props.isCatalyst
    ) {
      setShowHeader(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onBackButtonHandler = () => {
    setAnimationDirection('left')
    fromURL || (!fromURL && rootPath)
      ? history.push(`${base}/list`)
      : onBackButtonPress()
  }

  const onBackButtonPress = () => {
    setAnimationDirection('left')
    dispatchEvent('com.hp.jarvis.servicerouting.serviceinstance.closed')
    dispatchEvent('Close')
  }

  const determineOcrLanguage = () => {
    const preferences = userState?.printersPreferences ?? []
    const existingPreferencesIndex = preferences.findIndex(
      (printer) => printer.uuid === props.selectedPrinterUuid
    )

    const configuredLanguage =
      existingPreferencesIndex >= 0
        ? preferences[existingPreferencesIndex].defaultSettings?.ocrLanguage
        : undefined

    if (configuredLanguage) {
      const matchingValues = ocrLanguageConfigOptionsList.filter(
        (option) => option.value === configuredLanguage
      )
      return [matchingValues[0].label]
    } else {
      return 'English (US)'
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const configuredScanDestinations = await getShortcutConfiguration(
          deviceState.cloudId
        )
        const reportedCloudShortcuts =
          configuredScanDestinations.state?.reported?.cdmData?.cloudShortcuts
        const desiredCloudShortcuts =
          configuredScanDestinations.state?.desired?.cdmData?.cloudShortcuts
        if (
          desiredCloudShortcuts === 'true' ||
          reportedCloudShortcuts === 'true'
        ) {
          settingsActions.updateScanDestinations(true)
        } else if (
          !reportedCloudShortcuts ||
          reportedCloudShortcuts === 'false'
        ) {
          settingsActions.updateScanDestinations(false)
        }
      } catch (e) {
        console.error(
          `Exception while getting printer data for deviceUuid: ${props.selectedPrinterUuid}`
        )
        console.error(e)
      } finally {
        if (deviceState.allowSessions && !remainingDays) {
          // Assuming there will always be a selected printer when accessing this page.
          const sessionStatus = await getDeviceSessionByDeviceId(
            props.selectedPrinterUuid as string
          )

          if (sessionStatus.isActive && !sessionStatus.isExpired) {
            setRemainingDays(sessionStatus.remainingDays || 0)
            setSessionActive(true)
          } else {
            setSessionActive(false)
          }
        }
      }
      try {
        if (!userState) {
          let userStateFromDynamo: UserStates = await getUserStates()

          if (settingsState.ocrLanguage) {
            const preferences = userStateFromDynamo.printersPreferences ?? []
            const existingPreferencesIndex = preferences.findIndex(
              (printer) => printer.uuid === props.selectedPrinterUuid
            )
            const configuredLanguage =
              existingPreferencesIndex >= 0
                ? preferences[existingPreferencesIndex].defaultSettings
                    ?.ocrLanguage
                : undefined
            const configsNeedUpdate = configuredLanguage
              ? configuredLanguage !== settingsState.ocrLanguage
              : true

            if (configsNeedUpdate) {
              if (existingPreferencesIndex >= 0) {
                preferences[existingPreferencesIndex] = {
                  ...preferences[existingPreferencesIndex],
                  defaultSettings: {
                    ...preferences[existingPreferencesIndex].defaultSettings,
                    ocrLanguage: settingsState.ocrLanguage
                  }
                }
              } else {
                preferences.push({
                  uuid: props.selectedPrinterUuid as string,
                  defaultSettings: { ocrLanguage: settingsState.ocrLanguage }
                })
              }

              userStateFromDynamo = await saveUserStates({
                ...userStateFromDynamo,
                printersPreferences: preferences
              })
            }
          }
          setUserState(userStateFromDynamo)
        }
      } catch (e) {
        console.error('Exception while getting or saving user states: ', e)
      }
      setShowLoader(false)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useNativeBackButton(onBackButtonHandler)

  let options = []

  options.push({
    /* TODO: Replace when batch translation is done: i18n.t('pages.PrinterSettings.ocrLanguageSettingTitleHPX') */
    label: 'OCR language',
    /* TODO: Replace when batch translation is done: i18n.t('pages.PrinterSettings.ocrLanguageSettingDescriptionHPX') */
    description:
      'Language of scanned documents to be converted to editable text',
    end: (
      <PrinterSettingContent
        id="content-scan-destinations"
        enable={false}
        onClick={() => goToOcrSettings()}
      >
        <p
          style={{ display: 'inline', whiteSpace: 'nowrap' }}
          id="configured-scan-destinations"
        >
          {determineOcrLanguage()}
        </p>
        {props.desktopMode ? (
          <IconArrowRight customStyle={{ marginLeft: '10px' }} />
        ) : (
          <IconChevronRight />
        )}
      </PrinterSettingContent>
    )
  })

  if (deviceState.allowSessions) {
    options = [
      {
        /* TODO: Replace when batch translation is done: i18n.t('pages.PrinterSettings.titleSettingPrinterHPX') */
        label: 'Printer access',
        description: i18n.t('pages.PrinterSettings.descriptionSettingPrinter'),
        end: (
          <PrinterSettingContent
            id="content-access-printer"
            enable={false}
            onClick={() => goToAccessShortcut()}
          >
            {sessionActive && remainingDays ? (
              <div>
                <p id="manage-status-access">
                  {i18n.t('pages.PrinterSettings.messageStatusAccess')}
                </p>
                <p id="days-remaining">
                  {remainingDays >= 1 ? (
                    <>
                      {i18n.t('pages.PrinterSettings.daysRemaining', {
                        daysRemaining: remainingDays
                      })}
                    </>
                  ) : (
                    <>{i18n.t('pages.PrinterSettings.lessThanOneDay')}</>
                  )}
                </p>
              </div>
            ) : (
              <p
                style={{ display: 'inline', whiteSpace: 'nowrap' }}
                id="radio-off"
              >
                {i18n.t('pages.AccessShortcuts.radioOff')}
              </p>
            )}
            {props.desktopMode ? (
              <IconArrowRight customStyle={{ marginLeft: '10px' }} />
            ) : (
              <IconChevronRight />
            )}
          </PrinterSettingContent>
        )
      },
      ...options
    ]

    if (deviceState.supportsScanDestinations) {
      options.push({
        /* TODO: Replace when batch translation is done: i18n.t('pages.PrinterSettings.scanDestinationsTitleHPX') */
        label: 'Scan destinations',
        /* TODO: Replace when batch translation is done: i18n.t('pages.PrinterSettings.scanDestinationsDescriptionHPX') */
        description:
          'Scan and send documents to multiple destinations directly from the printer control panel',
        end: settingsState.scanDestinations ? (
          <PrinterSettingContent
            id="content-scan-destinations"
            enable={true}
          >
            {i18n.t('pages.PrinterSettings.scanDestinationsStatusEnabled')}
          </PrinterSettingContent>
        ) : (
          <PrinterSettingContent
            id="content-scan-destinations"
            enable={false}
            onClick={() => goToScanDestinationsSettings()}
          >
            <p
              id="configured-scan-destinations"
              style={{ display: 'inline', whiteSpace: 'nowrap' }}
            >
              {/* TODO: Replace when batch translation is done: i18n.t('pages.PrinterSettings.scanDestinationsStatusManageHPX') */}
              {'Manage'}
            </p>
            {props.desktopMode ? (
              <IconArrowRight customStyle={{ marginLeft: '10px' }} />
            ) : (
              <IconChevronRight />
            )}
          </PrinterSettingContent>
        )
      })
    }
  }

  return showLoader ? (
    <Loader />
  ) : (
    <>
      <PageTemplate
        header={
          showHeader &&
          (props.desktopMode ? (
            <Header
              title={i18n.t('pages.PrinterSettings.headerPrinterSetting')}
              backButtonLabel={'Back'}
              onPressIconLeft={onBackButtonHandler}
            />
          ) : (
            <HeaderContainerMobile>
              <Header
                title={i18n.t('Shortcuts')}
                backButtonLabel={''}
                onPressIconLeft={onBackButtonHandler}
              />
            </HeaderContainerMobile>
          ))
        }
        content={
          <GenericCard>
            {props.desktopMode && (
              <GenericCard.Header
                title={i18n.t('pages.ListShortcuts.header')}
              />
            )}
            <ScrollbarContent>
              <GenericCard.Body
                paddingBodyBottom={false}
                paddingBodyTop={false}
                paddingBodyHorizontal={false}
              >
                {props.desktopMode ? (
                  <ListContainer items={options} />
                ) : (
                  <MobileScroll offset="200px">
                    <GenericCardContainerMobile>
                      <ListContainer items={options} />
                    </GenericCardContainerMobile>
                  </MobileScroll>
                )}
              </GenericCard.Body>
            </ScrollbarContent>
          </GenericCard>
        }
        desktopMode={props.desktopMode}
      />
    </>
  )
}
