import React, { createContext, useContext, useReducer } from 'react'
import { initialState, UserContextState, userReducer } from './UserReducer'

type UserContextActions = {
  setEntitledStatus: (isEntitled: boolean) => void
  setGivenName: (name: string) => void
  setEmailAddress: (address: string) => void
  resetState: () => void
}

type UserContextType = {
  userContextState: UserContextState
  userContextActions: UserContextActions
}

type UserContextProviderProps = { children: React.ReactNode }

const defaultContextValue: UserContextType = {
  userContextState: {} as UserContextState,
  userContextActions: {} as UserContextActions
}

export const UserContext = createContext(defaultContextValue)

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [userContextState, dispatch] = useReducer(userReducer, initialState)

  const userContextActions: UserContextActions = {
    setEntitledStatus: (isEntitled: boolean) => {
      dispatch({ type: 'entitled', payload: isEntitled })
    },
    setGivenName: (name: string) => {
      dispatch({ type: 'name', payload: name })
    },
    setEmailAddress: (address: string) => {
      dispatch({ type: 'email', payload: address })
    },
    resetState: () => {
      dispatch({ type: 'reset' })
    }
  }

  return (
    <UserContext.Provider value={{ userContextState, userContextActions }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = () => useContext(UserContext)
