import { ToastProvider } from '@veneer/core'
import React from 'react'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useAnimationContext } from './contexts/animations/AnimationContext'
import { AppProps } from './data/schemas/app'
import { AccessShortcuts } from './pages/AccessShortcuts'
import './styles/animationDirection.css'

export const JarvisDeviceSession = (props: AppProps) => {
  let { url } = useRouteMatch()
  url = url.replace(/\/$/, '')
  const location = useLocation()

  const { animationDirection } = useAnimationContext()

  return (
    <>
      <ToastProvider position="top">
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={350}
            classNames={
              !props.desktopMode
                ? animationDirection === 'right'
                  ? 'slideRight'
                  : 'slideLeft'
                : ''
            }
          >
            <Switch>
              <Route path={[`${url}/access_shortcuts`, `${url}/`]}>
                <AccessShortcuts
                  base={url}
                  props={props}
                />
              </Route>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </ToastProvider>
    </>
  )
}

export default JarvisDeviceSession
