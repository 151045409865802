import { Modal } from '@clientos/ui-toolkit'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  div.vn-modal--content {
    .modal-title {
      padding-bottom: 0px !important;
    }
  }
`
