import styled from 'styled-components'

export const ContentLoader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -90px;
`

export const ShortcutNameContainer = styled.div`
  margin-top: 24px;
  width: 50%;
`

export const MainContainerHPX = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .clientos-ui-toolkit-section-list-container__content__header {
    padding: 14px 0px 8px 0px;
  }
`

export const SettingsContainer = styled.div`
  padding-right: 8px;
`
