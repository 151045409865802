import React from 'react'
import Images from '../../../../assets/images'
import { CustomImage } from '@clientos/ui-toolkit'

type AccountProviderIconProps = {
  [key: string]: (usePadding: boolean) => JSX.Element
}

export const AccountProviderIcon: AccountProviderIconProps = {
  googledrive: (usePadding: boolean) => (
    <CustomImage
      src={Images.googleDrive}
      aria-label="googledrive"
      size="32px"
    />
  ),
  dropbox: (usePadding: boolean) => (
    <CustomImage
      src={Images.dropBox}
      aria-label="dropbox"
      size="32px"
    />
  ),
  onedrive: (usePadding: boolean) => (
    <CustomImage
      src={Images.oneDrive}
      aria-label="onedrive"
      size="32px"
    />
  ),
  box: (usePadding: boolean) => (
    <CustomImage
      src={Images.box}
      aria-label="box"
      size="32px"
    />
  ),
  evernote: (usePadding: boolean) => (
    <CustomImage
      src={Images.evernote}
      aria-label="evernote"
      size="32px"
    />
  ),
  quickbooksonline: (usePadding: boolean) => (
    <CustomImage
      src={Images.quickBooks}
      aria-label="quickbooks"
      size="32px"
    />
  )
}
