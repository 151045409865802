import { colors, Modal } from '@clientos/ui-toolkit'
import { useToast } from '@veneer/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { i18n } from 'src/assets/locale'
import ToastNotification from 'src/components/ToastNotification'
import { sendUiEvent, uiData } from 'src/plugins/jweb/DataCollection'
import { deleteJob } from 'src/services/Tenzing'
import type { RootState } from 'src/stores/'
import { updateRefreshJobsEvent } from 'src/stores/event'
import { enableDeleteModal, enableLoader } from 'src/stores/screen'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const DeleteDocumentModal = () => {
  const dispatch = useDispatch()
  const toast = useToast()

  const mobileMode: boolean = useSelector(
    (state: RootState) => state.screens.mobileMode
  )

  const deleteDocumentModalTitle: string = i18n.t(
    'application.joblist-deleteDocumentModal.title'
  )
  // TODO: Replace when batch translation is done: i18n.t('application.joblist-deleteDocumentModal.modalContent')
  const deleteDocumentModalContent: string =
    'Permanently delete this document? You can’t undo this.'
  const deleteDocumentModalDeleteButton: string = i18n.t(
    'application.document-action.label-two'
  )
  // TODO: Replace when batch translation is done: i18n.t('application.joblist-deleteDocumentModal.delete-modal-cancel-button')
  const deleteDocumentModalCancelButton: string = 'Cancel'

  useEffect(() => {
    sendUiEvent({
      ...uiData.DeleteJob.deleteJobConfirmation
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [deleteModalState, setDeleteModalState] = useState(true)

  const jobId = useSelector((state: RootState) => state.screens.currentJob)

  const deleteJobAction = async () => {
    try {
      sendUiEvent({
        ...uiData.DeleteJob.btnYes
      })
      dispatch(enableLoader(true))
      await deleteJob(jobId)
    } catch (error) {
      console.error(error)
      ToastNotification(toast)
    } finally {
      dispatch(enableLoader(false))
      dispatch(enableDeleteModal(false))
      dispatch(updateRefreshJobsEvent(true))
    }
  }

  const onCloseModal = () => {
    setDeleteModalState(false)
    dispatch(enableDeleteModal(false))
    sendUiEvent({
      ...uiData.DeleteJob.btnNo
    })
  }

  return (
    <>
      <Modal
        isOpen={deleteModalState}
        data-testid="joblist-deleteDocumentModal"
        title={deleteDocumentModalTitle}
        className="subtitle-large-medium"
        align="center"
        modalSize="small"
        buttonGroup={{
          id: 'deleteModalButtons',
          textPrimaryButton: deleteDocumentModalDeleteButton,
          textSecondaryButton: deleteDocumentModalCancelButton,
          onClickPrimaryButton: () => deleteJobAction(),
          onClickSecondaryButton: onCloseModal,
          size: 'large',
          orientation: 'vertical'
        }}
      >
        <p
          data-testid="delete-document-modal-content"
          className={mobileMode ? 'body-large' : 'body-regular'}
          style={{
            color: mobileMode && colors.inkPrimary
          }}
        >
          {deleteDocumentModalContent}
        </p>
      </Modal>
    </>
  )
}

export default DeleteDocumentModal
