import { IconChevronRight } from '@veneer/core'
import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'container'
    'singleFooter';
`

export const ContentContainer = styled.div`
  padding: 0px 7px 40px 7px;
  grid-area: 'container';
  overflow: auto;
`

export const GenericCardContainer = styled.div`
  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__description {
    max-width: 80%;
    font-size: 12px;
  }

  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__label {
    font-size: 16px;
    font-weight: 400;
  }
`

export const GenericCardContainerMobile = styled.div`
  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__description {
    max-width: 80%;
    font-size: 12px;
  }

  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__label {
    font-size: 16px;
    font-weight: 400;
  }

  .clientos-ui-toolkit-list-container
    > .clientos-ui-toolkit-list-container__divider {
    border-color: #edeefe !important;
    border-width: 1px;
    border-style: solid;
    margin-right: 20px;
    margin-left: 20px;
  }

  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__start-content {
    color: #292a35 !important;
    text-align: left;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0px !important;
  }
`

export const HeaderContainerMobile = styled.div`
  button.clientos-ui-toolkit-back-navigation-button {
    background-color: rgba(130, 131, 152, 0.2) !important;
    color: #292a35 !important;
    border-radius: 999px;
    min-height: 40px;
    min-width: 0px;
    padding: 8px 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    height: 40px;
  }
`

export const PrinterSettingContent = styled.div<{ enable: boolean }>`
  display: flex;
  flex-shrink: 0;
  ${(props) => (props.enable ? 'cursor: no-drop;' : 'cursor: pointer;')}

  svg {
    color: #bcbcbc !important;
  }
`

export const ScrollbarContent = styled.div`
  .clientos-ui-toolkit-scrollbar
    > div
    > .clientos-ui-toolkit-scrollbar__content {
    padding-right: 0px !important;
  }
`

export const StyledIconChevronRight = styled(IconChevronRight)<{ id: string }>``
