import React, { useRef } from 'react'
import { Button } from '@clientos/ui-toolkit'
import useAssetsProvider from '../../../hooks/useAssetsProvider'
import useText from '../../../hooks/useText'
import NoTextModal, { NoTextModalRef } from './NoTextModal'
import { sendTextToNextService } from '../../../services/ServiceRouter'
import useIsDesktop from '../../../hooks/useIsDesktop'
import DataCollection, { uiEvents } from '../../../services/DataCollection'
import './Footer.scss'

const DoneButton = () => {
  const text = useText()
  const isDesktop = useIsDesktop()
  const assetsProvider = useAssetsProvider()
  const noTextModalRef = useRef<NoTextModalRef>()
  const isDisabled = text == null ? true : false
  if (!assetsProvider) return null
  const getText = (subkey: string) => {
    const assetKey = `application.${subkey}`
    return assetsProvider.getText(assetKey)
  }
  const handleAnalytics = () => {
    DataCollection.sendUIEvent(uiEvents.doneButton)
  }
  const handleClick = async () => {
    if (text == null) return
    handleAnalytics()
    const textReplaced = text.trim()
    if ((!textReplaced || textReplaced === '') && noTextModalRef.current) {
      noTextModalRef.current.triggerModal()
      return
    }
    sendTextToNextService(text, 'doneButton')
  }
  return (
    <>
      {isDesktop ? (
        <div className="footer">
          <Button
            id="done-button-desktop"
            type="primary"
            size="medium"
            onClick={handleClick}
            disabled={isDisabled}
          >
            {getText('header.done')}
          </Button>
        </div>
      ) : (
        <Button
          id="done-button-mobile"
          type="tertiary"
          size="medium"
          onClick={handleClick}
          disabled={isDisabled}
        >
          {getText('header.done')}
        </Button>
      )}
      <NoTextModal ref={noTextModalRef} />
    </>
  )
}
export default DoneButton
