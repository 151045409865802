import { JarvisWebHttpClient } from '@jarvis/web-http'
import { authProvider } from '../../auth'
import Config from '../../config'

const client: JarvisWebHttpClient = new JarvisWebHttpClient({
  defaultBaseURL: Config.STRATUS_DEVICES_URL,
  defaultAuthProvider: authProvider,
  defaultRetries: 0
})

export const getDeviceById = async (deviceId: string) => {
  const response = await client.get({
    url: `/devices/v1/${deviceId}`,
    timeout: 30000
  })

  return response.data
}

export const getDeviceByModel = async (model: string) => {
  const response = await client.get({
    url: `/devices/v1/models/${model}`,
    timeout: 30000
  })

  return response.data
}

export const getShortcutConfiguration = async (cloudId: string) => {
  const response = await client.get({
    url: `devices/v1/${cloudId}/shortcut/configuration`,
    timeout: 30000
  })

  return response.data
}

export const postShortcutConfiguration = async (
  cloudId: string,
  requestData: object
) => {
  const response = await client.post({
    url: `devices/v1/${cloudId}/shortcut/configuration`,
    data: requestData,
    timeout: 30000
  })

  return response.data
}
