import {
  CustomImage,
  IconEnvelope,
  IconPlus,
  IconPrinter,
  IconUpload,
  OptionInterface
} from '@veneer/core'
import React, { Fragment, ReactElement, Ref } from 'react'
import Images from '../../assets/images'
import { StyledContextualMenu } from '../../styles/global'
import {
  Actions,
  StyledCard,
  StyledContextualMenuButton,
  StyledIconEllipsis,
  StyledIconPlus,
  Title,
  TitleContainer
} from './styles'

const providerIcons = {
  googledrive: <CustomImage src={Images.googleDriveTile} />,
  onedrive: <CustomImage src={Images.oneDriveTile} />,
  dropbox: <CustomImage src={Images.dropBoxTile} />,
  box: <CustomImage src={Images.boxTile} />,
  evernote: <CustomImage src={Images.evernoteTile} />,
  quickbooksonline: <CustomImage src={Images.quickbooksTile} />
}

interface ActionIconsDictionary {
  print: ReactElement
  email: ReactElement
  upload: ReactElement
  addIcon: ReactElement
  plus: ReactElement
  [key: string]: ReactElement
}

const actionIcons: ActionIconsDictionary = {
  print: <IconPrinter color="gray0" />,
  email: <IconEnvelope color="gray0" />,
  upload: <IconUpload color="gray0" />,
  addIcon: (
    <IconPlus
      color="gray0"
      filled={true}
    />
  ),
  plus: (
    <StyledIconPlus
      color="gray0"
      size={16}
    />
  ),
  ...providerIcons
}

type ContentProps = {
  title: string
  contextualMenuOptions?: OptionInterface[]
  onContextualMenuOptionClick?: { (option: OptionInterface): void }
  actions: string[]
  desktopMode?: boolean
  coachmarkRef?: Ref<HTMLButtonElement>
}

const Content = ({
  title,
  contextualMenuOptions,
  onContextualMenuOptionClick,
  actions,
  desktopMode,
  coachmarkRef
}: ContentProps) => (
  <>
    <TitleContainer>
      <Title>{title}</Title>
      {contextualMenuOptions && (
        <StyledContextualMenu
          placement="leading-start"
          onClick={(e, option) => {
            e.stopPropagation()
            if (onContextualMenuOptionClick) {
              onContextualMenuOptionClick(option)
            }
          }}
          options={contextualMenuOptions}
        >
          <StyledContextualMenuButton
            desktopMode={desktopMode ?? false}
            onClick={(e) => e.stopPropagation()}
            aria-label="ContextualMenuButton"
            type="tertiary"
            icon={<StyledIconEllipsis />}
            ref={coachmarkRef}
          />
        </StyledContextualMenu>
      )}
    </TitleContainer>
    <Actions>
      {actions.map((action, index) => (
        <Fragment key={`container${index}`}>
          <Fragment key={action}>{actionIcons[action]}</Fragment>
          <Fragment key={`${action}${index}`}>
            {index !== actions.length - 1 && actionIcons['plus']}
          </Fragment>
        </Fragment>
      ))}
    </Actions>
  </>
)

type CardProps = {
  title: string
  contextualMenuOptions?: OptionInterface[]
  onContextualMenuOptionClick?: { (option: OptionInterface): void }
  onCardClick?: { (): void }
  actions: string[]
  color: string
  desktopMode?: boolean
  editCoachmarkRef?: Ref<HTMLButtonElement>
  firstShortcutCoachmarkRef?: Ref<HTMLElement>
}

export const Card = ({
  title,
  contextualMenuOptions,
  onContextualMenuOptionClick,
  onCardClick,
  actions,
  color,
  desktopMode,
  editCoachmarkRef,
  firstShortcutCoachmarkRef
}: CardProps) => (
  <StyledCard
    id={`shortcut-card-${title.replace(/[\s\W]+/g, '-').toLowerCase()}`}
    onClick={onCardClick}
    appearance="dropShadow"
    content={Content({
      title,
      contextualMenuOptions,
      onContextualMenuOptionClick,
      actions,
      desktopMode,
      coachmarkRef: editCoachmarkRef
    })}
    color={color}
    desktopMode={desktopMode}
    ref={firstShortcutCoachmarkRef}
  />
)

export default Card
