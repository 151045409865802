import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Header from '../../components/Header'
import { useAnimationContext } from '../../contexts/animations/AnimationContext'
import { useSettingsContext } from '../../contexts/settings/SettingsContext'
import { PageProps } from '../../data/schemas/app'
import { LocationState } from '../../data/schemas/location'
import { ocrLanguageConfigOptionsList } from '../../data/schemas/settings'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import {
  GenericCard,
  IconCheckCircle,
  ListContainer
} from '@clientos/ui-toolkit'
import PageTemplate from '../PageTemplate'
import { GenericCardContainer } from './styles'
import MobileScroll from 'src/components/MobileScroll'

export const OcrSettings = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()
  const location = useLocation<LocationState>()

  const { setAnimationDirection } = useAnimationContext()
  const { settingsState, settingsActions } = useSettingsContext()
  const rootPath = location.state?.session?.context?.rootPath

  const onBackButtonPress = () => {
    setAnimationDirection('left')
    history.push(`${base}/printer_settings`, {
      session: {
        context: {
          rootPath: rootPath
        }
      }
    })
  }

  const optionsList = ocrLanguageConfigOptionsList

  const options = []

  for (let i = 0; i < optionsList.length; i++) {
    const language = optionsList[i]
    options.push({
      label: language.label,
      onClick: () => {
        settingsActions.updateOcrLanguage(language.value)
      },
      end: language.value === settingsState.ocrLanguage && (
        <IconCheckCircle
          size={24}
          filled
        />
      )
    })
  }

  useNativeBackButton(onBackButtonPress)

  return (
    <>
      <PageTemplate
        header={
          <Header
            backButtonLabel={'Back'}
            onPressIconLeft={onBackButtonPress}
          />
        }
        content={
          <GenericCard>
            <GenericCard.Header title={'Select OCR Language'} />
            <GenericCard.Body
              paddingBodyBottom={false}
              paddingBodyTop={false}
              paddingBodyHorizontal={false}
            >
              <GenericCardContainer>
                {props.desktopMode ? (
                  <ListContainer items={options} />
                ) : (
                  <MobileScroll offset="200px">
                    <ListContainer items={options} />
                  </MobileScroll>
                )}
              </GenericCardContainer>
            </GenericCard.Body>
          </GenericCard>
        }
        desktopMode={props.desktopMode}
      />
    </>
  )
}
