import React, { SyntheticEvent } from 'react'
import { ListContainer, RadioButton } from '@clientos/ui-toolkit'
import { Divider, MainContainer } from './styles'
import { ListItemProps } from '@clientos/ui-toolkit/dist/types/components/list-item/types'

type FileTypeFormProps = {
  selectedItem: string
  image: string
  pdf: string
  searchablePdf: string
  searchableDescription: string
  wordDoc: string
  wordDescription: string
  plainText: string
  textDescription: string
  desktopMode: boolean
  entitled: boolean
  onClickFileType: { (e: SyntheticEvent, value: string): void }
}

const getComplementaryButtonList = (props: FileTypeFormProps) => [
  {
    value: 'searchablepdf',
    label: props.searchablePdf,
    checked: props.selectedItem === 'searchablepdf',
    description: props.searchableDescription
  },
  {
    value: 'docx',
    label: props.wordDoc,
    checked: props.selectedItem === 'docx',
    description: props.wordDescription
  },
  {
    value: 'txt',
    label: props.plainText,
    checked: props.selectedItem === 'txt',
    description: props.textDescription
  }
]

const Content = (props: FileTypeFormProps) => {
  const complementaryRadioButtons = props.entitled
    ? getComplementaryButtonList(props)
    : []

  const items = [
    {
      label: props.image,
      description: '',
      end: (
        <RadioButton
          id="jpeg-radio-btn"
          value="jpeg"
          checked={props.selectedItem === 'jpeg'}
          onClick={(e) => props.onClickFileType(e, 'jpeg')}
        />
      ),
      alignEnd: 'center',
      paddingEnd: false
    },
    {
      label: props.pdf,
      description: '',
      end: (
        <RadioButton
          id="standardpdf-radio-btn"
          value="standardpdf"
          checked={props.selectedItem === 'standardpdf'}
          onClick={(e) => props.onClickFileType(e, 'standardpdf')}
        />
      ),
      alignEnd: 'center',
      paddingEnd: false
    }
  ]

  if (complementaryRadioButtons.length > 0) {
    complementaryRadioButtons.forEach((item) => {
      items.push({
        label: item.label,
        description: item.description,
        end: (
          <RadioButton
            id={`${item.value}-radio-btn`}
            key={item.value}
            value={item.value}
            checked={props.selectedItem === item.value}
            onClick={(e) => props.onClickFileType(e, item.value)}
          />
        ),
        alignEnd: 'center',
        paddingEnd: false
      })
    })
  }

  return (
    <MainContainer>
      <ListContainer items={items as ListItemProps[]} />
      <Divider />
    </MainContainer>
  )
}

const FileTypeForm = (props: FileTypeFormProps) => Content(props)

export default FileTypeForm
