import styled from 'styled-components'

export const FolderContainer = styled.div`
  .clientos-ui-toolkit-scrollbar {
    > div {
      max-height: 100%;
    }
  }
`

export const ContentContainer = styled.div`
  width: 100%;

  .clientos-ui-toolkit-generic-card__content__header__title {
    font-size: 24px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
  }
`
